import React from "react";
import { View, FlatList, Text } from "react-native";
import { connect } from "react-redux";
import OutComingMessageField from "../UI/OutComingMessageField";
import ClientMessageField from "../UI/ClientMessageField";
import MessageInputField from "../UI/MessageInputField";
import firebase from "../../fire";
import Firebase from "firebase";
import defaultUserImage from "../../assets/profile_avatar.png";
import { setRoomName } from "../actions/chat";
import moment from "moment";
import { messagePageStyles } from "../styles/styles";
class MessagePage extends React.Component {
  state = {
    activeButtonId: 0,
    message: null,
    messageList: [],
    chatIds: [],
    usersInChat: [],
    usersSeenChat: [],
    messageToShowList: [],
  };

  changeActiveButton = (id) => {
    this.setState({ activeButtonId: id });
  };

  messageChangedHandler = (val) => {
    this.setState({ message: val });
  };

  sendMessage = async () => {
    const roomName = this.props.navigation.getParam("roomName", "1");
    const { auth, firstName, lastName } = this.props;
    const { usersInChat, usersSeenChat } = this.state;
    if (usersInChat && usersInChat.length > 0) {
      usersInChat.forEach((user) => {
        if (user.userInChat) {
          usersSeenChat.push(user.userId);
        }
      });
    }

    if (this.state.message.length > 0) {
      let msgId = firebase
        .database()
        .ref("rooms")
        .child(roomName)
        .child("messages")
        .child(auth)
        .push().key;
      let updates = {};
      let lastMessageUpdates = {};
      let message = {
        message: this.state.message,
        time: Firebase.database.ServerValue.TIMESTAMP,
        from: auth,
        name: firstName + " " + lastName,
        usersSeen: usersSeenChat,
      };
      updates["rooms/" + roomName + "/" + "messages" + "/" + msgId] = message;
      lastMessageUpdates["rooms/" + roomName + "/" + "lastMessage"] = message;
      firebase.database().ref().update(updates);
      firebase.database().ref().update(lastMessageUpdates);
      this.setState({ message: "" });
      this.setState({ usersSeenChat: [] });
    }
  };

  componentWillMount() {
    const roomName = this.props.navigation.getParam("roomName", "1");

    var d = new Date();
    var seenMessageTime = d.getTime();
    localStorage.setItem(roomName, seenMessageTime.toString());
    firebase
      .database()
      .ref("rooms")
      .child(roomName)
      .child("messages")
      .on("child_added", (value) => {
        this.setState((prevState) => {
          return {
            messageList: [...prevState.messageList, value.val()],
            chatIds: [...prevState.chatIds, value.key],
          };
        });
      });
    firebase
      .database()
      .ref("rooms")
      .child(roomName)
      .child("usersInChat")
      .on("child_added", (value) => {
        this.setState((prevState) => {
          return {
            usersInChat: [...prevState.usersInChat, value.val()],
          };
        });
      });
  }

  componentDidMount() {
    const roomName = this.props.navigation.getParam("roomName", "1");
    let msgId = this.props.auth;
    let updates = {};
    let message = {
      userId: this.props.auth,
      userInChat: true,
    };
    updates["rooms/" + roomName + "/" + "usersInChat" + "/" + msgId] = message;
    firebase.database().ref().update(updates);
    this.props.setRoomName(roomName);

    for (let i = 0; i < this.state.messageList.length - 1; i++) {
      let date1 = new Date(this.state.messageList[i].time);
      let date2 = new Date(this.state.messageList[i + 1].time);
      let messages = [];
      if (date1.toLocaleDateString() === date2.toLocaleDateString()) {
        messages.push(this.state.messageToShowList[i]);
      }
      if (date1.toLocaleDateString() != date2.toLocaleDateString()) {
        messages.push(this.state.messageToShowList[i]);
        this.setState((prevState) => {
          return {
            messageToShowList: [...prevState.messageList, date1, messages],
          };
        });
      }
    }
  }

  componentWillUnmount() {
    const roomName = this.props.navigation.getParam("roomName", "1");
    let msgId = this.props.auth;
    let updates = {};
    let message = {
      userId: this.props.auth,
      userInChat: false,
    };
    updates["rooms/" + roomName + "/" + "usersInChat" + "/" + msgId] = message;
    firebase.database().ref().update(updates);
  }

  convertTime = (time) => {
    let d = new Date(time);
    let result = (d.getHours() < 10 ? "0" : "") + d.getHours() + ":";
    result += (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    return result;
  };

  render() {
    const roomName = this.props.navigation.getParam("roomName", "1");
    const { auth } = this.props;

    return (
      <View style={messagePageStyles.container}>
        <View style={messagePageStyles.messageTitleContainerStyle}>
          <View style={messagePageStyles.titleTextContainer}>
            <Text style={messagePageStyles.titleStyle}>{roomName}</Text>
          </View>
        </View>
        <FlatList
          ref={(ref) => (this.flatList = ref)}
          onContentSizeChange={() =>
            this.flatList.scrollToEnd({ animated: true })
          }
          onLayout={() => this.flatList.scrollToEnd({ animated: true })}
          containerStyle={messagePageStyles.fieldsContainerStyle}
          data={this.state.messageList}
          keyExtractor={(item, index) => "key" + index}
          renderItem={({ item }) =>
            item.from === auth ? (
              <ClientMessageField
                message={item.message}
                time={moment.utc(item.time).fromNow()}
              />
            ) : (
              <OutComingMessageField
                image={defaultUserImage}
                message={item.message}
                time={moment.utc(item.time).fromNow()}
                name={item.name}
              />
            )
          }
        />

        <MessageInputField
          placeholderText={"Type a message ..."}
          inputValue={this.state.message}
          onChangeText={this.messageChangedHandler}
          ref="message"
          onFocus={() => this.setState({ onFocus: true })}
          onBlur={() => this.setState({ onFocus: false })}
          onSendMessasge={this.sendMessage}
          onRequestClose={() => this.setState({ onFocus: false })}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.userId,
    firstName: state.userProfileReducer.firstName,
    lastName: state.userProfileReducer.lastName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRoomName: (roomName) => dispatch(setRoomName(roomName)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MessagePage);
