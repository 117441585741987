import globalStyles from "../../../globalStyles";
import EStyleSheet from "react-native-extended-stylesheet";

const contactUsStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "80%",
    height: "100%",
    margin: "auto",
  },
  fieldsContainer: {
    width: "100%",
    paddingHorizontal: 6,
    paddingVertical: 18,
  },
  contactUsContainer: {
    backgroundColor: globalStyles.white,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 3,
    borderRadius: 9,
    marginHorizontal: 9,
  },
  inputText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.textBlue,
    fontSize: 15,
    width: "100%",
    height: "100%",
    textAlignVertical: "top",
  },
  inputBox: {
    height: 160,
    width: "100%",
    paddingHorizontal: 3,
    marginTop: 3,
  },
  confirmButtonContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: 12,
    paddingRight: 12,
    width: "100%",
  },
});

const gameRulesStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  titleTextContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  titleText: {
    paddingTop: "3rem",
    fontFamily: globalStyles.fontRobotoBold,
    color: globalStyles.textBlue,
    fontSize: 21,
  },
  articleTextContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 12,
    paddingBottom: 30,
    paddingHorizontal: 6,
  },
  articleText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.textBlue,
    fontSize: 15,
  },
});

const notificationsStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  buttonContainer: {
    flexDirection: "row",
    width: "100%",
    height: 54,
    borderBottomWidth: 0.5,
    borderBottomColor: globalStyles.borderRoom,
  },
  chatContainer: {
    paddingTop: 6,
    paddingBottom: 24,
    paddingHorizontal: 3,
  },
  noMessagesText: {
    color: globalStyles.textBlue,
    fontSize: 20,
    fontFamily: globalStyles.fontFamilyBold,
  },

  markAsReadText: {
    color: globalStyles.textBlue,
    fontSize: 16,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  markAsReadContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  markAsReadImageContainer: {
    padding: 12,
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  signInUpContainer: {
    backgroundColor: globalStyles.backgroundGrey,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 480,
    justifyContent: "center",
    paddingVertical: 6,
  },
  signInContainer: {
    backgroundColor: globalStyles.white,
    paddingHorizontal: 18,
    paddingVertical: 9,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 120,
    maxWidth: 120,
    borderTopLeftRadius: 9,
    borderBottomLeftRadius: 9,
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
    paddingHorizontal: 18,
    paddingVertical: 9,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 120,
    maxWidth: 120,
    borderTopRightRadius: 9,
    borderBottomRightRadius: 9,
  },
  signUpText: {
    color: globalStyles.white,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 15,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  signInText: {
    color: globalStyles.textBlue,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 15,
    fontFamily: globalStyles.fontUbuntuBold,
  },
});

const privacyPolicyStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  titleTextContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  titleText: {
    paddingTop: 18,
    fontFamily: globalStyles.fontRobotoBold,
    color: globalStyles.textBlue,
    fontSize: 16,
  },
  articleTextContainer: {
    paddingTop: 12,
    paddingBottom: 30,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 6,
  },
  articleText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.textBlue,
    fontSize: 12,
  },
  contactUsTitleContainer: {
    width: "100%",
    alignItems: "center",
    paddingBottom: 12,
  },
  contactUsTitleText: {
    color: globalStyles.mainBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 12,
  },
  confirmButtonContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: 12,
    paddingRight: 12,
    width: "100%",
  },
});

const profileStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  fieldsContainer: {
    width: "60%",
    marginHorizontal: "auto",
    marginTop: 90,
  },
  profileContainer: {
    width: "100vw",
    zIndex: 1,
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    paddingVertical: 15,
    backgroundColor: globalStyles.white,
  },
  profileImg: {
    height: 54,
    width: 54,
    resizeMode: "contain",
    borderRadius: 27,
  },
  profileNameMoneyContainer: {
    paddingLeft: 6,
    justifyContent: "space-between",
  },
  profileName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 16,
  },
  userNameText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 14,
  },
  prizeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 6,
  },
  prizeImg: {
    height: 15,
    width: 18,
    resizeMode: "contain",
    marginRight: 6,
  },
  prizeText: {
    color: globalStyles.basicBlueGreyColor,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 14,
  },
  dataFieldsContainer: {
    borderRadius: 9,
    justifyContent: "space-between",
    alignItems: "center",
    marginHorizontal: 12,
  },
  dataFieldNoChangeTextContainer: {
    width: "100%",
    padding: 12,
    backgroundColor: globalStyles.white,
    opacity: 0.7,
    borderBottomWidth: 1.5,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  dataFieldNoChangeText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 15,
  },
  notificationsContainer: {
    backgroundColor: globalStyles.white,
    borderRadius: 3,
    paddingHorizontal: 12,
    paddingVertical: 21,
    justifyContent: "space-between",
    height: 140,
    borderRadius: 8,
    marginHorizontal: 12,
  },
  notificationFieldContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  notificationText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 16,
    marginLeft: 12,
  },
  deleteAccountContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: 24,
  },
  deletеAccountText: {
    color: globalStyles.error,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 15,
  },
  deleteAccountBorderContainer: {
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.error,
  },
  personalDataContainer: {
    width: "100%",
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  personalDataText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 20,
  },
  footerView: {
    height: 75,
    width: "100%",
  },
  discardSaveBtnContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginVertical: 12,
  },
  discardButtonContainer: {
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    padding: 6,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: globalStyles.backgroundGrey,
    borderLeftColor: globalStyles.backgroundGrey,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  discardText: {
    fontSize: 17,
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    textAlign: "center",
  },
  saveButton: {
    backgroundColor: globalStyles.activeBlue,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    padding: 6,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: globalStyles.backgroundGrey,
    borderRightColor: globalStyles.backgroundGrey,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  saveText: {
    fontSize: 17,
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    textAlign: "center",
  },
});

const statisticsStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  fieldsContainer: {
    width: "100%",
  },
  profileContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    paddingVertical: 12,
    backgroundColor: globalStyles.white,
  },
  profileImg: {
    height: 60,
    width: 60,
    borderRadius: 30,
    resizeMode: "contain",
  },
  profileImgContainer: {},
  profileNameMoneyContainer: {
    paddingLeft: 6,
    justifyContent: "space-between",
  },
  profileName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 17,
  },
  userNameText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
  },
  prizeContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 6,
  },
  prizeImg: {
    height: 12,
    width: 30,
    resizeMode: "contain",
    marginRight: 6,
  },
  prizeText: {
    color: globalStyles.basicBlueGreyColor,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 17,
  },
  leagueStatisticsTitleContianer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    marginHorizontal: 3,
    marginBottom: 24,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  leagueStatisticsTitleText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
    textAlign: "center",
  },
  matchScoreFieldContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "15%",
    flexDirection: "row",
  },
  listItem: {
    width: "98%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingVertical: 12,
    marginHorizontal: 3,
    paddingLeft: 6,
    marginTop: 3,
  },
  leagueTitleContainer: {
    alignItems: "center",
    flexDirection: "row",
    width: "55%",
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuRegular,
    textAlignVertical: "center",
    fontSize: 17,
    height: "100%",
  },
  shieldImg: {
    height: 36,
    width: 24,
    resizeMode: "contain",
  },
});

const termsNConditionsStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  titleTextContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  titleText: {
    paddingTop: 18,
    fontFamily: globalStyles.fontRobotoBold,
    color: globalStyles.textBlue,
    fontSize: 21,
  },
  articleTextContainer: {
    paddingTop: 12,
    paddingBottom: 30,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 6,
  },
  articleText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.textBlue,
    fontSize: 15,
  },
  contactUsTitleContainer: {
    width: "100%",
    alignItems: "center",
    paddingBottom: 12,
  },
  contactUsTitleText: {
    color: globalStyles.mainBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 12,
  },
  confirmButtonContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: 12,
    paddingRight: 12,
    width: "100%",
  },
});

const drawerFieldStyles = EStyleSheet.create({
  drawerFieldContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingTop: 9,
  },
  drawerImg: {
    height: 21,
    width: 21,
    resizeMode: "contain",
    marginRight: 9,
  },
  drawerText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
  },
});

const drawerTitleFieldStyles = EStyleSheet.create({
  drawerTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 21,
    paddingTop: 15,
  },
  drawerTitleText: {
    color: globalStyles.borderRoom,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 12,
  },
});

const leagueStatisticsFieldStyles = EStyleSheet.create({
  leagueStatisticsContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  leagueNameAndImgContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 6,
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
    width: "55%",
    height: 54,
  },

  leagueImg: {
    height: 42,
    width: 42,
    resizeMode: "contain",
    marginRight: 6,
  },
  leagueNameText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 16,
  },
  matchScoreContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 6,
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
    width: "15%",
    height: 54,
  },
  matchScoreText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 16,
    opacity: 0.7,
  },
  percentText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 17,
  },
  shieldScoreContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 6,
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
    width: "15%",
    height: 54,
  },
});

const socialMediaBtnStyles = EStyleSheet.create({
  mediaFieldContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 12,
    paddingVertical: 9,
  },
  mediaImgStyle: {
    height: 24,
    width: 24,
    resizeMode: "contain",
  },
});

export {
  contactUsStyles,
  gameRulesStyles,
  notificationsStyles,
  privacyPolicyStyles,
  profileStyles,
  statisticsStyles,
  termsNConditionsStyles,
  drawerFieldStyles,
  drawerTitleFieldStyles,
  leagueStatisticsFieldStyles,
  socialMediaBtnStyles,
};
