import React from "react"
import {
    Image,
    View,
    Platform,
    TouchableOpacity
} from 'react-native';

import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import Shield from "./Shield";
import WarningImg from '../../../assets/warning.png'
import {connect} from 'react-redux';

const matchButton = props => {
    const{reactRem}=props;
    const buttonStyles = [styles.btnBackground,{height:7*reactRem}];
    if (props.isOpen) {
        buttonStyles.push({ backgroundColor: globalStyles.activeBlue,borderLeftColor:globalStyles.activeBlue })
    }
    else {
        buttonStyles.push({ backgroundColor: globalStyles.white, })
    }

    if(props.last){
        buttonStyles.push({borderBottomRightRadius:1.5*reactRem})
    }

    let betMissing=false;
    if(props.bets && props.bets.length!=0){
        for(var i=0;i<props.bets.length;i++){
            if(props.bets[i].bet==='-'){
                betMissing=true;
            }
        }
    }

    let imageToShow = props.image;
    if(betMissing&&!props.score){
        imageToShow=WarningImg;
    };
    
    return (
         <TouchableOpacity style={buttonStyles} onPress={props.onPress}>
              {props.betPoints && props.shield>0? 
                 <Shield points={props.shield}/> :
                 <Image source={imageToShow} style={[styles.btnImage,{height:3.5*reactRem,width:3*reactRem}]} />}
        </TouchableOpacity>
    );
   
}

const styles = EStyleSheet.create({
    btnBackground: {
        justifyContent:"center",
        alignItems:"center",
        borderLeftWidth:1,
        borderLeftColor: globalStyles.backgroundGrey,
        width:"7%",
        height:"7rem"
    },
    btnImage: {
        height: "3.5rem",
        width: "3rem",
        resizeMode:"contain"
    },
});

const mapStateToProps = (state) => {
    return {
        reactRem:state.homeScreenLeagues.reactRem,
    }
  }
  
  export default connect(mapStateToProps,null)(matchButton);