import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import globalStyles from "../../globalStyles";

const SpecialSideMenu = (props) => {
  const containerStyle = [
    styles.container,
    {
      paddingBottom: 12,
      paddingHorizontal: 12,
      paddingTop: 6,
      top: 96,
      right: 12,
      borderRadius: 9,
    },
  ];
  const textContainerStyle = [styles.textContainer, { paddingVertical: 6 }];
  const textStyle = [styles.text, { fontSize: 9 }];

  return (
    <View style={containerStyle}>
      <TouchableOpacity style={textContainerStyle} onPress={props.onShowInfo}>
        <Text style={textStyle}>Info</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={textContainerStyle}
        onPress={props.onShowFullPoint}
      >
        <Text style={textStyle}>
          {props.showFullPoints ? "Short-size points" : "Full-size points"}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = EStyleSheet.create({
  container: {
    position: "absolute",
    width: "35%",
    alignItems: "center",
    paddingBottom: "2rem",
    paddingHorizontal: "2rem",
    paddingTop: "1rem",
    top: "16rem",
    right: "2rem",
    borderRadius: "1.5rem",
    backgroundColor: globalStyles.white,
    borderWidth: 1,
    borderColor: globalStyles.backgroundGrey,
  },
  textContainer: {
    width: "100%",
    paddingVertical: "1rem",
  },
  text: {
    fontSize: "1.4rem",
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
  },
});

export default SpecialSideMenu;
