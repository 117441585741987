import React from "react";
import { View, Text } from "react-native";
import { noStartedMatchContainer } from "../styles/styles";

class NoStartedMatchContainer extends React.Component {
  render() {
    return (
      <View style={noStartedMatchContainer.playerInfoContainer}>
        <View style={noStartedMatchContainer.playerPositionContainer}>
          <Text style={noStartedMatchContainer.positionText}></Text>
        </View>
        <View style={noStartedMatchContainer.playerNameBetContainer}>
          <Text style={noStartedMatchContainer.betPlayerText}>
            The match has not started
          </Text>
        </View>
      </View>
    );
  }
}

export default NoStartedMatchContainer;
