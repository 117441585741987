import React from 'react';
import {
    Text,
    View,
    Platform,
    Image
} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';

class StandingsField extends React.Component {
    render() {
        const {
            id,
            teamImg,
            teamName,
            matches,
            matchesWin,
            matchesDraw,
            matchesLose,
            goalDifference,
            points,
            index,
            length,
            champLeaguePromotion,
            europeLeaguePromotion,
            relegationPromotion,
            reactRem } = this.props;
        let clubName = teamName;
        if (teamName.length > 10 && /\s/.test(teamName)) {
            const splitClubName = teamName.split(" ");
            clubName = splitClubName[0] + "\n" + splitClubName[1];
        }

        const tableTeamContainerStyle = [styles.tableTeamContainer, { height: 8 * reactRem }];
        const leaguePositionFieldStyle = [styles.leaguePositionField, { height: 8 * reactRem, width: 8 * reactRem }];

        if (index === length - 1) {
            tableTeamContainerStyle.push(styles.lastContainer);
            tableTeamContainerStyle.push({ borderBottomLeftRadius: 1.5 * reactRem, borderBottomRightRadius: 1.5 * reactRem })
            leaguePositionFieldStyle.push(styles.lastPositionContainer);
            leaguePositionFieldStyle.push({ borderBottomLeftRadius: 1.5 * reactRem })
        }
        if (champLeaguePromotion) {
            tableTeamContainerStyle.push({ backgroundColor: globalStyles.promotionGreen });
        } else if (europeLeaguePromotion) {
            tableTeamContainerStyle.push({ backgroundColor: globalStyles.euroLeagueRed });
        } else if (relegationPromotion) {
            tableTeamContainerStyle.push({ backgroundColor: globalStyles.relegationRed });
        }

        return (
            <View style={tableTeamContainerStyle}>
                <View style={styles.teamRankContainer}>
                    <View style={leaguePositionFieldStyle}>
                        <Text style={[styles.leaguePositionText, { fontSize: 2.4 * reactRem }]}>{id}</Text>
                    </View>
                    <Image source={{ uri: teamImg }} style={[styles.teamImg, {
                        height: 5.5 * reactRem,
                        width: 5.5 * reactRem,
                        marginHorizontal: 0.5 * reactRem
                    }]} />
                    <View style={[styles.teamNameContainer, { height: 8 * reactRem, marginLeft: reactRem }]}>
                        <Text style={[styles.teamNameText, { fontSize: 2.4 * reactRem }]}>{clubName}</Text>
                    </View>
                </View>
                <View style={[styles.rankingStatsContainer, { paddingHorizontal: reactRem }]}>
                    <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>{matches}</Text>
                    <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>{matchesWin}</Text>
                    <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>{matchesDraw}</Text>
                    <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>{matchesLose}</Text>
                    <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>{goalDifference}</Text>
                    <View style={[styles.teamPointsContainer, {
                        height: 3 * reactRem,
                        width: 4 * reactRem,
                        borderRadius: 0.5 * reactRem
                    }]}>
                        <Text style={[styles.teamNameText, { fontSize: 2.4 * reactRem }]} >{points}</Text>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = EStyleSheet.create({
    tableTeamContainer: {
        height: "8rem",
        width: "100%",
        flexDirection: "row",
        backgroundColor: globalStyles.white,
        justifyContent: "space-between",
        alignItems: "center",
        borderBottomWidth: 0.5,
        borderBottomColor: globalStyles.backgroundGrey
    },
    teamRankContainer: {
        flex: 4,
        flexDirection: "row",
        alignItems: "center"
    },
    leaguePositionField: {
        height: "8rem",
        width: "8rem",
        justifyContent: "center",
        alignItems: "center",
        borderRightWidth: 1,
        borderRightColor: globalStyles.backgroundGrey,
        borderBottomWidth: 0.5,
        borderBottomColor: globalStyles.backgroundGrey
    },
    leaguePositionText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuMedium,
        fontSize: "2.4rem"
    },
    teamImg: {
        height: "5.5rem",
        width: "5.5rem",
        marginHorizontal: "0.5rem",
        resizeMode: "contain"
    },
    teamNameText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuMedium,
        fontSize: "2.4rem"
    },
    teamPointsContainer: {
        height: "3rem",
        width: "4rem",
        borderRadius: "0.5rem",
        justifyContent: "center",
        alignItems: "center"
    },
    defaultText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuMedium,
        fontSize: "2.4rem",
        opacity: 0.7
    },
    rankingStatsContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: "1rem",
        width: "52%"
    },
    teamNameContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: "8rem",
        marginLeft: "1rem"
    },
    lastContainer: {
        borderBottomLeftRadius: "1.5rem",
        borderBottomRightRadius: "1.5rem",
    },
    lastPositionContainer: {
        borderBottomLeftRadius: "1.5rem",
    },
});
export default StandingsField;