import React from "react";
import {Animated} from "react-native";




class AnimationTeamContainer extends React.Component {

  state = {
    paddingHorizontal: new Animated.Value(40),
    stateHeight: new Animated.Value(this.props.defaultHeight),
  };

  _start = () => {
    Animated.timing(this.state.paddingHorizontal, {
        toValue: 0,
        duration: 1400
      }).start();
      Animated.timing(this.state.stateHeight, {
        toValue: this.props.heightTo,
        duration: 1400
      }).start();
  };


  
  _start2 = () => {
    Animated.timing(this.state.paddingHorizontal, {
        toValue: 40,
        duration: 1400
      }).start();
      Animated.timing(this.state.stateHeight, {
        toValue: this.props.defaultHeight,
        duration: 1400
      }).start();
  };


  componentDidUpdate(prevProps){
    if(this.props.offset!=0){
        this._start();
    }
    
    if(prevProps.offset&& prevProps.offset>0&& this.props.offset===0){
        this._start2();
    }
  }


  render() {
    return (    
        <Animated.View
          style={[{
            paddingHorizontal:this.state.paddingHorizonta,
            height:this.state.stateHeight,
          },this.props.style]}
        >
        {this.props.children}
    </Animated.View>
    );
  }
}

export default AnimationTeamContainer;