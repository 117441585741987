import firebase from 'firebase'
var config = { 
    apiKey: "AIzaSyD-hOBStNbJWmM7s8KjdhzP0zk6A9hN6BY",
    authDomain: "glowter-9eadd.firebaseapp.com",
    databaseURL: "https://glowter-9eadd.firebaseio.com",
    projectId: "glowter-9eadd",
    storageBucket: "glowter-9eadd.appspot.com",
    messagingSenderId: "514363847583",
    appId: "1:514363847583:web:afbfea6215e47ea3",
    measurementId: "G-9XB4Z1J073"
};
var fire = firebase;
if (!firebase.apps.length) {
    fire = firebase.initializeApp(config);
}
export default fire;