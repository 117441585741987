import { GET_STANDINGS_LEAGUE_DATA } from "../actions/actionTypes";

const initialState = {
  leagueImg: null,
  leagueName: null,
  rankings: null,
  staging: false,
  adverts: [],
};

const standings = (state = initialState, action) => {
  switch (action.type) {
    case GET_STANDINGS_LEAGUE_DATA: {
      return {
        ...state,
        leagueImg: action.data.leagueImg,
        leagueName: action.data.leagueName,
        rankings: action.data.rankings,
        staging: action.data.staging,
        adverts: action.data.banner,
      };
    }
    default:
      return state;
  }
};

export default standings;
