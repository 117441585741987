import React from "react";
import { View, FlatList, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import globalStyles from "../../globalStyles";
import NavButton from "../../Common/components/UI/HomeNavButton";
import SpecialGamesFree from "../UI/SpecialGamesFree";
import {
  getMySpecialRooms,
  getUpcomingSpecialRooms,
  getHitorySpecialRooms,
  joinFromSpecial,
} from "../actions/specialGames";
import { setSpecialViewHeigh } from "../../Home/actions/homeScreenLeagues";
import { specialGamesPageStyles } from "../styles/styles";
import SpecialGamesIndicator from "../UI/SpecialGamesIndicator";
import { onOpenRegister, authSetToken } from "../../Login/actions/auth";
import LeagueAd from "../../Common/LeagueList/LeagueAd";

class SpecialGamesPage extends React.Component {
  state = {
    activeButtonId: 0,
    refreshing: false,
    type: "FREE",
    duration: "UPCOMING",
  };

  _onRefresh = () => {
    this.setState({ refreshing: true });
    if (this.props.userId) {
      this.props.getMySpecialRooms(this.state.type);
      this.props.getUpcomingSpecialRooms(this.state.type);
      this.props.getHitorySpecialRooms(this.state.type);
    }
  };

  onLoginPressed = () => {
    this.props.onOpenRegister(true, false);
    window.location.replace("/login");
  };

  componentDidUpdate(prevProps) {
    if (prevProps.addLeagueSuccesfull != this.props.addLeagueSuccesfull) {
      if (this.props.userId) {
        this.props.getMySpecialRooms(this.state.type);
        this.props.getUpcomingSpecialRooms(this.state.type);
        this.props.getHitorySpecialRooms(this.state.type);
      }
    }
    if (this.state.refreshing) {
      this.setState({ refreshing: false });
    }

    if (this.props.navigation) {
      const fromJoint = this.props.navigation.getParam("fromJoin", null);
      if (fromJoint && !this.props.joinProps) {
        this.props.joinFromSpecial("join");
        this.props.navigation.navigate("PredictSpecialGames", {
          roomId: this.props.navigation.getParam("roomId", -1),
          rounds: this.props.navigation.getParam("rounds", null),
          special: true,
        });
      }
    }
  }

  changeActiveButton = (id) => {
    if (id === 0) {
      this.setState({ activeButtonId: id, type: "FREE" });
    }
    if (id === 1) {
      this.setState({ activeButtonId: id, type: "PAID" });
    }
  };

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    this.goToTop();
    if (localStorage.getItem("token")) {
      this.props.getMySpecialRooms(this.state.type);
      this.props.getUpcomingSpecialRooms(this.state.type);
      this.props.getHitorySpecialRooms(this.state.type);
      this.props.authSetToken(localStorage.getItem("token"));
    }
  }

  onLayout = (e) => {
    this.props.setSpecialViewHeigh(e.nativeEvent.layout.height);
  };
  render() {
    const {
      userId,
      mySpecialGames,
      upcomingSpecialGames,
      historySpecialGames,
      myAdverts,
      upcomingAdverts,
      historyAdverts,
    } = this.props;

    if (userId) {
      return (
        <View style={specialGamesPageStyles.container} onLayout={this.onLayout}>
          <View style={specialGamesPageStyles.buttonContainer}>
            <NavButton
              id={0}
              activeButtonId={this.state.activeButtonId}
              onPress={() => this.changeActiveButton(0)}
              title={"Free Games"}
            />
          </View>
          {this.state.activeButtonId === 1 ? (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                backgroundColor: globalStyles.backgroundGrey,
              }}
            >
              <View style={specialGamesPageStyles.commingSoonContainer}>
                <Text style={specialGamesPageStyles.commingSoonText}>
                  Coming soon...
                </Text>
              </View>
            </View>
          ) : (
            <View
              refreshing={this.state.refreshing}
              onRefresh={this._onRefresh}
              style={specialGamesPageStyles.fieldsContainer}
            >
              <View
                style={
                  upcomingSpecialGames && upcomingSpecialGames.length != 0
                    ? { height: 500 }
                    : { height: 50 }
                }
              >
                <SpecialGamesIndicator title={"Upcoming"} />
                {upcomingSpecialGames && upcomingSpecialGames.length != 0 ? (
                  <FlatList
                    style={specialGamesPageStyles.flatListContainer}
                    data={upcomingSpecialGames}
                    keyExtractor={(item, index) => "key" + index}
                    renderItem={({ item }) =>
                      this.state.activeButtonId === 0 ? (
                        <SpecialGamesFree
                          {...item}
                          type={this.state.type}
                          duration={this.state.duration}
                          navigation={this.props.navigation}
                        />
                      ) : null
                    }
                  />
                ) : (
                  <FlatList
                    style={specialGamesPageStyles.flatListContainer}
                    data={upcomingAdverts}
                    keyExtractor={(item, index) => "key" + index}
                    renderItem={({ item }) => (
                      <LeagueAd
                        banner={upcomingAdverts}
                        navigation={this.props.navigation}
                      />
                    )}
                  />
                )}
                {upcomingSpecialGames &&
                upcomingSpecialGames.length === 1 &&
                upcomingAdverts &&
                upcomingAdverts.length != 0 ? (
                  <LeagueAd
                    banner={upcomingAdverts}
                    navigation={this.props.navigation}
                  />
                ) : null}
              </View>
              <View
                style={
                  mySpecialGames && mySpecialGames.length != 0
                    ? { height: 500 }
                    : { height: 50 }
                }
              >
                <SpecialGamesIndicator title={"My Rooms"} />
                {mySpecialGames && mySpecialGames.length != 0 ? (
                  <FlatList
                    style={specialGamesPageStyles.flatListContainer}
                    data={mySpecialGames}
                    keyExtractor={(item, index) => "key" + index}
                    renderItem={({ item }) =>
                      this.state.activeButtonId === 0 ? (
                        <SpecialGamesFree
                          {...item}
                          type={this.state.type}
                          duration={this.state.duration}
                          navigation={this.props.navigation}
                        />
                      ) : null
                    }
                  />
                ) : (
                  <FlatList
                    style={specialGamesPageStyles.flatListContainer}
                    data={myAdverts}
                    keyExtractor={(item, index) => "key" + index}
                    renderItem={({ item }) => (
                      <LeagueAd
                        banner={myAdverts}
                        navigation={this.props.navigation}
                      />
                    )}
                  />
                )}
                {mySpecialGames &&
                mySpecialGames.length === 1 &&
                myAdverts &&
                myAdverts.length != 0 ? (
                  <LeagueAd
                    banner={myAdverts}
                    navigation={this.props.navigation}
                  />
                ) : null}
              </View>
              <View
                style={
                  historySpecialGames && historySpecialGames.length != 0
                    ? { height: 500 }
                    : { height: 50 }
                }
              >
                <SpecialGamesIndicator title={"My History"} />
                {historySpecialGames && historySpecialGames.length != 0 ? (
                  <FlatList
                    style={specialGamesPageStyles.flatListContainer}
                    data={historySpecialGames}
                    keyExtractor={(item, index) => "key" + index}
                    renderItem={({ item }) =>
                      this.state.activeButtonId === 0 ? (
                        <SpecialGamesFree
                          {...item}
                          type={this.state.type}
                          duration={this.state.duration}
                          navigation={this.props.navigation}
                        />
                      ) : null
                    }
                  />
                ) : (
                  <FlatList
                    style={specialGamesPageStyles.flatListContainer}
                    data={historyAdverts}
                    keyExtractor={(item, index) => "key" + index}
                    renderItem={({ item }) => (
                      <LeagueAd
                        banner={historyAdverts}
                        navigation={this.props.navigation}
                      />
                    )}
                  />
                )}
                {historySpecialGames &&
                historySpecialGames.length === 1 &&
                historyAdverts &&
                historyAdverts.length != 0 ? (
                  <LeagueAd
                    banner={historyAdverts}
                    navigation={this.props.navigation}
                  />
                ) : null}
              </View>
            </View>
          )}
        </View>
      );
    } else {
      return (
        <View style={specialGamesPageStyles.container} onLayout={this.onLayout}>
          <View
            refreshing={this.state.refreshing}
            onRefresh={this._onRefresh}
            style={specialGamesPageStyles.fieldsContainer}
          >
            <View style={specialGamesPageStyles.signInUpContainer}>
              <TouchableOpacity onPress={() => this.onLoginPressed()}>
                <View style={specialGamesPageStyles.signInContainer}>
                  <Text style={specialGamesPageStyles.signInText}>SIGN IN</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.onLoginPressed()}>
                <View style={specialGamesPageStyles.signUpContainer}>
                  <Text style={specialGamesPageStyles.signUpText}>SIGN UP</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    addLeagueSuccesfull: state.predictions.addLeagueSuccesfull,
    token: state.auth.token,
    userId: state.auth.userId,
    mySpecialGames: state.specialGames.mySpecialGames,
    upcomingSpecialGames: state.specialGames.upcomingSpecialGames,
    historySpecialGames: state.specialGames.historySpecialGames,
    myAdverts: state.specialGames.myAdverts,
    upcomingAdverts: state.specialGames.upcomingAdverts,
    historyAdverts: state.specialGames.historyAdverts,
    joinProps: state.specialGames.joinFromSpecial,
    navigation: state.specialGames.navigation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMySpecialRooms: (type) => dispatch(getMySpecialRooms(type)),
    getUpcomingSpecialRooms: (type) => dispatch(getUpcomingSpecialRooms(type)),
    getHitorySpecialRooms: (type) => dispatch(getHitorySpecialRooms(type)),
    onOpenRegister: (loginscreen, register) =>
      dispatch(onOpenRegister(loginscreen, register)),
    joinFromSpecial: (data) => dispatch(joinFromSpecial(data)),
    authSetToken: (token) => dispatch(authSetToken(token)),
    setSpecialViewHeigh: (specialHeight) =>
      dispatch(setSpecialViewHeigh(specialHeight)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SpecialGamesPage);
