import React from "react"
import {
    Text,
    View,
    Platform,
    ActivityIndicator,
    TouchableOpacity
} from 'react-native';

import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';

const floatingButton = props => {
    const { reactRem } = props;
    const buttonStyle = [styles.button, { padding: reactRem, borderRadius: 1.5 * reactRem }];
    if (props.style) {
        buttonStyle.push(props.style)
    }
    return (
        <View style={[styles.container, { paddingBottom: 3 * reactRem, }]}>
            <View style={buttonStyle}>
                <TouchableOpacity onPress={() => !props.buttonIsPress && props.onPress()} >
                    {props.buttonIsPress ? <ActivityIndicator size="small" color={globalStyles.white} /> :
                        <Text style={[styles.text, { fontSize: 2.8 * reactRem }]}>{props.children}</Text>}
                </TouchableOpacity>
            </View>
        </View>
    );

}

const styles = EStyleSheet.create({
    container: {
        width: "100%",
        alignItems: "center",
        paddingBottom: "3rem",
    },
    button: {
        backgroundColor: globalStyles.activeBlue,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: "40%",
        padding: "1rem",
        borderRadius: "1.5rem",
    },
    text: {
        fontSize: "2.8rem",
        color: globalStyles.white,
        fontFamily: globalStyles.fontUbuntuBold,
        textAlign: "center"
    },
    blurButton: {
        opacity: 0.5
    }
});

export default floatingButton;