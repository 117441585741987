const images = {
  ac_milan: require("../../assets/clubs/ac-milan.png.png"),
  alaves: require("../../assets/clubs/alaves.png.png"),
  amiens: require("../../assets/clubs/amiens.png.png"),
  angers: require("../../assets/clubs/angers.png.png"),
  arda: require("../../assets/clubs/arda.png.png"),
  arsenal: require("../../assets/clubs/arsenal.png.png"),
  as_roma: require("../../assets/clubs/as-roma.png.png"),
  aston_villa: require("../../assets/clubs/aston-villa.png.png"),
  atalanta: require("../../assets/clubs/atalanta.png.png"),
  ath_bilbao: require("../../assets/clubs/ath-bilbao.png.png"),
  atl_madrid: require("../../assets/clubs/atl-madrid.png.png"),
  augsburg: require("../../assets/clubs/augsburg.png.png"),
  b_monchengladbach: require("../../assets/clubs/b.-monchengladbach.png.png"),
  barcelona: require("../../assets/clubs/barcelona.png.png"),
  bayer_leverkusen: require("../../assets/clubs/bayer-leverkusen.png.png"),
  bayern_munich: require("../../assets/clubs/bayern-munich.png.png"),
  beroe: require("../../assets/clubs/beroe.png.png"),
  betis: require("../../assets/clubs/betis.png.png"),
  bistritsa: require("../../assets/clubs/bistritsa.png.png"),
  bologna: require("../../assets/clubs/bologna.png.png"),
  bordeaux: require("../../assets/clubs/bordeaux.png.png"),
  botev_plovdiv: require("../../assets/clubs/botev-plovdiv.png.png"),
  botev_vratsa: require("../../assets/clubs/botev-vratsa.png.png"),
  bournemouth: require("../../assets/clubs/bournemouth.png.png"),
  brescia: require("../../assets/clubs/brescia.png.png"),
  brest: require("../../assets/clubs/brest.png.png"),
  brighton: require("../../assets/clubs/brighton.png.png"),
  burnley: require("../../assets/clubs/burnley.png.png"),
  cagliari: require("../../assets/clubs/cagliari.png.png"),
  celta_vigo: require("../../assets/clubs/celta-vigo.png.png"),
  chelsea: require("../../assets/clubs/chelsea.png.png"),
  cherno_more: require("../../assets/clubs/cherno-more.png.png"),
  crystal_palace: require("../../assets/clubs/crystal-palace.png.png"),
  cska_sofia: require("../../assets/clubs/cska-sofia.png.png"),
  dijon: require("../../assets/clubs/dijon.png.png"),
  dortmund: require("../../assets/clubs/dortmund.png.png"),
  dunav_ruse: require("../../assets/clubs/dunav-ruse.png.png"),
  dusseldorf: require("../../assets/clubs/dusseldorf.png.png"),
  eibar: require("../../assets/clubs/eibar.png.png"),
  eintracht_frankfurt: require("../../assets/clubs/eintracht-frankfurt.png.png"),
  espanyol: require("../../assets/clubs/espanyol.png.png"),
  etar: require("../../assets/clubs/etar.png.png"),
  everton: require("../../assets/clubs/everton.png.png"),
  fc_koln: require("../../assets/clubs/fc-koln.png.png"),
  fiorentina: require("../../assets/clubs/fiorentina.png.png"),
  freiburg: require("../../assets/clubs/freiburg.png.png"),
  genoa: require("../../assets/clubs/genoa.png.png"),
  getafe: require("../../assets/clubs/getafe.png.png"),
  granada_cf: require("../../assets/clubs/granada-cf.png.png"),
  hertha_berlin: require("../../assets/clubs/hertha-berlin.png.png"),
  hoffenheim: require("../../assets/clubs/hoffenheim.png.png"),
  inter: require("../../assets/clubs/inter.png.png"),
  juventus: require("../../assets/clubs/juventus.png.png"),
  lazio: require("../../assets/clubs/lazio.png.png"),
  lecce: require("../../assets/clubs/lecce.png.png"),
  leganes: require("../../assets/clubs/leganes.png.png"),
  leicester: require("../../assets/clubs/leicester.png.png"),
  levante: require("../../assets/clubs/levante.png.png"),
  levski_sofia: require("../../assets/clubs/levski.png.png"),
  lille: require("../../assets/clubs/lille.png.png"),
  liverpool: require("../../assets/clubs/liverpool.png.png"),
  lok_plovdiv: require("../../assets/clubs/lok.-plovdiv.png.png"),
  ludogorets: require("../../assets/clubs/ludogorets.png.png"),
  lyon: require("../../assets/clubs/lyon.png.png"),
  mainz: require("../../assets/clubs/mainz.png.png"),
  mallorca: require("../../assets/clubs/mallorca.png.png"),
  manchester_city: require("../../assets/clubs/manchester-city.png.png"),
  manchester_utd: require("../../assets/clubs/manchester-united.png.png"),
  marseille: require("../../assets/clubs/marseille.png.png"),
  metz: require("../../assets/clubs/metz.png.png"),
  monaco: require("../../assets/clubs/monaco.png.png"),
  montpellier: require("../../assets/clubs/montpellier.png.png"),
  nantes: require("../../assets/clubs/nantes.png.png"),
  napoli: require("../../assets/clubs/napoli.png.png"),
  newcastle: require("../../assets/clubs/newcastle.png.png"),
  nice: require("../../assets/clubs/nice.png.png"),
  nimes: require("../../assets/clubs/nimes.png.png"),
  norwich: require("../../assets/clubs/norwich.png.png"),
  osasuna: require("../../assets/clubs/osasuna.png.png"),
  paderborn: require("../../assets/clubs/paderborn.png.png"),
  paris_sg: require("../../assets/clubs/paris-sg.png.png"),
  parma: require("../../assets/clubs/parma.png.png"),
  rb_leipzig: require("../../assets/clubs/rb-leipzig.png.png"),
  real_madrid: require("../../assets/clubs/real-madrid.png.png"),
  real_sociedad: require("../../assets/clubs/real-sociedad.png.png"),
  reims: require("../../assets/clubs/reims.png.png"),
  rennes: require("../../assets/clubs/rennes.png.png"),
  sampdoria: require("../../assets/clubs/sampdoria.png.png"),
  sassuolo: require("../../assets/clubs/sassuolo.png.png"),
  schalke: require("../../assets/clubs/schalke.png.png"),
  sevilla: require("../../assets/clubs/sevilla.png.png"),
  sheffield_utd: require("../../assets/clubs/sheffield-utd.png.png"),
  slavia_sofia: require("../../assets/clubs/slavia-sofia.png.png"),
  southampton: require("../../assets/clubs/southampton.png.png"),
  spal: require("../../assets/clubs/spal.png.png"),
  st_etienne: require("../../assets/clubs/st-etienne.png.png"),
  strasbourg: require("../../assets/clubs/strasbourg.png.png"),
  torino: require("../../assets/clubs/torino.png.png"),
  tottenham: require("../../assets/clubs/tottenham.png.png"),
  toulouse: require("../../assets/clubs/toulouse.png.png"),
  tsarsko_selo: require("../../assets/clubs/tsarsko-selo.png.png"),
  udinese: require("../../assets/clubs/udinese.png.png"),
  union_berlin: require("../../assets/clubs/union-berlin.png.png"),
  valencia: require("../../assets/clubs/valencia.png.png"),
  valladolid: require("../../assets/clubs/valladolid.png.png"),
  verona: require("../../assets/clubs/verona.png.png"),
  villarreal: require("../../assets/clubs/villarreal.png.png"),
  watford: require("../../assets/clubs/watford.png.png"),
  werder_bremen: require("../../assets/clubs/werder-bremen.png.png"),
  west_ham: require("../../assets/clubs/west-ham.png.png"),
  wolfsburg: require("../../assets/clubs/wolfsburg.png.png"),
  wolves: require("../../assets/clubs/wolves.png.png"),
};
export default images;
