
class ImageInterval {


    addListeners = (navigation, method, time) => {
        if (method) {
            clearInterval(this._imageInterval);
            this._imageInterval = setInterval(() => {
                method();
            }, time * 1000);

        }
    }

    clearListeners = () => {
        clearInterval(this._imageInterval);
    }
}


export default ImageInterval;

