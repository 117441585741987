import React from "react";
import { View, Text, TouchableOpacity, Image, FlatList } from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import settingImg from './assets/side_menu_profile.png';
import statisticsImg from './assets/side_menu_statistics.png';
import paymentImg from './assets/side_menu_budget.png';
import logoutImg from './assets/side_menu_logout.png';
import avatarImg from './assets/profile_avatar.png';
import globalStyles from './globalStyles';
import arrowDownImg from './assets/home_arrow_down_old.png';
import { connect } from 'react-redux';
import { authSetToken, deleteUser } from './Login/actions/auth';
import { deleteProfile } from './Common/Drawer/actions/profile';
import { deleteHomescreenData } from './Home/actions/homeScreenLeagues';
// import { LoginManager } from 'react-native-fbsdk';
import { deleteChatData } from './Chat/actions/chat';
import { deleteRoomData } from './Room/actions/rooms';
import { onOpenRegister } from './Login/actions/auth';

import flagImg from './assets/images/flag_bulgaria.png'
import flagFr from './assets/images/france.png';
import flagEn from './assets/images/united_kingdom.png';
import flagGr from './assets/images/germany.png';
import flagIt from './assets/images/italy.png';


class LangaugeMenu extends React.Component {
    state = {
        languages: [{ image: flagEn, language: "English", initials: "En" },
        { image: flagGr, language: "German", initials: "De" },
        { image: flagFr, language: "French", initials: "Fr" },
        { image: flagIt, language: "Italian", initials: "It" },
        { image: flagImg, language: "Bulgarian", initials: "Bg" },],
    }
    onPressSettings = () => {
        const { userId } = this.props;
        this.props.onShowMenu();
        window.location.replace("/profile/" + userId);

    }

    onPressStatistics = () => {
        const { userId } = this.props;
        this.props.onShowMenu();
        window.location.replace("/statistics/" + userId);
    }

    onPickLanguage = (language) => {
        this.props.onSetLanguage(language);
        this.props.onShowMenu();
    }

    logout = async () => {
        // LoginManager.logOut()
        this.props.onShowMenu();
        this.props.deleteUser();
        this.props.deleteProfile();
        this.props.deleteHomescreenData();
        this.props.deleteChatData();
        this.props.deleteRoomData();
        await localStorage.removeItem('token');
        // Window.localStorage.getAllKeys().then(keys => {
        //   Window.localStorage.multiRemove(keys).then(err => {
        //     Window.localStorage.setItem('tutorial', "true");
        //     this.props.onOpenRegister(true,false);
        //     this.props.authSetToken(null);
        //     this.props.navigation.navigate('Login',{ signIn:true });
        //   });
        // });

        localStorage.clear();

        // const currentUser = await GoogleSignin.getCurrentUser();
        //   if(currentUser){
        //     try {
        //       await GoogleSignin.revokeAccess();
        //       await GoogleSignin.signOut();
        //     } catch (error) {
        //       console.error(error);
        //     }
        // }
        window.location.replace("/login");
    }
    render() {

        const { reactRem } = this.props;

        const menuContainerStyle = [styles.menuContainer, { maxHeight: 60 * reactRem, borderRadius: reactRem }];
        const flagImgStyle = [styles.flagImg, {
            height: 3 * reactRem,
            width: 3 * reactRem,
            marginRight: reactRem
        }];
        const pickerContainerStyle = [styles.pickerContainer, {
            paddingHorizontal: 2 * reactRem,
            paddingVertical: reactRem
        }]

        return (
            <View style={menuContainerStyle}>
                <FlatList
                    data={this.state.languages}
                    keyExtractor={(item, index) => 'key' + index}
                    renderItem={({ item }) => (
                        <TouchableOpacity style={pickerContainerStyle} onPress={() => this.onPickLanguage(item)}>
                            <View style={styles.nationalityContainer}>
                                <Image source={item.image} style={flagImgStyle} />
                                <Text style={styles.chooseLeagueText}>{item.language}</Text>
                            </View>
                        </TouchableOpacity>
                    )} />
            </View>
        );
    }
}

const styles = EStyleSheet.create({
    menuContainer: {
        left: "89.5%",
        top: "10rem",
        position: "absolute",
        width: "10%",
        backgroundColor: globalStyles.white,
        zIndex: 1,
        maxHeight: "60rem",
        borderRadius: "1rem",
        borderWidth: 1,
        borderColor: globalStyles.backgroundGrey
    },
    flagImg: {
        height: "3rem",
        width: "3rem",
        resizeMode: "contain",
        marginRight: "1rem"
    },
    pickerContainer: {
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: "2rem",
        paddingVertical: "1rem",
        flexDirection: "row",
    },
    nationalityContainer: {
        flexDirection: "row",
        alignItems: "center",
    },
});

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.auth.token,
        userId: state.auth.userId,
        register: state.auth.register,
        error: state.errorReducer.error,
        isLoading: state.loadingReducer.isLoading,
        isNewUser: state.auth.isNewUser,
        leagues: state.auth.leagues,
        genders: state.auth.genders,
        nationalities: state.auth.nationalities,
        firstName: state.userProfileReducer.firstName,
        lastName: state.userProfileReducer.lastName,
        reactRem: state.homeScreenLeagues.reactRem,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        authSetToken: token => dispatch(authSetToken(token)),
        deleteUser: () => dispatch(deleteUser()),
        deleteProfile: () => dispatch(deleteProfile()),
        deleteHomescreenData: () => dispatch(deleteHomescreenData()),
        deleteChatData: () => dispatch(deleteChatData()),
        deleteRoomData: () => dispatch(deleteRoomData()),
        onOpenRegister: (loginscreen, register) => dispatch(onOpenRegister(loginscreen, register)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LangaugeMenu);