import React from "react";
import { TouchableOpacity, View, Text, Image } from "react-native";
import globalStyles from "../../globalStyles";
import PredictionDot from "../../assets/prediction_dot.png";
import { predictionGWFieldStyles } from "../styles/styles";

class PredictionGWField extends React.Component {
  render() {
    const {
      gameWeek,
      timeUntilStart,
      isPredicted,
      isPassed,
      token,
      matches,
      predictedMatches,
      isCurrentGameWeek,
      areBetsAllowed,
      remainingAllowedMatchesForBet,
      isTotalPassed,
      endOfSeason,
    } = this.props;

    const predictButtonText = [predictionGWFieldStyles.predictButtonText];
    const predictButtonContainer = [
      predictionGWFieldStyles.predictButtonContainer,
    ];

    let buttonText = "PREDICT";
    let underButtonText = predictedMatches + "/" + matches;
    if (isTotalPassed || (isPassed && !isCurrentGameWeek)) {
      predictButtonContainer.push(predictionGWFieldStyles.finishedContainer);
      predictButtonText.push({ color: globalStyles.white });
      buttonText = "VIEW";
      underButtonText = "finished";
    } else if (
      !isTotalPassed &&
      isPredicted &&
      matches === predictedMatches &&
      areBetsAllowed
    ) {
      predictButtonContainer.push(predictionGWFieldStyles.finishedContainer);
      predictButtonText.push({ color: globalStyles.white });
      buttonText = "PREDICTED";
      underButtonText = predictedMatches + "/" + matches;
    } else if (
      !isTotalPassed &&
      isPredicted &&
      areBetsAllowed &&
      timeUntilStart === "Started" &&
      remainingAllowedMatchesForBet === 0
    ) {
      predictButtonContainer.push(predictionGWFieldStyles.finishedContainer);
      predictButtonText.push({ color: globalStyles.white });
      buttonText = "PREDICTED";
      underButtonText = predictedMatches + "/" + matches;
    } else if (
      !isTotalPassed &&
      isPredicted &&
      remainingAllowedMatchesForBet === 0 &&
      areBetsAllowed
    ) {
      predictButtonContainer.push(predictionGWFieldStyles.finishedContainer);
      predictButtonText.push({ color: globalStyles.white });
      buttonText = "PREDICTED";
      underButtonText = predictedMatches + "/" + matches;
    } else if (
      !isTotalPassed &&
      isPredicted &&
      predictedMatches != matches &&
      areBetsAllowed
    ) {
      predictButtonContainer.push({ backgroundColor: globalStyles.activeBlue });
      predictButtonText.push({ color: globalStyles.white });
      buttonText = "PREDICT";
      underButtonText = predictedMatches + "/" + matches;
    } else {
      predictButtonContainer.push(
        predictionGWFieldStyles.borderRadiusContainer
      );
    }

    return (
      <View>
        {endOfSeason ? (
          <View style={predictionGWFieldStyles.gameWeekContainer}>
            <View style={predictionGWFieldStyles.endofSeasonTextContainer}>
              <Text style={predictionGWFieldStyles.endofSeasonText}>
                END OF SEASON
              </Text>
            </View>
            <View style={predictionGWFieldStyles.betTextContainer}>
              <Text
                style={predictionGWFieldStyles.textUnderPredictButton}
              ></Text>
            </View>
          </View>
        ) : (
          <View style={predictionGWFieldStyles.gameWeekContainer}>
            <View style={predictionGWFieldStyles.gameWeekTextContainer}>
              <View style={predictionGWFieldStyles.predictionDotContainer}>
                <Text style={predictionGWFieldStyles.gameWeekText}>
                  {gameWeek}
                </Text>
                {isCurrentGameWeek ? (
                  <Image
                    source={PredictionDot}
                    style={predictionGWFieldStyles.predictionDotImg}
                  />
                ) : null}
              </View>
              {timeUntilStart && buttonText !== "VIEW" ? (
                <Text style={predictionGWFieldStyles.timePeriodText}>
                  {timeUntilStart}
                </Text>
              ) : (
                <Text style={predictionGWFieldStyles.timePeriodText}> </Text>
              )}
            </View>
            <View style={predictionGWFieldStyles.predictionContainer}>
              <TouchableOpacity
                style={predictButtonContainer}
                onPress={() => this.props.onPress()}
              >
                <Text style={predictButtonText}>{buttonText}</Text>
              </TouchableOpacity>
              <View style={predictionGWFieldStyles.betTextContainer}>
                <Text style={predictionGWFieldStyles.textUnderPredictButton}>
                  {" "}
                  {token ? underButtonText : null}
                </Text>
              </View>
            </View>
          </View>
        )}
      </View>
    );
  }
}

export default PredictionGWField;
