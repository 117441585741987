export const SELECT_ROOM = 'SELECT_ROOM';
export const SET_DATA_FOR_LEAGUE = 'SET_DATA_FOR_LEAGUE';
export const SET_JOINED_ROOMS_DATA = 'SET_JOINED_ROOMS_DATA';
export const SET_SELECTED_ROOM_DATA = "SET_SELECTED_ROOM_DATA";
export const SET_PREDICTION_DATA = 'SET_PREDICTION_DATA';
export const CREATE_ROOM = "CREATE_ROOM";
export const JOIN_ROOM = "JOIN_ROOM";
export const DELETE_ROOM_CODE = "DELETE_ROOM_CODE";
export const DELETE_ROOM_DATA = "DELETE_ROOM_DATA";
export const SET_H2H_ROOMS_DATA = "SET_H2H_ROOMS_DATA";
export const SET_PRIVATE_ROOMS_DATA = "SET_PRIVATE_ROOMS_DATA";
export const SET_LEAGUES_WITH_GAME_WEEKS_PREDICTION = 'SET_LEAGUES_WITH_GAME_WEEKS_PREDICTION';
export const SET_LEAGUE_DATA_BY_GAME_WEEK_PREDICTION = "SET_LEAGUE_DATA_BY_GAME_WEEK_PREDICTION";
export const DELETE_LEAGUES_GW = 'DELETE_LEAGUES_GW';
export const GET_USER_PREDICTIONS_BY_ID = "GET_USER_PREDICTIONS_BY_ID";
export const GET_SELECTED_USER_PREDICTIONS_BY_ID = "GET_SELECTED_USER_PREDICTIONS_BY_ID";
export const LEAVE_ROOM = "ROOM_LEAVE";
export const SET_PRIORITY_MESSAGE = "SET_PRIORITY_MESSAGE";
export const SET_SPECIAL_USER_DATA = "SET_SPECIAL_USER_DATA";
export const SET_SPECIAL_LOGGED_USER_DATA = "SET_SPECIAL_LOGGED_USER_DATA";
export const SET_LEAGUES_IN_ROOM = "SET_LEAGUES_IN_ROOM";
export const ROOMS_TO_LEAVE = 'ROOMS_TO_LEAVE';
