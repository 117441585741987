import React from "react";
import { View, TouchableOpacity, Text, Image, ScrollView } from "react-native";
import SpecialGamesMatchesContainer from "./SpecialGamesMatchesContainer";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import GoalImg from "../../assets/livescore/goal.png";
import { specialGamesShowMatchesStyles } from "../styles/styles";

class SpecialGamesShowMatches extends React.Component {
  onCloseModal = () => {
    this.props.onChangeModalVisibility();
  };

  render() {
    const { isModalVisible, specialGamesMatches, reactRem } = this.props;

    return (
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <TouchableOpacity
          style={specialGamesShowMatchesStyles.specialGamesButton}
          onPress={() => {
            this.props.onChangeModalVisibility();
          }}
        >
          <Image
            source={GoalImg}
            style={specialGamesShowMatchesStyles.goalImg}
          />
          <Text style={specialGamesShowMatchesStyles.confirmButton}>
            VIEW MATCHES
          </Text>
        </TouchableOpacity>
        {specialGamesMatches ? (
          <Modal open={isModalVisible} onClose={() => this.onCloseModal()}>
            <TouchableOpacity
              style={specialGamesShowMatchesStyles.mainContainer}
              onPress={() => this.props.onChangeModalVisibility()}
            >
              <ScrollView
                style={specialGamesShowMatchesStyles.alertContainer}
                contentContainerStyle={{ alignItems: "center" }}
              >
                <View style={specialGamesShowMatchesStyles.titleContainer}>
                  <Text style={specialGamesShowMatchesStyles.title}>
                    {specialGamesMatches.roomName.toUpperCase()}
                  </Text>
                </View>
                {specialGamesMatches.matches.leagues.map((item, index) => (
                  <SpecialGamesMatchesContainer
                    {...item}
                    reactRem={reactRem}
                    key={index}
                  />
                ))}
              </ScrollView>
            </TouchableOpacity>
          </Modal>
        ) : null}
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    specialGamesMatches: state.specialGames.specialGamesMatches,
  };
};

export default connect(mapStateToProps)(SpecialGamesShowMatches);
