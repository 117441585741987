import React from "react";
import { View, FlatList, Text, Platform } from "react-native";
import NavButton from "../../Common/components/UI/HomeNavButton";
import LeagueList from "../../Common/LeagueList/LeagueList";
import { connect } from "react-redux";
import {
  getHomeScreenData,
  getHomeScreenDataTodayMatches,
  getHomeScreenDataLive,
  getHomeScreenDataNoToken,
  getHomeScreenDataTodayMatchesNoToken,
  getHomeScreenDataLiveNoToken,
  setLeaguesWithGameWeeks,
  getHomeScreenDataForGameWeek,
  getHomeScreenDataForGameWeekNoToken,
  getDataForLeagueGW,
  setViewHeight,
} from "../actions/homeScreenLeagues";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import IntervalUpdater from "../../Common/components/UI/IntervalUpdater";
import {
  getJoinedRoomDataNoToken,
  getJoinedRoomData,
  getH2HRoomData,
  getPrivateRoomData,
} from "../../Room/actions/rooms";
import { setUnseenChats, getNotificationData } from "../../Chat/actions/chat";
import firebase from "../../fire";
import { setNavigation } from "../../SpecialGames/actions/specialGames";
import { getLeagues, onOpenRegister } from "../../Login/actions/auth";
import { homeStyles } from "../styles/styles";

class HomeScreen extends React.Component {
  state = {
    activeButtonId: 0,
    refreshing: false,
    scrollDisabled: false,
    roomSelected: 0,
    isLeagueGameWeekChanged: false,
    noMatches: false,
    checkForMatches: false,
    rooms: [],
    userRooms: [],
    windowWidth: 1000,
    fetchStartingData: false,
  };

  constructor(props) {
    super(props);
    this.intervalUpdater = new IntervalUpdater();
  }

  _onRefresh = () => {
    this.setState({ refreshing: true });
    this.onGetHomeScreenData();
    this.setState({
      roomSelected: 0,
      isLeagueGameWeekChanged: false,
      noMatches: false,
    });
  };

  onChangeLeuageGameWeek = () => {
    if (!this.state.isLeagueGameWeekChanged) {
      this.setState({ isLeagueGameWeekChanged: true });
    }
  };

  changeActiveButton = (id) => {
    const { todayMatches, liveMatches } = this.props;

    this.setState({ activeButtonId: id, noMatches: false });
    if (id === 1 && todayMatches === 0)
      this.setState({ noMatches: true, noMatches: true });
    else if (id === 2 && liveMatches === 0)
      this.setState({ noMatches: true, noMatches: true });

    this.intervalUpdater.clearListeners();
    this.intervalUpdater.addListeners(
      this.props.navigation,
      this.onGetHomeScreenData
    );
  };

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    this.goToTop();
    this.setState({ windowWidth: window.innerWidth });
    this.props.setNavigation(this.props.navigation);

    this.intervalUpdater.clearListeners();
    this.intervalUpdater.addListeners(
      this.props.navigation,
      this.onGetHomeScreenData
    );
    this.props.getLeagues();
    let dbRef = firebase.database().ref("rooms");
    dbRef.on("value", (val) => {
      let roomsFromDb = [];
      val.forEach((child) => {
        roomsFromDb.push(child.val());
      });
      this.setState({ rooms: roomsFromDb });
    });
  }
  componentWillUnmount() {
    this.intervalUpdater.clearListeners();
  }
  componentDidUpdate(prevProps, prevState) {
    const { leagues, leaguesGW } = this.props;

    if (this.state.refreshing) {
      this.setState({ refreshing: false });
    }
    //In the componentDidMount the userId isn't loaded still. If the user is logged here you can see it.
    if (this.state.fetchStartingData === false) {
      if (this.props.userId) {
        this.props.getH2HRoomData();
        this.props.getPrivateRoomData();
        this.props.getJoinedRoomData();
      } else {
        this.props.getJoinedRoomDataNoToken();
      }
      this.onGetHomeScreenData();
      this.setState({ fetchStartingData: true });
    }
    if (this.state.isLeagueGameWeekChanged) {
      if (this.props.userId) {
        this.props.getHomeScreenDataForGameWeek(this.props.leaguesGW);
        this.props.getJoinedRoomData();
      } else {
        this.props.getHomeScreenDataForGameWeekNoToken(this.props.leaguesGW);
        this.props.getJoinedRoomDataNoToken();
      }
      this.setState({ isLeagueGameWeekChanged: false });
    }
    if (prevProps.leagues && leagues) {
      for (let index = 0; index < prevProps.leagues.length; index++) {
        if (
          prevProps.leagues[index] &&
          leagues[index] &&
          prevProps.leagues[index].id != leagues[index].id
        ) {
          const leaguesWithGameWeeks = [];
          leagues.forEach((league) => {
            leaguesWithGameWeeks.push({
              api_football_id: league.id,
              game_week: league.gameWeek,
            });
          });
          this.props.setLeaguesWithGameWeeks(leaguesWithGameWeeks);
        }
      }
    }
    if (!leaguesGW && leagues) {
      const leaguesWithGameWeeks = [];
      leagues.forEach((league) => {
        leaguesWithGameWeeks.push({
          api_football_id: league.id,
          game_week: league.gameWeek,
        });
      });
      this.props.setLeaguesWithGameWeeks(leaguesWithGameWeeks);
    }
    if (prevProps.leaguesGW) {
      for (var i = 0; i < prevProps.leaguesGW.length; i++) {
        if (prevProps.leaguesGW[i] != this.props.leaguesGW[i]) {
          this.props.getDataForLeagueGW(this.props.leaguesGW[i], i);
          this.intervalUpdater.clearListeners();
          this.intervalUpdater.addListeners(
            this.props.navigation,
            this.onGetHomeScreenData
          );
          break;
        }
      }
    }

    const { globalRooms, headToHeadRooms, privateRooms } = this.props;

    if (
      prevState.rooms !== this.state.rooms ||
      (this.state.userRooms.length === 0 && this.state.rooms.length != 0)
    ) {
      let userRooms = [];
      let unseenChats = 0;
      if (globalRooms) {
        for (var i = 0; i < this.state.rooms.length; i++) {
          for (var j = 0; j < globalRooms.length; j++) {
            if (globalRooms[j].nameOfRoom === this.state.rooms[i].name) {
              userRooms.push(this.state.rooms[i]);
              break;
            }
          }
        }
      }
      if (headToHeadRooms) {
        for (var i = 0; i < this.state.rooms.length; i++) {
          for (var j = 0; j < headToHeadRooms.length; j++) {
            if (headToHeadRooms[j].nameOfRoom === this.state.rooms[i].name) {
              userRooms.push(this.state.rooms[i]);
              break;
            }
          }
        }
      }
      if (privateRooms) {
        for (var i = 0; i < this.state.rooms.length; i++) {
          for (var j = 0; j < privateRooms.length; j++) {
            if (privateRooms[j].nameOfRoom === this.state.rooms[i].name) {
              userRooms.push(this.state.rooms[i]);
              break;
            }
          }
        }
      }
      if (
        userRooms.length != 0 &&
        !this.props.unseenChatsInReducer &&
        this.props.userId
      ) {
        const promises = [];
        for (let index = 0; index < userRooms.length; index++) {
          if (localStorage.getItem(userRooms[index].name)) {
            promises.push(localStorage.getItem(userRooms[index].name));
            if (userRooms[index].name === this.props.inRoom) {
              return;
            }
            if (localStorage.getItem(userRooms[index].name)) {
              if (
                localStorage.getItem(userRooms[index].name) <
                userRooms[index].lastMessage.time
              ) {
                unseenChats += 1;
              }
            } else {
              if (userRooms[index].lastMessage.from) {
                unseenChats += 1;
              }
            }
          }
        }

        Promise.all(promises).then(() => {
          this.props.setUnseenChats(unseenChats);
          this.props.getNotificationData(1);
          this.setState({ userRooms: userRooms });
        });
      }
    }
  }

  onGetHomeScreenData = () => {
    if (this.props.userId) {
      this.props.getHomeScreenData(this.state.firebaseToken);
      this.props.getHomeScreenDataTodayMatches();
      this.props.getHomeScreenDataLive();
    } else {
      this.props.getHomeScreenDataNoToken();
      this.props.getHomeScreenDataTodayMatchesNoToken();
      this.props.getHomeScreenDataLiveNoToken();
    }
  };

  onRoomSelected = (roomId) => {
    this.setState({ roomSelected: roomId });
  };

  onLayout = (e) => {
    this.props.setViewHeight(e.nativeEvent.layout.height);
  };

  render() {
    const { todayMatches, liveMatches, leagues, navigation } = this.props;

    const { activeButtonId, windowWidth } = this.state;

    let noMatchesText;
    if (this.state.activeButtonId === 1) {
      noMatchesText = "There are no matches today";
    }
    if (this.state.activeButtonId === 2) {
      noMatchesText = "There are no matches at the moment";
    }
    if (!leagues && activeButtonId === 0) {
      return <LoadingScreen />;
    }

    return (
      <View style={homeStyles.container} onLayout={this.onLayout}>
        <View style={homeStyles.buttonContainer}>
          <NavButton
            id={0}
            activeButtonId={this.state.activeButtonId}
            onPress={() => this.changeActiveButton(0)}
            onGetData={() => null}
            title={"Matches"}
          />
          <NavButton
            id={1}
            activeButtonId={this.state.activeButtonId}
            onPress={() => this.changeActiveButton(1)}
            onGetData={() => null}
            title={"Today"}
            todayMatches={todayMatches}
            style={homeStyles.borderRight}
          />
          <NavButton
            id={2}
            activeButtonId={this.state.activeButtonId}
            onPress={() => this.changeActiveButton(2)}
            onGetData={() => null}
            title={"Live"}
            liveMatches={liveMatches}
          />
        </View>
        {this.state.noMatches ? (
          <View style={homeStyles.noMatchesContainer}>
            <View
              style={[
                windowWidth > 650
                  ? {
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: 600,
                    }
                  : { justifyContent: "center", alignItems: "center", flex: 1 },
              ]}
            >
              <Text style={homeStyles.noMatchesText}>{noMatchesText}</Text>
            </View>
          </View>
        ) : (
          <View style={{ width: "100%", flex: 1 }}>
            <FlatList
              style={homeStyles.leaguesContainer}
              data={
                this.state.activeButtonId === 0
                  ? leagues
                  : this.state.activeButtonId === 1
                  ? this.props.todayMatchesHomeScreen
                  : this.props.liveMatchesHomeScreen
              }
              roomSelected={this.state.roomSelected}
              keyExtractor={(item, index) => "key" + index}
              renderItem={({ item, index }) => (
                <LeagueList
                  index={index}
                  {...item}
                  betPoints={false}
                  navigation={navigation}
                  onRoomSelected={this.onRoomSelected}
                  roomSelected={this.state.roomSelected}
                  onChangeLeuageGameWeek={this.onChangeLeuageGameWeek}
                  isAllMatches={this.state.activeButtonId === 0 ? true : false}
                />
              )}
            />
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    leagues: state.homeScreenLeagues.leagues,
    todayMatches: state.homeScreenLeagues.todayMatches,
    liveMatches: state.homeScreenLeagues.liveMatches,
    leaguesGW: state.homeScreenLeagues.leaguesGW,
    userId: state.auth.userId,
    globalRooms: state.privateRooms.globalRooms,
    headToHeadRooms: state.privateRooms.headToHeadRooms,
    privateRooms: state.privateRooms.privateRooms,
    inRoom: state.chatReducer.inRoom,
    unseenChatsInReducer: state.chatReducer.unseenChats,
    liveMatchesHomeScreen: state.homeScreenLeagues.liveMatchesHomeScreen,
    todayMatchesHomeScreen: state.homeScreenLeagues.todayMatchesHomeScreen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHomeScreenData: (firebaseToken) =>
      dispatch(getHomeScreenData(firebaseToken)),
    getHomeScreenDataTodayMatches: () =>
      dispatch(getHomeScreenDataTodayMatches()),
    getHomeScreenDataLive: () => dispatch(getHomeScreenDataLive()),
    getHomeScreenDataNoToken: () => dispatch(getHomeScreenDataNoToken()),
    getHomeScreenDataTodayMatchesNoToken: () =>
      dispatch(getHomeScreenDataTodayMatchesNoToken()),
    getHomeScreenDataLiveNoToken: () =>
      dispatch(getHomeScreenDataLiveNoToken()),
    setLeaguesWithGameWeeks: (leagues) =>
      dispatch(setLeaguesWithGameWeeks(leagues)),
    getHomeScreenDataForGameWeek: (leaguesGW) =>
      dispatch(getHomeScreenDataForGameWeek(leaguesGW)),
    getHomeScreenDataForGameWeekNoToken: (leaguesGW) =>
      dispatch(getHomeScreenDataForGameWeekNoToken(leaguesGW)),
    getJoinedRoomDataNoToken: () => dispatch(getJoinedRoomDataNoToken()),
    getJoinedRoomData: () => dispatch(getJoinedRoomData()),
    getH2HRoomData: () => dispatch(getH2HRoomData()),
    getPrivateRoomData: () => dispatch(getPrivateRoomData()),
    setUnseenChats: (unseenChats) => dispatch(setUnseenChats(unseenChats)),
    getLeagues: () => dispatch(getLeagues()),
    getNotificationData: (page) => dispatch(getNotificationData(page)),
    onOpenRegister: (loginscreen, register) =>
      dispatch(onOpenRegister(loginscreen, register)),
    setNavigation: (navigation) => dispatch(setNavigation(navigation)),
    setViewHeight: (height) => dispatch(setViewHeight(height)),
    getDataForLeagueGW: (league, index) =>
      dispatch(getDataForLeagueGW(league, index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
