import { createStore, combineReducers,compose, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import homeScreenLeagueReducer from '../../Home/reducers/homeScreenLeagues';
import liveMatchReducer from '../../Home/reducers/livematch';
import futureMatchReducer   from '../../Home/reducers/futurematch';
import privateRoomReducer from '../../Room/reducers/privaterooms';
import roomLeagueReducer from '../../Room/reducers/roomleague';
import roomPredictionReducer from '../../Room/reducers/roompredictions';
import standingReducer from '../../Home/reducers/standings';
import leaguelistReducer from './reducers/leaguelist';
import predictionsReducer from '../../Prediction/reducers/predictions';
import predictionsByWeekReducer from '../../Prediction/reducers/predictionsbyweek';
import auth from '../../Login/reducers/auth';
import loadingReducer from '../store/reducers/ui';
import errorReducer from '../store/reducers/error';
import userProfileReducer from '../Drawer/reducers/profile';
import userStatisticsReducer from '../Drawer/reducers/statistics';
import chatReducer from '../../Chat/reducers/chat';
import specialGamesReducer from '../../SpecialGames/reducers/specialGames';
import resourcesReducer from './reducers/resources';

const rootReducer = combineReducers({
    homeScreenLeagues: homeScreenLeagueReducer,
    standings:standingReducer,
    liveMatches: liveMatchReducer,
    futureMatch: futureMatchReducer,
    privateRooms: privateRoomReducer,
    roomLeague: roomLeagueReducer,
    roomPrediction: roomPredictionReducer,
    leaguelist:leaguelistReducer,
    predictions:predictionsReducer,
    predictionsByWeek:predictionsByWeekReducer,
    auth:auth,
    loadingReducer:loadingReducer,
    errorReducer:errorReducer,
    userProfileReducer:userProfileReducer,
    userStatisticsReducer:userStatisticsReducer,
    chatReducer:chatReducer,
    specialGames:specialGamesReducer,
    resources:resourcesReducer,
});


let composeEnhancers = compose;

if (window.__DEV__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const configureStore = () =>{
    return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};

export default configureStore;