import React from "react";
import { View, Image, Text, TouchableHighlight, TouchableOpacity } from "react-native";
import { whileStatement } from "@babel/types";
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles'



class HeadToHeadContainer extends React.Component {

  render() {
    const { headToHead, homeTeamName, guestTeamName, leagueId, reactRem } = this.props;
    return (
      <View style={[styles.headToHeadContainer, { marginTop: 3 * reactRem, paddingHorizontal: 2 * reactRem }]}>
        <View style={[styles.headToHeadTextContainer, { paddingLeft: reactRem, marginBottom: reactRem }]}>
          <Text style={[styles.headToHeadText, { fontSize: 2.6 * reactRem }]}>HEAD TO HEAD</Text>
          <Text style={[styles.lastMatchesText, { fontSize: 2.2 * reactRem }]}> - last matches</Text>
        </View>
        <View style={[styles.teamLastMatchesContainer, { borderRadius: 1.5 * reactRem }]}>
          <View style={[styles.homeTeamContainer, { height: 18 * reactRem, paddingVertical: 2 * reactRem }]}>
            <View style={styles.statsContainer}>
              <Text style={[styles.statsNumber, { fontSize: 4.2 * reactRem }]}>{headToHead.homeTeamWins}</Text>
            </View>
            <Image source={{ uri: headToHead.homeTeamImg }} style={[styles.teamImage, { height: 5.5 * reactRem, width: 5.5 * reactRem }]} />
          </View>
          <View style={[styles.drawContainer, { height: 18 * reactRem, paddingVertical: 2 * reactRem }]}>
            <View style={styles.statsContainer}>
              <Text style={[styles.statsNumber, { fontSize: 4.2 * reactRem }]}>{headToHead.draws}</Text>
            </View>
            <View style={[styles.drawTextContainer, { height: 5 * reactRem }]}>
              <Text style={[styles.drawText, { fontSize: 2.4 * reactRem }]}>DRAWS</Text>
            </View>
          </View>
          <View style={[styles.guestTeamContainer, { height: 18 * reactRem, paddingVertical: 2 * reactRem }]}>
            <View style={styles.statsContainer}>
              <Text style={[styles.statsNumber, { fontSize: 4.2 * reactRem }]}>{headToHead.guestTeamWins}</Text>
            </View>
            <Image source={{ uri: headToHead.guestTeamImg }} style={[styles.teamImage, { height: 5.5 * reactRem, width: 5.5 * reactRem }]} />
          </View>
        </View>
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  headToHeadContainer: {
    marginTop: "3rem",
    paddingHorizontal: "2rem",
  },
  headToHeadTextContainer: {
    flexDirection: "row",
    paddingLeft: "1rem",
    marginBottom: "1rem",
  },
  headToHeadText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: '2.6rem'
  },
  lastMatchesText: {
    color: globalStyles.textGrey,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: "2.2rem"
  },
  teamLastMatchesContainer: {
    flexDirection: "row",
    backgroundColor: globalStyles.white,
    borderRadius: "1.5rem",
    justifyContent: "space-around",
    width: "100%",
  },
  homeTeamContainer: {
    justifyContent: "space-around",
    alignItems: "center",
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
    flex: 1,
    height: "18rem",
    paddingVertical: "2rem"
  },
  statsContainer: {
    alignItems: "center",
    justifyContent: "center"
  },
  statsNumber: {
    color: globalStyles.black,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: "4.2rem"
  },
  teamImage: {
    height: "5.5rem",
    width: "5.5rem",
  },
  drawContainer: {
    justifyContent: "space-around",
    alignItems: "center",
    flex: 1,
    height: "18rem",
    paddingVertical: "2rem"
  },
  drawTextContainer: {
    height: "5rem",
    alignItems: "center",
    justifyContent: "center"
  },
  drawText: {
    color: globalStyles.black,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: "2.4rem"
  },
  guestTeamContainer: {
    justifyContent: "space-around",
    alignItems: "center",
    borderLeftWidth: 1,
    borderLeftColor: globalStyles.backgroundGrey,
    flex: 1,
    height: "18rem",
    paddingVertical: "2rem"
  },
});


export default HeadToHeadContainer