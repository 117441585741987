import {
  SELECT_ROOM,
  SET_DATA_FOR_LEAGUE,
  SET_JOINED_ROOMS_DATA,
  SET_SELECTED_ROOM_DATA,
  SET_PREDICTION_DATA,
  CREATE_ROOM,
  JOIN_ROOM,
  DELETE_ROOM_CODE,
  DELETE_ROOM_DATA,
  SET_H2H_ROOMS_DATA,
  SET_PRIVATE_ROOMS_DATA,
  SET_LEAGUES_WITH_GAME_WEEKS_PREDICTION,
  SET_LEAGUE_DATA_BY_GAME_WEEK_PREDICTION,
  DELETE_LEAGUES_GW,
  GET_USER_PREDICTIONS_BY_ID,
  GET_SELECTED_USER_PREDICTIONS_BY_ID,
  LEAVE_ROOM,
  SET_PRIORITY_MESSAGE,
  SET_SPECIAL_USER_DATA,
  SET_SPECIAL_LOGGED_USER_DATA,
  SET_LEAGUES_IN_ROOM,
  ROOMS_TO_LEAVE,
} from "./actionTypes";
import { authFetch, authFetchNoToken } from "../../Common/store/actions/token";
import { uiStartLoading, uiStopLoading } from "../../Common/store/actions/ui";
import { setError } from "../../Common/store/actions/errors";

export const setRoomsToLeave = (roomsToLeave) => {
  return {
    type: ROOMS_TO_LEAVE,
    data: roomsToLeave,
  };
};

export const setLeaguesInRoom = (leaguesInRoom) => {
  return {
    type: SET_LEAGUES_IN_ROOM,
    data: leaguesInRoom,
  };
};

export const selectRoom = (id) => {
  return {
    type: SELECT_ROOM,
    roomId: id,
  };
};
export const setLeaguesWithGameWeeks = (leaguesGW) => {
  return {
    type: SET_LEAGUES_WITH_GAME_WEEKS_PREDICTION,
    leaguesGW: leaguesGW,
  };
};

export const setLeagueDataByGameWeekPrediction = (leagueId, gameWeek) => {
  const data = { league_id: leagueId, game_week: gameWeek };
  return {
    type: SET_LEAGUE_DATA_BY_GAME_WEEK_PREDICTION,
    data,
  };
};
export const getDataForLeague = (roomId, leagueId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/user/rooms/" + roomId + "?league=" + leagueId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setDataForLeague(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setDataForLeague = (data) => {
  return {
    type: SET_DATA_FOR_LEAGUE,
    data,
  };
};

export const getJoinedRoomData = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/user/rooms"))
      .catch((err) => console.log(err.message))
      .then((res) => {
        dispatch(setJoinedRoomData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getH2HRoomData = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/user/rooms?type=H2H"))
      .catch((err) => console.log(err.message))
      .then((res) => {
        dispatch(setH2HRoomData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const leaveRoom = (rooms) => {
  let data = { rooms: rooms };
  if (!rooms) {
    data = {};
  }
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/leaveRoom", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(onLeaveRoom(res.message));
        dispatch(uiStopLoading());
      });
  };
};

export const onLeaveRoom = (data) => {
  return {
    type: LEAVE_ROOM,
    data,
  };
};

export const getPrivateRoomData = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/user/rooms?type=PRIVATE"))
      .catch((err) => console.log(err.message))
      .then((res) => {
        dispatch(setPrivateRoomData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getJoinedRoomDataNoToken = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetchNoToken("/user/rooms"))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setJoinedRoomData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setJoinedRoomData = (data) => {
  return {
    type: SET_JOINED_ROOMS_DATA,
    data,
  };
};

export const setH2HRoomData = (data) => {
  return {
    type: SET_H2H_ROOMS_DATA,
    data,
  };
};

export const setRoomsPriority = (rooms, room_type) => {
  let data = { rooms: rooms, room_type: room_type };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/room/priority", "PUT", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setPriorityMessage(res.message));
        dispatch(uiStopLoading());
      });
  };
};

export const setPriorityMessage = (data) => {
  return {
    type: SET_PRIORITY_MESSAGE,
    data,
  };
};

export const setPrivateRoomData = (data) => {
  return {
    type: SET_PRIVATE_ROOMS_DATA,
    data,
  };
};

export const getSelectedRoomData = (roomId, page) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(
      authFetch(
        "/user/rooms/" + roomId + (page !== undefined ? "?page=" + page : "")
      )
    )
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setSelectedRoomData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getSelectedRoomDataNoToken = (roomId, page) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(
      authFetchNoToken(
        "/user/rooms/" + roomId + (page !== undefined ? "?page=" + page : "")
      )
    )
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setSelectedRoomData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setSelectedRoomData = (data) => {
  return {
    type: SET_SELECTED_ROOM_DATA,
    data,
  };
};

export const getSpecialSelectedUserPredictionData = (
  roomId,
  leagues,
  userId
) => {
  let data = { leagues: leagues };
  if (!leagues) {
    data = {};
  }

  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/specialroom/" + roomId + "/userId/" + userId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setSpecialSelectedUserPredictionData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getSelectedUserPredictionData = (roomId, leagues, userId) => {
  let data = { leagues: leagues };
  if (!leagues) {
    data = {};
  }
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(
      authFetch(
        "/users/points?room_id=" + roomId + "&user_id=" + userId,
        "POST",
        data
      )
    )
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setSelectedUserPredictionData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setUserPredictionData = (data) => {
  return {
    type: GET_USER_PREDICTIONS_BY_ID,
    data,
  };
};

export const setSpecialSelectedUserPredictionData = (data) => {
  return {
    type: SET_SPECIAL_USER_DATA,
    data,
  };
};
export const setSelectedUserPredictionData = (data) => {
  return {
    type: GET_SELECTED_USER_PREDICTIONS_BY_ID,
    data,
  };
};
export const getPredictionData = (roomId, leagues) => {
  let data = { leagues: leagues };
  if (!leagues) {
    data = {};
  }
  return (dispatch) => {
    uiStartLoading();
    dispatch(authFetch("/user/rooms/predictions/" + roomId, "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setPredictionData(res.data, roomId));
        dispatch(uiStopLoading());
      });
  };
};
export const getPredictionDataNoToken = (roomId, leagues) => {
  let data = { leagues: leagues };
  if (!leagues) {
    data = {};
  }
  return (dispatch) => {
    uiStartLoading();
    dispatch(
      authFetchNoToken("/user/rooms/predictions/" + roomId, "POST", data)
    )
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setPredictionData(res.data, roomId));
        dispatch(uiStopLoading());
      });
  };
};

export const getPredictionSpecialRoomsData = (roomId, leagueId, gameWeek) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(
      authFetch(
        "/specialroom/predictions/" +
          roomId +
          "?league_id=" +
          leagueId +
          "&game_week=" +
          gameWeek
      )
    )
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setPredictionData(res.data, roomId));
        dispatch(uiStopLoading());
      });
  };
};

export const setPredictionData = (data, roomId) => {
  return {
    type: SET_PREDICTION_DATA,
    data,
    roomId,
  };
};

export const joinRoom = (roomCode) => {
  const data = { privateRoomCode: roomCode };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/join", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        if (res.has_error) {
          dispatch(setError(res.message));
        } else {
          dispatch(setJoinRoomCode(res.data));
        }
        dispatch(uiStopLoading());
      });
  };
};

export const setJoinRoomCode = (data) => {
  return {
    type: JOIN_ROOM,
    data,
  };
};

export const createRoom = (
  privateRoomName,
  leagues,
  knockoutRound,
  players
) => {
  const data = {
    privateRoomName: privateRoomName,
    leagues: leagues,
    knockOutRounds: knockoutRound,
    players: players,
  };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/room", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        if (res.has_error) {
          dispatch(setError("This room name exists"));
        } else {
          dispatch(setCreateRoomCode(res.data));
          dispatch(setError(""));
        }
        dispatch(uiStopLoading());
      });
  };
};

export const setCreateRoomCode = (data) => {
  return {
    type: CREATE_ROOM,
    data,
  };
};

export const deleteRoomCode = () => {
  return {
    type: DELETE_ROOM_CODE,
  };
};

export const deleteRoomData = () => {
  return {
    type: DELETE_ROOM_DATA,
  };
};

export const deleteLeagueGW = () => {
  return {
    type: DELETE_LEAGUES_GW,
  };
};
