import React from 'react';
import { TouchableWithoutFeedback, View, Text, Image, TouchableOpacity } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import HappyFace from '../../../assets/happy_face.png';
import SadFace from '../../../assets/sad_face.png';
import ClearImg from '../../../assets/clear.png';
import { connect } from 'react-redux';
import { setLeaveRoom, setLeaveIsPressed } from '../../store/actions/resources';
import { leaveRoom, setRoomsToLeave } from '../../../Room/actions/rooms';
import Modal from '@material-ui/core/Modal';

class AlertDialogWithConfirmButton extends React.Component {



    onGotItButtonPress = () => {
        if (this.props.fromLeave) {
            this.props.onChangeModalVisibility()
        }
        else if (this.props.fromSpecial) {
            this.props.onChangeModalVisibility()
            this.props.onCloseGameRoom();
        }
        else if (this.props.fromPredict) {
            this.props.onChangeModalVisibility()
            this.props.onCloseGameRoom();
        }
        else {
            if (this.props.navigation) {
                this.props.navigation.goBack();
            }
            this.props.onChangeModalVisibility();
            this.props.onCloseGameRoom();
        }
    }

    onLeaveRoomDiscard = () => {
        this.props.setLeaveRoom(false);
        this.props.onChangeModalVisibility();
        if (this.props.navigation) {
            this.props.navigation.goBack();
        }
    }

    onLeaveRoomPressed = () => {
        if (this.props.leaveRoom) {
            this.props.setRoomsToLeave(null)
            this.props.setLeaveIsPressed(true);
            this.props.onCloseGameRoom();
            this.props.onLeaveRoom(this.props.rooms);
            this.props.onChangeModalVisibility();
            if (this.props.navigation) {
                this.props.navigation.goBack();
            }
        } else {
            this.props.onCloseGameRoom();
            this.props.onChangeModalVisibility();
            window.location.replace("/login");
        }

    }

    render() {
        const { isModalVisible, onChangeModalVisibility, title, navigation, descriptionText, leaveRoom, fromSpecial, reactRem, deleteAccount } = this.props;
        const mainContainerStyle = [styles.mainContainer, { borderRadius: 1.5 * reactRem }];
        const alertContainerStyle = [styles.alertContainer, {
            paddingTop: reactRem,
            paddingBottom: 3 * reactRem,
            paddingHorizontal: 2 * reactRem,
            borderRadius: 1.5 * reactRem,
        }];
        const titleContainerStyle = [styles.titleContainer, {
            paddingBottom: 2 * reactRem,
            marginVertical: 4 * reactRem
        }];
        const titleStyle = [styles.title, { fontSize: 3.2 * reactRem }];
        const buttonContainerStyle = [styles.buttonContainer, {
            paddingVertical: 2 * reactRem,
            paddingHorizontal: 8 * reactRem,
            borderRadius: reactRem
        }];
        const buttonTextStyle = [styles.buttonText, { fontSize: 2.8 * reactRem }];
        const clearImgStyle = [styles.clearImg, {
            height: 2.5 * reactRem,
            width: 2.5 * reactRem

        }];
        const clearContainerStyle = [styles.clearContainer, { paddingBottom: 2 * reactRem }];
        const clearImgContainerStyle = [styles.clearImgContainer, {
            paddingTop: 2 * reactRem,
            paddingRight: 2 * reactRem
        }];
        const faceImgStyle = [styles.faceImg, {
            height: 12 * reactRem,
            width: 12 * reactRem
        }];
        const discardButtonContainerStyle = [styles.discardButtonContainer, {
            padding: reactRem,
            width: 15 * reactRem,
            borderTopLeftRadius: 0.5 * reactRem,
            borderBottomLeftRadius: 0.5 * reactRem,
        }];
        const discardTextStyle = [styles.discardText, { fontSize: 2.6 * reactRem }];
        const buttonStyle = [styles.button, {
            width: 15 * reactRem,
            padding: reactRem,
            borderTopRightRadius: 0.5 * reactRem,
            borderBottomRightRadius: 0.5 * reactRem,
        }]
        const textStyle = [styles.text, { fontSize: 2.6 * reactRem }];
        return (<Modal
            open={isModalVisible}
            onClose={() => onChangeModalVisibility()}>
            <TouchableOpacity style={{ flex: 1, width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }} onPress={() => this.props.onChangeModalVisibility()}>
                <View style={mainContainerStyle}>
                    <View style={clearContainerStyle}>
                        <TouchableOpacity onPress={() => this.onGotItButtonPress()}>
                            <View style={clearImgContainerStyle}>
                                <Image source={ClearImg} style={clearImgStyle} />
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View style={{ width: "100%", height: "90%", justifyContent: "center", alignItems: 'center' }}>
                        <View style={styles.faceContainer}>
                            <Image source={leaveRoom || deleteAccount ? SadFace : HappyFace} style={faceImgStyle} />
                        </View>
                        {leaveRoom || deleteAccount ?
                            <View style={alertContainerStyle}>
                                <View style={titleContainerStyle}>
                                    <Text style={titleStyle}>{title}</Text>
                                </View>
                                <View style={styles.leaveButtonsContainer}>
                                    <TouchableOpacity onPress={() => this.onLeaveRoomDiscard()}>
                                        <View style={discardButtonContainerStyle}>
                                            <Text style={discardTextStyle}>DISCARD</Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => this.onLeaveRoomPressed()}>
                                        <View style={buttonStyle}>
                                            <Text style={textStyle}> {deleteAccount ? "DELETE" : "LEAVE"}</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            </View> :
                            <View style={alertContainerStyle}>
                                <View style={titleContainerStyle}>
                                    <Text style={titleStyle}>{title}</Text>
                                </View>
                                <TouchableOpacity onPress={() => this.onGotItButtonPress()}>
                                    <View style={buttonContainerStyle}>
                                        <Text style={buttonTextStyle}>GOT IT</Text>
                                    </View>
                                </TouchableOpacity>

                            </View>}
                    </View>
                </View>
            </TouchableOpacity>
        </Modal>);
    }
}

const styles = EStyleSheet.create({
    mainContainer: {
        alignItems: 'center',
        height: "50vh",
        width: "50vh",
        backgroundColor: globalStyles.white,
        borderRadius: "1.5rem"
    },
    alertContainer: {
        width: "100%",
        paddingTop: "1rem",
        paddingBottom: "3rem",
        paddingHorizontal: "2rem",
        borderRadius: "1.5rem",
        backgroundColor: globalStyles.white,
        justifyContent: "center",
        alignItems: "center"
    },
    titleContainer: {
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
        paddingBottom: "2rem",
        marginVertical: '4rem',
    },
    title: {
        color: globalStyles.textBlue,
        fontSize: "3.2rem",
        fontFamily: globalStyles.fontUbuntuBold,
        textAlign: "center"
    },
    buttonContainer: {
        backgroundColor: globalStyles.activeBlue,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: "2rem",
        paddingHorizontal: "8rem",
        borderRadius: "1rem",
    },
    buttonText: {
        color: globalStyles.white,
        fontSize: "2.8rem",
        fontFamily: globalStyles.fontUbuntuBold
    },
    clearImg: {
        height: "2.5rem",
        width: "2.5rem",
        resizeMode: "contain"
    },
    clearContainer: {
        width: "100%",
        height: "10%",
        justifyContent: "center",
        alignItems: "flex-end",
        paddingBottom: '2rem'
    },
    clearImgContainer: {
        paddingTop: "2rem",
        paddingRight: "2rem",
    },
    faceImg: {
        height: "12rem",
        width: "12rem",
        resizeMode: 'contain'
    },
    faceContainer: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    leaveButtonsContainer: {
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    discardButtonContainer: {
        backgroundColor: globalStyles.white,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: "1rem",
        width: "15rem",
        borderTopLeftRadius: "0.5rem",
        borderBottomLeftRadius: "0.5rem",
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderTopColor: globalStyles.backgroundGrey,
        borderLeftColor: globalStyles.backgroundGrey,
        borderBottomColor: globalStyles.backgroundGrey,

    },
    discardText: {
        fontSize: "2.6rem",
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuBold,
        textAlign: "center"
    },
    button: {
        backgroundColor: globalStyles.error,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: "15rem",
        padding: "1rem",
        borderTopRightRadius: "0.5rem",
        borderBottomRightRadius: "0.5rem",
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderTopColor: globalStyles.backgroundGrey,
        borderRightColor: globalStyles.backgroundGrey,
        borderBottomColor: globalStyles.backgroundGrey,

    },
    text: {
        fontSize: "2.6rem",
        color: globalStyles.white,
        fontFamily: globalStyles.fontUbuntuBold,
        textAlign: "center"
    },

});
const mapStateToProps = (state, ownProps) => {
    return {
        navigation: state.specialGames.navigation,
        leaveRoom: state.resources.leaveRoom,
        rooms: state.resources.rooms,
        reactRem: state.homeScreenLeagues.reactRem,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLeaveRoom: (leave) => dispatch(setLeaveRoom(leave)),
        setLeaveIsPressed: (isLeavePressed) => dispatch(setLeaveIsPressed(isLeavePressed)),
        onLeaveRoom: (rooms) => dispatch(leaveRoom(rooms)),
        setRoomsToLeave: (roomsToLeave) => dispatch(setRoomsToLeave(roomsToLeave)),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertDialogWithConfirmButton);