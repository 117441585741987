import React from "react"
import {
    Text,
    View,
    Platform,
    ActivityIndicator,
    TouchableOpacity,
} from 'react-native';

import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import { connect } from 'react-redux';

const defaultWhiteButton = props => {
    const { reactRem } = props;
    const buttonStyle = [styles.button, { padding: 2 * reactRem, borderRadius: 1.5 * reactRem }];
    if (props.style) {
        buttonStyle.push(props.style)
    }
    if (!props.acceptTerms && props.activeButtonId === 1) {
        buttonStyle.push(styles.blurButton)
    }

    const login = () => {
        props.onPress();
    }

    const register = () => {
        if (props.acceptTerms) {
            props.onPress();
        }
    }

    const loginOrRegister = () => {
        if (props.activeButtonId === 0) {
            login();
        } else {
            register();
        }
    }

    return (
        <View style={{ width: "50%", alignItems: 'center', justifyContent: 'center' }}>
            {props.singInPress ?
                <TouchableOpacity style={buttonStyle}>
                    <ActivityIndicator size="small" color={globalStyles.white} />
                </TouchableOpacity>
                :
                <TouchableOpacity style={buttonStyle} onPress={() => props.loginOrRegister ? loginOrRegister() : props.onPress()} >
                    <Text style={[styles.text, { fontSize: 2.5 * reactRem }]}>{props.children}</Text>
                </TouchableOpacity>
            }
        </View>


    );

}

const styles = EStyleSheet.create({
    button: {
        backgroundColor: globalStyles.activeBlue,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: "100%",
        padding: "2rem",
        borderRadius: "1.5rem"
    },
    text: {
        fontSize: "3rem",
        color: globalStyles.white,
        fontFamily: globalStyles.fontUbuntuBold,
        textAlign: "center",
        justifyContent: 'center',
    },
    blurButton: {
        opacity: 0.5
    }
});

const mapStateToProps = (state, ownProps) => {
    return {
        reactRem: state.homeScreenLeagues.reactRem,
    }
}

export default connect(mapStateToProps, null)(defaultWhiteButton);