import React from "react";
import { View, FlatList } from "react-native";
import CreateRoomLeaguePickerField from "./CreateRoomLeaguePickerField";
import CreateRoomLeagueRoundsPickerField from "./CreateRoomLeagueRoundsPickerField";
import { createRoomLeaguePickerStyles } from "../styles/styles";

class CreateRoomLeaguePicker extends React.Component {
  render() {
    const {
      roomLeague,
      onPickLeague,
      chooseLeague,
      onRemoveLeague,
      onPickKnockoutRound,
      choosenKnockoutRound,
    } = this.props;

    if (!roomLeague) {
      return null;
    }

    return (
      <View
        style={
          chooseLeague
            ? createRoomLeaguePickerStyles.pickerContainer
            : createRoomLeaguePickerStyles.pickerContainer2
        }
      >
        {chooseLeague ? (
          <FlatList
            data={roomLeague}
            keyExtractor={(item, index) => "key" + index}
            renderItem={({ item }) => (
              <CreateRoomLeaguePickerField
                onPress={() => onPickLeague(item.id)}
                onRemove={() => onRemoveLeague(item.id)}
                leagueImg={item.icon}
                leagueName={item.name}
              />
            )}
          />
        ) : (
          <FlatList
            data={roomLeague}
            keyExtractor={(item, index) => "key" + index}
            renderItem={({ item }) => (
              <CreateRoomLeagueRoundsPickerField
                onPress={() => onPickKnockoutRound(item)}
                leagueName={item}
                choosenKnockoutRound={choosenKnockoutRound}
              />
            )}
          />
        )}
      </View>
    );
  }
}

export default CreateRoomLeaguePicker;
