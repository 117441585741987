import React from "react";
import { View, FlatList, Text, Image, TouchableOpacity } from "react-native";
import SpecialGamesPrizeContainer from "./SpecialGamesPrizeContainer";
import ClearImg from "../../assets/clear.png";
import CrownImg from "../../assets/crown.png";
import Modal from "@material-ui/core/Modal";
import { specialGamesShowPrizesStyles } from "../styles/styles";

class SpecialGamesShowPrizes extends React.Component {
  componentWillUnmount() {
    if (this.props.isModalVisible) {
      this.props.onChangeModalVisibility();
    }
  }
  render() {
    const {
      isModalVisible,
      onChangeModalVisibility,
      prizes,
      reactRem,
    } = this.props;

    if (!prizes) {
      return null;
    }

    return (
      <Modal open={isModalVisible} onClose={() => onChangeModalVisibility()}>
        <TouchableOpacity
          style={{
            width: "100%",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => this.props.onChangeModalVisibility()}
        >
          <View style={specialGamesShowPrizesStyles.mainContainer}>
            <View style={specialGamesShowPrizesStyles.alertContainer}>
              <View style={specialGamesShowPrizesStyles.titleContainer}>
                <View style={specialGamesShowPrizesStyles.crownContainer}>
                  <Image
                    source={CrownImg}
                    style={specialGamesShowPrizesStyles.crownImg}
                  />
                </View>
                <View style={specialGamesShowPrizesStyles.titleTextContainer}>
                  <Text style={specialGamesShowPrizesStyles.title}>Prizes</Text>
                </View>
                <TouchableOpacity
                  style={specialGamesShowPrizesStyles.clearContainer}
                  onPress={onChangeModalVisibility}
                >
                  <Image
                    source={ClearImg}
                    style={specialGamesShowPrizesStyles.clearImg}
                  />
                </TouchableOpacity>
              </View>
              <FlatList
                data={prizes}
                style={{ width: "100%", maxHeight: "80vh" }}
                keyExtractor={(item, index) => "key" + index}
                renderItem={({ item, index }) => (
                  <SpecialGamesPrizeContainer
                    reactRem={reactRem}
                    {...item}
                    index={index}
                  />
                )}
              />
            </View>
          </View>
        </TouchableOpacity>
      </Modal>
    );
  }
}

export default SpecialGamesShowPrizes;
