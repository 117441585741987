import React from "react";
import { Image, View, Text, TouchableWithoutFeedback } from "react-native";
import globalStyles from "../../globalStyles";
import ChooseLeagueImg from "../../assets/choose_league.png";
import { createRoomLeaguePickerFieldStyles } from "../styles/styles";

class CreateRoomLeaguePickerField extends React.Component {
  state = {
    isLeagueChoosen: false,
  };

  onChooseLeague = (open) => {
    this.setState({ isLeagueChoosen: !open });
  };

  render() {
    const { leagueImg, leagueName } = this.props;
    const pickerText = [createRoomLeaguePickerFieldStyles.pickerText];

    if (this.state.isLeagueChoosen) {
      pickerText.push({ color: globalStyles.textBlue });
    } else {
      pickerText.push({ color: globalStyles.textGrey });
    }
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          {
            this.state.isLeagueChoosen
              ? this.props.onRemove()
              : this.props.onPress();
          }
          this.onChooseLeague(this.state.isLeagueChoosen);
        }}
      >
        <View style={createRoomLeaguePickerFieldStyles.pickerLeagueContainer}>
          <View style={{ flexDirection: "row" }}>
            <Image
              source={{ uri: globalStyles.BASE_URL + leagueImg }}
              style={createRoomLeaguePickerFieldStyles.leagueIcon}
            />
            <View style={createRoomLeaguePickerFieldStyles.pickerTextContainer}>
              <Text style={pickerText}>{leagueName}</Text>
            </View>
          </View>
          <View>
            {this.state.isLeagueChoosen ? (
              <Image
                source={ChooseLeagueImg}
                style={createRoomLeaguePickerFieldStyles.chooseIcon}
              />
            ) : null}
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

export default CreateRoomLeaguePickerField;
