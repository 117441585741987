import { StyleSheet } from "react-native";
import globalStyles from "../../globalStyles";

const chatPageStyles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  buttonContainerStyle: {
    flexDirection: "row",
    width: "100%",
    height: 60,
    borderBottomWidth: 0.5,
    borderBottomColor: globalStyles.borderRoom,
  },
  chatContainerStyle: {
    paddingTop: 6,
    paddingBottom: 24,
    paddingHorizontal: 3,
  },
  noMessagesTextStyle: {
    color: globalStyles.textBlue,
    fontSize: 20,
    fontFamily: globalStyles.fontFamilyBold,
  },
  markAsReadTextStyle: {
    color: globalStyles.textBlue,
    fontSize: 15.6,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  markAsReadImageContainerStyle: {
    padding: 12,
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  signInUpContainerStyle: {
    backgroundColor: globalStyles.backgroundGrey,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "50vh",
    justifyContent: "center",
    paddingVertical: 6,
  },
  signInContainerStyle: {
    backgroundColor: globalStyles.white,
    paddingHorizontal: 18,
    paddingVertical: 9,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 120,
    maxWidth: 120,
    borderTopLeftRadius: 9,
    borderBottomLeftRadius: 9,
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
    paddingHorizontal: 18,
    paddingVertical: 9,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 120,
    maxWidth: 120,
    borderTopRightRadius: 9,
    borderBottomRightRadius: 9,
  },
  signUpTextStyle: {
    color: globalStyles.white,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 14.4,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  signInTextStyle: {
    color: globalStyles.textBlue,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 14.4,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  noMessagesTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "80%",
  },
  activeButtonContainer: { height: "100%", width: "100%" },
});

const messagePageStyles = StyleSheet.create({
  container: {
    height: "90vh",
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  fieldsContainerStyle: {
    width: "100%",
    paddingTop: 6,
    overflow: "hidden",
  },
  messageTitleContainerStyle: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 18,
    backgroundColor: globalStyles.white,
    opacity: 0.7,
  },
  titleTextContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
  },
  titleStyle: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 16.8,
    marginLeft: "10rem",
  },
});

const chatRoomFieldStyles = StyleSheet.create({
  container: {
    backgroundColor: globalStyles.white,
    borderRadius: 9,
    marginVertical: 1.2,
    paddingVertical: 6,
    alignItems: "center",
    justifyContent: "center",
    height: 48,
  },
  chatsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  chatContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 12,
    width: "10%",
  },
  roomLogoImg: {
    height: 18,
    width: 18,
    resizeMode: "contain",
  },
  privateLogoImg: {
    height: 15,
    width: 15,
    resizeMode: "contain",
  },
  chatDataContainer: {
    width: "88%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  chatTitleContainer: {
    justifyContent: "space-between",
    width: "70%",
  },
  chatLastMessageDateContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "25%",
    paddingRight: 12,
  },

  chatTitleText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 14.4,
  },
  chatMessageText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 13.2,
  },

  chatLastMessageDateText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
    opacity: 0.7,
  },
  chatUnreadMessagesContainer: {
    height: 15,
    borderRadius: 7.2,
    width: 15,
    backgroundColor: globalStyles.activeBlue,
    justifyContent: "center",
    alignItems: "center",
  },
  chatUnreadMessagesText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 9.6,
  },
  bottomContainer: {
    height: 24,
    width: "100%",
  },
});

const clientMessageFieldStyle = StyleSheet.create({
  messageContainer: {
    width: "100%",
    justifyContent: "space-between",
    paddingHorizontal: 6,
    paddingVertical: 6,
  },
  messageTextContainer: {
    maxWidth: "80%",
    minWidth: "20%",
    backgroundColor: globalStyles.textBlue,
    justifyContent: "flex-end",
    alignItems: "center",
    borderRadius: 9,
    marginRight: 6,
    paddingVertical: 6,
    paddingHorizontal: 12,
  },
  messageText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: 15,
  },
  messageTimeContainer: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingRight: 12,
  },
  messageTime: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
    opacity: 0.7,
  },
});

const messageInputFieldStyles = StyleSheet.create({
  messageInputContainer: {
    flexDirection: "row",
    backgroundColor: globalStyles.white,
    marginHorizontal: 6,
    borderRadius: 3,
    marginBottom: 6,
    minHeight: 36,
    maxHeight: 72,
    width: "95%",
  },
  inputText: {
    fontFamily: globalStyles.fontUbuntuBold,
    color: globalStyles.black,
    justifyContent: "center",
    alignItems: "center",
    fontSize: 15,
    maxWidth: "80%",
    width: "80%",
    paddingLeft: 6,
    minHeight: 36,
    maxHeight: 72,
  },
  imageStyle: {
    height: 24,
    width: 24,
    resizeMode: "contain",
  },
  imageContainer: {
    flexDirection: "row",
    width: "20%",
    justifyContent: "center",
    alignItems: "center",
  },
});

const notificationFieldStyles = StyleSheet.create({
  notificationContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    marginTop: 3,
    padding: 12,
    borderRadius: 10,
  },
  notificationContainerSeen: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    marginTop: 3,
    padding: 12,
    borderRadius: 10,
    opacity: 0.3,
  },
  dateText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 15,
  },
  notificationImageContainer: {
    width: "100%",
  },
  notificationTextContainer: {
    justifyContent: "center",
    width: "100%",
  },
  notificationText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: 15,
  },

  rightAction: {
    backgroundColor: globalStyles.white,
    opacity: 0.7,
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    minHeight: 40,
    borderRadius: 10,
    marginTop: 3,
  },
  actionText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 15,
  },
  clearImg: {
    height: 12,
    width: 12,
    resizeMode: "contain",
  },
  notificationTextSeen: {
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: 15,
    color: globalStyles.borderRoom,
  },
});

const outComingMessageFieldStyles = StyleSheet.create({
  messageContainer: {
    width: "100%",
    justifyContent: "space-between",
    paddingHorizontal: 6,
    paddingVertical: 3,
  },
  messageImg: {
    height: 40,
    width: 40,
    borderRadius: 20,
    resizeMode: "contain",
    marginRight: 6,
  },
  messageTextContainer: {
    maxWidth: "80%",
    minWidth: "20%",
    backgroundColor: globalStyles.white,
    justifyContent: "center",
    borderRadius: 10,
    padding: 6,
  },
  messageText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: 15,
  },
  nameText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
  },
  messageTimeContainer: {
    width: "100%",
    paddingLeft: 55,
    paddingTop: 3,
  },
  messageTime: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
    opacity: 0.7,
  },
  nameContainer: {
    width: "100%",
  },
});

export {
  chatPageStyles,
  messagePageStyles,
  chatRoomFieldStyles,
  clientMessageFieldStyle,
  messageInputFieldStyles,
  notificationFieldStyles,
  outComingMessageFieldStyles
};
