import React from 'react';
import { TouchableWithoutFeedback, View, Text, TouchableOpacity, Image } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import HappyFace from '../../../assets/happy_face.png';
import SadFace from '../../../assets/sad_face.png';
import ClearImg from '../../../assets/clear.png';
import { onOpenRegister } from '../../../Login/actions/auth';
import { connect } from 'react-redux';
import Modal from '@material-ui/core/Modal';

class AlertDialog extends React.Component {

  componentWillUnmount() {
    if (this.props.isModalVisible) {
      this.props.onChangeModalVisibility();
    }
  }

  onLoginPressed = () => {
    this.props.onOpenRegister(true, false);
    window.location.replace("/login");
  }

  render() {
    const { isModalVisible, onChangeModalVisibility, title, useMyPredictions, reactRem } = this.props;
    let faceImage = HappyFace;
    if (useMyPredictions) {
      faceImage = SadFace;
    }

    const mainContainerStyle = [styles.mainContainer, { borderRadius: 1.5 * reactRem }];
    const alertContainerStyle = [styles.alertContainer, {
      paddingTop: reactRem,
      paddingBottom: 3 * reactRem,
      paddingHorizontal: 2 * reactRem,
      borderRadius: 1.5 * reactRem,
    }];
    const titleContainerStyle = [styles.titleContainer, {
      paddingBottom: 2 * reactRem,
      marginVertical: 4 * reactRem
    }];
    const titleStyle = [styles.title, { fontSize: 3.2 * reactRem }];
    const clearContainerStyle = [styles.clearContainer, { paddingBottom: 2 * reactRem }];
    const clearImgContainerStyle = [styles.clearImgContainer, {
      paddingTop: 2 * reactRem,
      paddingRight: 2 * reactRem,
    }];
    const clearImgStyle = [styles.clearImg, {
      height: 2.5 * reactRem,
      width: 2.5 * reactRem,
    }];
    const faceImageStyle = [styles.faceImg, {
      height: 12 * reactRem,
      width: 12 * reactRem
    }];
    const signInUpContainerStyle = [styles.signInUpContainer, { paddingVertical: reactRem }];
    const signInContainerStyle = [styles.signInContainer, {
      paddingHorizontal: 3 * reactRem,
      paddingVertical: 1.5 * reactRem,
      minWidth: 20 * reactRem,
      maxWidth: 20 * reactRem,
      borderTopLeftRadius: 1.5 * reactRem,
      borderBottomLeftRadius: 1.5 * reactRem,
    }];
    const signUpContainerStyle = [styles.signUpContainer, {
      paddingHorizontal: 3 * reactRem,
      paddingVertical: 1.5 * reactRem,
      minWidth: 20 * reactRem,
      maxWidth: 20 * reactRem,
      borderTopRightRadius: 1.5 * reactRem,
      borderBottomRightRadius: 1.5 * reactRem,
    }];
    const signUpTextStyle = [styles.signUpText, { fontSize: 1.8 * reactRem }];
    const signInTextStyle = [styles.signInText, { fontSize: 1.8 * reactRem }];
    return (
      <Modal
        open={isModalVisible}
        onClose={() => onChangeModalVisibility()}>
        <TouchableOpacity style={{ flex: 1, width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }} onPress={() => this.props.onChangeModalVisibility()}>
          <View style={mainContainerStyle}>
            <View style={clearContainerStyle}>
              <TouchableOpacity onPress={() => this.props.onChangeModalVisibility()}>
                <View style={clearImgContainerStyle}>
                  <Image source={ClearImg} style={clearImgStyle} />
                </View>
              </TouchableOpacity>
            </View>
            <View style={styles.faceContainer}>
              <Image source={faceImage} style={faceImageStyle} />
            </View>
            <View style={alertContainerStyle}>
              <View style={titleContainerStyle}>
                <Text style={titleStyle}>{title}</Text>
              </View>
              {useMyPredictions ? null :
                <View style={signInUpContainerStyle}>
                  <TouchableOpacity onPress={() => this.onLoginPressed()}>
                    <View style={signInContainerStyle}>
                      <Text style={signInTextStyle}>SIGN IN</Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => this.onLoginPressed()}>
                    <View style={signUpContainerStyle}>
                      <Text style={signUpTextStyle}>SIGN UP</Text>
                    </View>
                  </TouchableOpacity>
                </View>}
            </View>
          </View>

        </TouchableOpacity >
      </Modal >
    );
  }
}

const styles = EStyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    height: "50vh",
    width: "50vh",
    backgroundColor: globalStyles.white,
    borderRadius: "1.5rem"
  },
  alertContainer: {
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "3rem",
    paddingHorizontal: "2rem",
    borderRadius: "1.5rem",
    backgroundColor: globalStyles.white,
    justifyContent: "center",
    alignItems: "center"
  },
  titleContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    paddingBottom: "2rem",
    marginVertical: '4rem',
  },
  title: {
    color: globalStyles.textBlue,
    fontSize: "3.2rem",
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlign: "center"
  },
  clearContainer: {
    width: "100%",
    height: "10%",
    justifyContent: "center",
    alignItems: "flex-end",
    paddingBottom: '2rem',
  },
  clearImgContainer: {
    paddingTop: "2rem",
    paddingRight: "2rem",
  },
  clearImg: {
    height: "2.5rem",
    width: "2.5rem",
    resizeMode: "contain"
  },
  faceImg: {
    height: "12rem",
    width: "12rem",
    resizeMode: 'contain'
  },
  faceContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  signInUpContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: "100%",
    justifyContent: "center",
    paddingVertical: "1rem",
  },
  signInContainer: {
    backgroundColor: globalStyles.white,
    paddingHorizontal: "3rem",
    paddingVertical: "1.5rem",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "20rem",
    maxWidth: "20rem",
    borderTopLeftRadius: "1.5rem",
    borderBottomLeftRadius: "1.5rem",
    borderWidth: 1,
    borderColor: globalStyles.backgroundGrey
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
    paddingHorizontal: "3rem",
    paddingVertical: "1.5rem",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "20rem",
    maxWidth: "20rem",
    borderTopRightRadius: "1.5rem",
    borderBottomRightRadius: "1.5rem",
    borderColor: globalStyles.backgroundGrey,
    borderWidth: 1,
  },
  signUpText: {
    color: globalStyles.white,
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: "1.8rem",
    fontFamily: globalStyles.fontUbuntuBold,
  },
  signInText: {
    color: globalStyles.textBlue,
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: "1.8rem",
    fontFamily: globalStyles.fontUbuntuBold,
  },


});

const mapStateToProps = (state, ownProps) => {
  return {
    reactRem: state.homeScreenLeagues.reactRem
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onOpenRegister: (loginscreen, register) => dispatch(onOpenRegister(loginscreen, register)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);