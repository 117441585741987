import React from 'react';
import { Image, View, Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import HalfTimeImg from '../../../assets/livescore/first-second-half.png';

import GoalImg from '../../../assets/livescore/goal.png';

class LineupsHomePlayerInfo extends React.Component {

    render() {
        const { info, reactRem } = this.props;
        // lineup_player,lineup_number,lineup_position,playerOut,yellowCard,goalScorer
        const playerNumberContainerStyle = [styles.playerNumberContainer, { width: 5 * reactRem, paddingLeft: reactRem }];
        const playerNumberTextStyle = [styles.playerNumberText, { fontSize: 2.2 * reactRem }];
        const playerNameTextStyle = [styles.playerNameText, { fontSize: 2.2 * reactRem, marginLeft: reactRem }];
        const commentImgStyle = [styles.commentImg, {
            width: 3 * reactRem,
            height: 3 * reactRem,
            marginLeft: reactRem
        }]

        return (
            <View style={styles.playerInfoContainer}>
                <View style={playerNumberContainerStyle}>
                    <Text style={playerNumberTextStyle}>{info.lineup_number}</Text>
                </View>
                <Text style={playerNameTextStyle}>{info.lineup_player}</Text>
                {/* <Image source={GoalImg} style={commentImgStyle} /> */}
            </View>
        );
    }
}

const styles = EStyleSheet.create({
    playerInfoContainer: {
        flexDirection: "row",
        alignItems: "center",
    },
    playerNumberContainer: {
        width: "5rem",
        paddingLeft: "1rem",
        alignItems: 'flex-end',
    },
    playerNumberText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuMedium,
        fontSize: "2.2rem",
    },
    playerNameText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuRegular,
        fontSize: "2.2rem",
        marginLeft: "1rem"
    },
    commentImg: {
        width: "3rem",
        height: "3rem",
        resizeMode: "contain",
        marginLeft: "1rem"
    },
});
export default LineupsHomePlayerInfo;