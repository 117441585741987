import {
  SET_UNSEEN_CHATS,
  SET_IN_ROOM,
  DELETE_CHAT_DATA,
  GET_NOTIFICATION_DATA,
  UNREAD_MESSAGE,
  SET_READ_NOTIFICATION,
  GET_NEW_NOTIFICATION_DATA,
} from "../actions/actionTypes";

const initialState = {
  unseenChats: null,
  inRoom: null,
  current_page: null,
  unreadNotifications: null,
  notifications: [],
  from: null,
  last_page: null,
  per_page: null,
  to: null,
  total: null,
  readMessage: null,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEW_NOTIFICATION_DATA: {
      return {
        ...state,
        notifications: action.data.data,
      };
    }
    case SET_READ_NOTIFICATION: {
      if (action.notificationId === 0) {
        return {
          ...state,
          readMessage: action.data,
        };
      } else {
        const notificationIndex = state.notifications.findIndex(
          (notification) =>
            Number(notification.id) === Number(action.notificationId)
        );
        const notifications = [...state.notifications];
        const notification = { ...state.notifications[notificationIndex] };
        notification.seen = true;
        notifications[notificationIndex] = notification;

        return { ...state, readMessage: action.data, notifications };
      }
    }
    case SET_UNSEEN_CHATS: {
      return {
        ...state,
        unseenChats: action.unseenChats,
      };
    }
    case SET_IN_ROOM: {
      return {
        ...state,
        inRoom: action.roomName,
      };
    }
    case DELETE_CHAT_DATA: {
      let unseenChats = { ...state.unseenChats };
      let inRoom = { ...state.inRoom };
      unseenChats = null;
      inRoom = null;
      return { ...state, unseenChats, inRoom };
    }
    case GET_NOTIFICATION_DATA: {
      if (action.data) {
        const notifications = [...state.notifications];
        for (let i = 0; i < 10; i++) {
          if (action.data.data[i]) notifications.push(action.data.data[i]);
        }
        return {
          ...state,
          current_page: action.data.current_page,
          notifications: notifications,
          unreadNotifications: action.data.unreadNotifications,
          from: action.data.from,
          last_page: action.data.last_page,
          per_page: action.data.per_page,
          to: action.data.to,
          total: action.data.total,
        };
      }
    }
    case UNREAD_MESSAGE: {
      let unseenChats = { ...state.unseenChats };
      unseenChats = unseenChats - 1;
      return {
        ...state,
        unseenChats,
      };
    }
    default:
      return state;
  }
};

export default chatReducer;
