export const SELECT_ROOM = 'SELECT_ROOM';
export const SELECT_LEAGUE = 'SELECT_LEAGUE';
export const GET_SPECIAL_GAMES = "GET_SPECIAL_GAMES";
export const GET_SPECIAL_GAMES_MATCHES = "GET_SPECIAL_GAMES_MATCHES";
export const GET_SPECIAL_GAMES_ROOM_INFO = 'GET_SPECIAL_GAMES_ROOM_INFO';
export const SET_LEAGUE_PREDICTIONS_BY_WEEK = "SET_LEAGUE_PREDICTIONS_BY_WEEK";
export const DELETE_PREDICTION = 'DELETE_PREDICTIONS';
export const DELETE_JOIN_SPECIAL_ROOM="DELETE_JOIN_SPECIAL_ROOM";
export const JOIN_SPECIAL_ROOM = "JOIN_SPECIAL_ROOM"
export const SET_MY_SPECIAL_ROOM = "SET_MY_SPECIAL_ROOM";
export const SET_RUNNING_SPECIAL_ROOM = "SET_RUNNING_SPECIAL_ROOM";
export const SET_UPCOMING_SPECIAL_ROOM = "SET_UPCOMING_SPECIAL_ROOM";
export const SET_HISTORY_SPECIAL_ROOM = "SET_HISTORY_SPECIAL_ROOM";
export const SET_SPECIAL_ROOM = "SET_SPECIAL_ROOM";
export const ROOM_MESSAGE ="ROOM_MESSAGE";
export const SET_PREDICTION_GAMES ="SET_PREDICTION_GAMES";
export const JOIN_FROM_SPECIAL = "JOIN_FROM_SPECIAL";
export const MATCH_BET = 'MATCH_BET';
export const SET_NAVIGATION = "SET_NAVIGATION";
