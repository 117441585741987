import React from "react";
import { View, Text,Image } from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles'



class TeamStatsField extends React.Component {

    render() {
        const { position, name, matchPlayed, goalDifference, points, firstClub,clubImg,reactRem } = this.props;
        const clubContainer = [styles.clubContainer];
        const clubPointsContainer = [styles.clubPointsContainer,{width:7*reactRem,height:7*reactRem}];
        if (firstClub) {
            clubContainer.push(styles.clubTopContainer);
            clubContainer.push({borderTopRightRadius:1.5*reactRem,borderTopLeftRadius:1.5*reactRem})
            clubPointsContainer.push(styles.clubTopPointsContainer);
            clubPointsContainer.push({borderTopLeftRadius:1.5*reactRem});
        }
        else {
            clubContainer.push(styles.clubBottomContainer);
            clubContainer.push({borderBottomRightRadius:1.5*reactRem,borderBottomLeftRadius:1.5*reactRem})
            clubPointsContainer.push(styles.clubBottomPointsContainer);
            clubPointsContainer.push({borderBottomLeftRadius:1.5*reactRem})
        }
        return (
            <View style={clubContainer}>
                <View style={{ width: "60%", flexDirection: "row" }}>
                    <View style={clubPointsContainer}>
                        <Text style={[styles.clubPositionText,{fontSize:2.4*reactRem}]}>{position}</Text>
                    </View>
                    <View style={{ justifyContent: "center", alignItems: "center", textAlign: "center",flexDirection:"row"}}>
                        <Image source={{uri:clubImg}} style={[styles.clubImgStyle,{width:5*reactRem,height:5*reactRem,marginLeft:0.5*reactRem}]}/>
                        <View style={[styles.teamNameContainer,{height:7*reactRem,marginLeft:reactRem}]}>
                            <Text style={[styles.teamName,{fontSize:2.4*reactRem}]}>{name}</Text>
                        </View>
                    </View>
                </View>
                <View style={{ width: "40%", flexDirection: "row" }}>
                    <View style={styles.statsContainer}>
                        <Text style={[styles.teamMatchPlayedText,{fontSize:2.4*reactRem,marginRight:0.5*reactRem}]}>{matchPlayed}</Text>
                        <Text style={[styles.clubPointsText,{paddingHorizontal:1.5*reactRem,fontSize:2.4*reactRem}]}>{points}</Text>
                    </View>
                </View>
            </View>
        );
    }
}


const styles = EStyleSheet.create({
    clubContainer: {
        flexDirection: "row",
        width: "100%",
        backgroundColor: globalStyles.white,
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: globalStyles.backgroundGrey,
    },
    clubTopContainer: {
        borderTopRightRadius: "1.5rem",
        borderTopLeftRadius: "1.5rem"
    },
    clubBottomContainer: {
        borderBottomRightRadius: "1.5rem",
        borderBottomLeftRadius: "1.5rem",
    },
    clubPointsContainer: {
        alignItems: "center",
        justifyContent: "center",
        width: "7rem",
        height: "7rem",
        backgroundColor: globalStyles.white,
        borderRightWidth: 1,
        borderRightColor: globalStyles.backgroundGrey,
    },
    clubTopPointsContainer: {
        borderTopLeftRadius: "1.5rem",
        borderBottomColor: globalStyles.basicBlueGreyColor,
    },
    clubBottomPointsContainer: {
        borderBottomLeftRadius: "1.5rem",
    },
    clubPositionText: {
        fontFamily: globalStyles.fontUbuntuMedium,
        color: globalStyles.black,
        fontSize: "2.4rem",
    },
    statsContainer: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row',
        width: "100%"
    },
    teamMatchPlayedText: {
        fontFamily: globalStyles.fontUbuntuMedium,
        color: globalStyles.black,
        fontSize: "2.4rem",
        marginRight:"0.5rem",
        opacity:0.7,
    },
    clubPointsText: {
        color: globalStyles.black,
        fontFamily: globalStyles.fontUbuntuBold,
        paddingHorizontal: "1.5rem",
        fontSize: "2.4rem",
    },
    teamNameContainer:{
        alignItems:"center",
        justifyContent:"center",
        height:"7rem",
        marginLeft:"1rem"
    },
    teamName: {
        fontFamily: globalStyles.fontUbuntuMedium,
        color: globalStyles.black,
        fontSize: '2.4rem',
    },
    clubImgStyle:{
        width:"5rem",
        height:"5rem",
        marginLeft: "0.5rem",
        resizeMode:"contain",
    }
});


export default TeamStatsField