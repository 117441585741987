import React, { useState } from 'react';
import { TouchableOpacity, Text, StyleSheet, Image } from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import activeHomeImg from '../../../assets/navigation_home_active.png'
import activeRoomImg from '../../../assets/navigation_rooms_active.png'
import activePredictImg from '../../../assets/navigation_predict_active.png'
import activeChatImg from '../../../assets/navigation_chat_active.png'
import { connect } from 'react-redux';

function TopBarNavigationButton(props) {
    const [isHover, setHover] = useState(false);
    const { reactRem, windowWidth } = props;
    const titleStyle = [styles.title];
    let buttonImage = props.image;
    if (isHover) {
        titleStyle.push(styles.activeTitle)
        if (props.title === "Home") {
            buttonImage = activeHomeImg;
        } else if (props.title === "Rooms") {
            buttonImage = activeRoomImg;
        } else if (props.title === "Predict") {
            buttonImage = activePredictImg;
        } else if (props.title === "Chat") {
            buttonImage = activeChatImg;
        }
    } else {
        titleStyle.push(styles.title)
        buttonImage = props.image;
    }
    if (props.buttonId) {
        titleStyle.push(styles.activeTitle)
    }
    if (props.special) {
        titleStyle.push(styles.specialTitle)
    }

    titleStyle.push({ fontSize: 2.8 * reactRem })
    const containerStyle = [styles.container, { height: 9 * reactRem, marginRight: 5 * reactRem, }];
    const specialContainerStyle = [styles.specialContainer, { paddingVertical: 2.5 * reactRem, }];
    if (windowWidth < 650) {
        containerStyle.push({ flexDirection: "column", marginRight: 2 * reactRem });
    }


    return (
        <TouchableOpacity
            style={props.special ? specialContainerStyle : containerStyle}
            onPress={props.onPress}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <Image source={buttonImage} style={[styles.buttonImage, { width: 4.5 * reactRem, height: 4.5 * reactRem }]} />
            <Text style={titleStyle}>{props.title}</Text>
        </TouchableOpacity>
    );


}
const styles = EStyleSheet.create({
    container: {
        flexDirection: "row",
        width: "15%",
        justifyContent: 'center',
        alignItems: "center",
        height: "9rem",
        marginRight: "5rem",
    },
    buttonImage: {
        width: "4.5rem",
        height: "4.5rem",
        resizeMode: "contain",
        marginRight: "1rem",
    },
    title: {
        color: globalStyles.white,
        fontSize: "2.8rem",
        fontFamily: globalStyles.fontUbuntuRegular,
        textAlign: "center"
    },
    activeTitle: {
        color: globalStyles.activeBlue,
        fontSize: "2.8rem",
        fontFamily: globalStyles.fontUbuntuRegular,
        textAlign: "center"
    },
    specialContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(255,255,255, 0.1)',
        paddingVertical: "2.5rem",
        width: "100%",
    },
    specialTitle: {
        color: globalStyles.golden,
        fontSize: "2.8rem",
        fontFamily: globalStyles.fontUbuntuBold,
        textAlign: "center"
    }
});

const mapStateToProps = (state, ownProps) => {
    return {
        reactRem: state.homeScreenLeagues.reactRem,
    }
}



export default connect(mapStateToProps, null)(TopBarNavigationButton);