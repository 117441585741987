import React from "react";
import { View, Text } from "react-native";
import { specialGamesMatchesContainerStyles } from "../styles/styles";

import SpecialGamesMatchField from "./SpecialGamesMatchField";

class SpecialGamesMatchesContainer extends React.Component {
  render() {
    const { name, games } = this.props;

    return (
      <View style={specialGamesMatchesContainerStyles.titleContainer}>
        <Text style={specialGamesMatchesContainerStyles.title}>{name}</Text>
        {games.map((item, index) => (
          <SpecialGamesMatchField {...item} key={index} />
        ))}
      </View>
    );
  }
}

export default SpecialGamesMatchesContainer;
