import React from "react";
import { Image, View, Text, TouchableOpacity } from "react-native";
import globalStyles from "../../globalStyles";
import SpecialGamesShowMatches from "./SpecialGamesShowMatches";
import { connect } from "react-redux";
import { getSpecialGamesMatches } from "../actions/specialGames";
import { specialGamesFreeStyles } from "../styles/styles";

class SpecialGamesFree extends React.Component {
  state = {
    isModalVisible: false,
    showAllLeagues: false,
  };

  onOpenPredictionMatchpage = () => {
    window.location.replace("/predictions");
  };
  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
    this.props.getSpecialGamesMatches(this.props.id);
  };

  changeModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
    this.props.getSpecialGamesMatches(this.props.id);
  };

  showAll = (e) => {
    e.preventDefault();
    this.setState({ showAllLeagues: true });
  };

  render() {
    const {
      startDate,
      endDate,
      prizeImage,
      prize1st,
      isUserInRoom,
      specialGamesMatches,
      leagues,
      maxPlayers,
      name,
      type,
      duration,
      playersInRoom,
    } = this.props;

    const testLeagues2 = [];
    let extraLeagues = 0;

    if (leagues && leagues.length > 5) {
      extraLeagues = leagues.length - 4;
      for (var i = 0; i < 4; i++) {
        testLeagues2.push(leagues[i]);
      }
    }
    return (
      <View style={specialGamesFreeStyles.container}>
        <View style={specialGamesFreeStyles.weeklyGameNameContainer}>
          <View style={specialGamesFreeStyles.weeklyGameNameTextContainer}>
            <Text style={specialGamesFreeStyles.weeklyGameNameText}>
              {name}
            </Text>
          </View>
          <View style={specialGamesFreeStyles.weeklyGameNameTextContainerRight}>
            <Text style={specialGamesFreeStyles.weeklyGameNameText}>Prize</Text>
          </View>
        </View>
        <View style={specialGamesFreeStyles.roomInfoContainer}>
          <View style={specialGamesFreeStyles.roomDataContainer}>
            <View>
              <Text style={specialGamesFreeStyles.specialRoomTitleText}>
                Special Room
              </Text>
            </View>
            <View style={specialGamesFreeStyles.weeklyGameLeagueImgContainer}>
              {(extraLeagues === 0 && leagues && leagues.length != 0) ||
              this.state.showAllLeagues ? (
                <View style={specialGamesFreeStyles.weeklyGameImgContainer}>
                  {leagues.map((info, i) => (
                    <Image
                      source={{ uri: globalStyles.BASE_URL + info.image }}
                      style={specialGamesFreeStyles.weeklyGameLeaguesImg}
                      key={i}
                    />
                  ))}
                </View>
              ) : (
                <TouchableOpacity
                  style={specialGamesFreeStyles.weeklyGameImgContainer}
                  onPress={(e) => this.showAll(e)}
                >
                  {testLeagues2.map((info, i) => (
                    <Image
                      source={{ uri: globalStyles.BASE_URL + info.image }}
                      style={specialGamesFreeStyles.weeklyGameLeaguesImg}
                      key={i}
                    />
                  ))}
                  <View style={specialGamesFreeStyles.extraLeagueContainer}>
                    <Text style={specialGamesFreeStyles.extraLeagueText}>
                      +{extraLeagues}
                    </Text>
                    <Text style={specialGamesFreeStyles.extraLeagueText}>
                      more
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
            </View>
            <View style={specialGamesFreeStyles.extraInfoContainer}>
              <View style={specialGamesFreeStyles.startEndContainer}>
                <View style={specialGamesFreeStyles.startContainer}>
                  <Text style={specialGamesFreeStyles.startText}>Start</Text>
                </View>
                <View style={specialGamesFreeStyles.endContainer}>
                  <Text style={specialGamesFreeStyles.endText}>
                    {startDate}
                  </Text>
                </View>
              </View>
              <View style={specialGamesFreeStyles.startEndContainer}>
                <View style={specialGamesFreeStyles.startContainer}>
                  <Text style={specialGamesFreeStyles.startText}>Ends</Text>
                </View>
                <View style={specialGamesFreeStyles.endContainer}>
                  <Text style={specialGamesFreeStyles.endText}>{endDate}</Text>
                </View>
              </View>
            </View>
            <View style={specialGamesFreeStyles.extraInfoContainer}>
              <View style={specialGamesFreeStyles.startEndContainer}>
                <View style={specialGamesFreeStyles.startContainer}>
                  <Text style={specialGamesFreeStyles.startText}>Players</Text>
                </View>
                <View style={specialGamesFreeStyles.endContainer}>
                  <Text style={specialGamesFreeStyles.playerText}>
                    {playersInRoom}/{maxPlayers ? maxPlayers : "∞"}
                  </Text>
                </View>
              </View>
              {isUserInRoom ? (
                <View style={specialGamesFreeStyles.startEndContainer}>
                  <View style={specialGamesFreeStyles.startContainer}>
                    <Text style={specialGamesFreeStyles.startText}>You</Text>
                  </View>
                  <View style={specialGamesFreeStyles.endContainer}>
                    <Text style={specialGamesFreeStyles.endText}></Text>
                  </View>
                </View>
              ) : null}
            </View>
          </View>
          <View style={specialGamesFreeStyles.prizeContainer}>
            <View style={specialGamesFreeStyles.prizeImageContainer}>
              <Image
                source={
                  prizeImage
                    ? { uri: globalStyles.BASE_URL + prizeImage }
                    : null
                }
                style={specialGamesFreeStyles.prizeImage}
              />
            </View>
            <View style={specialGamesFreeStyles.prizeTextContainer}>
              <Text style={specialGamesFreeStyles.prizeText}>{prize1st}</Text>
            </View>
          </View>
        </View>
        <View style={specialGamesFreeStyles.specialGamesButtonContainer}>
          <SpecialGamesShowMatches
            isModalVisible={this.state.isModalVisible}
            onChangeModalVisibility={this.changeModal}
            leagues={specialGamesMatches}
          />
          <TouchableOpacity
            style={
              isUserInRoom
                ? specialGamesFreeStyles.viewButtonContainer
                : specialGamesFreeStyles.joinButtonContainer
            }
            onPress={() => {
              isUserInRoom
                ? window.location.replace("/specialgame/" + this.props.id)
                : window.location.replace(
                    "/specialgame/join/" +
                      this.props.id +
                      "/" +
                      type +
                      "/" +
                      duration
                  );
            }}
          >
            <Text style={specialGamesFreeStyles.joinText}>
              {isUserInRoom ? "VIEW" : "JOIN"}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    specialGamesMatches: state.specialGames.specialGamesMatches,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialGamesMatches: (roomId) =>
      dispatch(getSpecialGamesMatches(roomId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SpecialGamesFree);
