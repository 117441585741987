import React from "react";
import { Image, View, Text } from "react-native";
import { leagueStatisticsFieldStyles } from "../drawerStyles/styles";
import globalStyles from "../../../globalStyles";

class LeagueStatisticsField extends React.Component {
  render() {
    const {
      leagueImg,
      leagueName,
      totalMatchScore,
      matchScore,
      matchScorePercent,
      totalShieldScore,
      shieldScore,
      shieldScorePercent,
      shieldScore_3,
      shieldScorePercent_3,
      totalShieldScore_3,
    } = this.props;

    return (
      <View style={leagueStatisticsFieldStyles.leagueStatisticsContainer}>
        <View style={leagueStatisticsFieldStyles.leagueNameAndImgContainer}>
          <Image
            source={{ uri: globalStyles.BASE_URL + leagueImg }}
            style={leagueStatisticsFieldStyles.leagueImg}
          />
          <Text style={leagueStatisticsFieldStyles.leagueNameText}>
            {leagueName}
          </Text>
        </View>
        <View style={leagueStatisticsFieldStyles.matchScoreContainer}>
          <Text style={leagueStatisticsFieldStyles.percentText}>
            {matchScorePercent + "%"}
          </Text>
          <Text style={leagueStatisticsFieldStyles.matchScoreText}>
            {matchScore + "/" + totalMatchScore}
          </Text>
        </View>
        <View style={leagueStatisticsFieldStyles.shieldScoreContainer}>
          <Text style={leagueStatisticsFieldStyles.percentText}>
            {shieldScorePercent + "%"}
          </Text>
          <Text style={leagueStatisticsFieldStyles.matchScoreText}>
            {shieldScore + "/" + totalShieldScore}
          </Text>
        </View>
        <View style={leagueStatisticsFieldStyles.shieldScoreContainer}>
          <Text style={leagueStatisticsFieldStyles.percentText}>
            {shieldScorePercent_3 + "%"}
          </Text>
          <Text style={leagueStatisticsFieldStyles.matchScoreText}>
            {shieldScore_3 + "/" + totalShieldScore_3}
          </Text>
        </View>
      </View>
    );
  }
}

export default LeagueStatisticsField;
