import React from "react";
import { View, Image, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import globalStyles from "../../globalStyles";
import {
  getSpecialGameRoomInfo,
  getSpecialGamesMatches,
  joinSpecialGame,
} from "../actions/specialGames";
import RefreshContainer from "../../Common/components/UI/RefreshContainer";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import JoinSpecialGamesInfo from "../UI/JoinSpecialGamesInfo";
import SpecialGamesShowMatches from "../UI/SpecialGamesShowMatches";
import AlertDialogWithConfirmButton from "../../Common/components/UI/AlertDialogWithConfirmButton";
import { joinSpecialGamesPageStyles } from "../styles/styles";

class JoinSpecialGamesPage extends React.Component {
  state = {
    refreshing: false,
    isModalVisible: false,
    showAlert: false,
  };

  onShowAlert = () => {
    this.setState({ showAlert: !this.state.showAlert });
  };

  _onRefresh = () => {
    this.setState({ refreshing: true });
    if (this.props.token) {
      this.props.getSpecialGameRoomInfo(
        this.props.navigation.getParam("roomId", 0)
      );
    }
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
    const roomId = this.props.navigation.getParam("roomId", 0);
    this.props.getSpecialGamesMatches(roomId);
  };

  onCloseGameRoom = () => {};

  componentDidUpdate(prevProps, prevState) {
    if (this.state.refreshing) {
      this.setState({ refreshing: false });
    }
  }

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    this.goToTop();
    if (this.props.token) {
      this.props.getSpecialGameRoomInfo(
        this.props.navigation.getParam("roomId", 0)
      );
    }
  }

  render() {
    const { specialRoomInfo, specialGamesMatches } = this.props;

    const type = this.props.navigation.getParam("type", null);
    const duration = this.props.navigation.getParam("duration", null);
    const roomId = this.props.navigation.getParam("roomId", 0);
    const info = this.props.navigation.getParam("info", null);

    const specialGamesButtonContainerStyle = [
      joinSpecialGamesPageStyles.specialGamesButtonContainer,
    ];

    if (info) {
      specialGamesButtonContainerStyle.push({
        alignItems: "center",
        justifyContent: "center",
      });
    }

    if (!specialRoomInfo) {
      return <LoadingScreen />;
    }
    return (
      <View style={joinSpecialGamesPageStyles.container}>
        <RefreshContainer
          refreshing={this.state.refreshing}
          onRefresh={this._onRefresh}
          style={joinSpecialGamesPageStyles.fieldsContainer}
        >
          <View style={joinSpecialGamesPageStyles.titleContainer}>
            <View style={joinSpecialGamesPageStyles.titleTextContainer}>
              <Text style={joinSpecialGamesPageStyles.roomNameText}>
                {specialRoomInfo.roomName}
              </Text>
              <Text style={joinSpecialGamesPageStyles.specialRoomText}>
                Special Room
              </Text>
              <View style={joinSpecialGamesPageStyles.weeklyGameImgContainer}>
                {specialRoomInfo.leagueInRoom.map((info, i) => (
                  <Image
                    source={{ uri: globalStyles.BASE_URL + info.leagueImg }}
                    style={joinSpecialGamesPageStyles.weeklyGameLeaguesImg}
                    key={i}
                  />
                ))}
              </View>
            </View>
            <View style={joinSpecialGamesPageStyles.roomInfoContainer}>
              <View style={joinSpecialGamesPageStyles.roomExtraInfoContainer}>
                <View style={joinSpecialGamesPageStyles.extraInfoContainer}>
                  <View style={joinSpecialGamesPageStyles.startEndContainer}>
                    <View style={joinSpecialGamesPageStyles.startContainer}>
                      <Text style={joinSpecialGamesPageStyles.startText}>
                        Start
                      </Text>
                    </View>
                    <View style={joinSpecialGamesPageStyles.endContainer}>
                      <Text style={joinSpecialGamesPageStyles.endText}>
                        {specialRoomInfo.startDate}
                      </Text>
                    </View>
                  </View>
                  <View style={joinSpecialGamesPageStyles.startEndContainer}>
                    <View style={joinSpecialGamesPageStyles.startContainer}>
                      <Text style={joinSpecialGamesPageStyles.startText}>
                        Ends
                      </Text>
                    </View>
                    <View style={joinSpecialGamesPageStyles.endContainer}>
                      <Text style={joinSpecialGamesPageStyles.endText}>
                        {specialRoomInfo.endDate}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={joinSpecialGamesPageStyles.extraInfoContainer}>
                  <View style={joinSpecialGamesPageStyles.startEndContainer}>
                    <View style={joinSpecialGamesPageStyles.startContainer}>
                      <Text style={joinSpecialGamesPageStyles.startText}>
                        Players
                      </Text>
                    </View>
                    <View style={joinSpecialGamesPageStyles.endContainer}>
                      <Text style={joinSpecialGamesPageStyles.playerText}>
                        {specialRoomInfo.players
                          ? specialRoomInfo.players.length
                          : 0}
                        /
                        {specialRoomInfo.maxPlayers
                          ? specialRoomInfo.maxPlayers
                          : "∞"}
                      </Text>
                    </View>
                  </View>
                  <View style={joinSpecialGamesPageStyles.startEndContainer}>
                    <View style={joinSpecialGamesPageStyles.startContainer}>
                      <Text style={joinSpecialGamesPageStyles.startText}>
                        You
                      </Text>
                    </View>
                    <View style={joinSpecialGamesPageStyles.endContainer}>
                      <Text style={joinSpecialGamesPageStyles.endText}></Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={joinSpecialGamesPageStyles.prizeContainer}>
                <View style={joinSpecialGamesPageStyles.prizeTextContainer}>
                  <Text style={joinSpecialGamesPageStyles.startText}>
                    Prize
                  </Text>
                  <Text style={joinSpecialGamesPageStyles.endText}>
                    {specialRoomInfo.prize1st}
                  </Text>
                </View>
                <View style={joinSpecialGamesPageStyles.prizeImageContainer}>
                  <Image
                    style={joinSpecialGamesPageStyles.prizeImage}
                    source={{
                      uri: globalStyles.BASE_URL + specialRoomInfo.prizeImage,
                    }}
                  />
                </View>
              </View>
            </View>
            <View style={specialGamesButtonContainerStyle}>
              <SpecialGamesShowMatches
                isModalVisible={this.state.isModalVisible}
                onChangeModalVisibility={this.toggleModal}
                leagues={specialGamesMatches}
              />
              {type || duration ? (
                <TouchableOpacity
                  style={joinSpecialGamesPageStyles.joinButtonContainer}
                  onPress={() => {
                    specialRoomInfo.maxPlayers &&
                    Number(specialRoomInfo.maxPlayers) ===
                      Number(specialRoomInfo.playersInRoom)
                      ? this.onShowAlert()
                      : this.props.joinSpecialGame(roomId);
                  }}
                >
                  <Text style={joinSpecialGamesPageStyles.joinText}>
                    {"JOIN"}
                  </Text>
                </TouchableOpacity>
              ) : null}
            </View>
          </View>
          <JoinSpecialGamesInfo
            {...specialRoomInfo}
            type={type}
            duration={duration}
            playersList={specialRoomInfo.players}
            roomId={this.props.navigation.getParam("roomId", 0)}
            navigation={this.props.navigation}
            rounds={specialRoomInfo.rounds}
          />
        </RefreshContainer>
        <AlertDialogWithConfirmButton
          isModalVisible={this.state.showAlert}
          onChangeModalVisibility={this.onShowAlert}
          onCloseGameRoom={this.onCloseGameRoom}
          title={"The room is full"}
          navigation={this.props.navigation}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    specialRoomInfo: state.specialGames.specialRoomInfo,
    specialGamesMatches: state.specialGames.specialGamesMatches,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSpecialGameRoomInfo: (roomId) =>
      dispatch(getSpecialGameRoomInfo(roomId)),
    getSpecialGamesMatches: (roomId) =>
      dispatch(getSpecialGamesMatches(roomId)),
    joinSpecialGame: (roomId) => dispatch(joinSpecialGame(roomId)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JoinSpecialGamesPage);
