import {
  SET_USER_PROFILE,
  DELETE_PROFILE,
  SET_USER_PROFILE_IMAGE,
  SET_HAS_MATCHED,
  SET_CONTACT_US_MESSAGE,
} from "./actionTypes";
import { authFetch, authFetchNoToken } from "../../store/actions/token";
import { deleteUser } from "../../../Login/actions/auth";

export const getUserProfile = (userId) => {
  return (dispatch) => {
    dispatch(authFetch("/user/profile/" + userId))
      .catch((err) => console.log(err))
      .then((res) => {
        if (res && res.data) {
          dispatch(setUserProfile(res.data));
        }
      });
  };
};

export const updateProfile = (
  newPassword,
  firstName,
  lastName,
  userName,
  dateOfBirth,
  gender,
  nationality,
  notifications_settings
) => {
  const data = {
    newPassword: newPassword,
    firstName: firstName,
    lastName: lastName,
    user_name: userName,
    dateOfBirth: dateOfBirth,
    gender: gender,
    nationality: nationality,
    notifications_settings: notifications_settings,
  };
  return (dispatch) => {
    dispatch(authFetch("/users", "PUT", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setUserProfile(res.data));
      });
  };
};

export const setUserProfile = (data) => {
  return {
    type: SET_USER_PROFILE,
    data,
  };
};

export const deleteAccount = () => {
  return (dispatch) => {
    dispatch(authFetch("/users", "DELETE"))
      .catch((err) => console.log(err))
      .then((res) => {
        if (res && res.message === "User deleted successfully") {
          dispatch(deleteUser());
        }
      });
  };
};

export const deleteProfile = () => {
  return {
    type: DELETE_PROFILE,
  };
};

export const checkPasswordMatch = (data) => {
  return (dispatch) => {
    dispatch(authFetchNoToken("/auth/login", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setPasswordMatch(res.has_error));
      });
  };
};

export const setPasswordMatch = (rightPassword) => {
  return {
    type: SET_HAS_MATCHED,
    rightPassword,
  };
};

export const onContactUs = (data) => {
  return (dispatch) => {
    dispatch(authFetch("/users/contact", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setContactUsMessage(res.message));
      });
  };
};

export const setContactUsMessage = (message) => {
  return {
    type: SET_CONTACT_US_MESSAGE,
    message,
  };
};

export const uploadImage = (base64) => {
  const data = { base64: base64 };
  return (dispatch) => {
    dispatch(authFetch("/users/images", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setUserProfile(res.data));
      });
  };
};

export const setUserProfileImage = (image) => {
  return {
    type: SET_USER_PROFILE_IMAGE,
    image,
  };
};
