import React from "react";
import { View, ScrollView, TextInput, Dimensions } from "react-native";
import globalStyles from "../../globalStyles";
import { connect } from "react-redux";
import ScreenTitleText from "../components/UI/ScreenTitleText";
import DefaultInput from "../components/UI/DefaultInput";
import ConfirmButton from "../components/UI/ConfirmButton";
import AlertDialogWithConfirmButton from "../components/UI/AlertDialogWithConfirmButton";
import { onContactUs, setContactUsMessage } from "./actions/profile";
import { contactUsStyles } from "./drawerStyles/styles";

class ContactUsScreen extends React.Component {
  state = {
    email: null,
    fullName: null,
    subject: null,
    message: null,
    isModalVisible: false,
    emailIncorrect: false,
    fullNameIncorrect: false,
    subjectIncorrect: false,
    messageIncorrect: false,
    windowWidth: 1000,
  };
  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  emailChangedHandler = (val) => {
    this.setState({ email: val });
    if (this.state.email) {
      this.setState({ emailIncorrect: false });
    }
  };
  fullNameChangedHandler = (val) => {
    this.setState({ fullName: val });
    if (this.state.fullName) {
      this.setState({ fullNameIncorrect: false });
    }
  };
  subjectChangedHandler = (val) => {
    this.setState({ subject: val });
    if (this.state.subject) {
      this.setState({ subjectIncorrect: false });
    }
  };
  messageChangedHandler = (val) => {
    this.setState({ message: val });
    if (this.state.message) {
      this.setState({ messageIncorrect: false });
    }
  };

  onSend = () => {
    if (this.checkForEmptyFields()) {
      const { email, fullName, subject, message } = this.state;
      const data = {
        email: email,
        fullName: fullName,
        subject: subject,
        message: message,
      };
      this.props.onContactUs(data);
      this.setState({
        email: null,
        fullName: null,
        subject: null,
        message: null,
      });
    }
  };
  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  componentDidMount() {
    this.goToTop();
    window.addEventListener("resize", () => {
      this.setState({ windowWidth: window.innerWidth });
    });

    let { width } = Dimensions.get("window");
    this.setState({ windowWidth: width });
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.contactUsMessage &&
      prevProps.contactUsMessage != this.props.contactUsMessage
    ) {
      this.props.setContactUsMessage(null);
      this.toggleModal();
    }
  }
  onCloseGameRoom = () => {
    this.toggleModal();
    this.props.navigation.goBack();
  };

  checkForEmptyFields() {
    const { email, fullName, subject, message } = this.state;

    if (!email) {
      this.setState({
        emailIncorrect: true,
        errorEmail: "Please provide non empty email address",
      });
      return false;
    }
    if (email) {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email) === false) {
        this.setState({
          emailIncorrect: true,
          errorEmail: "Please provide correct email address",
        });
        return false;
      }
    }
    if (!fullName) {
      this.setState({
        fullNameIncorrect: true,
        errorName: "Please write your name",
      });
      return false;
    }
    if (!subject) {
      this.setState({
        subjectIncorrect: true,
        errorSubject: "Please write a subject",
      });
      return false;
    }
    if (!message) {
      this.setState({ messageIncorrect: true });
      return false;
    }
    this.setState({
      emailIncorrect: false,
      fullNameIncorrect: false,
      subjectIncorrect: false,
      messageIncorrect: false,
      errorEmail: null,
      errorName: null,
      errorSubject: null,
    });
    return true;
  }

  render() {
    const {
      email,
      fullName,
      subject,
      message,
      emailIncorrect,
      fullNameIncorrect,
      subjectIncorrect,
      messageIncorrect,
    } = this.state;

    return (
      <View style={contactUsStyles.container}>
        <ScrollView
          contentContainerStyle={contactUsStyles.fieldsContainer}
          ref="_scrollView"
        >
          <ScreenTitleText
            title="Contact Us"
            extraText="Let us know if you have any questions:"
          />
          <View style={contactUsStyles.contactUsContainer}>
            <DefaultInput
              placeholderText={"E-Mail Address"}
              inputValue={email}
              onChangeText={this.emailChangedHandler}
              keyboardType={"email-address"}
              correctInput={emailIncorrect}
              error={this.state.errorEmail}
              ref="email"
              autoCapitalize="none"
              editable={true}
            />
            <DefaultInput
              placeholderText={"Full Name"}
              inputValue={fullName}
              onChangeText={this.fullNameChangedHandler}
              editable={true}
              correctInput={fullNameIncorrect}
              error={this.state.errorName}
              ref="fullName"
              keyboardType={"default"}
            />
            <DefaultInput
              placeholderText={"Subject"}
              inputValue={subject}
              onChangeText={this.subjectChangedHandler}
              editable={true}
              correctInput={subjectIncorrect}
              error={this.state.errorSubject}
              ref="subject"
              keyboardType={"default"}
            />
            <View style={contactUsStyles.inputBox}>
              <TextInput
                placeholderTextColor={
                  messageIncorrect ? globalStyles.red : globalStyles.greyText
                }
                placeholder={"Write your message here..."}
                value={message}
                onChangeText={this.messageChangedHandler}
                style={contactUsStyles.inputText}
                ref="message"
                multiline={true}
              />
            </View>
          </View>
          <View style={contactUsStyles.confirmButtonContainer}>
            <ConfirmButton title={"SEND"} onPress={() => this.onSend()} />
          </View>
        </ScrollView>
        <AlertDialogWithConfirmButton
          isModalVisible={this.state.isModalVisible}
          onChangeModalVisibility={this.toggleModal}
          onCloseGameRoom={this.onCloseGameRoom}
          title={"We received your message."}
          navigation={this.props.navigation}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contactUsMessage: state.userProfileReducer.contactUsMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onContactUs: (data) => dispatch(onContactUs(data)),
    setContactUsMessage: (message) => dispatch(setContactUsMessage(message)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactUsScreen);
