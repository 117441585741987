import React from 'react';
import {
  Text,
  View,
  Image,
  TouchableOpacity
} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import liveDot from '../../../assets/live_dot.png';
import { connect } from 'react-redux';

class HomeNavButton extends React.Component {

  onButtonPress = () => {
    this.props.onPress();
    if (this.props.onGetData) {
      this.props.onGetData()
    }
  }

  render() {
    const { style, id, activeButtonId, todayMatches, title, liveMatches, onGetData, reactRem } = this.props;
    let btnSelected = [styles.btnSelected];
    let btnUnselected = [styles.btnUnselected];
    if (style) {
      btnSelected.push(style);
      btnUnselected.push(style);
    }
    let titleText = title;
    if (todayMatches > -1) {
      titleText = title + " (" + todayMatches + ")";
    }
    if (liveMatches > -1) {
      titleText = title + " (" + liveMatches + ")";
    }

    return (
      <TouchableOpacity style={[btnUnselected, activeButtonId === id ? btnSelected : null]} onPress={() => this.onButtonPress()}>
        {title === "Live" ? <Image source={liveDot} style={[styles.liveDotImage, {
          width: 1.5 * reactRem,
          height: 1.5 * reactRem,
          marginTop: 0.2 * reactRem,
          marginRight: 0.5 * reactRem
        }]} />
          : null}
        <Text style={[styles.btnTextUnselected, { fontSize: 2.6 * reactRem }, activeButtonId === id ? [styles.btnTextSelected, { fontSize: 2.6 * reactRem }] : null]}>
          {titleText}
        </Text>
      </TouchableOpacity>

    );

  }

}

const styles = EStyleSheet.create({
  btnTextSelected: {
    color: globalStyles.white,
    textAlign: "center",
    textAlignVertical: "center",
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: "2.6rem"
  },
  btnTextUnselected: {
    color: globalStyles.textBlue,
    textAlign: "center",
    textAlignVertical: "center",
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: "2.6rem"
  },

  btnSelected: {
    flex: 1,
    backgroundColor: globalStyles.activeBlue,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: "row"
  },
  btnUnselected: {
    flex: 1,
    backgroundColor: globalStyles.white,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: "row"
  },
  liveDotImage: {
    width: "1.5rem",
    height: "1.5rem",
    resizeMode: "contain",
    marginTop: "0.2rem",
    marginRight: "0.5rem"
  }
});

const mapStateToProps = state => {
  return {
    reactRem: state.homeScreenLeagues.reactRem,
  };
};


export default connect(mapStateToProps, null)(HomeNavButton);