import React from 'react';
import { TextInput, StyleSheet } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import { connect } from 'react-redux';

class LoginInputText extends React.Component {

    render() {
        const { inputValue, onChangeText, placeholderText, password, keyboardType, onFocus, style, reactRem } = this.props;
        return (
            <TextInput
                underlineColorAndroid="transparent"
                placeholderTextColor={globalStyles.textBlue}
                placeholder={placeholderText}
                value={inputValue}
                onChangeText={onChangeText}
                onFocus={onFocus}
                onKeyPress={this.props.onKeyPress}
                secureTextEntry={password}
                keyboardType={keyboardType}
                style={[styles.inputBox, style, { outline: "none", border: "none", fontSize: 2.4 * reactRem, height: 7 * reactRem, paddingHorizontal: 2 * reactRem }]}
                autoCapitalize='none' />
        );
    }
}

const styles = EStyleSheet.create({
    inputBox: {
        borderBottomWidth: 1.5,
        borderBottomColor: globalStyles.backgroundGrey,
        backgroundColor: globalStyles.white,
        color: globalStyles.textBlue,
        paddingHorizontal: "2rem",
        height: "7rem",
        width: "100%",
        fontFamily: globalStyles.fontUbuntuMedium,
        fontSize: "2.4rem",
    },
});
const mapStateToProps = (state, ownProps) => {
    return {
        reactRem: state.homeScreenLeagues.reactRem,
    }
}

export default connect(mapStateToProps, null)(LoginInputText);