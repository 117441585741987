import React from "react";
import {
  Text,
  View,
  StyleSheet,
  Animated,
  TouchableOpacity
} from "react-native";
import globalStyles from "../../../globalStyles";




class AnimatableProgressbarView extends React.Component {

  state = {
    stateWidth: new Animated.Value(0),
  };

  _start = () => {
    Animated.timing(this.state.stateWidth, {
      toValue: this.props.widthTo,
      duration: 1400
    }).start();
  };


  componentDidMount(){
    this._start();

  }


  componentDidUpdate(prevProps){
        this._start();
  }


  render() {

    return (    
        <Animated.View
          source={this.props.image}
          style={[{
            width:this.state.stateWidth.interpolate({
                inputRange: [0, 100],
                outputRange: ['0%', '100%'],
              }),
          },this.props.style]}
        >
        {this.props.children}
    </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  

  

 
});
export default AnimatableProgressbarView;