import React from "react";
import { View, Text } from "react-native";
import { clientMessageFieldStyle } from "../styles/styles";

class ClientMessageField extends React.Component {
  render() {
    const { message, time } = this.props;
    return (
      <View style={clientMessageFieldStyle.messageContainer}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <View style={clientMessageFieldStyle.messageTextContainer}>
            <Text style={clientMessageFieldStyle.messageText}>{message}</Text>
          </View>
        </View>
        <View style={clientMessageFieldStyle.messageTimeContainer}>
          <Text style={clientMessageFieldStyle.messageTime}>{time}</Text>
        </View>
      </View>
    );
  }
}

export default ClientMessageField;
