import React from 'react';
import {
    Text,
    View,
    FlatList,
    Image
} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import StandingsFeild from './StandingsField';

class StandingsGroupStages extends React.Component {
    render() {
        const { group, teams, reactRem } = this.props;

        return (
            <View style={[styles.standingsContainer, { paddingHorizontal: 0.5 * reactRem }]}>
                <View style={[styles.listItem, {
                    marginTop: 0.5 * reactRem,
                    borderTopRightRadius: reactRem,
                    borderTopLeftRadius: reactRem,
                    paddingVertical: reactRem,
                    paddingLeft: reactRem
                }]}>
                    <Text style={[styles.leagueTitle, { fontSize: 2.6 * reactRem, padding: reactRem }]}>Group {group}</Text>
                </View>
                <View style={[styles.textTableContainer, { paddingRight: reactRem }]}>
                    <View style={{ flexDirection: "row" }}>
                        <View style={[styles.numberTextContainer, { height: 8 * reactRem, width: 8 * reactRem }]}>
                            <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>No.</Text>
                        </View>
                        <View style={[styles.teamImg, { height: 5.5 * reactRem, width: 5.5 * reactRem, marginHorizontal: 0.5 * reactRem }]} />
                        <View style={styles.teamRankContainer}>
                            <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>Team</Text>
                        </View>
                    </View>
                    <View style={[styles.rankingStatsContainer, { paddingRight: 0.5 * reactRem, marginLeft: 10 * reactRem }]}>
                        <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>M</Text>
                        <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>W</Text>
                        <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>D</Text>
                        <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>L</Text>
                        <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>GD</Text>
                        <Text style={[styles.defaultText, { fontSize: 2.4 * reactRem }]}>PTS</Text>
                    </View>
                </View>
                <View style={[styles.flatListContainer, { marginBottom: 10 * reactRem }]}>
                    <FlatList
                        data={teams}
                        keyExtractor={(item, index) => 'key' + index}
                        renderItem={({ item, index }) => (
                            <StandingsFeild {...item} index={index} length={teams.length} reactRem={reactRem} />
                        )} />
                </View>

            </View>
        );
    }
}

const styles = EStyleSheet.create({
    standingsContainer: {
        paddingHorizontal: "0.5rem"
    },
    listItem: {
        width: "100%",
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: "space-between",
        backgroundColor: globalStyles.black,
        marginTop: "0.5rem",
        borderTopRightRadius: "1rem",
        borderTopLeftRadius: "1rem",
        paddingVertical: "1rem",
        paddingLeft: "1rem"
    },
    leagueTitle: {
        color: globalStyles.white,
        fontFamily: globalStyles.fontUbuntuMedium,
        textAlignVertical: "center",
        fontSize: "2.2rem",
        padding: "1rem"
    },
    textTableContainer: {
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: "center",
        backgroundColor: globalStyles.white,
        opacity: 0.7
    },
    numberTextContainer: {
        height: "5rem",
        width: "5rem",
        alignItems: "center",
        justifyContent: "center",
        borderRightWidth: 1,
        borderRightColor: globalStyles.backgroundGrey
    },
    defaultText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuRegular,
        fontSize: "1.8rem"
    },
    teamImg: {
        height: "3.5rem",
        width: "3.5rem",
        marginHorizontal: "0.5rem",
    },
    teamRankContainer: {
        flexDirection: "row",
        alignItems: "center",
    },
    rankingStatsContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "0.5rem",
        marginLeft: "10rem",
        width: "50%",
    },
    flatListContainer: {
        marginBottom: "10rem",
    },
});
export default StandingsGroupStages;