import React from "react";
import { View, Text } from "react-native";
import DefaultWhiteButton from "../../Common/components/UI/DefaultWhiteButton";
import LoginInputText from "../../Common/components/UI/LoginInputText";
import { changePassword, deleteChangedPassword } from "../actions/auth";
import { connect } from "react-redux";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import { forgotPasswordStyles } from "../styles/styles";

class ForgotPasswordScreen extends React.Component {
  state = {
    email: null,
  };

  emailChangedHandler = (val) => {
    this.setState({ email: val });
    this.setState({ error: null });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
    if (prevProps.changedPassword !== this.props.changedPassword) {
      this.props.deleteChangedPassword();
      this.props.navigation.goBack();
    }
  }

  onEmptyText = () => {
    this.setState({ error: "Please enter an e-mail" });
  };

  render() {
    const { onChangePassword, isLoading, reactRem } = this.props;
    const { error, email } = this.state;
    return (
      <View style={forgotPasswordStyles.container}>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <View style={forgotPasswordStyles.componentContainer}>
            <View style={forgotPasswordStyles.resetPasswordTitleContainer}>
              <Text style={forgotPasswordStyles.resetPasswordTitleText}>
                Reset Password
              </Text>
            </View>
            <View style={forgotPasswordStyles.resetPasswordTextContainer}>
              <Text style={forgotPasswordStyles.resetPasswordText}>
                Submit your e-mail address and we'll send you a link to reset
                your password
              </Text>
            </View>
            <LoginInputText
              reactRem={reactRem}
              placeholderText="Type your e-mail address"
              inputValue={this.state.email}
              onChangeText={this.emailChangedHandler}
              keyboardType={"email-address"}
              style={forgotPasswordStyles.emailInput}
            />
            {error ? (
              <Text style={forgotPasswordStyles.errorMessage}>{error}</Text>
            ) : null}
            <DefaultWhiteButton
              reactRem={reactRem}
              onPress={() => {
                email ? onChangePassword(this.state.email) : this.onEmptyText();
              }}
            >
              Send New Password
            </DefaultWhiteButton>
          </View>
        )}
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onChangePassword: (email) => dispatch(changePassword(email)),
    deleteChangedPassword: () => dispatch(deleteChangedPassword()),
  };
};
const mapStateToProps = (state) => {
  return {
    changedPassword: state.auth.changedPassword,
    error: state.errorReducer.error,
    reactRem: state.homeScreenLeagues.reactRem,
    isLoading: state.loadingReducer.isLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordScreen);
