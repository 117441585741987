import React from "react";
import {
  Text,
  View,
  StyleSheet,
  Animated,
  TouchableOpacity
} from "react-native";
import globalStyles from "../../../globalStyles";




class AnimatableImage extends React.Component {

  state = {
    stateHeight: new Animated.Value(60),
    stateWidth: new Animated.Value(60),
  };

  _start = () => {
    Animated.timing(this.state.stateHeight, {
      toValue: 30,
      duration: 1400
    }).start();
    Animated.timing(this.state.stateWidth, {
        toValue: 30,
        duration: 1400
      }).start();
  };


  
  _start2 = () => {
    Animated.timing(this.state.stateHeight, {
        toValue: 60,
        duration: 1400
      }).start();
      Animated.timing(this.state.stateWidth, {
          toValue: 60,
          duration: 1400
        }).start();
   
  };


  componentDidUpdate(prevProps){
    if(this.props.offset!=0){
        this._start();
    }
    
    if(prevProps.offset&& prevProps.offset>0&& this.props.offset===0){
        this._start2();
    }
  }


  render() {
    return (    
        <Animated.Image
          source={this.props.image}
          style={{
            height: this.state.stateHeight,
            width:this.state.stateWidth,
            resizeMode:"contain"
          }}
        >
        {this.props.children}
    </Animated.Image>
    );
  }
}

const styles = StyleSheet.create({
  

  

 
});
export default AnimatableImage;