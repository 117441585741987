import {
  SET_LIVE_MATCH_DATA,
  SET_LIVE_MATCH_LINEUPS,
  RESET_LIVE_SCORE,
} from "./actionTypes";
import { authFetch, authFetchNoToken } from "../../Common/store/actions/token";
import { uiStartLoading, uiStopLoading } from "../../Common/store/actions/ui";

export const getLiveMatchData = (matchId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/users/match/" + matchId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setLiveMatchData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getLiveMatchDataNoToken = (matchId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetchNoToken("/users/match/" + matchId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setLiveMatchData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getLiveMatchLineUps = (matchId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetchNoToken("/users/match/" + matchId + "/lineups"))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setLiveMatchLineUps(res ? res.data : null));
        dispatch(uiStopLoading());
      });
  };
};

export const setLiveMatchData = (data) => {
  return {
    type: SET_LIVE_MATCH_DATA,
    data,
  };
};

export const setLiveMatchLineUps = (data) => {
  return {
    type: SET_LIVE_MATCH_LINEUPS,
    data,
  };
};

export const resetLiveScore = () => {
  return {
    type: RESET_LIVE_SCORE,
  };
};
