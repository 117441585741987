import React from 'react';
import { Image, View, Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import HalfTimeImg from '../../../assets/livescore/first-second-half.png';

import SubsImg from '../../../assets/livescore/substitute.png';

class LineupsHomePlayerSubstitutions extends React.Component {

    render() {
        const { info, reactRem } = this.props;
        // lineup_player,lineup_number,lineup_position,playerOut,yellowCard,goalScorer
        const playerInfoContainerStyle = [styles.playerInfoContainer, { paddingBottom: reactRem }];
        const playerContainerStyle = [styles.playerContainer, {
            height: 4 * reactRem,
            paddingLeft: reactRem,
        }];
        const playerInTextStyle = [styles.playerInText, { fontSize: 1.6 * reactRem }];
        const playerOutTextStyle = [styles.playerOutText, { fontSize: 1.6 * reactRem }];
        const subsImgContainerStyle = [styles.subsImgContainer, { marginTop: 0.5 * reactRem }];
        const commentImgStyle = [styles.commentImg, {
            width: 2 * reactRem,
            height: 2 * reactRem,
            marginLeft: reactRem
        }]
        return (
            <View style={playerInfoContainerStyle}>
                <View style={subsImgContainerStyle}>
                    <Image source={SubsImg} style={commentImgStyle} />
                </View>
                <View style={playerContainerStyle}>
                    <Text style={playerOutTextStyle}>{info.homeOut}</Text>
                    <Text style={playerInTextStyle}>{info.homeIn}</Text>
                </View>

            </View>
        );
    }
}

const styles = EStyleSheet.create({
    playerInfoContainer: {
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: "1.5rem",
    },
    playerContainer: {
        height: "4rem",
        paddingLeft: "1rem",
        justifyContent: "space-between",
    },
    playerInText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuBold,
        fontSize: "1.6rem",
    },
    playerOutText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuRegular,
        fontSize: "1.6rem",
    },
    subsImgContainer: {
        height: "100%",
        marginTop: "0.5rem"
    },
    commentImg: {
        width: "2rem",
        height: "2rem",
        resizeMode: "contain",
        marginLeft: "1rem",
    },
});
export default LineupsHomePlayerSubstitutions;