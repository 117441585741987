
class IntervalUpdater {

  addListeners = (navigation, method) => {
    if (navigation) {
      clearInterval(this._interval);
      this._interval = setInterval(() => {
        method();
      }, 60000);
    }
  }

  clearListeners = () => {
    clearInterval(this._interval);
  }
}


export default IntervalUpdater;

