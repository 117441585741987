import globalStyles from "../../globalStyles";
import EStyleSheet from "react-native-extended-stylesheet";

const createFriendlyRoomStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    paddingVertical: 30,
    paddingHorizontal: 6,
  },
  pickerContainer: {
    width: "100%",
    backgroundColor: globalStyles.white,
    alignItems: "center",
    borderRadius: 3,
  },
  confirmButtonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12,
    width: "100%",
  },
  errorMessageContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 12,
  },
  errorMessage: {
    color: globalStyles.error,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 15,
  },
  listItem: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    marginTop: 3,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingVertical: 6,
    paddingLeft: 6,
  },
  placeImageContainer: {
    marginRight: 6,
    padding: 3,
  },
  placeImage: {
    height: 24,
    width: 24,
    resizeMode: "contain",
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: 18,
    height: "100%",
  },
  lastPicker: {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomWidth: 0,
  },
  discardButtonContainer: {
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    padding: 6,
  },
  discardText: {
    fontSize: 16,
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    textAlign: "center",
  },
  button: {
    backgroundColor: globalStyles.activeBlue,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    padding: 6,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  text: {
    fontSize: 16,
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    textAlign: "center",
  },
});

const createH2HRoomStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    paddingVertical: 30,
    paddingHorizontal: 6,
  },
  pickerContainer: {
    width: "100%",
    backgroundColor: globalStyles.white,
    alignItems: "center",
    borderRadius: 3,
  },
  confirmButtonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12,
    width: "100%",
  },
  errorMessageContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 12,
  },
  errorMessage: {
    color: globalStyles.error,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 15,
  },
  listItem: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    marginTop: 3,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingVertical: 6,
    paddingLeft: 6,
  },
  placeImageContainer: {
    marginRight: 6,
    padding: 3,
  },
  placeImage: {
    height: 24,
    width: 24,
    resizeMode: "contain",
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: 17,
    height: "100%",
  },
  lastPicker: {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomWidth: 0,
  },
  discardButtonContainer: {
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    padding: 6,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  discardText: {
    fontSize: 16,
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    textAlign: "center",
  },
  button: {
    backgroundColor: globalStyles.activeBlue,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    padding: 6,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  text: {
    fontSize: 16,
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    textAlign: "center",
  },
});

const depositStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  fieldsContainer: {
    width: "100%",

    paddingHorizontal: 6,
    paddingVertical: 18,
  },

  infoContainer: {
    width: "100%",
    backgroundColor: globalStyles.notPredictedColor,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 18,
    marginTop: 6,
    borderRadius: 3,
  },
  infoText: {
    color: globalStyles.borderRoom,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  learnMoreText: {
    color: globalStyles.darkBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    marginTop: 6,
    fontSize: 12,
  },

  roomContainer: {
    marginTop: 12,
  },
  confirmButtonContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: 25,
    width: "100%",
  },
});

const friendlyRoomPaimentStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  fieldsContainer: {
    width: "100%",
    paddingHorizontal: 6,
    paddingVertical: 18,
  },

  infoContainer: {
    width: "100%",
    height: 100,
    backgroundColor: globalStyles.notPredictedColor,
    justifyContent: "flex-start",
    padding: 12,
    marginTop: 6,
    borderRadius: 3,
  },
  infoText: {
    color: globalStyles.borderRoom,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  learnMoreText: {
    color: globalStyles.darkBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    marginTop: 6,
    fontSize: 12,
  },

  roomContainer: {
    marginTop: 12,
  },

  confirmButtonContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },

  privateRoomTextContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  privateText: {
    color: globalStyles.basicBlueGreyColor,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  roomText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
  },

  pickerLeagueContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 6,
    borderBottomWidth: 0.5,
    borderBottomColor: globalStyles.borderRoom,
  },
  pickerText: {
    color: globalStyles.mainBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  pickerTextContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  totalText: {
    color: globalStyles.borderRoom,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  totalResultText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
    marginLeft: 6,
  },
});

const joinRoomStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  fieldsContainer: {
    width: "100%",
    paddingHorizontal: 6,
    paddingVertical: 18,
  },
  inputContainer: {
    width: "100%",
    backgroundColor: globalStyles.notPredictedColor,
    justifyContent: "center",
    alignItems: "center",
    padding: 18,
    borderRadius: 6,
  },
  confirmButtonContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: 24,
    width: "100%",
  },
  errorMessageContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 12,
  },
  errorMessage: {
    color: globalStyles.red,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 16,
  },
});

const privateRoomsStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  fieldsContainer: {
    width: "100%",
    paddingHorizontal: 6,
    paddingVertical: 18,
  },
  infoContainer: {
    width: "100%",
    backgroundColor: globalStyles.notPredictedColor,
    justifyContent: "space-between",
    alignItems: "center",
    height: 110,
    padding: 12,
    marginTop: 6,
    borderRadius: 3,
  },
  infoText: {
    color: globalStyles.borderRoom,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  learnMoreText: {
    color: globalStyles.darkBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    marginTop: 6,
    fontSize: 12,
  },
  roomContainer: {
    marginTop: 12,
  },
  confirmButtonContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  privateRoomTextContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  privateText: {
    color: globalStyles.basicBlueGreyColor,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  roomText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 12,
  },
});

const roomStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    alignItems: "center",
    borderBottomColor: globalStyles.borderRoom,
  },
  buttonContainer: {
    flexDirection: "row",
    width: "100%",
    height: 64,
    borderBottomWidth: 0.5,
    borderBottomColor: globalStyles.borderRoom,
  },
  borderRight: {
    borderLeftWidth: 0.5,
    borderLeftColor: globalStyles.borderRoom,
    borderRightWidth: 0.5,
    borderRightColor: globalStyles.borderRoom,
  },
  roomsContainer: {
    flex: 1,
    backgroundColor: globalStyles.backgroundGrey,
    paddingHorizontal: 3,
    paddingTop: 6,
    paddingBottom: 72,
  },
  listItem: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    marginTop: 3,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingVertical: 6,
    paddingLeft: 6,
  },
  placeImageContainer: {
    marginRight: 6,
    padding: 3,
  },
  placeImage: {
    height: 30,
    width: 30,
    resizeMode: "contain",
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: 16,
    height: "100%",
  },
  gameWeekContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 18,
  },
  editRoomsContainer: {
    width: "100%",
    height: "100%",
  },
  signInUpContainer: {
    backgroundColor: globalStyles.backgroundGrey,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    paddingVertical: 6,
  },
  signInContainer: {
    backgroundColor: globalStyles.white,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 18,
    paddingVertical: 10,
    minWidth: 120,
    maxWidth: 120,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 18,
    paddingVertical: 10,
    minWidth: 120,
    maxWidth: 120,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  signUpText: {
    color: globalStyles.white,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 16,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  signInText: {
    color: globalStyles.textBlue,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 16,
    fontFamily: globalStyles.fontUbuntuBold,
  },
});

const roomLeagueStyles = EStyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    flex: 1,
  },
  fieldsContainer: {
    width: "100%",
  },
  roomPlayersContainer: {
    marginTop: 3,
    paddingHorizontal: 3,
    width: "100%",
  },
  infoTableTextContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 36,
    backgroundColor: globalStyles.white,
    opacity: 0.7,
  },
  positionTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "10.4%",
    paddingVertical: 6,
    paddingHorizontal: 10,
    borderRightWidth: 1.5,
    borderRightColor: globalStyles.backgroundGrey,
  },
  nameTextContainer: {
    paddingLeft: 26,
    width: "65%",
  },
  totalTextContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "12%",
  },
  totalText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 14,
  },
  infoTableText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: 14,
  },
  infoTableContainer: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 10,
  },
  playersContainer: {
    width: "100%",
  },
  predictionsContainer: {
    width: "100%",
    paddingHorizontal: 3,
  },
  buttonContainer: {
    flexDirection: "row",
    width: "100%",
    height: "9rem",
    borderBottomWidth: 0.5,
    borderBottomColor: globalStyles.borderRoom,
    backgroundColor: "blue",
  },
  listItem: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingVertical: 12,
    paddingLeft: 12,
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: 17,
    height: "100%",
  },
  lastTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "12%",
    padding: 6,
    marginRight: 3,
  },
  totalArrow: {
    width: 6,
    height: 6,
    resizeMode: "contain",
  },
  loadMoreButtonContainer: {
    backgroundColor: globalStyles.white,
    opacity: 0.7,
    justifyContent: "center",
    alignItems: "center",
    width: "99%",
    paddingVertical: 12,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    marginHorizontal: 3,
  },
  loadMoreButtonText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 14,
  },
  placeContainer: {
    height: 60,
    width: "100%",
  },
  roomPlayerPadding: {
    marginTop: 6,
    paddingHorizontal: 3,
    width: "100%",
  },
});

const roomPredictionsStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
  },
  fieldsContainer: {
    width: "97%",
  },
  leagueContainer: {
    flex: 6,
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingHorizontal: 6,
    backgroundColor: "red",
  },
});

const successfullyCreatedRoomStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
    alignItems: "center",
    paddingVertical: 30,
    paddingHorizontal: 6,
  },
  roomContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: globalStyles.white,
    width: "100%",
    borderRadius: 10,
    paddingTop: 18,
    marginHorizontal: 3,
  },
  shareImg: {
    height: 30,
    width: 18,
    resizeMode: "contain",
  },
  congratulationsText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 20,
  },
  roomNameContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 18,
    paddingTop: 6,
  },
  roomNameText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 16,
  },
  defaultText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 15,
  },
  roomCodeContainer: {
    flexDirection: "row",
    backgroundColor: globalStyles.error,
    borderRightWidth: 0.5,
    borderRightColor: globalStyles.white,
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    padding: 12,
  },
  roomCodeText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 14,
    marginRight: 6,
  },
  codeText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 16,
  },
  menuContainer: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: globalStyles.error,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    paddingHorizontal: 12,
  },
  continueContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 15,
  },
  continueText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 17,
  },
});

const animationRoomLeagueTitleStyles = EStyleSheet.create({
  titleContainer: {
    width: "100%",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    paddingBottom: 6,
    marginTop: 10,
  },
  mainContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  titleDontsContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 10,
  },
  titleTextContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "90%",
  },
  dotsContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "10%",
    padding: 12,
  },
  dotsImg: {
    width: 18,
    height: 10,
    resizeMode: "contain",
  },
  compareImg: {
    width: 25,
    height: 25,
    resizeMode: "contain",
  },
  title: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 16,
    marginLeft: 60,
  },
  roomTypeText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
  },
  leagueImagesContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 6,
  },
  leagueIcon: {
    width: 36,
    height: 36,
    marginHorizontal: 2,
    resizeMode: "contain",
  },
  compareText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: 14,
  },
  searchBoxContainer: {
    width: "100%",
    borderTopWidth: 1,
    borderTopColor: globalStyles.backgroundGrey,
    padding: 12,
  },
  searchInputContainer: {
    width: "95%",
  },
  searchInput: {
    flexDirection: "row",
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: 15,
    height: 36,
    alignItems: "center",
    justifyContent: "center",
  },
  searchImg: {
    width: 21,
    height: 30,
    resizeMode: "contain",
  },
  searchContainer: {
    width: "85%",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: globalStyles.searchBacground,
    borderRadius: 6,
    paddingHorizontal: 3,
  },
  searchBoxAndButtonContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  searchImageContainer: {
    width: "10%",
    alignItems: "center",
  },
  clearImageContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: globalStyles.clearButton,
    borderRadius: 10,
    width: 30,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
  },
  clearImage: {
    color: globalStyles.backgroundGrey,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 14,
  },
  cancelContainer: {
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
    padding: 6,
  },
  cancelText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
  },
  playerNameContainer: {
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    paddingTop: 12,
  },
  playerNameContainerSelected: {
    alignItems: "center",
    flexDirection: "row",
  },
  roundPointsContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  profileImage: {
    height: 36,
    width: 36,
    borderRadius: 18,
    paddingLeft: 3,
    marginLeft: 3,
    resizeMode: "contain",
  },
  betPlayerText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.textBlue,
    fontSize: 14,
    paddingLeft: 6,
    opacity: 0.7,
  },
  betPlayerUsername: {
    fontFamily: globalStyles.fontUbuntuBold,
    color: globalStyles.textBlue,
    fontSize: 15,
    paddingLeft: 6,
  },
  userCompareContainer: {
    flexDirection: "row",
    backgroundColor: globalStyles.backgroundGrey,
    paddingHorizontal: 10,
    paddingTop: 12,
    paddingBottom: 3,
    width: "100%",
    alignItems: "center",
  },
  selectedUserContainer: {
    width: "40%",
  },
  selectedUserText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 14,
    paddingLeft: 3,
    marginLeft: 3,
  },
  vsContainer: {
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
  },
  vsText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 18,
  },
});

const createRoomLeaguePickerStyles = EStyleSheet.create({
  pickerContainer: {
    position: "absolute",
    top: "19%",
    left: 6,
    width: "98%",
    backgroundColor: globalStyles.white,
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderRadius: 3,
    height: 180,
    borderColor: globalStyles.backgroundGrey,
    borderWidth: 1,
  },
  pickerContainer2: {
    position: "absolute",
    top: "24%",
    left: 6,
    width: "98%",
    backgroundColor: globalStyles.white,
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderRadius: 3,
    height: 180,
    borderColor: globalStyles.backgroundGrey,
    borderWidth: 1,
  },
});

const createRoomLeaguePickerFieldStyles = EStyleSheet.create({
  pickerLeagueContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 6,
    borderBottomWidth: 0.5,
    borderBottomColor: globalStyles.borderRoom,
  },
  pickerText: {
    color: globalStyles.mainBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 16,
  },
  pickerTextContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  leagueIcon: {
    width: 30,
    height: 30,
    marginRight: 6,
    resizeMode: "contain",
  },
  chooseIcon: {
    width: 21,
    height: 21,
    marginRight: 6,
    resizeMode: "contain",
  },
});

const createRoomLeagueRoundsPickerFieldStyles = EStyleSheet.create({
  pickerLeagueContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 6,
    borderBottomWidth: 0.5,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  pickerText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.textBlue,
    fontSize: 16,
  },
  pickerTextContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  leagueIcon: {
    width: 36,
    height: 36,
    marginRight: 6,
    resizeMode: "contain",
  },
});

const depositFieldStyles = EStyleSheet.create({
  depositFieldContainer: {
    flexDirection: "row",
    marginVertical: 3,
    width: "100%",
    alignItems: "center",
  },
  tickImg: {
    height: 12,
    width: 12,
    resizeMode: "contain",
    marginRight: 6,
  },
  greyText: {
    color: globalStyles.borderRoom,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  darkColor: {
    color: globalStyles.darkBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
});

const editRoomLeagueFieldStyles = EStyleSheet.create({
  roomContainer: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: globalStyles.white,
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  roomTitleContainer: {
    alignItems: "center",
    width: "85%",
    paddingLeft: 10,
    paddingVertical: 6,
    flexDirection: "row",
  },
  roomTitleText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
  },
  roomPositionContainer: {
    width: "15%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 3,
    paddingVertical: 6,
  },
  lastContainer: {
    width: "100%",
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: globalStyles.backgroundGrey,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  headToHeadImgContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 6,
  },
  playerImg: {
    width: 18,
    height: 18,
    resizeMode: "contain",
  },
  placeImage: {
    height: 18,
    width: 18,
    resizeMode: "contain",
  },
});

const h2hContainerStyles = EStyleSheet.create({
  h2hContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: globalStyles.white,
    paddingHorizontal: 36,
    paddingBottom: 12,
    marginTop: -6,
  },
  profileImg: {
    height: 60,
    width: 60,
    borderRadius: 30,
    resizeMode: "contain",
  },
  profileImgContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  playersInitialsContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "20%",
    marginBottom: 24,
  },
  imageContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "40%",
  },
  playerInitialsText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
    paddingTop: 9,
    paddingBottom: 3,
  },
  playerNicknameText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
    opacity: 0.7,
  },
  vsText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 30,
  },
  crownImg: {
    width: 30,
    height: 18,
    marginBottom: 3,
    resizeMode: "contain",
  },
});

const noStartedMatchContainer = EStyleSheet.create({
  playerInfoContainer: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.notPredictedColor,
  },
  playerPositionContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.borderRoom,
    width: 36,
    height: 36,
  },
  positionText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
    color: globalStyles.mainBlue,
  },
  playerNameBetContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },

  betPlayerText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.mainBlue,
    fontSize: 10,
    paddingLeft: 12,
    flex: 8,
  },
});

const roomLeagueFieldStyles = EStyleSheet.create({
  roomContainer: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: globalStyles.white,
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  roomTitleContainer: {
    alignItems: "center",
    width: "85%",
    paddingLeft: 9,
    paddingVertical: 6,
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
    flexDirection: "row",
  },
  roomTitleText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 16,
  },
  roomPositionContainer: {
    width: "15%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingHorizontal: 3,
    paddingVertical: 6,
  },
  crownImage: {
    width: 18,
    height: 18,
    resizeMode: "contain",
    marginRight: 3,
  },
  arrowImage: {
    width: 12,
    height: 10,
    resizeMode: "contain",
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: 12,
    marginHorizontal: 6,
  },
  samePositionImage: {
    width: 6,
    height: 3,
    resizeMode: "contain",
  },
  positionTextContainer: {
    maxWidth: 45,
    alignItems: "flex-end",
  },
  lastContainer: {
    width: "100%",
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: globalStyles.backgroundGrey,
    borderBottomRightRadius: 9,
    borderBottomLeftRadius: 9,
  },
  headToHeadImgContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 6,
  },
  playerImg: {
    width: 30,
    height: 30,
    borderRadius: 9,
  },
  playerTwoContainer: {
    borderRadius: 9,
    marginLeft: -9,
    borderWidth: 1,
    borderColor: globalStyles.white,
    backgroundColor: globalStyles.white,
  },
  privateRoomContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 6,
  },
  playerNumber: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 15,
  },
  playerText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 9,
  },
});

const roomLeagueTitleBtnStyles = EStyleSheet.create({
  roomLeagueContainer: {
    flexDirection: "row",
    flex: 8,
    paddingHorizontal: 12,
    backgroundColor: globalStyles.white,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.basicBlueGreyColor,
    paddingVertical: 6,
  },
  leagueImg: {
    height: 24,
    width: 24,
    resizeMode: "contain",
  },
  leagueTitle: {
    color: globalStyles.darkBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
    paddingLeft: 6,
  },
  rightArrowImg: {
    height: 18,
    width: 24,
  },
  leagueTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});

const roomLeagueTitleContainerStyles = EStyleSheet.create({
  titleContainer: {
    width: "100%",
    alignItems: "center",
    backgroundColor: globalStyles.white,
  },
  mainContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  titleDontsContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  titleTextContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "90%",
  },
  dotsContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "10%",
  },
  dotsImg: {
    width: 18,
    height: 10,
    resizeMode: "contain",
  },
  compareImg: {
    width: 18,
    height: 18,
    resizeMode: "contain",
  },
  title: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 16,
    marginLeft: 60,
  },
  roomTypeText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 14,
    marginTop: 2,
  },
  leagueImagesContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 6,
  },
  leagueIcon: {
    width: 36,
    height: 36,
    marginHorizontal: 2,
    resizeMode: "contain",
  },
  compareText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: 14,
  },
  searchBoxContainer: {
    width: "100%",
    borderTopWidth: 1,
    borderTopColor: globalStyles.backgroundGrey,
    padding: 12,
  },
  searchInputContainer: {
    width: "95%",
  },
  searchInput: {
    flexDirection: "row",
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: 15,
    height: 36,
    alignItems: "center",
    justifyContent: "center",
  },
  searchImg: {
    width: 21,
    height: 30,
    resizeMode: "contain",
  },
  searchContainer: {
    width: "85%",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: globalStyles.searchBacground,
    borderRadius: 6,
    paddingHorizontal: 3,
  },
  searchBoxAndButtonContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  searchImageContainer: {
    width: "10%",
    alignItems: "center",
  },
  clearImageContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: globalStyles.clearButton,
    width: 30,
    height: 30,
    borderRadius: 9,
    alignItems: "center",
    justifyContent: "center",
  },
  clearImage: {
    color: globalStyles.backgroundGrey,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 14,
  },
  cancelContainer: {
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
    padding: 6,
  },
  cancelText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
  },
  playerNameContainer: {
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    paddingTop: 12,
  },
  playerNameContainerSelected: {
    alignItems: "center",
    flexDirection: "row",
  },
  roundPointsContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  profileImage: {
    height: 36,
    width: 36,
    borderRadius: 18,
    paddingLeft: 3,
    marginLeft: 3,
  },
  betPlayerText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.textBlue,
    fontSize: 14,
    paddingLeft: 6,
    opacity: 0.7,
  },
  betPlayerUsername: {
    fontFamily: globalStyles.fontUbuntuBold,
    color: globalStyles.textBlue,
    fontSize: 15,
    paddingLeft: 6,
  },
  userCompareContainer: {
    flexDirection: "row",
    backgroundColor: globalStyles.backgroundGrey,
    paddingHorizontal: 12,
    paddingTop: 12,
    paddingBottom: 3,
    width: "100%",
    alignItems: "center",
  },
  selectedUserContainer: {
    width: "40%",
  },
  selectedUserText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 14,
    paddingLeft: 3,
    marginLeft: 3,
  },
  vsContainer: {
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
  },
  vsText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 18,
  },
  h2hTitleDontsContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 9,
  },
});

const roomPredictionBetPlayerInfoStyles = EStyleSheet.create({
  playerInfoContainer: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  playerPositionContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    width: 72,
    height: 48,
    opacity: 0.7,
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
  },
  crownImg: {
    height: 15,
    width: 24,
    marginLeft: 3,
    resizeMode: "contain",
  },
  profileImage: {
    height: 30,
    width: 30,
    borderRadius: 9,
    paddingLeft: 3,
    marginLeft: 3,
  },
  positionContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
  },
  defaultPositionContainer: {
    width: "70%",
    alignItems: "flex-end",
    paddingRight: 3,
  },
  positionText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
    color: globalStyles.textBlue,
  },
  whitePositionText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
    color: globalStyles.white,
  },
  arrowImg: {
    height: 6,
    width: 12,
    marginTop: 3,
    marginRight: 3,
    resizeMode: "contain",
  },
  playerNameBetContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    backgroundColor: "white",
    opacity: 0.7,
  },
  playerNameContainer: {
    alignItems: "center",
    flexDirection: "row",
    flex: 8,
  },
  betPlayerText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.textBlue,
    fontSize: 14,
    paddingLeft: 6,
    opacity: 0.7,
  },
  betPlayerUsername: {
    fontFamily: globalStyles.fontUbuntuBold,
    color: globalStyles.textBlue,
    fontSize: 15,
    paddingLeft: 6,
  },
  userBetPlayerUsername: {
    fontSize: 16,
    color: globalStyles.white,
  },
  userBetPlayerText: {
    fontSize: 15,
    color: globalStyles.white,
  },
  betPointsContainer: {
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    flex: 2,
    paddingRight: 24,
  },
  betContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
    paddingVertical: 6,
    paddingHorizontal: 9,
    borderRadius: 3,
    backgroundColor: globalStyles.unratedGrey,
  },
  pointsContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: 42,
  },
  pointsText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.red,
    fontSize: 14,
    marginRight: 6,
  },
  betText: {
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 14,
    color: globalStyles.white,
  },
});

const roomRankingPlayerInfoStyles = EStyleSheet.create({
  playerInfoContainer: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
    width: "99%",
    paddingHorizontal: 3,
    marginLeft: 3,
    height: 48,
    backgroundColor: globalStyles.white,
  },
  playerPositionContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    width: "10%",
    height: 48,
    paddingHorizontal: 3,
    opacity: 0.7,
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  crownImg: {
    height: 15,
    width: 15,
    marginLeft: 3,
    resizeMode: "contain",
  },
  profileImage: {
    height: 36,
    width: 36,
    borderRadius: 18,
    paddingLeft: 3,
    marginHorizontal: 6,
    resizeMode: "contain",
  },
  positionContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    paddingHorizontal: 3,
  },
  defaultPositionContainer: {
    width: "70%",
    alignItems: "flex-end",
    paddingRight: 3,
  },
  positionText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
    color: globalStyles.textBlue,
  },
  whitePositionText: {
    color: globalStyles.white,
  },
  playerNameBetContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    opacity: 0.7,
    width: "85%",
  },
  playerNameContainer: {
    alignItems: "center",
    flexDirection: "row",
    width: "80%",
  },
  betPlayerText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.textBlue,
    fontSize: 12,
    paddingLeft: 6,
    opacity: 0.7,
  },
  betPlayerUsername: {
    fontFamily: globalStyles.fontUbuntuBold,
    color: globalStyles.textBlue,
    fontSize: 15,
    paddingLeft: 6,
  },
  userBetPlayerUsername: {
    fontSize: 16,
    color: globalStyles.white,
  },
  userBetPlayerText: {
    fontSize: 15,
    color: globalStyles.white,
  },
  betPointsContainer: {
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    width: "20%",
  },
  betContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginRight: 30,
    paddingVertical: 6,
    width: 48,
  },
  pointsContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: 36,
    paddingRight: 3,
    marginHorizontal: 6,
  },
  pointsText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    color: globalStyles.textBlue,
    fontSize: 14,
    marginRight: 6,
  },
  betText: {
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 14,
    color: globalStyles.textBlue,
  },
  samePositionImage: {
    width: 9,
    height: 3,
    resizeMode: "contain",
  },
  arrowImage: {
    width: 12,
    height: 12,
    resizeMode: "contain",
  },
});

export {
  createFriendlyRoomStyles,
  createH2HRoomStyles,
  depositStyles,
  friendlyRoomPaimentStyles,
  joinRoomStyles,
  privateRoomsStyles,
  roomStyles,
  roomLeagueStyles,
  roomPredictionsStyles,
  successfullyCreatedRoomStyles,
  animationRoomLeagueTitleStyles,
  createRoomLeaguePickerStyles,
  createRoomLeaguePickerFieldStyles,
  createRoomLeagueRoundsPickerFieldStyles,
  depositFieldStyles,
  editRoomLeagueFieldStyles,
  h2hContainerStyles,
  noStartedMatchContainer,
  roomLeagueFieldStyles,
  roomLeagueTitleBtnStyles,
  roomLeagueTitleContainerStyles,
  roomPredictionBetPlayerInfoStyles,
  roomRankingPlayerInfoStyles,
};
