import {
  SET_USER_PROFILE,
  DELETE_PROFILE,
  SET_USER_PROFILE_IMAGE,
  SET_CONTACT_US_MESSAGE,
  SET_HAS_MATCHED,
} from "../actions/actionTypes";

const initialState = {
  id: null,
  image: null,
  firstName: null,
  lastName: null,
  userName: null,
  nationality: null,
  sex: null,
  balanceWallet: null,
  dateOfBirth: null,
  email: null,
  notify_game_week_start_end: false,
  notify_chat: false,
  notify_system_notifications: false,
  contactUsMessage: null,
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACT_US_MESSAGE: {
      return {
        ...state,
        contactUsMessage: action.message,
      };
    }
    case SET_USER_PROFILE: {
      return {
        ...state,
        id: action.data.id,
        image: action.data.image,
        firstName: action.data.first_name,
        lastName: action.data.last_name,
        userName: action.data.user_name,
        nationality: action.data.nationality,
        sex: action.data.sex,
        balanceWallet: action.data.balanceWallet,
        dateOfBirth: action.data.dateOfBirth,
        email: action.data.email,
        notify_game_week_start_end:
          action.data.notifications_settings.notify_game_week_start_end,
        notify_chat: action.data.notifications_settings.notify_chat,
        notify_system_notifications:
          action.data.notifications_settings.notify_system_notifications,
      };
    }
    case DELETE_PROFILE: {
      let id = { ...state.id };
      let image = { ...state.image };
      let firstName = { ...state.firstName };
      let lastName = { ...state.lastName };
      let userName = { ...state.userName };
      let nationality = { ...state.nationality };
      let sex = { ...state.sex };
      let balanceWallet = { ...state.balanceWallet };
      let dateOfBirth = { ...state.dateOfBirth };
      let email = { ...state.email };
      let notify_game_week_start_end = { ...state.notify_game_week_start_end };
      let notify_chat = { ...state.notify_chat };
      let notify_system_notifications = {
        ...state.notify_system_notifications,
      };
      id = null;
      image = null;
      firstName = null;
      lastName = null;
      userName = null;
      nationality = null;
      sex = null;
      balanceWallet = null;
      dateOfBirth = null;
      email = null;
      notify_game_week_start_end = false;
      notify_chat = false;
      notify_system_notifications = false;
      return {
        ...state,
        id,
        image,
        firstName,
        lastName,
        nationality,
        sex,
        balanceWallet,
        dateOfBirth,
        email,
        notify_game_week_start_end,
        notify_chat,
        notify_system_notifications,
      };
    }
    case SET_USER_PROFILE_IMAGE: {
      return {
        ...state,
        userProfile: { ...state.userProfile, image: action.image },
      };
    }
    case SET_HAS_MATCHED: {
      return {
        ...state,
        rightPassword: action.rightPassword,
      };
    }
    default:
      return state;
  }
};

export default userProfileReducer;
