import React from "react";
import {
  View,
  FlatList,
  Dimensions,
  Text,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import NavButton from "../../Common/components/UI/HomeNavButton";
import PredictionLeagueField from "../UI/PredictionLeagueField";
import PredictionWeeklyGames from "../UI/PredictionWeeklyGames";
import {
  getRoomPredictionData,
  getPredictionByLeagueData,
  getLeaguePredictionsByGameWeekData,
  getPredictionByLeagueDataNoToken,
  getRoomPredictionDataNoToken,
  getMySpecialGames,
  setLastGameWeek,
  getMobileRoomPredictionData,
  getMobileRoomPredictionDataNoToken,
  getSpecialPredictionByLeagueData,
} from "../actions/roompredictions";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import { onOpenRegister } from "../../Login/actions/auth";
import { predictionPageStyles } from "../styles/styles";

class PredictionPage extends React.Component {
  state = {
    activeButtonId: 0,
    refreshing: false,
    windowWidth: 1000,
    setMobile: false,
    getData: true,
  };

  _onRefresh = () => {
    this.setState({ refreshing: true });
    if (this.props.userId && this.state.activeButtonId === 0) {
      this.props.getRoomPredictionData();
    }
    if (!this.props.userId && this.state.activeButtonId === 0) {
      this.props.getRoomPredictionDataNoToken();
    }
    if (this.props.userId && this.state.activeButtonId === 1) {
      this.props.getMySpecialGames();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.getData === false) {
      let { width } = Dimensions.get("window");
      if (this.props.userId) {
        if (width > 650) {
          this.props.getRoomPredictionData();
        } else {
          this.props.getMobileRoomPredictionData();
        }
        this.props.getMySpecialGames();
      }
      if (!this.props.userId) {
        if (width > 650) {
          this.props.getRoomPredictionDataNoToken();
        } else {
          this.props.getMobileRoomPredictionDataNoToken();
        }
      }
      this.setState({ getData: true });
    }
    if (this.state.refreshing) {
      this.setState({ refreshing: false });
    }
    if (
      prevState.windowWidth > this.state.windowWidth &&
      !this.state.setMobile
    ) {
      this.setState({ setMobile: true });
      if (this.props.userId) {
        this.props.getMobileRoomPredictionData();
      }
      if (!this.props.userId) {
        this.props.getMobileRoomPredictionDataNoToken();
      }
    } else if (
      prevState.windowWidth < this.state.windowWidth &&
      this.state.setMobile
    ) {
      this.setState({ setMobile: false });
      if (this.props.userId) {
        this.props.getRoomPredictionData();
      }
      if (!this.props.userId) {
        this.props.getRoomPredictionDataNoToken();
      }
    }
  }

  changeActiveButton = (id) => {
    this.setState({ activeButtonId: id });
  };

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    this.goToTop();
    window.addEventListener("resize", () => {
      this.setState({ windowWidth: window.innerWidth });
    });
    if (this.state.getData === true) this.setState({ getData: false });
  }

  getLeaguePredictionsByWeek = (leagueId, gameWeek) => {
    if (this.props.userId) {
      this.props.getPredictionByLeagueData(leagueId, gameWeek);
    } else {
      this.props.getPredictionByLeagueDataNoToken(leagueId, gameWeek);
    }
  };

  getSpecialLeaguePredictionByRound = (roomId, round) => {
    if (this.props.userId) {
      this.props.getSpecialPredictionByLeagueData(roomId, round);
    }
  };
  getLeaguePredictionsByGameWeekData = (leagueId, gameWeek) => {
    const { leaguesPrediction } = this.props;
    const { setMobile } = this.state;
    const leagueIndex = leaguesPrediction.findIndex(
      (league) => Number(league.leagueId) === Number(leagueId)
    );
    if (
      gameWeek > 0 &&
      leaguesPrediction[leagueIndex].lastGameWeeks >= gameWeek
    ) {
      this.props.getLeaguePredictionsByGameWeekData(
        leagueId,
        gameWeek,
        setMobile
      );
    }
    if (leaguesPrediction[leagueIndex].lastGameWeeks < gameWeek) {
      this.props.setLastGameWeek(leagueId, setMobile);
    }
  };

  render() {
    const { leaguesPrediction, userId, specialGames, reactRem } = this.props;
    const { setMobile } = this.state;
    if (!leaguesPrediction) {
      return <LoadingScreen />;
    }
    return (
      <View style={predictionPageStyles.container}>
        <View style={predictionPageStyles.buttonContainer}>
          <NavButton
            id={0}
            activeButtonId={this.state.activeButtonId}
            onPress={() => this.changeActiveButton(0)}
            title={"Global/Private/H2H"}
          />
          <NavButton
            id={1}
            activeButtonId={this.state.activeButtonId}
            onPress={() => this.changeActiveButton(1)}
            title={"Special Games"}
            todayMatches={this.props.todayMatches}
          />
        </View>
        <View
          refreshing={this.state.refreshing}
          onRefresh={this._onRefresh}
          style={predictionPageStyles.fieldsContainer}
        >
          <FlatList
            data={
              this.state.activeButtonId === 0 ? leaguesPrediction : specialGames
            }
            keyExtractor={(item, index) => "key" + index}
            renderItem={({ item }) =>
              this.state.activeButtonId === 0 ? (
                <PredictionLeagueField
                  {...item}
                  navigation={this.props.navigation}
                  setMobile={setMobile}
                  onPress={this.getLeaguePredictionsByWeek}
                  token={userId}
                  reactRem={reactRem}
                  onGameWeekData={this.getLeaguePredictionsByGameWeekData}
                />
              ) : (
                <PredictionWeeklyGames
                  {...item}
                  navigation={this.props.navigation}
                  token={userId}
                  reactRem={reactRem}
                  onRoundChange={this.getSpecialLeaguePredictionByRound}
                />
              )
            }
          />
          {!userId && this.state.activeButtonId === 1 ? (
            <View style={predictionPageStyles.signInUpContainer}>
              <TouchableOpacity
                onPress={() => {
                  this.props.onOpenRegister(true, false);
                  this.props.navigation.navigate("LoginScreen");
                }}
              >
                <View style={predictionPageStyles.signInContainer}>
                  <Text style={predictionPageStyles.signInText}>SIGN IN</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  this.props.onOpenRegister(true, true);
                  this.props.navigation.navigate("LoginScreen", {
                    register: true,
                  });
                }}
              >
                <View style={predictionPageStyles.signUpContainer}>
                  <Text style={predictionPageStyles.signUpText}>SIGN UP</Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : null}
          {userId &&
          this.state.activeButtonId === 1 &&
          specialGames &&
          specialGames.length === 0 ? (
            <View style={predictionPageStyles.noMatchesContainer}>
              <Text style={predictionPageStyles.noMatchesText}>
                You are not participating in a Special Game
              </Text>
            </View>
          ) : (
            <View style={predictionPageStyles.lastContainerBorder} />
          )}
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leaguesPrediction: state.predictions.leagues,
    leagues: state.auth.leagues,
    specialGames: state.predictionsByWeek.specialGames,
    userId: state.auth.userId,
    reactRem: state.homeScreenLeagues.reactRem,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRoomPredictionData: () => dispatch(getRoomPredictionData()),
    getRoomPredictionDataNoToken: () =>
      dispatch(getRoomPredictionDataNoToken()),
    getMobileRoomPredictionData: () => dispatch(getMobileRoomPredictionData()),
    getMobileRoomPredictionDataNoToken: () =>
      dispatch(getMobileRoomPredictionDataNoToken()),
    getPredictionByLeagueData: (leagueId, gameWeek) =>
      dispatch(getPredictionByLeagueData(leagueId, gameWeek)),
    getPredictionByLeagueDataNoToken: (leagueId, gameWeek) =>
      dispatch(getPredictionByLeagueDataNoToken(leagueId, gameWeek)),
    getLeaguePredictionsByGameWeekData: (leagueId, gameWeek, isMobile) =>
      dispatch(
        getLeaguePredictionsByGameWeekData(leagueId, gameWeek, isMobile)
      ),
    getMySpecialGames: () => dispatch(getMySpecialGames()),
    onOpenRegister: (loginscreen, register) =>
      dispatch(onOpenRegister(loginscreen, register)),
    setLastGameWeek: (leagueId, setMobile) =>
      dispatch(setLastGameWeek(leagueId, setMobile)),
    getSpecialPredictionByLeagueData: (roomId, round) =>
      dispatch(getSpecialPredictionByLeagueData(roomId, round)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PredictionPage);
