import React from "react";
import { View, FlatList, ScrollView, Image, Text } from "react-native";
import globalStyles from "../../globalStyles";
import { connect } from "react-redux";
import defaultUserImage from "../../assets/profile_avatar.png";
import LeagueStatisticsField from "./UI/LeagueStatisticsField";
import { getUserStatistics } from "./actions/statistics";
import Shield2xImg from "../../assets/home_bonus_x2.png";
import Shield3xImg from "../../assets/home_bonus_x3.png";
import LeagueAd from "../../Common/LeagueList/LeagueAd";
import { statisticsStyles } from "./drawerStyles/styles";

class StatisticsScreen extends React.Component {
  state = {
    gameWeekSwitch: false,
    chatSwitch: false,
    notificationsSwitch: false,
  };

  gameWeekSwitch = () => {
    this.setState({ gameWeekSwitch: !this.state.gameWeekSwitch });
  };
  chatSwitch = () => {
    this.setState({ chatSwitch: !this.state.chatSwitch });
  };
  notificationsSwitch = () => {
    this.setState({ notificationsSwitch: !this.state.notificationsSwitch });
  };

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    this.goToTop();
    const userId = this.props.navigation.getParam("userId", null);
    this.props.getUserStatistics(userId);
  }

  render() {
    const {
      image,
      firstName,
      lastName,
      leagues,
      reactRem,
      banner,
      userName,
    } = this.props;

    return (
      <View style={statisticsStyles.container}>
        <ScrollView contentContainerStyle={statisticsStyles.fieldsContainer}>
          <View style={statisticsStyles.profileContainer}>
            <View style={statisticsStyles.profileImgContainer}>
              <Image
                source={
                  image
                    ? { uri: globalStyles.BASE_URL + image }
                    : defaultUserImage
                }
                style={statisticsStyles.profileImg}
              />
            </View>
            <View style={statisticsStyles.profileNameMoneyContainer}>
              <View>
                <Text style={statisticsStyles.profileName}>
                  {firstName + " " + lastName}
                </Text>
              </View>
              <View>
                <Text style={statisticsStyles.userNameText}>
                  {userName ? userName : firstName + " " + lastName}
                </Text>
              </View>
            </View>
          </View>
          <View style={statisticsStyles.listItem}>
            <View style={statisticsStyles.leagueTitleContainer}>
              <Text style={statisticsStyles.leagueTitle}>
                League Statistics
              </Text>
            </View>
            <View style={statisticsStyles.matchScoreFieldContainer}>
              <Text style={statisticsStyles.leagueStatisticsTitleText}>
                Match Score
              </Text>
            </View>
            <View style={statisticsStyles.matchScoreFieldContainer}>
              <Image source={Shield2xImg} style={statisticsStyles.shieldImg} />
            </View>
            <View style={statisticsStyles.matchScoreFieldContainer}>
              <Image source={Shield3xImg} style={statisticsStyles.shieldImg} />
            </View>
          </View>
          <View style={statisticsStyles.leagueStatisticsTitleContianer}>
            <FlatList
              data={leagues}
              keyExtractor={(item, index) => "key" + index}
              renderItem={({ item }) => (
                <LeagueStatisticsField {...item} reactRem={reactRem} />
              )}
            />
          </View>
          {banner && banner.length !== 0 ? (
            <LeagueAd banner={banner} navigation={this.props.navigation} />
          ) : null}
        </ScrollView>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.userId,
    image: state.userStatisticsReducer.image,
    firstName: state.userStatisticsReducer.firstName,
    lastName: state.userStatisticsReducer.lastName,
    nationality: state.userStatisticsReducer.nationality,
    leagues: state.userStatisticsReducer.leagues,
    reactRem: state.homeScreenLeagues.reactRem,
    banner: state.userStatisticsReducer.adverts,
    userName: state.userStatisticsReducer.userName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserStatistics: (userId) => dispatch(getUserStatistics(userId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StatisticsScreen);
