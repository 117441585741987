import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import ProfileMenuBtn from "./Common/components/UI/ProfileMenuBtn";
import settingImg from "./assets/side_menu_profile.png";
import statisticsImg from "./assets/side_menu_statistics.png";
import logoutImg from "./assets/side_menu_logout.png";
import avatarImg from "./assets/profile_avatar.png";
import GameRulesImg from "./assets/side_menu_game_rules.png";
import TermsAndConditionsImg from "./assets/side_menu_terms_and_conditions.png";
import NotificationsImg from "./assets/ic_notification.png";
import ContactUsImg from "./assets/side_menu_contacts.png";
import globalStyles from "./globalStyles";
import flagImg from "./assets/images/united_kingdom.png";
import { connect } from "react-redux";
import { authSetToken, deleteUser } from "./Login/actions/auth";
import { deleteProfile } from "./Common/Drawer/actions/profile";
import { deleteHomescreenData } from "./Home/actions/homeScreenLeagues";
// import { LoginManager } from 'react-native-fbsdk';
import { deleteChatData } from "./Chat/actions/chat";
import { deleteRoomData } from "./Room/actions/rooms";
import { onOpenRegister } from "./Login/actions/auth";

class ProfileMenu extends React.Component {
  onPressSettings = () => {
    const { userId } = this.props;
    this.props.onShowMenu();
    window.location.replace("/profile/" + userId);
  };

  onContactUs = () => {
    this.props.onShowMenu();
    window.location.replace("/contactus");
  };

  onGameRulse = () => {
    this.props.onShowMenu();
    window.location.replace("/gamerules");
  };

  onTermsNConditions = () => {
    this.props.onShowMenu();
    window.location.replace("/termsnconditions");
  };

  onPrivacyPolicy = () => {
    this.props.onShowMenu();
    window.location.replace("/privacypolicy");
  };

  onPressStatistics = () => {
    const { userId } = this.props;
    this.props.onShowMenu();
    window.location.replace("/statistics/" + userId);
  };

  onPressNotifications = () => {
    const { userId } = this.props;
    this.props.onShowMenu();
    window.location.replace("/notifications/" + userId);
  };

  logout = async () => {
    // LoginManager.logOut()
    this.props.onShowMenu();
    this.props.deleteUser();
    this.props.deleteProfile();
    this.props.deleteHomescreenData();
    this.props.deleteChatData();
    this.props.deleteRoomData();
    await localStorage.removeItem("token");

    localStorage.clear();

    window.location.replace("/login");
  };
  render() {
    const { reactRem } = this.props;
    const menuContainerStyle = [
      styles.menuContainer,
      {
        height: 75 * reactRem,
        borderRadius: reactRem,
        marginTop: 10 * reactRem,
        width: 25 * reactRem,
      },
    ];
    const avatarContainerStyle = [
      styles.avatarContainer,
      { marginTop: 4 * reactRem, marginBottom: 2 * reactRem },
    ];
    const avatarImgStyle = [
      styles.avatarImgStyle,
      {
        height: 7 * reactRem,
        width: 7 * reactRem,
        marginBottom: reactRem,
      },
    ];
    const userNameStyle = [styles.userName, { fontSize: 2.4 * reactRem }];
    const languageContainerStyle = [
      styles.languageContainer,
      { marginVertical: 2 * reactRem },
    ];
    const flagImgStyle = [
      styles.flagImg,
      {
        height: 3 * reactRem,
        width: 3 * reactRem,
        marginRight: reactRem,
      },
    ];
    const languageTextStyle = [
      styles.languageTextStyle,
      { fontSize: 2.2 * reactRem, marginRight: 8 * reactRem },
    ];

    return (
      <View style={menuContainerStyle}>
        <TouchableOpacity style={avatarContainerStyle}>
          <Image source={avatarImg} style={avatarImgStyle} />
          <Text style={userNameStyle}>
            {this.props.firstName + " " + this.props.lastName}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={languageContainerStyle}>
          <Image source={flagImg} style={flagImgStyle} />
          <Text style={languageTextStyle}>English</Text>
        </TouchableOpacity>
        <ProfileMenuBtn
          reactRem={reactRem}
          image={settingImg}
          title={"Profile"}
          onPress={this.onPressSettings}
        />
        <ProfileMenuBtn
          reactRem={reactRem}
          image={statisticsImg}
          title={"Statistics"}
          onPress={this.onPressStatistics}
        />
        <ProfileMenuBtn
          reactRem={reactRem}
          image={NotificationsImg}
          title={"Notifications"}
          onPress={this.onPressNotifications}
        />
        <ProfileMenuBtn
          reactRem={reactRem}
          image={ContactUsImg}
          title={"Contact us"}
          onPress={this.onContactUs}
        />
        <ProfileMenuBtn
          reactRem={reactRem}
          image={GameRulesImg}
          title={"Game Rules"}
          onPress={this.onGameRulse}
        />
        <ProfileMenuBtn
          reactRem={reactRem}
          image={TermsAndConditionsImg}
          title={"Terms & Conditions "}
          onPress={this.onTermsNConditions}
        />
        <ProfileMenuBtn
          reactRem={reactRem}
          image={GameRulesImg}
          title={"Privacy Policy"}
          onPress={this.onPrivacyPolicy}
        />
        <ProfileMenuBtn
          reactRem={reactRem}
          image={logoutImg}
          title={"Logout"}
          onPress={this.logout}
        />
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  menuContainer: {
    position: "fixed",
    width: "10%",
    backgroundColor: globalStyles.white,
    zIndex: 1,
    height: "60rem",
    borderRadius: "1rem",
    borderWidth: 1,
    borderColor: globalStyles.backgroundGrey,
    alignItems: "center",
  },
  avatarContainer: {
    marginTop: "4rem",
    marginBottom: "2rem",
    alignItems: "center",
    justifyContent: "center",
  },
  avatarImgStyle: {
    height: "7rem",
    width: "7rem",
    resizeMode: "contain",
    marginBottom: "1rem",
  },
  userName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "2.4rem",
  },
  balanceText: {
    color: globalStyles.green,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: "2.2rem",
  },
  languageContainer: {
    flexDirection: "row",
    marginVertical: "2rem",
    marginHorizontal: "auto",
    alignItems: "center",
  },
  flagImg: {
    height: "3rem",
    width: "3rem",
    resizeMode: "contain",
    marginRight: "1rem",
  },
  languageTextStyle: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "2.2rem",
  },
  arrowDown: {
    height: "2rem",
    width: "3rem",
    resizeMode: "contain",
    marginLeft: "5rem",
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.auth.userId,
    firstName: state.userProfileReducer.firstName,
    lastName: state.userProfileReducer.lastName,
    reactRem: state.homeScreenLeagues.reactRem,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    authSetToken: (token) => dispatch(authSetToken(token)),
    deleteUser: () => dispatch(deleteUser()),
    deleteProfile: () => dispatch(deleteProfile()),
    deleteHomescreenData: () => dispatch(deleteHomescreenData()),
    deleteChatData: () => dispatch(deleteChatData()),
    deleteRoomData: () => dispatch(deleteRoomData()),
    onOpenRegister: (loginscreen, register) =>
      dispatch(onOpenRegister(loginscreen, register)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
