import { SET_USER_STATISTICS } from '../actions/actionTypes';


const initialState = {
    image: null,
    firstName: null,
    lastName: null,
    nationality: null,
    leagues: null,
    adverts: [],
    userName: null,

};

const userStatisticsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_STATISTICS: {
            return {
                ...state,
                image: action.data.image,
                firstName: action.data.firstName,
                lastName: action.data.lastName,
                nationality: action.data.nationality,
                leagues: action.data.leagues,
                adverts: action.data.banner,
                userName: action.data.userName,
            }
        };
        default:
            return state;
    }
};

export default userStatisticsReducer;