import React from 'react';
import { StyleSheet, ScrollView, RefreshControl } from 'react-native';

const refreshContainer = (props) => {
    return (
        <ScrollView 
            style={[styles.scrollView, props.style]} 
            scrollEnabled={!props.scrollDisabled}
            contentContainerStyle={[styles.container, props.containerStyle]}>
            {props.children}
        </ScrollView>
    );
}

export default refreshContainer;

const styles = StyleSheet.create({
  scrollView: {
    width: '100%',
    height: '100%',
  },
  container: {
    width: '100%',
    minHeight: '100%'
  }
});

