import { SET_RESOURCES, ALERT_DIALOG_TITLE, LEAVE_ROOM, LEAVE_IS_PRESSED, LEAVE_ROOM_ID } from './actionTypes';
// import { BASE_URL } from '../../Common/config/config';
// import { authGetToken } from '../../../Common/actions/token';



// export const getResources = () => {
//     return dispatch => {
//         dispatch(authGetToken())
//         .catch(err => {
//             console.log('No auth token exists!');
//             alert("Please login again, your session is expired!");
//         })
//         .then(token => {
//             return fetch(BASE_URL + '/towns/resources', {
//                 headers: {
//                     "Content-Type": "application/json; charset=utf-8",
//                     "Authorization": token
//                 }
//             });
//         })
//         .catch(err => console.log(err))
//         .then(res => res.json())
//         .catch(err => console.log(err))
//         .then(res => {
//             if (!res) return;
//             res.data.forEach(r => r.icon = BASE_URL + r.icon);
//             dispatch(setResources(res.data));
//         });
//     };
// }

// export const setResources = resources => {
//     return {
//         type: SET_RESOURCES, resources
//     };
// };

export const setAlertDialogTitle = title => {
    return {
        type: ALERT_DIALOG_TITLE, title
    };
};

export const setLeaveRoom = data => {
    return {
        type: LEAVE_ROOM, data
    };
};

export const setLeaveIsPressed = data => {
    return {
        type: LEAVE_IS_PRESSED, data
    };
};

export const setLeaveRoomId = data => {
    return {
        type: LEAVE_ROOM_ID, data
    };
};