import React from "react";
import { View, Text } from "react-native";
import { gameRulesStyles } from "./drawerStyles/styles";

class GameRulesScreen extends React.Component {
  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    this.goToTop();
  }

  render() {
    return (
      <View style={gameRulesStyles.container}>
        <View style={gameRulesStyles.titleTextContainer}>
          <Text style={gameRulesStyles.titleText}>Game Rules</Text>
        </View>
        <View style={gameRulesStyles.articleTextContainer}>
          <Text style={gameRulesStyles.articleText}>
            {"INTRODUCTION \n\nGLOWTER is an online platform that enables its users to predict the final outcome of football matches, adulthood with other users in created virtual rooms. The aim is for users to predict correctly the maximum number of football matches, to occupy top places in the formed virtual rooms, and thus to earn prizes. GLOWTER is free to play. \nAnyone can play, but players need to register. \n\n" +
              "PREDICTION RULES \n\nPredictions include 3 possible outputs: 1 (win for the home team), X (draw score) and 2 (win for the team guest). Each one concerns the final outcome (including the added times) of the football matches. \n\nEach player makes his own match predictions, and for each Special Games match, the player may give a different prediction than the one he gave for the same match in the Global / Private / H2H Room. \n\nPredictions can be changed at any point up to the scheduled kick-off time of the match. \n\n" +
              "ROOMS \n\nA key aspect of the game is the ability for players to compete with friends, colleagues and players from around the world in Global, Private and Head to Head (H2H) Rooms and play in Special Games. \n\n      •Global Rooms \n\nEach registered player, according to the championships he has chosen at his registration in GLOWTER, automatically joins the Global Room for the respective championship, in which they will be able to track their performance against all other players globally. At the end of the season for the respective championship, the Global Rooms winners receive prizes provided by GLOWTER. \n\nThe duration of each Global Room is for the entire season of the respective championship. \n\nAnyone can add championships after their initial registration. \n\n       •Private Rooms and Head To Head (Н2Н) Rooms \n\n" +
              "Each registered player can create Private and Н2H Rooms, as well as to join Private and Н2H Rooms created by other users. They are the heart and soul of the game, where players can compete with their friends, family and colleagues. Anyone can join or create a Private or Н2H Room by entering or sending a unique code generated by GLOWTER when setting up the room. \n\nOne Private or Н2H Room lasts as long as the creator has indicated and in the creation and – one or several rounds, half-season or whole season. During the action of these rooms no one has the right to change their duration. The championships included in these rooms are also set by the creator. \n\n         •Special Games \n\nThese are created rooms from GLOWTER with a certain duration (one or several rounds, half-season or whole season), the accession to which does not require a code. \n\n" +
              "The duration of each room by Special Games is set by GLOWTER at the time of their creation. In the course of action of these rooms, no one has the right to change their duration. The championships included in these rooms are also set by GLOWTER when they are created and can not be changed either. \n\nIf there are matches in a particular Special Game in which the player has not joined, during the Special Game, those championships are reflected on the Livescore Home screen, but the player does not automatically join the Global Rooms of those championships. \n\nThere is no limit to the number of Global, Private, Head to Head (H2H) Rooms and Special Games that each player can participate in. \n\nAnyone can leave a Private or Н2H Room at any time, but it is not possible to enter/play/return again. If leaving the room during a started round, his points from the matches until the end of a gameweek are charged or deducted and are frozen after the gameweek ends. The abandoned player does not participate in the allocation of GLOWTER prize seats. \n\n" +
              "MATCHES \n\nThe football championships are divided into gameweeks as each football federation of the respective country has designated. \n\nBefore each gameweek GLOWTER determines the difficulty levels of football matches. They can be: \n\n      •Difficulty level 1 (any match not specifically designated as having a higher difficulty level); \n     •Difficulty level 2; \n     •Difficulty level 3; \n\nIf a match is postponed and is not played at a predetermined time, it moves to the closest gameweek according to the new date of the match. The prediction made for him is saved, and can be changed at any point up to the new scheduled kick-off time of the match.\n\n" +
              "POINTS \n\nFor each football match, points are awarded and deducted from the following scheme: \n\n      •For matches with difficulty level 1: \n\nFor a correct prediction, the player gets as many points as the player has given an incorrect prediction in the respective room in which they participate; \n\nFor an incorrect prediction the player will be taken as many points as the player has given the correct forecast in the respective room in which they participate. \n\n        •For matches with difficulty level 2: \n\nSame as the upper scoring system (for matches with difficulty level 1), but the points received or withdrawn are doubled. \n\n       •For matches with difficulty level 3: \n\nSame as the upper scoring system (for matches with difficulty level 1), but the points received or withdrawn are tripled. \n\nIf one of the matches is not predicted by the start of the match, then the player is considered to have made a wrong prediction and the player is deducted points as described above. \n\n" +
              "RANKING \n\nThe ranking of users is fully consistent with the points won, and is in no way linked to a random lottery.\n\nIf two or more users have the same points, the one who has more correct predictions with difficulty levels 2 and 3 is ranked higher.\n\n" +
              "COMPARE \n\nEach player can compare their predictions, earned and lost points with other competitors in Global, Private, Head to Head (H2H) Rooms and Special Games \n\n" +
              "PRIZES \n\nAfter the end of each individual Global Room and Special Games, GLOWTER gives out prizes to the winners. These prizes are entirely provided by GLOWTER, unless the source of the prize is explicitly stated. GLOWTER send a notification email and may ask the player to send a delivery address to the prize. \n\n" +
              "THIRD PARTYS\n\nThird parties (Apple, Google, etc.) do not have any relation and don’t participate in any way in the organization of games and contests, in the provision and distribution of prizes among users. \n\n"}
          </Text>
        </View>
      </View>
    );
  }
}

export default GameRulesScreen;
