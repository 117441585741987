export const SELECT_ROOM = "SELECT_ROOM";
export const SELECT_LEAGUE = "SELECT_LEAGUE";
export const SET_ROOM_PREDICTION_DATA = "SET_ROOM_PREDICTION_DATA";
export const SET_MOBILE_PREDICTION_DATA = "SET_MOBILE_PREDICTION_DATA";
export const SET_MOBILE_LEAGUE_PREDICTIONS_BY_WEEK =
  "SET_MOBILE_LEAGUE_PREDICTIONS_BY_WEEK";
export const SET_PREDICTION_BY_WEEK_DATA = "SET_PREDICTION_BY_WEEK_DATA";
export const PUT_BETS_FOR_LEAGUE_BY_WEEK_GAME =
  "PUT_BETS_FOR_LEAGUE_BY_WEEK_GAME";
export const SET_LEAGUE_PREDICTIONS_BY_WEEK = "SET_LEAGUE_PREDICTIONS_BY_WEEK";
export const ADD_NEW_LEAGUE = "ADD_NEW_LEAGUE";
export const DELETE_PREDICTION = "DELETE_PREDICTIONS";
export const GET_SPECIAL_GAME_DATA = "GET_SPECIAL_GAME_DATA";
export const SET_MY_SPECIAL_GAMES = "SET_MY_SPECIAL_GAMES";
export const CHANGE_IS_THERE_BETS = "CHANGE_IS_THERE_BETS";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const THE_LAST_GAME_WEEK = "THE_LAST_GAME_WEEK";
export const SET_SPECIAL_PREDICTION_BY_ROUND =
  "SET_SPECIAL_PREDICTION_BY_ROUND";
