import React from 'react';
import { Image, View, Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import GoalImg from '../../../assets/livescore/goal.png';
import OwnGoalImg from '../../../assets/livescore/own_goal.png';
import PenaltyImg from '../../../assets/livescore/penalty.png';
import RedCardImg from '../../../assets/livescore/red_card.png';
import SecondYellowCardImg from '../../../assets/livescore/double_yellow_card.png';
import SubstitueImg from '../../../assets/livescore/substitute.png';
import YellowCardImg from '../../../assets/livescore/yellow_card.png';

class CommentContainer extends React.Component {
    render() {
        const {
            awayTeam,
            goalScored,
            homeTeam,
            ownGoal,
            penalty,
            player,
            playerAssit,
            redCard,
            score,
            secondYellowCard,
            playerOut,
            time,
            yellowCard,
            reactRem } = this.props;
        const matchScore = score.split("-");
        let commentImg;
        if (goalScored) {
            commentImg = GoalImg;
        }
        if (ownGoal) {
            commentImg = OwnGoalImg;
        }
        if (penalty) {
            commentImg = PenaltyImg;
        }
        if (redCard) {
            commentImg = RedCardImg;
        }
        if (secondYellowCard) {
            commentImg = SecondYellowCardImg;
        }
        if (playerOut) {
            commentImg = SubstitueImg;
        }
        if (yellowCard) {
            commentImg = YellowCardImg;
        }

        const commentsContainerStyle = [styles.commentsContainer, { paddingVertical: 1.5 * reactRem }];
        const homePlayerScoredTextStyle = [styles.homePlayerScoredText, {
            fontSize: 2.2 * reactRem,
            paddingRight: 2 * reactRem
        }]
        const commentImgStyle = [styles.commentImg, {
            fontSize: 2.2 * reactRem,
            paddingRight: reactRem
        }];
        const minuteScoreTextStyle = [styles.minuteScoreText, { fontSize: 2.2 * reactRem }];
        const liveScoreTextStyle = [styles.liveScoreText, {
            fontSize: 2.6 * reactRem,
            paddingTop: 0.5 * reactRem
        }];
        const awayPlayerScoredTextStyle = [styles.awayPlayerScoredText, {
            fontSize: 2.2 * reactRem,
            paddingLeft: 2 * reactRem
        }];
        const extraCommentTextStyle = [styles.extraCommentText, { fontSize: 2.2 * reactRem }];

        return (
            <View>
                {!playerOut ?
                    <View style={commentsContainerStyle}>
                        {homeTeam ? <View style={styles.homeTeamPlayerContainer}>
                            <View style={styles.playerInfo}>
                                <Text style={homePlayerScoredTextStyle}>{player}</Text>
                                <Image source={commentImg} style={commentImgStyle} />
                            </View>
                            {penalty ? <Text style={extraCommentTextStyle}>{"(Penalty)"}</Text> : null}
                            {/* {playerAssit? <Text style={styles.minuteScoreText}>{playerAssit}</Text>:null}
                            {playerOut? <Text style={styles.minuteScoreText}>{playerOut}</Text>:null} */}
                        </View> : <View style={styles.homeTeamPlayerContainer} />}
                        <View style={styles.awayCommentContainer}>
                            <Text style={minuteScoreTextStyle}>{time}'</Text>
                            {goalScored ? <Text style={liveScoreTextStyle}>{matchScore[0]} - {matchScore[1]}</Text> : null}
                        </View>
                        {awayTeam ? <View style={styles.awayTeamPlayerContainer}>
                            <View style={styles.playerInfo}>
                                <Image source={commentImg} style={commentImgStyle} />
                                <Text style={awayPlayerScoredTextStyle}>{player}</Text>
                            </View>
                            {penalty ? <Text style={extraCommentTextStyle}>{"(Penalty)"}</Text> : null}
                            {/* {playerAssit? <Text style={styles.minuteScoreText}>{playerAssit}</Text>:null}
                            {playerOut? <Text style={styles.minuteScoreText}>{playerOut}</Text>:null} */}
                        </View> : <View style={styles.awayTeamPlayerContainer} />}
                    </View> : null}
            </View>

        );
    }
}

const styles = EStyleSheet.create({
    commentsContainer: {
        flexDirection: "row",
        paddingVertical: "1.5rem",
    },
    homeTeamPlayerContainer: {
        alignItems: "flex-end",
        width: "42%",
    },
    awayTeamPlayerContainer: {
        width: "42%",
    },
    playerInfo: {
        flexDirection: "row",
        alignItems: "center",
    },
    homePlayerScoredText: {
        fontFamily: globalStyles.fontUbuntuBold,
        color: globalStyles.textBlue,
        fontSize: "2.2rem",
        paddingRight: "2rem"
    },
    commentImg: {
        width: "2rem",
        height: "2rem",
        resizeMode: "contain",
    },
    minuteScoreText: {
        fontFamily: globalStyles.fontUbuntuMedium,
        fontSize: "2.2rem",
        color: globalStyles.textBlue
    },
    awayCommentContainer: {
        justifyContent: "space-between",
        alignItems: "center",
        width: "15%",
    },
    liveScoreText: {
        fontFamily: globalStyles.fontUbuntuBold,
        fontSize: "2.6rem",
        paddingTop: "0.5rem",
        color: globalStyles.textBlue
    },
    awayPlayerScoredText: {
        fontFamily: globalStyles.fontUbuntuBold,
        color: globalStyles.textBlue,
        fontSize: "2.2rem",
        paddingLeft: "2rem"
    },
    extraCommentText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuMedium,
        fontSize: "2.2rem"
    },
});
export default CommentContainer;