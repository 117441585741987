import React from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import AnimatableView from "../../Common/components/UI/AnimatableView";
import globalStyles from "../../globalStyles";
import Shield from "../components/UI/Shield";
import MatchResult from "../components/UI/MatchResult";
import { connect } from "react-redux";
import {
  getRoomPredictionsByRoomIdAndMatchId,
  getSpecialRoomPredictionsByRoomIdAndMatchId,
  setResetRoomPredictions,
} from "../../Home/actions/homeScreenLeagues";
import images from "../../Home/actions/clubsImages";
import { onOpenRegister } from "../../Login/actions/auth";
import RoomPredictionBetPlayerInfo from "../../Room/UI/RoomPredictionBetPlayerInfo";

class PredictionItem extends React.Component {
  state = {
    isOpen: false,
    isPlayerShowen: false,
    roomIdOpen: -1,
    roomSelected: null,
    windowWidth: 1000,
    fullPeopleRankingInRoom: [],
    loadedMorePeople: false,
    currentPage: 2,
  };

  roomTabVisible = (open) => {
    this.setState({ isOpen: !open });
    if (this.state.isOpen) {
      this.setState({ isPlayerShowen: false });
    }
  };

  showField = () => {
    if (this.props.matchIsOpen) {
      this.setState({ animation: "slideInDown" });
    } else {
      this.setState({ animation: "slideInUp" });
    }
  };

  onLoadMorePeople = () => {
    this.setState({
      loadedMorePeople: true,
    });

    if (this.props.specialRoom) {
      this.props.getSpecialRoomPredictionsByRoomIdAndMatchId(
        this.state.roomSelected,
        this.props.id,
        this.state.currentPage
      );
    } else {
      this.props.getRoomPredictionsByRoomIdAndMatchId(
        this.state.roomSelected,
        this.props.id,
        this.state.currentPage
      );
    }
  };

  onShowPlayers = (show, roomId) => {
    this.props.setResetRoomPredictions();
    const { roomIdOpen } = this.state;
    if (this.props.userId) {
      if (roomIdOpen === -1) {
        this.setState({ isPlayerShowen: !show, roomIdOpen: roomId });
      }
      if (roomId != roomIdOpen) {
        this.setState({ roomIdOpen: roomId });
      }
      if (roomId === roomIdOpen) {
        this.setState({
          isPlayerShowen: !show,
          currentPage: 2,
          fullPeopleRankingInRoom: [],
          loadedMorePeople: false,
        });
      }
      if (this.props.specialRoom) {
        this.props.getSpecialRoomPredictionsByRoomIdAndMatchId(
          roomId,
          this.props.id,
          1
        );
      } else {
        this.props.getRoomPredictionsByRoomIdAndMatchId(
          roomId,
          this.props.id,
          1
        );
      }
    }
    this.setState({ roomSelected: roomId });
  };

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.setState({ windowWidth: window.innerWidth });
    });

    let { height, width } = Dimensions.get("window");
    this.setState({ windowWidth: width });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.playersBetedHome !== this.props.playersBetedHome &&
      this.state.loadedMorePeople
    ) {
      this.setState({
        fullPeopleRankingInRoom: this.state.fullPeopleRankingInRoom.concat(
          this.props.playersBetedHome
        ),
        currentPage: this.state.currentPage + 1,
        loadedMorePeople: false,
      });
    }
  }
  onStaticsShow = (userId) => {
    this.props.navigation.navigate("StatisticsScreen", { userId: userId });
  };

  render() {
    const {
      matchIsOpen,
      betPoints,
      playTime,
      score,
      homeTeam,
      guestTeam,
      time,
      date,
      isPlaying,
      homeTeamImg,
      guestTeamImg,
      shield,
      bet,
      points,
      playersBeted,
      fullPointsShown,
      default_points,
      guessed,
      userId,
      playersBetedHome,
      is_delayed,
      matchesLength,
      index,
      userPrediction,
      selectedUserPrediction,
      userSelected,
      search,
      roomId,
      roomNameType,
      reactRem,
    } = this.props;

    let timeDateScoreViewStyles = [
      styles.timeDateScoreViewStyles,
      { width: 10 * reactRem },
    ];
    let scores;
    let leftClub = [styles.leftClubName, { fontSize: 2.2 * reactRem }];
    let rigthClub = [styles.rightClubName, { fontSize: 2.2 * reactRem }];
    let mainMatchInfoContainer = [styles.mainMatchInfoContainer];
    let listItemStyle = [styles.listItem];
    let pointsTextStyle = [styles.pointsText, { fontSize: 2.2 * reactRem }];
    let selectedUserPointsTextStyle = [
      styles.selectedUserPointsText,
      { fontSize: 2.2 * reactRem },
    ];
    let betLineStyle = [
      styles.betLineStyle,
      { height: 0.3 * reactRem, width: 2.5 * reactRem },
    ];
    const leftClubContainerStyle = [styles.leftClubContainer];
    const rightClubContainerStyle = [styles.rightClubContainer];
    const playersBetedContainerStyle = [
      styles.playersBetedContainer,
      { maxHeight: 31 * reactRem },
    ];
    const roomContainerStyle = [styles.roomContainer];
    const availableAfterMatchContainerStyle = [
      styles.availableAfterMatchContainer,
      { height: 6 * reactRem },
    ];
    const dateFieldStyle = [styles.dateField, { fontSize: 2 * reactRem }];
    const timeFieldStyle = [styles.timeField, { fontSize: 2.4 * reactRem }];
    let content;
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const { roomSelected } = this.state;
    let betText = bet || "-";

    if (this.state.windowWidth < 650) {
      if (homeTeam.length > 10) {
        leftClub.push({ fontSize: 1.8 * reactRem });
      }

      if (guestTeam.length > 10) {
        rigthClub.push({ fontSize: 1.9 * reactRem });
      }
    }

    if (is_delayed) {
      content = (
        <View style={{ justifyContent: "center", alignContent: "center" }}>
          <Text style={[styles.delayedField, { fontSize: 2 * reactRem }]}>
            Delayed
          </Text>
        </View>
      );
    }
    if (score) {
      scores = score.split(" : ");
      if (isPlaying && playTime != "Finished") {
        if (playTime === "Awarded") {
          content = (
            <View style={timeDateScoreViewStyles}>
              <Text
                style={[
                  styles.playingTimeField,
                  { fontSize: 2 * reactRem, paddingBottom: 0.5 * reactRem },
                ]}
              >
                {" "}
                {playTime === "Finished" ? "FT" : playTime}
              </Text>
              <Text
                style={[
                  styles.playingSocreField,
                  { fontSize: 2.6 * reactRem, marginTop: -0.5 * reactRem },
                ]}
              >
                {" "}
                {scores[0] + " - " + scores[1]}
              </Text>
            </View>
          );
        } else {
          content = (
            <View style={timeDateScoreViewStyles}>
              <Text
                style={
                  playTime === "Half Time"
                    ? [
                        styles.halfTimeField,
                        {
                          fontSize: 2 * reactRem,
                          paddingBottom: 0.5 * reactRem,
                        },
                      ]
                    : [
                        styles.halfTimeMinutesField,
                        {
                          fontSize: 2 * reactRem,
                          paddingBottom: 0.5 * reactRem,
                        },
                      ]
                }
              >
                {" "}
                {playTime === "Half Time" ? "HT" : playTime + "'"}
              </Text>
              <Text
                style={
                  playTime === "Half Time"
                    ? [
                        styles.playingSocreField,
                        {
                          fontSize: 2.6 * reactRem,
                          marginTop: -0.5 * reactRem,
                        },
                      ]
                    : [
                        styles.halfTimeScoreField,
                        {
                          fontSize: 2.6 * reactRem,
                          marginTop: -0.5 * reactRem,
                        },
                      ]
                }
              >
                {" "}
                {scores[0] + " - " + scores[1]}
              </Text>
            </View>
          );
        }
      } else {
        if (scores.length === 2 && scores[0] > scores[1]) {
          leftClub.push({
            color: globalStyles.textBlue,
            fontFamily: globalStyles.fontUbuntuBold,
          });
          if (betText === "1") {
            betLineStyle.push({ backgroundColor: globalStyles.green });
            pointsTextStyle.push({ color: globalStyles.green });
          }
          if (betText === "2") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
            pointsTextStyle.push({ color: globalStyles.red });
          }
          if (betText === "X") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
            pointsTextStyle.push({ color: globalStyles.red });
          }
        }
        if (scores.length === 2 && scores[0] < scores[1]) {
          rigthClub.push({
            color: globalStyles.textBlue,
            fontFamily: globalStyles.fontUbuntuBold,
          });
          if (betText === "2") {
            betLineStyle.push({ backgroundColor: globalStyles.green });
            pointsTextStyle.push({ color: globalStyles.green });
          }
          if (betText === "1") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
            pointsTextStyle.push({ color: globalStyles.red });
          }
          if (betText === "X") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
            pointsTextStyle.push({ color: globalStyles.red });
          }
        }
        if (scores.length === 2 && scores[0] === scores[1]) {
          if (bet === "X") {
            betLineStyle.push({ backgroundColor: globalStyles.green });
            pointsTextStyle.push({ color: globalStyles.green });
          }
          if (bet === "1" || bet === "2") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
            pointsTextStyle.push({ color: globalStyles.red });
          }
        }
        if (scores.length === 2 && scores[0] > scores[1]) {
          leftClub.push({
            color: globalStyles.textBlue,
            fontFamily: globalStyles.fontUbuntuBold,
          });
          if (betText === "1") {
            betLineStyle.push({ backgroundColor: globalStyles.green });
            pointsTextStyle.push({ color: globalStyles.green });
          }
          if (betText === "2") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
            pointsTextStyle.push({ color: globalStyles.red });
          }
          if (betText === "X") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
            pointsTextStyle.push({ color: globalStyles.red });
          }
        }
        if (selectedUserPrediction) {
          if (scores.length === 2 && scores[0] < scores[1]) {
            if (selectedUserPrediction.prediction === "2") {
              selectedUserPointsTextStyle.push({ color: globalStyles.green });
            }
            if (selectedUserPrediction.prediction === "1") {
              selectedUserPointsTextStyle.push({ color: globalStyles.red });
            }
            if (selectedUserPrediction.prediction === "X") {
              selectedUserPointsTextStyle.push({ color: globalStyles.red });
            }
          }
          if (scores.length === 2 && scores[0] === scores[1]) {
            if (selectedUserPrediction.prediction === "X") {
              selectedUserPointsTextStyle.push({ color: globalStyles.green });
            }
            if (
              selectedUserPrediction.prediction === "1" ||
              selectedUserPrediction.prediction === "2"
            ) {
              selectedUserPointsTextStyle.push({ color: globalStyles.red });
            }
          }
          if (scores.length === 2 && scores[0] > scores[1]) {
            if (selectedUserPrediction.prediction === "1") {
              selectedUserPointsTextStyle.push({ color: globalStyles.green });
            }
            if (selectedUserPrediction.prediction === "2") {
              selectedUserPointsTextStyle.push({ color: globalStyles.red });
            }
            if (selectedUserPrediction.prediction === "X") {
              selectedUserPointsTextStyle.push({ color: globalStyles.red });
            }
          }
        }

        content = (
          <View style={timeDateScoreViewStyles}>
            <Text
              style={[
                styles.playingTimeField,
                { fontSize: 2 * reactRem, paddingBottom: 0.5 * reactRem },
              ]}
            >
              {" "}
              {playTime === "Finished" ? "FT" : playTime}
            </Text>
            <Text
              style={[
                styles.playingSocreField,
                { fontSize: 2.6 * reactRem, marginTop: -0.5 * reactRem },
              ]}
            >
              {" "}
              {scores[0] + " - " + scores[1]}
            </Text>
          </View>
        );
      }
    } else if (!is_delayed) {
      leftClub.push({ color: globalStyles.textBlue });
      rigthClub.push({ color: globalStyles.textBlue });
      betLineStyle.push({ backgroundColor: globalStyles.red });

      const matchDate = date.split("-");
      let currentDay = matchDate[1];
      if (currentDay.charAt(0) === "0") {
        currentDay = currentDay.charAt(1);
      }
      let currentMonth = matchDate[0];
      if (currentMonth.charAt(0) === "0") {
        currentMonth = currentMonth.charAt(1);
      }
      const currentDate = months[currentMonth - 1] + " " + currentDay;

      content = (
        <View style={timeDateScoreViewStyles}>
          <Text style={dateFieldStyle}> {currentDate}</Text>
          <Text style={timeFieldStyle}> {time}</Text>
        </View>
      );
    }

    if (playTime === "Postponed") {
      const matchDate = date.split("-");
      let currentDay = matchDate[1];
      if (currentDay.charAt(0) === "0") {
        currentDay = currentDay.charAt(1);
      }
      let currentMonth = matchDate[0];
      if (currentMonth.charAt(0) === "0") {
        currentMonth = currentMonth.charAt(1);
      }
      const currentDate = months[currentMonth - 1] + " " + currentDay;
      dateFieldStyle.push({ color: globalStyles.red });
      timeFieldStyle.push({ color: globalStyles.red });
      content = (
        <View style={timeDateScoreViewStyles}>
          <Text style={dateFieldStyle}> {currentDate}</Text>
          <Text style={timeFieldStyle}> Postp</Text>
        </View>
      );
    }
    if (betPoints) {
      mainMatchInfoContainer.push({ backgroundColor: "red" });
    }
    let pointsText;
    let fullPointsText;
    let pointsToShow;
    if (points != null) {
      pointsToShow = points;
      if (pointsToShow > -1 && pointsToShow != 0) {
        fullPointsText = "+" + pointsToShow;
      } else {
        fullPointsText = pointsToShow;
      }
      if (pointsToShow >= 1000) {
        pointsText = "+" + (pointsToShow / 1000).toFixed(1) + "k";
      }
      if (pointsToShow <= -1000) {
        pointsToShow = pointsToShow * -1;
        pointsText = "-" + (pointsToShow / 1000).toFixed(1) + "k";
      }
      if (pointsToShow > -1000 && pointsToShow < 1000) {
        pointsText = fullPointsText;
      }
    }

    if (playersBetedHome && playersBetedHome.length === 0) {
      playersBetedContainerStyle.push({ height: 0 });
    }

    if (playersBeted && playersBeted.length === 0) {
      playersBetedContainerStyle.push({ height: 0 });
    }
    if (this.state.isPlayerShowen) {
      roomContainerStyle.push({ borderBottomWidth: 0 });
    }
    let homeTeamImgName = homeTeam.replace(" ", "_").toLowerCase();
    let guestTeamImgName = guestTeam.replace(" ", "_").toLowerCase();

    if (homeTeamImgName.includes(".")) {
      homeTeamImgName = homeTeamImgName.replace(".", "");
    }
    if (guestTeamImgName.includes(".")) {
      guestTeamImgName = guestTeamImgName.replace(".", "");
    }

    if (matchIsOpen) {
      listItemStyle.push(styles.activeListItem);
    }

    if (score && betText === "-") {
      pointsTextStyle.push({ color: globalStyles.red });
    }
    if (Number(userPrediction) === 0) {
      pointsTextStyle.push({ color: globalStyles.greyText });
    }
    if (selectedUserPrediction) {
      if (score && selectedUserPrediction.prediction === "-") {
        selectedUserPointsTextStyle.push({ color: globalStyles.red });
      }
      if (Number(selectedUserPrediction.points) === 0) {
        selectedUserPointsTextStyle.push({ color: globalStyles.greyText });
      }
    }

    if (
      this.state.fullPeopleRankingInRoom.length === 0 &&
      playersBetedHome &&
      playersBetedHome.length > 0
    ) {
      this.setState({ fullPeopleRankingInRoom: playersBetedHome });
    }

    return (
      <View style={styles.container}>
        <View
          style={
            matchesLength - 1 === index
              ? [
                  styles.lastContainer,
                  {
                    borderBottomLeftRadius: 1.5 * reactRem,
                    borderBottomRightRadius: 1.5 * reactRem,
                  },
                ]
              : listItemStyle
          }
        >
          <TouchableOpacity
            style={{ alignItems: "center", width: "95%" }}
            onPress={() => {
              this.roomTabVisible(this.state.isOpen);
              this.props.onShowMatchInfo();
              this.onShowPlayers(this.state.isPlayerShowen, roomId);
              this.showField();
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <View
                style={[
                  styles.predictionContainer,
                  { paddingVertical: reactRem },
                ]}
              >
                {selectedUserPrediction && userSelected ? (
                  <View
                    style={[
                      styles.matchResultContainer,
                      { paddingLeft: reactRem },
                    ]}
                  >
                    <MatchResult
                      matchResult={selectedUserPrediction.prediction}
                      predicted={
                        selectedUserPrediction.guessed != null ? true : false
                      }
                      guessed={selectedUserPrediction.guessed}
                    />
                  </View>
                ) : (
                  <View style={{ width: "7%" }}>
                    {shield > 0 ? (
                      <Shield points={shield} />
                    ) : (
                      <View
                        style={[
                          styles.noShieldView,
                          { height: 6 * reactRem, width: 6 * reactRem },
                        ]}
                      />
                    )}
                  </View>
                )}
                <View style={{ width: "86%", flexDirection: "row" }}>
                  <View style={leftClubContainerStyle}>
                    <View style={styles.leftClubNameContainer}>
                      <Text style={leftClub}>{homeTeam}</Text>
                    </View>
                    <View>
                      {homeTeamImg ? (
                        <Image
                          source={
                            images[homeTeamImgName]
                              ? images[homeTeamImgName]
                              : { uri: homeTeamImg }
                          }
                          style={[
                            styles.clubImage,
                            {
                              height: 6 * reactRem,
                              width: 6 * reactRem,
                              marginHorizontal: reactRem,
                            },
                          ]}
                        />
                      ) : (
                        <View
                          style={[
                            styles.clubImage,
                            {
                              height: 6 * reactRem,
                              width: 6 * reactRem,
                              marginHorizontal: reactRem,
                            },
                          ]}
                        />
                      )}
                    </View>
                  </View>
                  <View
                    style={[
                      styles.contentContainer,
                      { width: 10 * reactRem, marginHorizontal: reactRem },
                    ]}
                  >
                    {content}
                  </View>
                  <View style={rightClubContainerStyle}>
                    <View>
                      {guestTeamImg ? (
                        <Image
                          source={
                            images[guestTeamImgName]
                              ? images[guestTeamImgName]
                              : { uri: guestTeamImg }
                          }
                          style={[
                            styles.clubImage,
                            {
                              height: 6 * reactRem,
                              width: 6 * reactRem,
                              marginHorizontal: reactRem,
                            },
                          ]}
                        />
                      ) : (
                        <View
                          style={[
                            styles.clubImage,
                            {
                              height: 6 * reactRem,
                              width: 6 * reactRem,
                              marginHorizontal: reactRem,
                            },
                          ]}
                        />
                      )}
                    </View>
                    <View style={styles.rightClubNameContainer}>
                      <Text style={rigthClub}>{guestTeam}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "7%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MatchResult
                      matchResult={betText}
                      predicted={guessed != null ? true : false}
                      guessed={guessed}
                      loggedUser={true}
                      score={score}
                    />
                  </View>
                </View>
              </View>
            </View>
          </TouchableOpacity>
        </View>
        <View>
          {matchIsOpen && this.props.userId && search ? (
            <AnimatableView>
              {score ? (
                <View
                  style={[
                    styles.pointsTextContainer,
                    { paddingHorizontal: 1.5 * reactRem },
                  ]}
                >
                  <View>
                    <Text style={selectedUserPointsTextStyle}>
                      {selectedUserPrediction
                        ? Number(selectedUserPrediction.points) > 0
                          ? "+" + selectedUserPrediction.points + " Pts."
                          : selectedUserPrediction.points + " Pts."
                        : null}
                    </Text>
                  </View>
                  <View>
                    <Text style={pointsTextStyle}>
                      {Number(userPrediction) > 0
                        ? "+" + userPrediction
                        : userPrediction}{" "}
                      Pts.
                    </Text>
                  </View>
                </View>
              ) : (
                <View
                  style={[
                    styles.pointsTextContainer,
                    {
                      paddingHorizontal: 1.5 * reactRem,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.availableAfterMatchText,
                      { fontSize: 2.4 * reactRem },
                    ]}
                  >
                    AVAILABLE AFTER THE MATCH STARTS!
                  </Text>
                </View>
              )}
            </AnimatableView>
          ) : null}
          {matchIsOpen &&
          this.props.userId &&
          playersBetedHome &&
          !search &&
          roomNameType != "Head to Head" ? (
            <View style={{ backgroundColor: globalStyles.backgroundGrey }}>
              {playersBetedHome && playersBetedHome.length > 2 ? (
                <RoomPredictionBetPlayerInfo
                  {...this.props.userBeted}
                  score={score}
                  defaultPoints={default_points}
                  fullPointsShown={fullPointsShown}
                  onStaticsShow={this.onStaticsShow}
                />
              ) : null}
              {playersBetedHome && playersBetedHome.length === 0 ? (
                <View style={availableAfterMatchContainerStyle}>
                  <Text
                    style={[
                      styles.availableAfterMatchText,
                      { fontSize: 2.4 * reactRem },
                    ]}
                  >
                    AVAILABLE AFTER THE MATCH STARTS!
                  </Text>
                </View>
              ) : null}
              <ScrollView style={playersBetedContainerStyle}>
                {this.state.fullPeopleRankingInRoom.map((player, i) => (
                  <RoomPredictionBetPlayerInfo
                    {...player}
                    key={i}
                    position={i + 1}
                    score={score}
                    defaultPoints={default_points}
                    fullPointsShown={fullPointsShown}
                    onStaticsShow={this.onStaticsShow}
                  />
                ))}
              </ScrollView>
              {this.state.loadedMorePeople ? (
                <View>
                  <ActivityIndicator />
                </View>
              ) : null}
              {this.state.currentPage !== this.props.totalPages ? (
                <TouchableOpacity
                  style={styles.loadMoreButtonContainer}
                  onPress={() => this.onLoadMorePeople()}
                >
                  <Text style={styles.loadMoreButtonText}>LOAD MORE</Text>
                </TouchableOpacity>
              ) : null}
            </View>
          ) : null}
        </View>
        <View>
          {matchIsOpen && !this.props.userId ? (
            <AnimatableView style={styles.signInUpContainer}>
              <TouchableOpacity
                style={[
                  styles.signInContainer,
                  {
                    paddingHorizontal: 2 * reactRem,
                    paddingVertical: reactRem,
                    minWidth: 15 * reactRem,
                    maxWidth: 15 * reactRem,
                    borderTopLeftRadius: 0.5 * reactRem,
                    borderBottomLeftRadius: 0.5 * reactRem,
                  },
                ]}
                onPress={() => {
                  this.props.onOpenRegister(true, false);
                  this.props.navigation.navigate("LoginScreen");
                }}
              >
                <Text style={[styles.signInText, { fontSize: 2 * reactRem }]}>
                  SIGN IN
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.signUpContainer,
                  {
                    paddingHorizontal: 2 * reactRem,
                    paddingVertical: reactRem,
                    minWidth: 15 * reactRem,
                    maxWidth: 15 * reactRem,
                    borderTopRightRadius: 0.5 * reactRem,
                    borderBottomRightRadius: 0.5 * reactRem,
                  },
                ]}
                onPress={() => {
                  this.props.onOpenRegister(true, true);
                  this.props.navigation.navigate("LoginScreen", {
                    register: true,
                  });
                }}
              >
                <Text style={[styles.signUpText, { fontSize: 2 * reactRem }]}>
                  SIGN UP
                </Text>
              </TouchableOpacity>
            </AnimatableView>
          ) : null}
        </View>
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  listItem: {
    width: "100%",
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  activeListItem: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopColor: globalStyles.activeBlue,
    borderBottomColor: globalStyles.activeBlue,
    borderLeftColor: globalStyles.activeBlue,
    borderRightColor: globalStyles.activeBlue,
  },
  leftClubName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    textAlign: "right",
    fontSize: "2.2rem",
  },
  leftClubNameContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  leftClubContainer: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    width: "43%",
  },
  rightClubNameContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  rightClubContainer: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    width: "43%",
  },
  rightClubName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: "2.2rem",
  },
  clubImage: {
    height: "6rem",
    width: "6rem",
    marginHorizontal: "1rem",
  },
  roomContainer: {
    backgroundColor: globalStyles.white,
    opacity: 0.7,
    flexDirection: "row",
    alignItems: "center",
  },

  playingTimeField: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "2rem",
    paddingBottom: "0.5rem",
  },
  halfTimeField: {
    color: globalStyles.yellow,
    fontSize: "2rem",
    fontFamily: globalStyles.fontUbuntuBold,
    paddingBottom: "0.5rem",
  },
  halfTimeMinutesField: {
    color: globalStyles.activeBlue,
    fontSize: "2rem",
    fontFamily: globalStyles.fontUbuntuBold,
    paddingBottom: "0.5rem",
  },
  playingSocreField: {
    color: globalStyles.textBlue,
    fontSize: "2.6rem",
    marginTop: "-0.5rem",
    fontFamily: globalStyles.fontUbuntuBold,
  },
  halfTimeScoreField: {
    color: globalStyles.activeBlue,
    fontSize: "2.6rem",
    marginTop: "-0.5rem",
    fontFamily: globalStyles.fontUbuntuBold,
  },
  delayedField: {
    color: globalStyles.red,
    fontSize: "2rem",
    fontFamily: globalStyles.fontUbuntuMedium,
  },
  dateField: {
    color: globalStyles.textBlue,
    fontSize: "2rem",
    fontFamily: globalStyles.fontUbuntuRegular,
  },
  timeField: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "2.4rem",
  },
  timeDateScoreViewStyles: {
    flexDirection: "column",
    alignItems: "center",
    width: "10rem",
  },
  noShieldView: {
    height: "6rem",
    width: "6rem",
  },
  pointsText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: "2.2rem",
    color: globalStyles.unratedGrey,
  },
  betLineStyle: {
    height: "0.3rem",
    width: "2.5rem",
    backgroundColor: globalStyles.basicBlueGreyColor,
  },
  mainMatchInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "88%",
  },
  smallerClubName: {
    fontSize: "1.8rem",
  },
  playersBetedContainer: {
    maxHeight: "31rem",
  },
  availableAfterMatchContainer: {
    height: "6rem",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: globalStyles.whiteOpacity,
  },
  availableAfterMatchText: {
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "2.4rem",
    color: globalStyles.textBlue,
  },
  container: {
    backgroundColor: globalStyles.backgroundGrey,
  },
  lastContainer: {
    width: "100%",
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: globalStyles.backgroundGrey,
    borderBottomLeftRadius: "1.5rem",
    borderBottomRightRadius: "1.5rem",
  },
  predictionContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    paddingVertical: "1rem",
  },
  pointsTextContainer: {
    flexDirection: "row",
    backgroundColor: globalStyles.white,
    opacity: 0.7,
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: "1.5rem",
  },
  selectedUserPointsText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: "2.2rem",
    color: globalStyles.textGrey,
  },
  matchResultContainer: {
    width: "10%",
    paddingLeft: "1rem",
  },
  signInUpContainer: {
    backgroundColor: globalStyles.backgroundGrey,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  signInContainer: {
    backgroundColor: globalStyles.white,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: "2rem",
    paddingVertical: "1rem",
    minWidth: "15rem",
    maxWidth: "15rem",
    borderTopLeftRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: "2rem",
    paddingVertical: "1rem",
    minWidth: "15rem",
    maxWidth: "15rem",
    borderTopRightRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
  },
  signUpText: {
    color: globalStyles.white,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: "2rem",
    fontFamily: globalStyles.fontUbuntuBold,
  },
  signInText: {
    color: globalStyles.textBlue,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: "2rem",
    fontFamily: globalStyles.fontUbuntuBold,
  },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "10rem",
    marginHorizontal: "1rem",
  },
  loadMoreButtonContainer: {
    backgroundColor: globalStyles.white,
    opacity: 0.7,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingVertical: 12,
    borderBottomRightRadius: 9,
    borderBottomLeftRadius: 9,
    marginHorizontal: 3,
  },
  loadMoreButtonText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 10,
  },
});

const mapStateToProps = (state) => {
  return {
    playersBetedHome: state.homeScreenLeagues.playersBeted,
    userBeted: state.homeScreenLeagues.userBeted,
    reactRem: state.homeScreenLeagues.reactRem,
    token: state.auth.token,
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRoomPredictionsByRoomIdAndMatchId: (roomId, matchId, page) =>
      dispatch(getRoomPredictionsByRoomIdAndMatchId(roomId, matchId, page)),
    getSpecialRoomPredictionsByRoomIdAndMatchId: (roomId, matchId, page) =>
      dispatch(
        getSpecialRoomPredictionsByRoomIdAndMatchId(roomId, matchId, page)
      ),
    onOpenRegister: (loginscreen, register) =>
      dispatch(onOpenRegister(loginscreen, register)),
    setResetRoomPredictions: () => dispatch(setResetRoomPredictions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PredictionItem);
