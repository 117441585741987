import React from 'react';
import { StyleSheet, Image, Dimensions, View } from 'react-native';
import notLoggedBgImage from '../../assets/images/splash_background.png';
import loggedBgImage from '../../assets/images/splash_background.png';
import globalStyles from '../../globalStyles';


const background = (props) => {
    let image = loggedBgImage;
    if (props.notLogged) image = notLoggedBgImage;
    // if (props.loading) image =  loadingBgImage;    
    return (<View style={styles.container}>
        <Image style={[styles.image, props.style]} source={image} />
    </View>);

}


export default background;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 0,
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: globalStyles.black
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover'    
  }
});

