import {
  AUTH_SET_TOKEN,
  SET_GENDERS,
  SET_NATIONALITY,
  SET_LEAGUES,
  CHANGE_PASSWORD,
  AUTH_SET_USER_ID,
  SET_IS_NEW_USER,
  DELETE_USER,
  DELETE_CHANGED_PASSWORD,
  SHOW_REGISTER_FORM,
  REGISTER_DATA,
  SET_ADVERTS,
} from "./actionTypes";
import { BASE_URL } from "../../Common/store/config";
import { uiStartLoading, uiStopLoading } from "../../Common/store/actions/ui";
import { setError } from "../../Common/store/actions/errors";
import { getUserProfile } from "../../Common/Drawer/actions/profile";

export const setRegisterData = (data) => {
  return {
    type: REGISTER_DATA,
    data: data,
  };
};

export const tryAuth = (authData) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + "/auth/login", {
      method: "POST",
      body: JSON.stringify(authData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => Promise.all([res, res.json()]))
      .then(([res, responseObj]) => {
        dispatch(uiStopLoading());
        if (!res || !res.headers || !res.headers.get("authorization")) {
          dispatch(setError("Wrong email or password"));
        } else {
          localStorage.setItem("token", res.headers.get("authorization"));
          dispatch(authSetToken(res.headers.get("authorization")));
        }
        if (responseObj.data) {
          if (responseObj.data.id) {
            const userId = responseObj.data.id;
            localStorage.setItem("userId", userId.toString());
            localStorage.setItem("logout", "false");
            dispatch(authSetUserId(responseObj.data.id));
            dispatch(getUserProfile(responseObj.data.id));
          }
        }
      });
  };
};

export const faceBookLogin = (authData) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + "/auth/login/facebook", {
      method: "POST",
      body: JSON.stringify(authData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => Promise.all([res, res.json()]))
      .then(([res, responseObj]) => {
        if (responseObj.data.is_new_register) {
          dispatch(setIsNewUser(responseObj.data.is_new_register));
        }
        if (!res || !res.headers || !res.headers.get("authorization")) {
          dispatch(setError("Wrong email or password"));
        } else {
          localStorage.setItem("token", res.headers.get("authorization"));
          dispatch(authSetToken(res.headers.get("authorization")));
        }
        if (responseObj.data.id) {
          const userId = responseObj.data.id;
          localStorage.setItem("userId", userId.toString());
          dispatch(authSetUserId(responseObj.data.id));
          dispatch(getUserProfile(responseObj.data.id));
        }
      });
  };
};

export const GoogleLogin = (authData) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + "/auth/login/google", {
      method: "POST",
      body: JSON.stringify(authData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => Promise.all([res, res.json()]))
      .then(([res, responseObj]) => {
        if (responseObj.data.is_new_register) {
          dispatch(setIsNewUser(responseObj.data.is_new_register));
        }
        if (!res || !res.headers || !res.headers.get("authorization")) {
          dispatch(setError("Wrong email or password"));
        } else {
          localStorage.setItem("token", res.headers.get("authorization"));
          dispatch(authSetToken(res.headers.get("authorization")));
        }
        if (responseObj.data.id) {
          const userId = responseObj.data.id;
          localStorage.setItem("userId", userId.toString());
          dispatch(authSetUserId(responseObj.data.id));
          dispatch(getUserProfile(responseObj.data.id));
        }
      });
  };
};

export const onOpenRegister = (loginscreen, register) => {
  return {
    type: SHOW_REGISTER_FORM,
    register: register,
    loginscreen: loginscreen,
  };
};

export const authSetToken = (token) => {
  return {
    type: AUTH_SET_TOKEN,
    token: token,
  };
};

export const authSetUserId = (userId) => {
  return {
    type: AUTH_SET_USER_ID,
    userId: userId,
  };
};
export const setIsNewUser = (isNewUser) => {
  return {
    type: SET_IS_NEW_USER,
    isNewUser: isNewUser,
  };
};

export const deleteChangedPassword = () => {
  return {
    type: DELETE_CHANGED_PASSWORD,
  };
};
export const tryRegister = (data) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + "/users", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .catch((err) => {
        alert("Registration failed, please try again!");
        dispatch(uiStopLoading());
      })
      .then((res) => res.json())
      .then((res) => {
        if (res.has_error) {
          dispatch(setError(res.message));
        } else {
          dispatch(setError(""));
          dispatch(setRegisterData(null));
          dispatch(tryAuth({ email: data.email, password: data.password }));
        }
      });
  };
};

export const getGenders = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + "/user/genders", {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => (res ? res.json() : []))
      .then((res) => dispatch(setGenders(res.data)), dispatch(uiStopLoading()));
  };
};

export const getAdverts = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + "/ads/web", {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => (res ? res.json() : []))
      .then((res) => dispatch(setAdverts(res.data)), dispatch(uiStopLoading()));
  };
};

export const setAdverts = (data) => {
  return {
    type: SET_ADVERTS,
    data,
  };
};

export const getNationalities = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + "/user/nationalities", {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => (res ? res.json() : []))
      .then(
        (res) => dispatch(setNationalities(res.data)),
        dispatch(uiStopLoading())
      );
  };
};

export const getLeagues = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + "/leagues", {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => (res ? res.json() : []))
      .then((res) => dispatch(setLeagues(res.data)), dispatch(uiStopLoading()));
  };
};

export const setGenders = (data) => {
  return {
    type: SET_GENDERS,
    data,
  };
};

export const setNationalities = (data) => {
  return {
    type: SET_NATIONALITY,
    data,
  };
};

export const setLeagues = (data) => {
  return {
    type: SET_LEAGUES,
    data,
  };
};

export const setChangePassword = (data) => {
  return {
    type: CHANGE_PASSWORD,
    data,
  };
};

export const deleteUser = () => {
  return {
    type: DELETE_USER,
  };
};

export const changePassword = (email) => {
  const data = { email: email };
  return (dispatch) => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + "/users/forgotpass", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .catch((err) => {
        dispatch(uiStopLoading());
      })
      .then((res) => (res ? res.json() : []))
      .then((res) => {
        if (res.has_error) {
          dispatch(setError("Non valid email"));
          dispatch(uiStopLoading());
        } else {
          dispatch(setError(""));
          dispatch(setChangePassword(email));
          dispatch(uiStopLoading());
        }
      });
  };
};
