import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import globalStyles from "../../../globalStyles";
import { connect } from "react-redux";
import ShareModal from "./ShareModal";

class SideMenu extends React.Component {
  state = {
    isModalVisible: false,
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  render() {
    const {
      reactRem,
      roomCode,
      onCopyCode,
      message,
      onShowFullPoint,
      roomNameType,
      onLeaveRoom,
      showFullPoints,
    } = this.props;
    const containerStyle = [
      styles.container,
      {
        paddingBottom: 2 * reactRem,
        paddingHorizontal: 2 * reactRem,
        paddingTop: reactRem,
        top: 16 * reactRem,
        right: 2 * reactRem,
        borderRadius: 1.5 * reactRem,
      },
    ];
    const textContainerStyle = [
      styles.textContainer,
      { paddingVertical: reactRem },
    ];
    const shareButtonContainerStyle = [
      styles.shareButtonContainer,
      { paddingVertical: reactRem },
    ];
    const textStyle = [styles.text, { fontSize: 2 * reactRem }];
    const codeTextStyle = [styles.codeText, { fontSize: 2 * reactRem }];

    return (
      <View style={containerStyle}>
        {roomCode && roomNameType !== "Global Rooms" ? (
          <View style={{ width: "100%" }}>
            <Text style={codeTextStyle}>Code: {roomCode}</Text>
            <TouchableOpacity style={styles.textContainer} onPress={onCopyCode}>
              <Text style={textStyle}>Copy room code</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={shareButtonContainerStyle}
              onPress={() => this.toggleModal()}
            >
              <Text style={textStyle}>Share</Text>
            </TouchableOpacity>
          </View>
        ) : null}
        <TouchableOpacity style={textContainerStyle} onPress={onShowFullPoint}>
          <Text style={textStyle}>
            {showFullPoints ? "Short-size points" : "Full-size points"}
          </Text>
        </TouchableOpacity>
        {roomNameType != "Global Rooms" ? (
          <TouchableOpacity style={textContainerStyle} onPress={onLeaveRoom}>
            <Text style={textStyle}>Leave</Text>
          </TouchableOpacity>
        ) : null}
        <ShareModal
          reactRem={reactRem}
          isModalVisible={this.state.isModalVisible}
          onChangeModalVisibility={this.toggleModal}
          message={message}
        />
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  container: {
    position: "absolute",
    width: "35%",
    alignItems: "center",
    paddingBottom: "2rem",
    paddingHorizontal: "2rem",
    paddingTop: "1rem",
    top: "16rem",
    right: "2rem",
    borderRadius: "1.5rem",
    backgroundColor: globalStyles.white,
    borderWidth: 1,
    borderColor: globalStyles.backgroundGrey,
  },
  textContainer: {
    width: "100%",
    paddingVertical: "1rem",
  },
  shareButtonContainer: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingVertical: "1rem",
  },
  text: {
    fontSize: "2rem",
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
  },
  codeText: {
    color: globalStyles.error,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: "2rem",
  },
});

const mapStateToProps = (state) => {
  return {
    reactRem: state.homeScreenLeagues.reactRem,
  };
};
export default connect(mapStateToProps, null)(SideMenu);
