import React from "react";
import { View, Image, Text, TouchableOpacity } from "react-native";
import globalStyles from "../../globalStyles";
import LivescoreContainer from "../../Common/components/UI/LivescoreContainer";
import LineupsContainer from "../../Common/components/UI/LineupsContainer";
import { connect } from "react-redux";
import {
  selectMatch,
  selectLeague,
} from "../../Home/actions/homeScreenLeagues";
import {
  setMatchBet,
  setMatchSpecialGameBet,
  deleteFutureMatchInfo,
  getMatchData,
  getMatchDataNoToken,
} from "../../Home/actions/futurematch";
import {
  resetLiveScore,
  getLiveMatchLineUps,
} from "../../Home/actions/livematch";
import { onOpenRegister, getLeagues } from "../../Login/actions/auth";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import Shield from "../../Common/components/UI/Shield";
import leftArrow from "../../assets/left_gw_arrow.png";
import rightArrow from "../../assets/right_gw_arrow.png";
import AnimationTeamContainer from "../../Common/components/UI/AnimationTeamContainer";
import AnimatableImage from "../../Common/components/UI/AnimatableImage";
import AnimatableText from "../../Common/components/UI/AnimatableText";
import AnimatableProgressbarView from "../../Common/components/UI/AnimatableProgressbarView";
import { liveMatchStyles } from "../styles/styles";
import { futureMatchStyles } from "../styles/styles";
import RefreshContainer from "../../Common/components/UI/RefreshContainer";
import BetContainer from "../../Common/components/UI/BetContainer";
import LeagueAd from "../../Common/LeagueList/LeagueAd";
import LastMatchContainer from "../../Common/components/UI/LastMatchesContainer";
import HeadToHeadContainer from "../../Common/components/UI/HeadToHeadContainer";
import TeamStatsContainer from "../../Common/components/UI/TeamStatsContainer";

class MatchScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    refreshing: false,
    offset: 0,
    activeButtonId: 0,
    selectedRoomId: 0,
    selectedSpecialRoomId: 0,
    isLoading: false,
    checkForSpecialGames: false,
    specialRooms: [],
    currentDate: null,
    setData: true,
  };

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  currentDate = (date) => {
    let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      currentDate;

    if (date !== null) {
      let matchDate = date.split("-");

      let currentDay = matchDate[2];
      if (currentDay.charAt(0) === "0") {
        currentDay = currentDay.charAt(1);
      }
      let currentMonth = matchDate[1];
      if (currentMonth.charAt(0) === "0") {
        currentMonth = currentMonth.charAt(1);
      }
      currentDate = months[currentMonth - 1] + " " + currentDay;
    }
    return currentDate;
  };

  componentWillUnmount() {
    this.props.deleteFutureMatchInfo();
    this.setState({
      refreshing: false,
      isLoading: false,
      checkForSpecialGames: false,
      specialRooms: [],
    });
    this.props.onResetLiveScore();
  }

  changeActiveButton = (buttonId) => {
    this.setState({ activeButtonId: buttonId });
  };

  onNextRoomBetContainer = () => {
    if (this.state.specialRooms.length > 1) {
      if (
        this.state.selectedSpecialRoomId + 1 <
        this.state.specialRooms.length
      ) {
        this.setState({
          selectedSpecialRoomId: this.state.selectedSpecialRoomId + 1,
        });
      }
    }
  };

  onPrevRoomContainer = () => {
    if (this.state.specialRooms.length > 1) {
      if (this.state.selectedSpecialRoomId > 0) {
        this.setState({
          selectedSpecialRoomId: this.state.selectedSpecialRoomId - 1,
        });
      }
    }
  };

  onNextRoom = () => {
    if (this.state.selectedRoomId + 1 < this.props.rooms.length) {
      this.setState({ selectedRoomId: this.state.selectedRoomId + 1 });
    }
  };

  onPrevRoom = () => {
    if (this.state.selectedRoomId > 0) {
      this.setState({ selectedRoomId: this.state.selectedRoomId - 1 });
    }
  };

  onSetBet = (matchId, bet, roomId, specialRoom) => {
    if (specialRoom) {
      this.props.setMatchSpecialGameBet(
        bet,
        matchId,
        this.props.navigation.getParam("leagueId", "1"),
        roomId
      );
      const specialRooms = [...this.state.specialRooms];
      const specialRoomsIndex = specialRooms.findIndex(
        (room) => Number(room.roomId) === Number(roomId)
      );
      specialRooms[specialRoomsIndex].bet = bet;
      this.setState({ specialRooms: specialRooms });
    } else {
      const specialRooms = [...this.state.specialRooms];
      const specialRoomsIndex = specialRooms.findIndex(
        (room) => Number(room.roomId) === Number(roomId)
      );
      specialRooms[specialRoomsIndex].bet = bet;
      this.setState({ specialRooms: specialRooms });
      this.props.onSetMatchBet(
        bet,
        matchId,
        this.props.navigation.getParam("leagueId", "1"),
        roomId,
        specialRoom
      );
    }
  };

  _onRefresh = () => {
    this.setState({ refreshing: true });
    this.props.userId
      ? this.props.getMatchData(this.props.navigation.getParam("matchId", "1"))
      : this.props.getMatchDataNoToken(
          this.props.navigation.getParam("matchId", "1")
        );
  };

  componentDidUpdate() {
    if (this.state.refreshing) {
      this.setState({ refreshing: false });
    }
    if (
      !this.state.checkForSpecialGames &&
      this.props.bets &&
      this.props.bets.length != 0
    ) {
      this.state.specialRooms.push(this.props.bets[0]);
      this.props.bets.forEach((bet) => {
        if (bet.is_special) {
          this.state.specialRooms.push(bet);
        }
      });
      this.setState({ checkForSpecialGames: true });
      if (this.props.navigation.getParam("specialRoom", false)) {
        const roomId = this.props.navigation.getParam("roomId", 0);
        if (roomId != 0) {
          const specialRooms = [...this.state.specialRooms];
          const specialRoomsIndex = specialRooms.findIndex(
            (room) => Number(room.roomId) === Number(roomId)
          );
          if (specialRoomsIndex != -1) {
            this.setState({ selectedSpecialRoomId: specialRoomsIndex });
          }
        }
      }
      this.props.onMatchSelect(this.props.navigation.getParam("matchId", "1"));
    }
    if (this.state.setData === false) {
      this.props.onLeagueSelect(
        this.props.navigation.getParam("leagueId", "1")
      );
      this.props.userId
        ? this.props.getMatchData(
            this.props.navigation.getParam("matchId", "1")
          )
        : this.props.getMatchDataNoToken(
            this.props.navigation.getParam("matchId", "1")
          );
      this.props.getLiveMatchLineUps(
        this.props.navigation.getParam("matchId", "1")
      );
      this.setState({ setData: true });
    }
  }

  componentDidMount() {
    this.props.getLeagues();
    this.goToTop();
    this.setState({ setData: false });
  }

  render() {
    const {
      league,
      livescore,
      reactRem,
      standings,
      isLoading,
      time,
      date,
      rooms,
      headToHead,
      odsBanner,
      form,
      bets,
      homeTeam,
      homeTeamImg,
      guestTeam,
      guestTeamImg,
      lineups,
    } = this.props;

    const {
      refreshing,
      specialRooms,
      offset,
      selectedRoomId,
      selectedSpecialRoomId,
      activeButtonId,
    } = this.state;

    const shield = this.props.navigation.getParam("shield", null);
    const shieldToShow = shield != "null" ? shield : 0;
    const isMatchDelayed = this.props.livescore
      ? this.props.livescore.matchState["Postponed"]
        ? true
        : false
      : false;

    let playTime;
    let score = livescore ? livescore.score : "";
    if (livescore) {
      playTime = livescore.matchState;
      if (livescore.matchState === "Finished") {
        playTime = "FT";
      }

      if (
        livescore.liveComments &&
        livescore.liveComments != null &&
        livescore.liveComments.length != 0
      ) {
        score = livescore.liveComments[livescore.liveComments.length - 1].score;
      }
    }

    let homeTeamWinPercent;
    let drawPercent;
    let awayTeamWinPercent;
    const homeTeamTextStyle = [liveMatchStyles.percentText];
    const drawTextStyle = [liveMatchStyles.percentText];
    const awayTeamTextStyle = [liveMatchStyles.percentText];
    const betContainerStyle = [liveMatchStyles.betContainer];

    if (rooms && rooms.length != 0) {
      homeTeamWinPercent = Number(
        rooms[selectedRoomId].firstTeamPredictionsPercent
      );
      drawPercent = Number(rooms[selectedRoomId].drawPredictionsPercent);
      awayTeamWinPercent = Number(
        rooms[selectedRoomId].secondTeamPredictionsPercent
      );
      if (
        homeTeamWinPercent > drawPercent &&
        homeTeamWinPercent > awayTeamWinPercent
      ) {
        homeTeamTextStyle.push(liveMatchStyles.percentTextBig);
        homeTeamTextStyle.push({ fontSize: 4 * reactRem });
      } else if (
        drawPercent > homeTeamWinPercent &&
        drawPercent > awayTeamWinPercent
      ) {
        drawTextStyle.push(liveMatchStyles.percentTextBig);
        homeTeamTextStyle.push({ fontSize: 4 * reactRem });
      } else if (
        awayTeamWinPercent > drawPercent &&
        awayTeamWinPercent > homeTeamWinPercent
      ) {
        awayTeamTextStyle.push(liveMatchStyles.percentTextBig);
        homeTeamTextStyle.push({ fontSize: 4 * reactRem });
      }

      if (drawPercent != 0) {
        drawPercent =
          Number(homeTeamWinPercent) +
          Number(rooms[selectedRoomId].drawPredictionsPercent);
      }

      let scores = score !== null ? score.split(" - ") : "";
      if (scores.length === 2 && scores[0] > scores[1]) {
        if (rooms[this.state.selectedRoomId].myBet === "1") {
          betContainerStyle.push({ backgroundColor: globalStyles.green });
        }
        if (
          rooms[this.state.selectedRoomId].myBet === "2" ||
          rooms[this.state.selectedRoomId].myBet === "X"
        ) {
          betContainerStyle.push({ backgroundColor: globalStyles.red });
        }
      }
      if (scores.length === 2 && scores[0] < scores[1]) {
        if (rooms[this.state.selectedRoomId].myBet === "2") {
          betContainerStyle.push({ backgroundColor: globalStyles.green });
        }
        if (
          rooms[this.state.selectedRoomId].myBet === "1" ||
          rooms[this.state.selectedRoomId].myBet === "X"
        ) {
          betContainerStyle.push({ backgroundColor: globalStyles.red });
        }
      }
      if (scores.length === 2 && scores[0] === scores[1]) {
        if (rooms[this.state.selectedRoomId].myBet === "X") {
          betContainerStyle.push({ backgroundColor: globalStyles.green });
        }
        if (
          rooms[this.state.selectedRoomId].myBet === "1" ||
          rooms[this.state.selectedRoomId].myBet === "2"
        ) {
          betContainerStyle.push({ backgroundColor: globalStyles.red });
        }
      }
      if (!rooms[this.state.selectedRoomId].myBet) {
        betContainerStyle.push({ backgroundColor: globalStyles.red });
      }
    }

    const currentDate = this.currentDate(date);
    const leagueName = this.props.navigation.getParam("leagueName", null);

    if (!standings) {
      return <LoadingScreen />;
    }

    return (
      <View style={{ backgroundColor: globalStyles.backgroundGrey }}>
        <AnimationTeamContainer
          offset={offset}
          defaultHeight={120}
          heightTo={60}
          style={liveMatchStyles.teamsContainer}
        >
          {offset === 0 ? null : (
            <View style={futureMatchStyles.smallMatchFlagContainer}>
              {shieldToShow != 0 ? <Shield points={shield} /> : null}
            </View>
          )}
          <View
            style={
              offset === 0
                ? liveMatchStyles.container
                : liveMatchStyles.smallContainerLeft
            }
          >
            <AnimatableImage image={{ uri: homeTeamImg }} offset={offset} />
            {offset === 0 ? (
              <View style={liveMatchStyles.clubTeamContainer}>
                <Text style={liveMatchStyles.clubName}>{homeTeam}</Text>
              </View>
            ) : (
              <View
                style={[
                  liveMatchStyles.clubTeamContainer,
                  liveMatchStyles.smallClubTeamContainerLeft,
                ]}
              >
                <Text style={liveMatchStyles.smallClubName}>{homeTeam}</Text>
              </View>
            )}
          </View>
          {score !== "" && score !== null && !isMatchDelayed ? (
            <AnimationTeamContainer
              offset={offset}
              defaultHeight={60}
              heightTo={30}
              style={liveMatchStyles.timeDateContainer}
            >
              <AnimatableText
                text={playTime + " '"}
                offset={offset}
                style={liveMatchStyles.dateText}
                defaultSize={16}
                sizeTo={10}
              />
              <AnimatableText
                text={score}
                offset={offset}
                style={liveMatchStyles.timeText}
                defaultSize={20}
                sizeTo={12}
              />
            </AnimationTeamContainer>
          ) : (
            <AnimationTeamContainer
              offset={offset}
              defaultHeight={60}
              heightTo={30}
              style={liveMatchStyles.timeDateContainer}
            >
              <AnimatableText
                text={isMatchDelayed ? "" : currentDate}
                offset={offset}
                style={futureMatchStyles.dateText}
                defaultSize={16}
                sizeTo={10}
              />
              <AnimatableText
                text={isMatchDelayed ? "Delayed" : time}
                offset={offset}
                style={futureMatchStyles.timeText}
                defaultSize={20}
                sizeTo={12}
              />
            </AnimationTeamContainer>
          )}
          <View
            style={
              offset === 0
                ? liveMatchStyles.container
                : liveMatchStyles.smallContainerRight
            }
          >
            <AnimatableImage image={{ uri: guestTeamImg }} offset={offset} />
            {offset === 0 ? (
              <View style={liveMatchStyles.clubTeamContainer}>
                <Text style={liveMatchStyles.clubName}>{guestTeam}</Text>
              </View>
            ) : (
              <View
                style={[
                  liveMatchStyles.clubTeamContainer,
                  liveMatchStyles.smallClubTeamContainerRight,
                ]}
              >
                <Text style={liveMatchStyles.smallClubName}>{guestTeam}</Text>
              </View>
            )}
          </View>
        </AnimationTeamContainer>
        {livescore ? (
          <View style={liveMatchStyles.buttonsContainer}>
            <TouchableOpacity
              style={[
                liveMatchStyles.inactiveButton,
                liveMatchStyles.leftButtonContainer,
              ]}
              onPress={() => this.changeActiveButton(0)}
            >
              <Text style={liveMatchStyles.inactiveButtonText}>Summary</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={liveMatchStyles.inactiveButton}
              onPress={() => this.changeActiveButton(1)}
            >
              <Text style={liveMatchStyles.inactiveButton}>Line Ups</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <RefreshContainer
            handleScroll={this.handleScroll}
            refreshing={refreshing}
            onRefresh={this._onRefresh}
            style={futureMatchStyles.fieldsContainer}
          >
            {isLoading ? (
              <LoadingScreen />
            ) : (
              <View style={futureMatchStyles.flatListStyleContainer}>
                {bets && !isMatchDelayed ? (
                  <BetContainer
                    {...specialRooms[selectedSpecialRoomId]}
                    reactRem={reactRem}
                    onSetBet={this.onSetBet}
                    matchId={this.props.navigation.getParam("matchId", "1")}
                    homeTeam={standings.homeTeam.name}
                    guestTeam={standings.guestTeam.name}
                    time={time}
                    matchDate={date}
                    leagueName={leagueName}
                    specialRoomsLength={specialRooms.length}
                    specialRoom={
                      specialRooms.length != 0
                        ? specialRooms[selectedSpecialRoomId].is_special
                        : null
                    }
                    onNextRoom={this.onNextRoomBetContainer}
                    onPrevRoom={this.onPrevRoomContainer}
                    specialRoomName={
                      specialRooms.length != 0
                        ? specialRooms[selectedSpecialRoomId].roomName
                        : null
                    }
                  />
                ) : null}
                {odsBanner && odsBanner.length != 0 ? (
                  <LeagueAd
                    banner={odsBanner}
                    navigation={this.props.navigation}
                    futureMatch={true}
                  />
                ) : null}
                {!this.props.userId ? (
                  <View style={futureMatchStyles.predictionContainer}>
                    <View style={futureMatchStyles.singInUpBtnsContainer}>
                      <TouchableOpacity
                        style={futureMatchStyles.signInContainer}
                        onPress={() => {
                          this.props.onOpenRegister(true, false);
                          this.props.navigation.navigate("LoginScreen");
                        }}
                      >
                        <Text style={futureMatchStyles.signInText}>
                          SIGN IN
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={futureMatchStyles.signUpContainer}
                        onPress={() => {
                          this.props.onOpenRegister(true, true);
                          this.props.navigation.navigate("LoginScreen", {
                            register: true,
                          });
                        }}
                      >
                        <Text style={futureMatchStyles.signUpText}>
                          SIGN UP
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                ) : null}
                <TeamStatsContainer
                  reactRem={reactRem}
                  standings={standings}
                  navigation={this.props.navigation}
                  leagueId={this.props.navigation.getParam("leagueId", "1")}
                  homeTeamImg={headToHead.homeTeamImg}
                  guestTeamImg={headToHead.guestTeamImg}
                />
                <LastMatchContainer
                  reactRem={reactRem}
                  form={form}
                  homeTeamName={standings.homeTeam.name}
                  guestTeamName={standings.guestTeam.name}
                  navigation={this.props.navigation}
                  leagueId={this.props.navigation.getParam("leagueId", "1")}
                />
                <HeadToHeadContainer
                  reactRem={reactRem}
                  headToHead={headToHead}
                  navigation={this.props.navigation}
                  leagueId={this.props.navigation.getParam("leagueId", "1")}
                />
              </View>
            )}
          </RefreshContainer>
        )}
        {livescore ? (
          <View
            handleScroll={this.handleScroll}
            refreshing={this.state.refreshing}
            onRefresh={this._onRefresh}
            style={liveMatchStyles.scrollViewStyle}
          >
            <View style={liveMatchStyles.flatListStyleContainer}>
              {activeButtonId === 0 ? (
                <LivescoreContainer reactRem={reactRem} {...this.props} />
              ) : (
                <LineupsContainer reactRem={reactRem} lineups={lineups} />
              )}
              {rooms ? (
                <View style={liveMatchStyles.predictionContainer}>
                  <View style={liveMatchStyles.listItem}>
                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    >
                      <View style={betContainerStyle}>
                        <Text style={liveMatchStyles.betText}>
                          {rooms[this.state.selectedRoomId].myBet
                            ? rooms[this.state.selectedRoomId].myBet
                            : "-"}
                        </Text>
                      </View>
                      <Text style={liveMatchStyles.leagueTitle}>
                        Rooms Predictions
                      </Text>
                    </View>
                  </View>
                  <View style={liveMatchStyles.matchPredictionInfoContainer}>
                    <View style={liveMatchStyles.roomNameContainer}>
                      <TouchableOpacity
                        style={liveMatchStyles.arrowImageContainer}
                        onPress={() => this.onPrevRoom()}
                      >
                        <Image
                          source={leftArrow}
                          style={liveMatchStyles.arrowImage}
                        />
                      </TouchableOpacity>
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {league ? (
                          <Text style={liveMatchStyles.roomNameText}>
                            {league.name ===
                            rooms[this.state.selectedRoomId].nameOfRoom
                              ? "Global Room"
                              : rooms[this.state.selectedRoomId].nameOfRoom}
                          </Text>
                        ) : null}
                      </View>
                      <TouchableOpacity
                        style={liveMatchStyles.arrowImageContainer}
                        onPress={() => this.onNextRoom()}
                      >
                        <Image
                          source={rightArrow}
                          style={liveMatchStyles.arrowImage}
                        />
                      </TouchableOpacity>
                    </View>
                    <View style={liveMatchStyles.clubImageContainer}>
                      <View style={liveMatchStyles.miniContainer}>
                        <Image
                          source={{ uri: homeTeamImg }}
                          style={liveMatchStyles.clubSmallImage}
                        />
                        <Text style={liveMatchStyles.smallClubName}>
                          {homeTeam}
                        </Text>
                      </View>
                      <View style={liveMatchStyles.miniContainer}>
                        <Text style={liveMatchStyles.smallClubName}>DRAW</Text>
                      </View>
                      <View style={liveMatchStyles.miniContainer}>
                        <Image
                          source={{ uri: guestTeamImg }}
                          style={liveMatchStyles.clubSmallImage}
                        />
                        <Text style={liveMatchStyles.smallClubName}>
                          {guestTeam}
                        </Text>
                      </View>
                    </View>
                    <View style={liveMatchStyles.progressBarContainer}>
                      <AnimatableProgressbarView
                        style={liveMatchStyles.progressBarPercent}
                        widthTo={homeTeamWinPercent}
                      />
                      <AnimatableProgressbarView
                        style={liveMatchStyles.progressBarDrawPercent}
                        widthTo={drawPercent}
                      />
                      <AnimatableProgressbarView
                        style={liveMatchStyles.progessBarGuestPercent}
                        widthTo={100}
                      />
                    </View>
                    <View style={liveMatchStyles.clubPercentContainer}>
                      <View style={liveMatchStyles.miniContainer}>
                        <Text style={homeTeamTextStyle}>
                          {rooms[selectedRoomId].firstTeamPredictionsPercent}%
                        </Text>
                      </View>
                      <View style={liveMatchStyles.miniContainer}>
                        <Text style={drawTextStyle}>
                          {rooms[selectedRoomId].drawPredictionsPercent}%
                        </Text>
                      </View>
                      <View style={liveMatchStyles.miniContainer}>
                        <Text style={awayTeamTextStyle}>
                          {rooms[selectedRoomId].secondTeamPredictionsPercent}%
                        </Text>
                      </View>
                    </View>
                    <View style={liveMatchStyles.pointsContainer}>
                      <View style={liveMatchStyles.miniContainer}>
                        <Text style={liveMatchStyles.pointsText}>
                          {rooms[selectedRoomId].firstTeamPoints} Pts
                        </Text>
                      </View>
                      <View style={liveMatchStyles.miniContainer}>
                        <Text style={liveMatchStyles.pointsText}>
                          {rooms[selectedRoomId].drawPoints} Pts
                        </Text>
                      </View>
                      <View style={liveMatchStyles.miniContainer}>
                        <Text style={liveMatchStyles.pointsText}>
                          {rooms[selectedRoomId].secondTeamPoints} Pts
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              ) : (
                <View>
                  {!this.props.userId ? (
                    <View style={liveMatchStyles.predictionContainer}>
                      <View style={liveMatchStyles.singInUpBtnsContainer}>
                        <TouchableOpacity
                          style={liveMatchStyles.signInContainer}
                          onPress={() => {
                            this.props.onOpenRegister(true, false);
                            this.props.navigation.navigate("Login");
                          }}
                        >
                          <Text style={liveMatchStyles.signInText}>
                            SIGN IN
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={liveMatchStyles.signUpContainer}
                          onPress={() => {
                            this.props.onOpenRegister(true, true);
                            this.props.navigation.navigate("Login", {
                              register: true,
                            });
                          }}
                        >
                          <Text style={liveMatchStyles.signUpText}>
                            SIGN UP
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  ) : null}
                </View>
              )}
            </View>
          </View>
        ) : null}
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onMatchSelect: (matchId) => dispatch(selectMatch(matchId)),
    onResetLiveScore: () => dispatch(resetLiveScore()),
    onOpenRegister: (loginscreen, register) =>
      dispatch(onOpenRegister(loginscreen, register)),
    onSetMatchBet: (bet, matchId, leagueId, roomId, specialRoom) =>
      dispatch(setMatchBet(bet, matchId, leagueId, roomId, specialRoom)),
    setMatchSpecialGameBet: (bet, matchId, leagueId, roomId) =>
      dispatch(setMatchSpecialGameBet(bet, matchId, leagueId, roomId)),
    deleteFutureMatchInfo: () => dispatch(deleteFutureMatchInfo()),
    getMatchData: (matchId) => dispatch(getMatchData(matchId)),
    getMatchDataNoToken: (matchId) => dispatch(getMatchDataNoToken(matchId)),
    getLeagues: () => dispatch(getLeagues()),
    onLeagueSelect: (leagueId) => dispatch(selectLeague(leagueId)),
    getLiveMatchLineUps: (matchId) => dispatch(getLiveMatchLineUps(matchId)),
  };
};
const mapStateToProps = (state) => {
  return {
    match: state.homeScreenLeagues.selectMatch,
    league: state.homeScreenLeagues.selectLeague,
    reactRem: state.homeScreenLeagues.reactRem,
    livescore: state.futureMatch.liveScore,
    token: state.auth.token,
    userId: state.auth.userId,
    isLoading: state.loadingReducer.isLoading,
    fromSelectLeague: state.homeScreenLeagues.selectLeague,
    odsBanner: state.futureMatch.odsBanner,
    standings: state.futureMatch.standings,
    lineups: state.liveMatches.lineups,
    form: state.futureMatch.form,
    headToHead: state.futureMatch.headToHead,
    bets: state.futureMatch.bets,
    date: state.futureMatch.date,
    time: state.futureMatch.time,
    homeTeam: state.futureMatch.homeTeam,
    homeTeamImg: state.futureMatch.homeTeamImg,
    guestTeam: state.futureMatch.guestTeam,
    guestTeamImg: state.futureMatch.guestTeamImg,
    rooms: state.futureMatch.rooms,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchScreen);
