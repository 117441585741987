import React from "react";
import { View, FlatList, ScrollView, Text } from "react-native";
import { connect } from "react-redux";
import ScreenTitleText from "../../Common/components/UI/ScreenTitleText";
import LeagueField from "../../Common/components/UI/LeagueField";
import { tryRegister } from "../actions/auth";
import { getLeagues } from "../actions/auth";
import { addNewLeague } from "../../Prediction/actions/roompredictions";
import { BASE_URL } from "../../Common/store/config";
import FloatingButton from "../../Common/components/UI/FloatingButton";
import TwoFloatingButtons from "../../Common/components/UI/TwoFloatingButtons";
import { leaveRoom, setRoomsToLeave } from "../../Room/actions/rooms";
import {
  setLeaveRoom,
  setLeaveRoomId,
} from "../../Common/store/actions/resources";
import AlertDialogWithConfirmButton from "../../Common/components/UI/AlertDialogWithConfirmButton";
import { chooseLeaguesStyles } from "../styles/styles";

class ChooseLeagues extends React.Component {
  state = {
    choosenLeague: [],
    buttonIsPress: false,
    isModalVisible: false,
    emptyRooms: false,
  };

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  toggleModal = () => {
    this.props.setLeaveRoom(true);
    this.props.setLeaveRoomId(this.state.choosenLeague);
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };
  emptyRoomMessage = () => {
    this.setState({ emptyRooms: !this.state.emptyRooms });
  };
  onCloseGameRoom = () => {
    this.props.leaveRoom(this.state.choosenLeague);
  };
  onPickLeague = (id) => {
    const { choosenLeague } = this.state;
    this.setState({ choosenLeague: [...choosenLeague, id], error: null });
  };
  onRemoveLeague = (id) => {
    const { choosenLeague } = this.state;
    this.setState({
      choosenLeague: choosenLeague.filter((leagueId) => {
        return leagueId !== id;
      }),
    });
  };

  onDiscard = () => {
    this.props.setRoomsToLeave(null);
    this.setState({ choosenLeague: [] });
    this.props.navigation.goBack();
  };

  onConfirm = () => {
    if (this.state.choosenLeague.length != 0) {
      this.toggleModal();
    } else {
      this.emptyRoomMessage();
    }
  };

  registerHandler = () => {
    const { navigation, registerData } = this.props;
    this.setState({ buttonIsPress: true });
    const addLeague = navigation.getParam("addLeague", false);
    const isNewUser = navigation.getParam("isNewUser", false);
    if (isNewUser) {
      this.props.addNewLeague(this.state.choosenLeague);
      this.props.navigation.navigate("RoomScreen");
      return;
    }

    if (addLeague && this.state.choosenLeague.length > 0) {
      this.props.addNewLeague(this.state.choosenLeague);
      this.props.getLeagues();
      return;
    }
    const data = {
      email: registerData.email,
      password: registerData.password,
      first_name: registerData.firstName,
      last_name: registerData.lastName,
      date_of_birth: registerData.dateOfBirth,
      user_name: registerData.username,
      genderId: registerData.gender ? registerData.gender : 0,
      nationalityId: registerData.nationality ? registerData.nationality : 0,
      leagues: this.state.choosenLeague,
    };

    if (registerData) {
      this.props.onTryRegister(data);
    }
  };

  componentDidMount() {
    this.props.getLeagues();
    this.goToTop();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.addLeagueSuccesfull != this.props.addLeagueSuccesfull) {
      this.setState({ buttonIsPress: false });
      this.props.navigation.goBack();
    }
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
    if (!prevProps.isLeavePressed && this.props.isLeavePressed) {
    }
    if (prevProps.registerData && !this.props.registerData) {
      this.props.navigation.navigate("Home");
    }
  }

  onErrorShow = () => {
    const { choosenLeague } = this.state;
    if (choosenLeague.length === 0) {
      this.setState({ error: "Please choose a league" });
      return;
    }
  };

  onScrollToTheEnd = () => {
    if (this.state.error) {
      this.scrollView.scrollToEnd({ animated: true });
    }
  };

  render() {
    const {
      leagues,
      navigation,
      userLeagues,
      globalRooms,
      reactRem,
      roomsToLeave,
    } = this.props;

    const { choosenLeague, error, buttonIsPress } = this.state;

    const addLeague = navigation.getParam("addLeague", false);
    const isNewUser = navigation.getParam("isNewUser", false);
    const leaveRooms = navigation.getParam("leaveRooms", false);

    let titleText = "You want to play in";
    let extraText = "(You can add more later)";

    let buttonText = "JOIN GLOWTER";
    if (addLeague && addLeague === "true") {
      buttonText = "ADD LEAGUE";
    }
    if (isNewUser === "true") {
      buttonText = "JOIN GLOWTER";
    }
    if (
      (addLeague === "false" ||
        addLeague === "null" ||
        addLeague === "undefined") &&
      (isNewUser === "false" ||
        isNewUser === "null" ||
        isNewUser === "undefined")
    ) {
      buttonText = "JOIN GLOWTER";
    }
    let leaguesToShow;
    if (globalRooms) {
      leaguesToShow = globalRooms;
    } else {
      leaguesToShow = userLeagues;
    }
    if (leaveRooms && leaveRooms === "true") {
      titleText = "Choose rooms to leave";
      extraText = null;
    }

    return (
      <View style={chooseLeaguesStyles.container}>
        <ScrollView
          contentContainerStyle={chooseLeaguesStyles.scrollViewStyle}
          ref={(ref) => (this.scrollView = ref)}
          onContentSizeChange={(contentWidth, contentHeight) =>
            this.onScrollToTheEnd()
          }
        >
          <ScreenTitleText
            title={titleText}
            extraText={extraText}
            reactRem={reactRem}
          />
          <FlatList
            data={
              roomsToLeave && roomsToLeave.length != 0 ? roomsToLeave : leagues
            }
            reactRem={reactRem}
            keyExtractor={(item, index) => "key" + index}
            renderItem={({ item, index }) => (
              <LeagueField
                {...item}
                reactRem={reactRem}
                index={index}
                leaguesLength={
                  roomsToLeave && roomsToLeave.length != 0
                    ? roomsToLeave.length
                    : leagues.length
                }
                BASE_URL={BASE_URL}
                userLeagues={leaguesToShow}
                addLeague={addLeague}
                onPickLeague={() => this.onPickLeague(item.id)}
                onRemoveLeague={() => this.onRemoveLeague(item.id)}
                isNewUser={isNewUser}
              />
            )}
          />
          {error ? (
            <View style={chooseLeaguesStyles.errorMessageContainer}>
              <Text style={chooseLeaguesStyles.errorMessage}>{error}</Text>
            </View>
          ) : (
            <View style={chooseLeaguesStyles.errorMessageContainer}>
              <Text style={chooseLeaguesStyles.errorMessage}></Text>
            </View>
          )}
        </ScrollView>
        {!leaveRooms || leaveRooms === "false" ? (
          <FloatingButton
            reactRem={reactRem}
            onPress={() => {
              choosenLeague.length != 0
                ? this.registerHandler()
                : this.onErrorShow();
            }}
            buttonIsPress={buttonIsPress}
          >
            {buttonText}
          </FloatingButton>
        ) : null}
        {leaveRooms && leaveRooms === "true" ? (
          <TwoFloatingButtons
            reactRem={reactRem}
            firstOnPress={() => this.onDiscard()}
            firstButtonText={"DISCARD"}
            secondOnPress={() => this.onConfirm()}
            secondButtonText={"LEAVE"}
          />
        ) : null}

        <AlertDialogWithConfirmButton
          isModalVisible={this.state.isModalVisible}
          onChangeModalVisibility={this.toggleModal}
          onCloseGameRoom={this.onCloseGameRoom}
          leaveRooms={leaveRooms}
          title={
            "Are you sure you want to leave the room? You won’t be able to enter/play/return again."
          }
          navigation={this.props.navigation}
        />
        <AlertDialogWithConfirmButton
          isModalVisible={this.state.emptyRooms}
          onChangeModalVisibility={this.emptyRoomMessage}
          onCloseGameRoom={() => null}
          fromLeave={true}
          title={"You have not selected a room to leave"}
          navigation={this.props.navigation}
        />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTryRegister: (data) => dispatch(tryRegister(data)),
    getLeagues: () => dispatch(getLeagues()),
    addNewLeague: (data) => dispatch(addNewLeague(data)),
    leaveRoom: (rooms) => dispatch(leaveRoom(rooms)),
    setLeaveRoom: (leave) => dispatch(setLeaveRoom(leave)),
    setLeaveRoomId: (rooms) => dispatch(setLeaveRoomId(rooms)),
    setRoomsToLeave: (roomsToLeave) => dispatch(setRoomsToLeave(roomsToLeave)),
  };
};

const mapStateToProps = (state) => {
  return {
    leagues: state.auth.leagues,
    registerData: state.auth.registerData,
    userLeagues: state.homeScreenLeagues.leagues,
    addLeagueSuccesfull: state.predictions.addLeagueSuccesfull,
    error: state.errorReducer.error,
    globalRooms: state.privateRooms.globalRooms,
    roomsToLeave: state.privateRooms.roomsToLeave,
    isLeavePressed: state.resources.isLeavePressed,
    reactRem: state.homeScreenLeagues.reactRem,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseLeagues);
