import React from 'react';
import { Image, View, Text, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { setValueConstant } from './Home/actions/homeScreenLeagues';

class GetHeight extends React.Component {
    state = {
        isMobile: false,
    }

    onSetIsMobile = () => { this.setState({ isMobile: !this.state.isMobile }) };

    componentDidMount() {
        const { userId } = this.props;


        window.addEventListener('resize', () => {
            let isMobileSet = false;
            if (this.props.windowWidth < 650) {
                isMobileSet = true;
            }
            if (this.props.windowWidth < 959 && this.props.windowWidth > 650) {
                isMobileSet = false;
                this.props.setValueConstant((window.innerWidth + window.innerHeight) / 220);
                this.props.onChangeReactRem((window.innerWidth + window.innerHeight) / 220, userId);
            }
            else if (!isMobileSet) {
                isMobileSet = false;
                this.props.setValueConstant((window.innerWidth + window.innerHeight) / 350);
                this.props.onChangeReactRem((window.innerWidth + window.innerHeight) / 350, userId);
            }
        })
    };
    componentDidUpdate(prevProps) {
        const { userId, windowWidth } = this.props;
        const { isMobile } = this.state;
        if (prevProps.windowWidth != this.props.windowWidth) {
            if (this.props.windowWidth < 650 && !isMobile) {
                this.onSetIsMobile();
                this.props.setValueConstant((window.innerWidth + window.innerHeight) / 220);
                this.props.onChangeReactRem((window.innerWidth + window.innerHeight) / 220, userId);
            } else if (!isMobile) {
                this.props.setValueConstant((window.innerWidth + window.innerHeight) / 350);
                this.props.onChangeReactRem((window.innerWidth + window.innerHeight) / 350, userId);
            }
        }

        if (!this.props.stateAdverts && this.props.adverts) {
            this.props.onSetAdverts(this.props.adverts);
        }
    }

    render() {
        return (
            <View>

            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        height: state.homeScreenLeagues.height,
        specialHeight: state.homeScreenLeagues.specialHeight,
        userId: state.auth.userId,
        adverts: state.auth.adverts,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        setValueConstant: (constant) => dispatch(setValueConstant(constant)),


    };
};


export default connect(mapStateToProps, mapDispatchToProps)(GetHeight);