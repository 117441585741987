import { StyleSheet } from 'react-native'; 
import { BASE_URL } from './Common/store/config';
const globalStyles = {
    backgroundBtn: '#54718E',
    notPredictedColor:"#D8E1EA",
    basicBlueGreyColor:'#C0CFE9',
    borderRoom:"#6E879F",
    mainBlue:"#0F2C69",
    lightBlue:"#BBE4FA",
    darkBlue:"#020D2D",
    fontFamilyBold:"Montserrat-Bold",
    fontFamilyItalic:"Montserrat-ExtraLightItalic",
    fontFamilyRegular:"Montserrat-Regular",
    fontFamilyMedium:"Montserrat-Medium",
    backgroundDrawerBlue:"#00235F",
    imageBorder:"#113C88",
    BASE_URL:BASE_URL,
    greenBlur:"#DAF9DA",
    darkGreenBlur:"#B2F0AF",
    defaultUserImageColor:"#2899FF",
    fbColor:"#295396",

    black:"#050F24",
    backgroundGrey:"#D7DEE6",
    activeBlue:"#00C2FF",
    gradientBlue:"#8BE3FF",
    fontRobotoBold:"Roboto-Bold",
    fontRobotoMedium:"Roboto-Medium",
    white:"#FFFFFF",
    textBlue:"#00173E",
    fontUbuntuMedium:"Ubuntu-Medium",
    fontUbuntuBold:"Ubuntu-Bold",
    fontUbuntuRegular:"Ubuntu-Regular",
    error:"#FF0066",
    errorBackground:"#F3F5F8",
    yellow:"#E5A000",
    green:"#14CE59",
    whiteOpacity:"#F2F4F2",
    red:"#E51D1D",
    unratedGrey:"#C4CED9",
    textGrey:"#9AA5BB",
    progressBarBlue:"#254DA1",
    predictionButtonGrey:"#E5E6E8",
    greyText:"#B3B6BC",
    searchBacground:"#8E8E933D",
    clearButton:"#8E8E93",
    promotionGreen:"#EEFFF4",
    relegationRed:"#FFEFEF",
    euroLeagueRed:"#FFFAF2",
    golden:"#EFBC20",
    
};


export default globalStyles;