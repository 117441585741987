import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import Tutorail1 from "../../assets/images/Tutorial_1.jpg";
import Tutorail2 from "../../assets/images/Tutorial_2.jpg";
import Tutorail3 from "../../assets/images/Tutorial_3.jpg";
import Tutorail4 from "../../assets/images/Tutorial_4.jpg";
import { tutorialScreenStyles } from "../styles/styles";

class TutorialScreen extends React.Component {
  state = {
    tutorailImg: Tutorail1,
    activeButtonId: 1,
  };

  onChangeTutorailImage = (imageSelected, id) => {
    this.setState({ tutorailImg: imageSelected, activeButtonId: id });
  };

  onSkipTutorial = () => {
    localStorage.setItem("tutorial", "true");
    this.props.navigation.navigate("LoginScreen");
  };

  render() {
    let tutorialTitle;
    let tutorialDescription;

    if (this.state.activeButtonId === 1) {
      tutorialTitle = "Choose";
      tutorialDescription = "your favourite leagues";
    } else if (this.state.activeButtonId === 2) {
      tutorialTitle = "Create and Join";
      tutorialDescription = "rooms";
    } else if (this.state.activeButtonId === 3) {
      tutorialTitle = "Predict Games";
      tutorialDescription = "And stay informed about LIVE results";
    } else if (this.state.activeButtonId === 4) {
      tutorialTitle = "Stay";
      tutorialDescription =
        "until the last round of the season and BEAT your friends";
    }

    return (
      <View style={tutorialScreenStyles.container}>
        <View style={tutorialScreenStyles.tutorialContainer}>
          <View style={tutorialScreenStyles.tutorialDescriptionContainer}>
            <Text style={tutorialScreenStyles.tutorialTitleText}>
              {tutorialTitle}
            </Text>
            <View style={tutorialScreenStyles.turorialDescriptionContainer}>
              <Text style={tutorialScreenStyles.tutorialDescriptionText}>
                {tutorialDescription}
              </Text>
            </View>
          </View>
          <View style={tutorialScreenStyles.buttonContainer}>
            <TouchableOpacity
              style={tutorialScreenStyles.textButtonContainer}
              onPress={() => this.onChangeTutorailImage(Tutorail1, 1)}
            >
              <Text style={tutorialScreenStyles.textButton}>1</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={tutorialScreenStyles.textButtonContainer}
              onPress={() => this.onChangeTutorailImage(Tutorail2, 2)}
            >
              <Text style={tutorialScreenStyles.textButton}>2</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={tutorialScreenStyles.textButtonContainer}
              onPress={() => this.onChangeTutorailImage(Tutorail3, 3)}
            >
              <Text style={tutorialScreenStyles.textButton}>3</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={tutorialScreenStyles.textButtonContainer}
              onPress={() => this.onChangeTutorailImage(Tutorail4, 4)}
            >
              <Text style={tutorialScreenStyles.textButton}>4</Text>
            </TouchableOpacity>
          </View>
          <View style={tutorialScreenStyles.skipContainer}>
            <TouchableOpacity
              style={tutorialScreenStyles.skipTextContainer}
              onPress={() => this.onSkipTutorial()}
            >
              <Text style={tutorialScreenStyles.skipText}>
                {this.state.activeButtonId === 4 ? "JOIN NOW" : "SKIP"}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={tutorialScreenStyles.tutorailContainer}>
          <Image
            style={tutorialScreenStyles.image}
            source={this.state.tutorailImg}
          />
        </View>
      </View>
    );
  }
}

export default TutorialScreen;
