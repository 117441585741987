import React from 'react';
import { Image, View, Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import ShieldImg from '../../../assets/home_bonus_points.png'
import globalStyles from '../../../globalStyles';
import { connect } from 'react-redux';

const shield = props => {
    const { reactRem } = props;
    const shieldImgStyle = [styles.shieldImg, { height: 4 * reactRem, width: 3.5 * reactRem }];
    const textColorStyle = [styles.textPoints, { fontSize: 2 * reactRem, paddingBottom: reactRem }];
    if (props.style) {
        shieldImgStyle.push(props.style);
        textColorStyle.push(styles.textPointsStyle);
        textColorStyle.push({ fontSize: 2 * reactRem })
    }

    return (
        <View style={styles.shieldContainer}>
            <Image source={ShieldImg} style={shieldImgStyle} />
            <Text style={textColorStyle}>{"x" + props.points}</Text>
        </View>
    );

}

const styles = EStyleSheet.create({
    shieldImg: {
        height: "4rem",
        width: "3.5rem",
        position: "absolute",
        zIndex: 0
    },
    textPoints: {
        color: globalStyles.mainBlue,
        fontSize: "2rem",
        fontWeight: "bold",
        zIndex: 1
    },
    shieldContainer: {
        alignItems: "center",
        justifyContent: "center",
    },
    textPointsStyle: {
        color: globalStyles.mainBlue,
        fontSize: "2rem",
        fontWeight: "bold"
    }
});

const mapStateToProps = (state) => {
    return {
        reactRem: state.homeScreenLeagues.reactRem,
    }
}

export default connect(mapStateToProps, null)(shield);