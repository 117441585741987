import React from "react";
import {
  View,
  Image,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { connect } from "react-redux";
import globalStyles from "../../globalStyles";
import DotsImg from "../../assets/dots_blue.png";
import CompareImg from "../../assets/compare.png";
import ClearImg from "../../assets/clear.png";
import defaultUserImage from "../../assets/profile_avatar.png";
import { roomLeagueTitleContainerStyles } from "../styles/styles";

class RoomLeagueTitleContainer extends React.Component {
  state = {
    searchTerm: "",
    search: false,
  };

  onSearch = () => {
    if (!this.state.search && !this.props.userSelected) {
      this.setState({ search: true });
      this.props.onSearchIsPressed(true);
    }
    if (this.state.search && !this.props.userSelected) {
      this.setState({ search: false });
      this.props.onSearchIsPressed(false);
    }
    if (!this.state.search && this.props.userSelected) {
      this.setState({ search: true });
      this.props.onSearchIsPressed(true);
    } else if (this.props.userSelected) {
      this.props.onSelectUser(null);
      this.setState({ search: false });
      this.props.onSearchIsPressed(false);
    }
  };

  setCompare = () => {
    if (this.props.user) {
      this.onSearch();
      this.props.setSelectedUserPredictionData();
    }
  };

  handleInputChange = (val) => {
    this.setState({ searchTerm: val });
  };

  render() {
    const {
      leaguesInRoom,
      roomName,
      roomNameType,
      predictions,
      peopleRankingInRoom,
      userSelected,
      user,
      specialGamePeopleRaking,
    } = this.props;

    const { search, searchTerm } = this.state;

    let filteredEmails;
    if (specialGamePeopleRaking) {
      filteredEmails =
        searchTerm === ""
          ? []
          : specialGamePeopleRaking.filter(
              (p) =>
                p.playerName.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                  -1 ||
                (p.user_name != null
                  ? p.user_name
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase()) !== -1
                  : null)
            );
    } else {
      filteredEmails =
        searchTerm === ""
          ? []
          : peopleRankingInRoom.filter(
              (p) =>
                p.playerName.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                  -1 ||
                (p.user_name != null
                  ? p.user_name
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase()) !== -1
                  : null)
            );
    }

    const selectedUserTextStyle = [
      roomLeagueTitleContainerStyles.selectedUserText,
    ];
    const userTextStyle = [roomLeagueTitleContainerStyles.selectedUserText];

    if (userSelected && Number(userSelected.lastRoundPoints) > 0) {
      selectedUserTextStyle.push({ color: globalStyles.green });
    } else if (userSelected && Number(userSelected.lastRoundPoints) < 0) {
      selectedUserTextStyle.push({ color: globalStyles.red });
    }
    if (user && Number(user.lastRoundPoints) > 0) {
      userTextStyle.push({ color: globalStyles.green });
    } else if (user && Number(user.lastRoundPoints) < 0) {
      userTextStyle.push({ color: globalStyles.red });
    }

    return (
      <View style={roomLeagueTitleContainerStyles.titleContainer}>
        <View style={roomLeagueTitleContainerStyles.mainContainer}>
          <View
            style={
              roomNameType === "Head to Head"
                ? roomLeagueTitleContainerStyles.h2hTitleDontsContainer
                : roomLeagueTitleContainerStyles.titleDontsContainer
            }
          >
            <View style={roomLeagueTitleContainerStyles.titleTextContainer}>
              <Text style={roomLeagueTitleContainerStyles.title}>
                {roomName}
              </Text>
            </View>
            {predictions ? (
              <TouchableOpacity
                style={roomLeagueTitleContainerStyles.dotsContainer}
                onPress={() => this.setCompare()}
              >
                <Image
                  source={userSelected && search ? ClearImg : CompareImg}
                  style={roomLeagueTitleContainerStyles.compareImg}
                />
                <Text style={roomLeagueTitleContainerStyles.compareText}>
                  {userSelected && search ? "Clear" : "Compare"}
                </Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={roomLeagueTitleContainerStyles.dotsContainer}
                onPress={() => this.props.onOpenMenu()}
              >
                <Image
                  source={DotsImg}
                  style={roomLeagueTitleContainerStyles.dotsImg}
                />
              </TouchableOpacity>
            )}
          </View>
          <Text style={roomLeagueTitleContainerStyles.roomTypeText}>
            {roomNameType}
          </Text>
          {predictions ? null : (
            <View>
              <View
                style={roomLeagueTitleContainerStyles.leagueImagesContainer}
              >
                {leaguesInRoom &&
                  leaguesInRoom.map((room, i) => (
                    <Image
                      source={{ uri: globalStyles.BASE_URL + room.leagueImg }}
                      key={i}
                      style={roomLeagueTitleContainerStyles.leagueIcon}
                    />
                  ))}
              </View>
            </View>
          )}
        </View>
        {search && !userSelected && predictions ? (
          <View style={roomLeagueTitleContainerStyles.searchBoxContainer}>
            <View
              style={roomLeagueTitleContainerStyles.searchBoxAndButtonContainer}
            >
              <View style={roomLeagueTitleContainerStyles.searchContainer}>
                <View
                  style={roomLeagueTitleContainerStyles.searchImageContainer}
                >
                  <Image
                    source={CompareImg}
                    style={roomLeagueTitleContainerStyles.searchImg}
                  />
                </View>
                <View
                  style={roomLeagueTitleContainerStyles.searchInputContainer}
                >
                  <TextInput
                    style={roomLeagueTitleContainerStyles.searchInput}
                    placeholder="Type a name to search"
                    value={this.state.searchTerm}
                    onChangeText={this.handleInputChange}
                  />
                </View>
              </View>
              <TouchableOpacity
                style={roomLeagueTitleContainerStyles.cancelContainer}
                onPress={() => (!search ? null : this.onSearch())}
              >
                <Text style={roomLeagueTitleContainerStyles.cancelText}>
                  Cancel
                </Text>
              </TouchableOpacity>
            </View>
            <ScrollView>
              {filteredEmails.map((person, index) => {
                return (
                  <TouchableOpacity
                    style={roomLeagueTitleContainerStyles.playerNameContainer}
                    onPress={() => this.props.onSelectUser(person)}
                    key={person.id}
                  >
                    <Image
                      source={
                        person.image
                          ? { uri: globalStyles.BASE_URL + person.image }
                          : defaultUserImage
                      }
                      style={roomLeagueTitleContainerStyles.profileImage}
                    />
                    <View>
                      <Text
                        style={roomLeagueTitleContainerStyles.betPlayerUsername}
                      >
                        {person.user_name}
                      </Text>
                      <Text
                        style={roomLeagueTitleContainerStyles.betPlayerText}
                      >
                        {person.playerName}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
          </View>
        ) : null}
        {userSelected && predictions && search ? (
          <View style={roomLeagueTitleContainerStyles.userCompareContainer}>
            <View style={roomLeagueTitleContainerStyles.selectedUserContainer}>
              <View
                style={
                  roomLeagueTitleContainerStyles.playerNameContainerSelected
                }
              >
                <Image
                  source={
                    userSelected.image
                      ? { uri: globalStyles.BASE_URL + userSelected.image }
                      : defaultUserImage
                  }
                  style={roomLeagueTitleContainerStyles.profileImage}
                />
                <View>
                  <Text
                    style={roomLeagueTitleContainerStyles.betPlayerUsername}
                  >
                    {userSelected.user_name}
                  </Text>
                  <Text style={roomLeagueTitleContainerStyles.betPlayerText}>
                    {userSelected.playerName}
                  </Text>
                </View>
              </View>
              <View style={roomLeagueTitleContainerStyles.roundPointsContainer}>
                <View style={roomLeagueTitleContainerStyles.profileImage} />
                <Text style={selectedUserTextStyle}>
                  {Number(userSelected.lastRoundPoints) > 0
                    ? "+" + userSelected.lastRoundPoints
                    : userSelected.lastRoundPoints}{" "}
                  Pts.
                </Text>
              </View>
            </View>
            <View style={roomLeagueTitleContainerStyles.vsContainer}>
              <Text style={roomLeagueTitleContainerStyles.vsText}>VS</Text>
            </View>
            <View
              style={[
                roomLeagueTitleContainerStyles.selectedUserContainer,
                { justifyContent: "flex-end" },
              ]}
            >
              <View
                style={[
                  roomLeagueTitleContainerStyles.playerNameContainerSelected,
                  { justifyContent: "flex-end" },
                ]}
              >
                <View>
                  <Text
                    style={[
                      roomLeagueTitleContainerStyles.betPlayerUsername,
                      { textAlign: "right" },
                    ]}
                  >
                    {user.user_name}
                  </Text>
                  <Text
                    style={[
                      roomLeagueTitleContainerStyles.betPlayerText,
                      { textAlign: "right" },
                    ]}
                  >
                    {user.playerName}
                  </Text>
                </View>
                <Image
                  source={
                    user.image
                      ? { uri: globalStyles.BASE_URL + user.image }
                      : defaultUserImage
                  }
                  style={roomLeagueTitleContainerStyles.profileImage}
                />
              </View>
              <View
                style={[
                  roomLeagueTitleContainerStyles.roundPointsContainer,
                  { justifyContent: "flex-end" },
                ]}
              >
                <Text style={userTextStyle}>
                  {Number(user.lastRoundPoints) > 0
                    ? "+" + user.lastRoundPoints
                    : user.lastRoundPoints}{" "}
                  Pts.
                </Text>
                <View style={roomLeagueTitleContainerStyles.profileImage} />
              </View>
            </View>
          </View>
        ) : null}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    peopleRankingInRoom: state.roomLeague.peopleRankingInRoom,
  };
};
export default connect(mapStateToProps, null)(RoomLeagueTitleContainer);
