import React from "react";
import { View, FlatList, Text, Image, TouchableHighlight, TouchableOpacity } from "react-native";
import { whileStatement } from "@babel/types";
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles'
import CommentContainer from './CommentContainer';
import LineupsHomePlayerInfo from './LineupsHomePlayerInfo';
import LineupsAwayPlayerInfo from './LineupsAwayPlayerInfo';
import LineupsHomePlayerSubstitutions from './LineupsHomePlayerSubstitutions';
import LineupsAwayPlayerSubstitutions from './LineupsAwayPlayerSubstitutions';

import GoalImg from '../../../assets/livescore/goal.png';


class LineupsContainer extends React.Component {

    render() {
        const { lineups, reactRem } = this.props;
        let homeSystem = lineups.hometeam_system.split(" ");
        let homeSystemText = "";
        if (homeSystem) {
            for (let index = 0; index < homeSystem.length; index++) {
                homeSystemText = homeSystemText + homeSystem[index];

            }
        }
        let awaySystem = lineups.awayteam_system.split(" ");
        let awaySystemText = "";
        if (awaySystem) {
            for (let index = 0; index < awaySystem.length; index++) {
                awaySystemText = awaySystemText + awaySystem[index];

            }
        }

        const livematchContainerStyle = [styles.livematchContainer, {
            borderBottomRightRadius: 1.5 * reactRem,
            borderBottomLeftRadius: 1.5 * reactRem,
            marginHorizontal: 0.5 * reactRem,
            paddingBottom: reactRem
        }];
        const systemContainerStyle = [styles.systemContainer, {
            paddingHorizontal: 4 * reactRem,
            paddingTop: 1.5 * reactRem,
            paddingBottom: 0.5 * reactRem,
        }];
        const systemTextStyle = [styles.systemText, { fontSize: 2.8 * reactRem }];
        const lineupsContainerStyle = [styles.lineupsContainer, { paddingBottom: 2 * reactRem }];
        const titleTextStyle = [styles.titleText, { fontSize: 2.2 * reactRem }];
        const substitutionsPlayerContainerStyle = [styles.substitutionsPlayerContainer, {
            paddingBottom: 2 * reactRem,
            paddingTop: reactRem
        }];
        const coachTextStyle = [styles.coachText, { fontSize: 2.2 * reactRem }];
        const coachesContainerStyle = [styles.coachesContainer, {
            paddingBottom: 3 * reactRem,
            paddingTop: reactRem,
            paddingHorizontal: 4 * reactRem
        }]

        return (
            <View style={livematchContainerStyle}>
                <View style={systemContainerStyle}>
                    <Text style={systemTextStyle}>{homeSystemText}</Text>
                    <Text style={systemTextStyle}>{awaySystem}</Text>
                </View>
                <View style={lineupsContainerStyle}>
                    <View style={styles.lineupsPlayerContainer}>
                        {lineups.starting_line_ups.home.map((info, i) =>
                            <LineupsHomePlayerInfo
                                reactRem={reactRem}
                                info={info}
                                index={i}
                                key={i} />)}
                    </View>
                    <View style={styles.lineupsPlayerContainer}>
                        {lineups.starting_line_ups.away.map((info, i) =>
                            <LineupsAwayPlayerInfo
                                reactRem={reactRem}
                                info={info}
                                index={i}
                                key={i} />)}
                    </View>
                </View>
                <View style={{ width: "100%" }}>
                    <View style={styles.titleContainer}>
                        <Text style={titleTextStyle}>SUBSTITUTES</Text>
                    </View>
                    <View style={substitutionsPlayerContainerStyle}>
                        <View style={styles.lineupsPlayerContainer}>
                            {lineups.substitutes.home.map((info, i) =>
                                <LineupsHomePlayerInfo
                                    reactRem={reactRem}
                                    info={info}
                                    index={i}
                                    key={i} />)}
                        </View>
                        <View style={styles.lineupsPlayerContainer}>
                            {lineups.substitutes.away.map((info, i) =>
                                <LineupsAwayPlayerInfo
                                    reactRem={reactRem}
                                    info={info}
                                    index={i}
                                    key={i} />)}
                        </View>
                    </View>
                </View>
                {/* <View style={{ width: "100%" }}>
                    <View style={styles.titleContainer}>
                        <Text style={titleTextStyle}>SUBSTITUTIONS</Text>
                    </View>

                    <View style={substitutionsPlayerContainerStyle}>
                        {lineups && lineups.substitutions && lineups.substitutions.home ?
                            <View style={styles.lineupsPlayerContainer}>
                                {lineups.substitutions.home.map((info, i) =>
                                    <LineupsHomePlayerSubstitutions
                                        reactRem={reactRem}
                                        info={info}
                                        index={i}
                                        key={i} />)}
                            </View> : null}
                        {lineups && lineups.substitutions && lineups.substitutions.away ?
                            <View style={styles.lineupsPlayerContainer}>
                                {lineups.substitutions.away.map((info, i) =>
                                    <LineupsAwayPlayerSubstitutions
                                        reactRem={reactRem}
                                        info={info}
                                        index={i}
                                        key={i} />)}
                            </View> : null}

                    </View>
                </View> */}
                <View style={{ width: "100%" }}>
                    <View style={styles.titleContainer}>
                        <Text style={titleTextStyle}>COACHES</Text>
                    </View>
                    <View style={coachesContainerStyle}>
                        <Text style={coachTextStyle}>{lineups.coaches.home.lineup_player}</Text>
                        <Text style={coachTextStyle}>{lineups.coaches.away.lineup_player}</Text>
                    </View>
                </View>
            </View>
        );
    }
}

const styles = EStyleSheet.create({
    livematchContainer: {
        flex: 1,
        backgroundColor: globalStyles.white,
        alignItems: "center",
        justifyContent: "center",
        borderBottomRightRadius: '1.5rem',
        borderBottomLeftRadius: '1.5rem',
        marginHorizontal: "0.5rem",
        paddingBottom: "1rem"
    },
    systemContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingHorizontal: "4rem",
        paddingTop: "1.5rem",
        paddingBottom: "0.5rem",
        width: "100%",
    },
    systemText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuBold,
        fontSize: "2.8rem",
    },
    lineupsContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        paddingBottom: "2rem"
    },
    lineupsPlayerContainer: {
        width: "50%"
    },
    titleContainer: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    titleText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuBold,
        fontSize: "2.2rem"
    },
    substitutionsPlayerContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        paddingBottom: "2rem",
        paddingTop: "1rem"
    },
    coachText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuRegular,
        fontSize: "2.2rem",
    },
    coachesContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        paddingBottom: "3rem",
        paddingTop: "1rem",
        paddingHorizontal: "4rem",
    }

});


export default LineupsContainer