import React from "react";
import { View, Text } from "react-native";
import { prizeFieldStyles } from "../styles/styles";

class PrizeField extends React.Component {
  render() {
    const { prize, position } = this.props;
    return (
      <View style={prizeFieldStyles.playerInfoContainer}>
        <View style={prizeFieldStyles.playerPositionContainer}>
          <Text style={prizeFieldStyles.positionText}>
            {Number(position) + 1}.
          </Text>
        </View>
        <View style={prizeFieldStyles.prizeContainer}>
          <Text style={prizeFieldStyles.betPlayerText}>{prize}</Text>
        </View>
      </View>
    );
  }
}

export default PrizeField;
