import {
  SET_UNSEEN_CHATS,
  SET_IN_ROOM,
  DELETE_CHAT_DATA,
  GET_NOTIFICATION_DATA,
  SET_READ_NOTIFICATION,
  UNREAD_MESSAGE,
  GET_NEW_NOTIFICATION_DATA,
} from "./actionTypes";
import { authFetch } from "../../Common/store/actions/token";

export const getNotificationData = (page) => {
  return (dispatch) => {
    dispatch(authFetch("/notifications/all?page=" + page))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setNotificationData(res.data));
      });
  };
};

export const getNewNotificationData = (page) => {
  return (dispatch) => {
    dispatch(authFetch("/notifications/all?page=" + page))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(getNewMessageData(res.data));
      });
  };
};

export const getNewMessageData = (data) => {
  return {
    type: GET_NEW_NOTIFICATION_DATA,
    data,
  };
};

export const setReadNotification = (notificationId) => {
  const data = { notification_id: notificationId };
  return (dispatch) => {
    dispatch(authFetch("/notifications/read", "PUT", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setReadMessage(res.message, notificationId));
      });
  };
};

export const setAllReadNotification = () => {
  const data = {};
  return (dispatch) => {
    dispatch(authFetch("/notifications/readAll", "PUT", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setReadMessage(res.message, 0));
        dispatch(getNewNotificationData(1));
      });
  };
};

export const unreadMessage = () => {
  return {
    type: UNREAD_MESSAGE,
  };
};

export const setReadMessage = (data, notificationId) => {
  return {
    type: SET_READ_NOTIFICATION,
    data,
    notificationId,
  };
};

export const setNotificationData = (data) => {
  return {
    type: GET_NOTIFICATION_DATA,
    data,
  };
};

export const setUnseenChats = (unseenChats) => {
  return {
    type: SET_UNSEEN_CHATS,
    unseenChats: unseenChats,
  };
};

export const setRoomName = (roomName) => {
  return {
    type: SET_IN_ROOM,
    roomName: roomName,
  };
};

export const deleteChatData = () => {
  return {
    type: DELETE_CHAT_DATA,
  };
};
