import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import globalStyles from "../../globalStyles";
import moment from "moment";
import { unreadMessage } from "../actions/chat";
import { connect } from "react-redux";
import PrivateImg from "../../assets/private_room_grey.png";
import H2HImg from "../../assets/h2h_room_grey.png";
import { chatRoomFieldStyles } from "../styles/styles";

class ChatRoomField extends React.Component {
  state = {
    lastSeenChatTime: 0,
  };

  convertTime = (time) => {
    let d = new Date(time);
    let result = (d.getHours() < 10 ? "0" : "") + d.getHours() + ":";
    result += (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    return result;
  };

  onOpenChat = () => {
    this.setState({ lastSeenChatTime: 0 });
    if (this.state.lastSeenChatTime != 0) {
      this.props.unreadMessage();
    }
  };

  onPressNonOpen = () => {
    this.props.onPress();
    this.onOpenChat();
  };

  componentDidMount(prevProps, prevState) {
    const { name, messages } = this.props;
    const promises = [];
    let unseenChats = 0;
    let messagesFromChat;
    if (messages) {
      messagesFromChat = Object.values(messages);
    }
    if (this.state.lastSeenChatTime === 0 && messagesFromChat) {
      for (let index = 0; index < messagesFromChat.length; index++) {
        if (localStorage.getItem(messagesFromChat[index].name)) {
          promises.push(localStorage.getItem(name));

          if (localStorage.getItem(name)) {
            if (localStorage.getItem(name) < messagesFromChat[index].time) {
              unseenChats += 1;
            }
          } else {
            if (messagesFromChat[index]) {
              unseenChats += 1;
            }
          }
        }
      }

      Promise.all(promises).then(() => {
        this.setState({ lastSeenChatTime: unseenChats });
      });
    }
  }

  render() {
    const { name, roomType, lastMessage, index, length } = this.props;

    const { lastSeenChatTime } = this.state;

    let roomLogo;
    let lastMessageText;

    if (roomType === "Private") {
      roomLogo = PrivateImg;
    } else if (roomType === "H2H") {
      roomLogo = H2HImg;
    }
    if (lastMessage) {
      lastMessageText = lastMessage.message;
    }

    return (
      <TouchableOpacity onPress={() => this.onPressNonOpen()}>
        <View>
          <View style={chatRoomFieldStyles.container}>
            <View style={chatRoomFieldStyles.chatsContainer}>
              <View style={chatRoomFieldStyles.chatContainer}>
                <Image
                  source={roomLogo}
                  style={chatRoomFieldStyles.roomLogoImg}
                />
              </View>
              <View style={chatRoomFieldStyles.chatDataContainer}>
                <View style={chatRoomFieldStyles.chatTitleContainer}>
                  <Text style={chatRoomFieldStyles.chatTitleText}>{name}</Text>
                </View>
                <View style={chatRoomFieldStyles.chatLastMessageDateContainer}>
                  <Text style={chatRoomFieldStyles.chatUnreadMessagesText}>
                    {moment.utc(lastMessage.time).fromNow()}
                  </Text>
                </View>
              </View>
            </View>
            <View style={chatRoomFieldStyles.chatsContainer}>
              <View style={chatRoomFieldStyles.chatContainer}></View>
              <View style={chatRoomFieldStyles.chatDataContainer}>
                <View style={chatRoomFieldStyles.chatTitleContainer}>
                  <Text
                    numberOfLines={1}
                    style={chatRoomFieldStyles.chatMessageTextStyle}
                  >
                    {lastMessageText}
                  </Text>
                </View>
                <View style={chatRoomFieldStyles.chatLastMessageDateContainer}>
                  {lastSeenChatTime != 0 ? (
                    <View
                      style={chatRoomFieldStyles.chatUnreadMessagesContainer}
                    >
                      <Text style={chatRoomFieldStyles.chatUnreadMessagesText}>
                        {lastSeenChatTime}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
          {length - 1 === index ? (
            <View style={chatRoomFieldStyles.bottomContainerStyle} />
          ) : null}
        </View>
      </TouchableOpacity>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    unreadMessage: () => dispatch(unreadMessage()),
  };
};
export default connect(mapDispatchToProps)(ChatRoomField);
