
import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import TickImg from '../../../assets/choose_league.png'

class LeagueField extends React.Component {
    state = {
        isLeagueChoosen: false,
    }

    onChangeLeague = open => {
        this.setState({ isLeagueChoosen: !open });
    }

    componentDidUpdate() {

        const { name, userLeagues, addLeague, isNewUser, nameOfRoom } = this.props;
        const { isLeagueChoosen } = this.state;
        let leagueIndex = null;
        if (userLeagues && addLeague) {
            leagueIndex = userLeagues.findIndex(league => league.name === name);
            if (leagueIndex === -1) {
                leagueIndex = userLeagues.findIndex(league => league.nameOfRoom === name);
            }
        }
        if (leagueIndex > -1 && !isLeagueChoosen && addLeague && (isNewUser === "false" || isNewUser === "null" || isNewUser === "undefined" || !isNewUser)) {
            this.onChangeLeague(isLeagueChoosen)
        }
    }

    render() {
        const { icon, name, onPickLeague, onRemoveLeague, BASE_URL, userLeagues, addLeague, nameOfRoom, reactRem } = this.props;
        const { isLeagueChoosen } = this.state;

        const containerStyle = [styles.container, { height: 8 * reactRem, width: "50vw" }];
        const leagueNameStyle = [styles.leagueName, { fontSize: 2.4 * reactRem, paddingLeft: reactRem }];

        if (!isLeagueChoosen) {
            containerStyle.push({ backgroundColor: globalStyles.errorBackground });
            leagueNameStyle.push({ color: globalStyles.borderRoom });
        }
        if (this.props.index === 0) {
            containerStyle.push(styles.firstField);
            containerStyle.push({ borderTopLeftRadius: 1.5 * reactRem, borderTopRightRadius: 1.5 * reactRem })
        }
        if (this.props.index === this.props.leaguesLength - 1) {
            containerStyle.push(styles.lastField);
            containerStyle.push({ borderBottomLeftRadius: 1.5 * reactRem, borderBottomRightRadius: 1.5 * reactRem })
        }
        return (
            <TouchableOpacity style={containerStyle} onPress={() => {
                { isLeagueChoosen ? onRemoveLeague() : onPickLeague() }
                this.onChangeLeague(isLeagueChoosen)
            }}>

                <View style={[styles.leagueContainer, { paddingLeft: 2 * reactRem }]}>
                    {name ? <Image source={{ uri: BASE_URL + icon }} style={[styles.leagueImg, {
                        height: 6 * reactRem,
                        width: 6 * reactRem,
                        marginHorizontal: reactRem
                    }]} /> : null}
                    <Text style={leagueNameStyle}>{name ? name : nameOfRoom}</Text>
                </View>
                <View style={[styles.tickContainer, { height: 8 * reactRem }]}>
                    {isLeagueChoosen ?
                        <Image source={TickImg} style={[styles.tickImg, { height: 3 * reactRem, width: 3 * reactRem }]} /> : null}
                </View>

            </TouchableOpacity>
        );
    }
}

const styles = EStyleSheet.create({
    container: {
        width: "100%",
        flexDirection: "row",
        backgroundColor: globalStyles.white,
        alignItems: "center",
        borderBottomWidth: 1,
        borderBottomColor: globalStyles.backgroundGrey,
        height: "8rem"
    },
    leagueContainer: {
        width: "90%",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: "2rem"
    },
    leagueImg: {
        height: "6rem",
        width: "6rem",
        marginHorizontal: "1rem",
        resizeMode: "contain",
    },
    tickImg: {
        height: "3rem",
        width: "3rem",
        resizeMode: "contain",
    },
    leagueName: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuMedium,
        fontSize: "2.4rem",
        paddingLeft: "1rem"
    },
    tickContainer: {
        width: "10%",
        height: '8rem',
        borderLeftWidth: 1,
        borderLeftColor: globalStyles.backgroundGrey,
        alignItems: "center",
        justifyContent: "center",

    },
    firstField: {
        borderTopLeftRadius: "1.5rem",
        borderTopRightRadius: "1.5rem",
    },
    lastField: {
        borderBottomLeftRadius: "1.5rem",
        borderBottomRightRadius: "1.5rem",
    },
});
export default LeagueField;