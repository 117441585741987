import { UI_START_LOADING, UI_STOP_LOADING } from "./actionTypes";

export const uiStartLoading = (index) => {
  return {
    type: UI_START_LOADING,
    index: index,
  };
};

export const uiStopLoading = () => {
  return {
    type: UI_STOP_LOADING,
  };
};
