import {
  GET_HOME_SCREEN_DATA,
  SELECT_MATCH,
  SELECT_LEAGUE,
  SET_LEAGUE_DATA_BY_GAME_WEEK,
  MATCH_BET,
  SET_GAME_WEEK,
  DELETE_GAME_WEEK,
  SET_ROOM_PREDICTIONS,
  SET_LEAGUES_WITH_GAME_WEEKS,
  DELETE_HOMESCREEN_DATA,
  SET_VIEW_HEIGHT,
  SET_SPECIAL_VIEW_HEIGHT,
  VALUE_CONSTANT,
  SET_LEAGUE_GW_DATA,
  RESET_ROOM_PREDICTIONS,
  SET_TODAY_MATCHES,
  SET_LIVE_MATCHES,
} from "../actions/actionTypes";

const initialState = {
  todayMatches: -1,
  liveMatches: -1,
  leagues: null,
  gameWeek: null,
  playersBeted: null,
  leaguesGW: null,
  selectLeague: null,
  height: "100vh",
  specialHeight: "100vh",
  reactRem: 6,
};

const homeScreenLeagueReducer = (state = initialState, action) => {
  switch (action.type) {
    case VALUE_CONSTANT: {
      return {
        ...state,
        reactRem: action.constant,
      };
    }
    case SELECT_LEAGUE:
      if (state.leagues) {
        return {
          ...state,
          selectLeague: state.leagues.find((league) => {
            return league.id === Number(action.leagueId);
          }),
        };
      }
    case SELECT_MATCH:
      if (state.selectLeague) {
        return {
          ...state,
          selectMatch: state.selectLeague.matches.find((match) => {
            return match.id === Number(action.matchId);
          }),
        };
      }
    case SET_LEAGUE_GW_DATA: {
      if (state.leagues && action.data) {
        const leagueIndex = state.leagues.findIndex(
          (league) => league.id === action.data.leagues[0].id
        );
        const leagues = [...state.leagues];
        leagues[leagueIndex] = action.data.leagues[0];
        return { ...state, leagues };
      }
    }
    case GET_HOME_SCREEN_DATA: {
      if (action.data) {
        if (state.todayMatches === -1 && state.liveMatches === -1) {
          return {
            ...state,
            leagues: action.data.leagues,
            todayMatches: action.data.todayMatches,
            liveMatches: action.data.liveMatches,
          };
        } else {
          return {
            ...state,
            leagues: action.data.leagues,
          };
        }
      }
    }
    case SET_LEAGUE_DATA_BY_GAME_WEEK: {
      let leagueIndex;
      let leaguesGW;
      if (state.leaguesGW && action.data) {
        leagueIndex = state.leaguesGW.findIndex(
          (league) =>
            Number(league.api_football_id) ===
            Number(action.data.api_football_id)
        );
        leaguesGW = [...state.leaguesGW];
        leaguesGW[leagueIndex] = action.data;
        return { ...state, leaguesGW };
      }
    }
    case MATCH_BET: {
      let leagueIndex;
      if (state.leagues) {
        leagueIndex = state.leagues.findIndex(
          (league) => Number(league.id) === Number(action.leagueId)
        );
        const leagues = [...state.leagues];
        const league = { ...state.leagues[leagueIndex] };
        const matchIndex = league.matches.findIndex(
          (match) => Number(match.id) === Number(action.matchId)
        );
        const matches = [...league.matches];
        const match = { ...matches[matchIndex] };
        if (match.bets) {
          if (action.specialRoom) {
            for (let index = 0; index < match.bets.length; index++) {
              if (match.bets[index].roomId === action.roomId) {
                match.bets[index].bet = action.bet;
              }
            }
          } else {
            for (let index = 0; index < match.bets.length; index++) {
              if (!match.bets[index].is_special) {
                match.bets[index].bet = action.bet;
              }
            }
          }
          matches[matchIndex] = match;
          league.matches = matches;
          leagues[leagueIndex] = league;
          return { ...state, leagues, selectMatch: match };
        }
      }
    }
    case SET_TODAY_MATCHES: {
      if (action.data) {
        return {
          ...state,
          todayMatchesHomeScreen: action.data.leagues,
        };
      }
    }
    case SET_LIVE_MATCHES: {
      if (action.data) {
        return {
          ...state,
          liveMatchesHomeScreen: action.data.leagues,
        };
      }
    }
    case SET_GAME_WEEK: {
      let leagueIndex;
      if (state.leagues) {
        leagueIndex = state.leagues.findIndex(
          (league) => Number(league.id) === Number(action.leagueId)
        );
        const leagues = [...state.leagues];
        let gameWeek = { ...state.gameWeek };
        gameWeek = leagues[leagueIndex].gameWeek;
        return { ...state, gameWeek };
      }
    }
    case DELETE_GAME_WEEK: {
      let gameWeek = { ...state.gameWeek };
      gameWeek = null;
      return { ...state, gameWeek };
    }
    case SET_ROOM_PREDICTIONS: {
      return {
        ...state,
        playersBeted: action.data.playersBeted,
        userBeted: action.data.userBeted,
      };
    }
    case SET_LEAGUES_WITH_GAME_WEEKS: {
      return {
        ...state,
        leaguesGW: action.leaguesGW,
      };
    }
    case SET_VIEW_HEIGHT: {
      return {
        ...state,
        height: action.height,
      };
    }
    case SET_SPECIAL_VIEW_HEIGHT: {
      return { ...state, specialHeight: action.specialHeight };
    }
    case RESET_ROOM_PREDICTIONS: {
      return {
        ...state,
        playersBeted: null,
      };
    }
    case DELETE_HOMESCREEN_DATA: {
      let gameWeek = { ...state.gameWeek };
      let todayMatches = { ...state.todayMatches };
      let liveMatches = { ...state.liveMatches };
      let leagues = { ...state.leagues };
      let playersBeted = { ...state.playersBeted };
      let leaguesGW = { ...state.leaguesGW };
      leagues = null;
      return { ...state, leagues };
    }
    default:
      return state;
  }
};

export default homeScreenLeagueReducer;
