import React, { useState, useEffect, useRef } from "react";
import { View, Text, Image } from "react-native";
import {
  getJoinedRoomData,
  getJoinedRoomDataNoToken,
  getPrivateRoomData,
  setRoomsPriority,
  setPriorityMessage,
  onLeaveRoom,
  setLeaguesInRoom,
} from "../actions/rooms";
import { setAddLeagueFlag } from "../../Prediction/actions/roompredictions";
import { getMySpecialRooms } from "../../SpecialGames/actions/specialGames";
import GlobalRoomWhiteImg from "../../assets/global_room_white.png";
import weeklyImage from "../../assets/navigation_weekly_games.png";
import PrivateRoomWhiteImg from "../../assets/private_room_white.png";
import RoomLeagueList from "../UI/RoomLeagueList";
import FloatingButton from "../../Common/components/UI/FloatingButton";
import DragNDrop from "../../Common/components/UI/DragNDrop";
import TwoFloatingButtons from "../../Common/components/UI/TwoFloatingButtons";
import { roomStyles } from "../styles/styles";
import { setViewHeight } from "../../Home/actions/homeScreenLeagues";
import LeagueAd from "../../Common/LeagueList/LeagueAd";
import { useSelector, useDispatch } from "react-redux";

const RoomScreen = (props) => {
  const dispatch = useDispatch();
  const globalRooms = useSelector((state) => state.privateRooms.globalRooms);
  const privateRooms = useSelector((state) => state.privateRooms.privateRooms);
  const leaveRoomMessage = useSelector(
    (state) => state.privateRooms.leaveRoomMessage
  );
  const leagues = useSelector((state) => state.auth.leagues);
  const userId = useSelector((state) => state.auth.userId);
  const roomIdFrom = useSelector((state) => state.privateRooms.roomIdFrom);
  const roomCode = useSelector((state) => state.privateRooms.roomCode);
  const banner = useSelector((state) => state.privateRooms.adverts);
  const priorityMessage = useSelector(
    (state) => state.privateRooms.priorityMessage
  );
  const addLeagueSuccesfull = useSelector(
    (state) => state.predictions.addLeagueSuccesfull
  );
  const reactRem = useSelector((state) => state.homeScreenLeagues.reactRem);
  const mySpecialGames = useSelector(
    (state) => state.specialGames.mySpecialGames
  );
  const [refreshing, setRefreshing] = useState(false);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [orderList, setOrderList] = useState([]);

  const getLoggedUserData = () => {
    dispatch(getJoinedRoomData());
    dispatch(getMySpecialRooms("FREE"));
    dispatch(getPrivateRoomData());
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const _onRefresh = () => {
    setRefreshing(true);
    if (userId) {
      getLoggedUserData();
    }
    if (!userId) {
      dispatch(getJoinedRoomDataNoToken());
    }
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onLayout = (e) => {
    dispatch(setViewHeight(e.nativeEvent.layout.height));
  };

  useEffect(() => {
    goToTop();
    if (userId) {
      getLoggedUserData();
    }
    if (!userId) {
      dispatch(getJoinedRoomDataNoToken());
    }
  }, []);

  const onShowEditMenu = () => {
    setShowEditMenu(!showEditMenu);
  };

  const onDiscard = () => {
    setShowEditMenu(!showEditMenu);
    setOrderList(null);
  };

  const onConfirm = () => {
    const orderRoomIdList = [];
    orderList.forEach((room) => orderRoomIdList.push(room.id));
    dispatch(setRoomsPriority(orderRoomIdList, "GLOBAL"));
  };

  const getNewOrderList = (newOrderList) => {
    setOrderList(newOrderList);
  };

  const prevPriorityMessage = usePrevious(priorityMessage);
  const prevLeaveRoomMessage = usePrevious(leaveRoomMessage);
  const prevAddLeagueSuccesfull = usePrevious(addLeagueSuccesfull);
  const prevRoomCode = usePrevious(roomCode);

  useEffect(() => {
    if (!prevPriorityMessage && prevPriorityMessage != priorityMessage) {
      onShowEditMenu();
      dispatch(setPriorityMessage(null));
      getLoggedUserData();
    }

    if (!prevLeaveRoomMessage && prevLeaveRoomMessage != leaveRoomMessage) {
      if (showEditMenu) {
        onShowEditMenu();
      }
      dispatch(onLeaveRoom(null));
      dispatch(getPrivateRoomData());
      dispatch(getMySpecialRooms("FREE"));
    }

    if (roomIdFrom) {
      let privateRoomIndex = -1;

      privateRoomIndex = privateRooms.findIndex(
        (room) => Number(room.id) === Number(roomIdFrom)
      );

      if (privateRoomIndex != -1) {
        props.navigation.goBack();
        props.navigation.navigate("RoomLeagueScreen", {
          roomId: privateRooms[privateRoomIndex].id,
          leaguesInRoom: privateRooms[privateRoomIndex].leaguesInRoom,
          roomCode: privateRooms[privateRoomIndex].roomCode,
          roomType: null,
        });
      }
    }

    if (prevRoomCode != roomCode) {
      if (userId) {
        getLoggedUserData();
      } else {
        dispatch(getJoinedRoomDataNoToken());
      }
    }

    if (refreshing) {
      setRefreshing(false);
    }

    if (
      prevAddLeagueSuccesfull === null &&
      prevAddLeagueSuccesfull != addLeagueSuccesfull
    ) {
      dispatch(setAddLeagueFlag(null));
      getLoggedUserData();
    }
  }, [
    priorityMessage,
    leaveRoomMessage,
    roomCode,
    roomIdFrom,
    refreshing,
    addLeagueSuccesfull,
  ]);

  return (
    <View style={roomStyles.container} onLayout={(e) => onLayout(e)}>
      {showEditMenu ? (
        <View style={roomStyles.editRoomsContainer}>
          <View style={roomStyles.roomsContainer}>
            <View style={roomStyles.listItem}>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <View style={roomStyles.placeImageContainer}>
                  <Image style={roomStyles.placeImage} />
                </View>
                <Text style={roomStyles.leagueTitle}>Edit Rooms</Text>
              </View>
            </View>
            <DragNDrop
              reactRem={reactRem}
              navigation={props.navigation}
              rooms={globalRooms}
              roomsLength={globalRooms.length}
              roomNameType={"Global Rooms"}
              getNewOrderList={(newOrderList) => getNewOrderList(newOrderList)}
            />

            {showEditMenu ? (
              <View style={{ marginTop: 12 * reactRem }}>
                <TwoFloatingButtons
                  reactRem={reactRem}
                  firstOnPress={() => onDiscard()}
                  firstButtonText={"DISCARD"}
                  secondOnPress={() => onConfirm()}
                  secondButtonText={"CONFIRM"}
                />
              </View>
            ) : null}
          </View>
        </View>
      ) : (
        <View
          style={{ width: "100%" }}
          refreshing={refreshing}
          onRefresh={() => _onRefresh}
        >
          {userId ? (
            <View>
              <RoomLeagueList
                roomImg={GlobalRoomWhiteImg}
                roomName={"Global Rooms"}
                flatListData={globalRooms}
                navigation={props.navigation}
                reactRem={reactRem}
                setLeaguesInRoom={(leaguesInRoom) =>
                  dispatch(setLeaguesInRoom(leaguesInRoom))
                }
                onShowEditMenu={() => onShowEditMenu()}
              />
              {userId &&
              leagues &&
              globalRooms &&
              globalRooms.length < leagues.length &&
              !showEditMenu ? (
                <FloatingButton
                  reactRem={reactRem}
                  onPress={() =>
                    props.navigation.navigate("ChooseLeagues", {
                      addLeague: true,
                      isNewUser: false,
                      leaveRooms: false,
                    })
                  }
                >
                  {"JOIN A LEAGUE"}
                </FloatingButton>
              ) : null}
              <RoomLeagueList
                roomImg={weeklyImage}
                roomName={"Special Games"}
                flatListData={mySpecialGames}
                navigation={props.navigation}
                reactRem={reactRem}
                setLeaguesInRoom={(leaguesInRoom) =>
                  dispatch(setLeaguesInRoom(leaguesInRoom))
                }
              />
              <RoomLeagueList
                roomImg={PrivateRoomWhiteImg}
                roomName={"Private Rooms"}
                flatListData={privateRooms}
                navigation={props.navigation}
                reactRem={reactRem}
                setLeaguesInRoom={(leaguesInRoom) =>
                  dispatch(setLeaguesInRoom(leaguesInRoom))
                }
              />
            </View>
          ) : (
            <View>
              <RoomLeagueList
                roomImg={GlobalRoomWhiteImg}
                roomName={"Global Rooms"}
                flatListData={globalRooms}
                navigation={props.navigation}
                reactRem={reactRem}
                setLeaguesInRoom={(leaguesInRoom) =>
                  dispatch(setLeaguesInRoom(leaguesInRoom))
                }
                onShowEditMenu={() => {}}
              />
            </View>
          )}
        </View>
      )}
      {userId ? (
        <TwoFloatingButtons
          reactRem={reactRem}
          firstOnPress={() => props.navigation.navigate("JoinRoom")}
          firstButtonText={"JOIN"}
          secondOnPress={() =>
            props.navigation.navigate("CreateFriendlyRoom")
          }
          secondButtonText={"CREATE"}
        />
      ) : null}
      {showEditMenu ? (
        <TwoFloatingButtons
          reactRem={reactRem}
          firstOnPress={() => onDiscard()}
          firstButtonText={"DISCARD"}
          secondOnPress={() => onConfirm()}
          secondButtonText={"CONFIRM"}
        />
      ) : null}
      {banner && banner.length != 0 ? (
        <LeagueAd banner={banner} navigation={props.navigation} />
      ) : null}
    </View>
  );
};

export default RoomScreen;
