import React from "react";
import { View, Text, Image } from "react-native";
import globalStyles from "../../globalStyles";
import Crown from "../../assets/crown.png";
import defaultUserImage from "../../assets/profile_avatar.png";
import { h2hContainerStyles } from "../styles/styles";

class H2HContainer extends React.Component {
  render() {
    const {
      playerOnePosition,
      playerOneImage,
      playerTwoImage,
      playerOneInitials,
      playerTwoInitials,
    } = this.props;

    return (
      <View style={h2hContainerStyles.h2hContainer}>
        <View style={h2hContainerStyles.imageContainer}>
          <View style={h2hContainerStyles.profileImgContainer}>
            {playerOnePosition !== 1 ? (
              <Image source={Crown} style={h2hContainerStyles.crownImg} />
            ) : (
              <View style={h2hContainerStyles.crownImg} />
            )}
            <Image
              source={
                playerOneImage
                  ? { uri: globalStyles.BASE_URL + playerOneImage }
                  : defaultUserImage
              }
              style={h2hContainerStyles.profileImg}
            />
            <Text style={h2hContainerStyles.playerInitialsText}>
              {playerOneInitials}
            </Text>
            <Text style={h2hContainerStyles.playerNicknameText}>
              {playerOneInitials}
            </Text>
          </View>
        </View>
        <View style={h2hContainerStyles.playersInitialsContainer}>
          <Text style={h2hContainerStyles.vsText}>vs</Text>
        </View>
        <View style={h2hContainerStyles.imageContainer}>
          <View style={h2hContainerStyles.profileImgContainer}>
            {playerOnePosition === 1 ? (
              <Image source={Crown} style={h2hContainerStyles.crownImg} />
            ) : (
              <View style={h2hContainerStyles.crownImg} />
            )}
            <Image
              source={
                playerTwoImage
                  ? { uri: globalStyles.BASE_URL + playerTwoImage }
                  : defaultUserImage
              }
              style={h2hContainerStyles.profileImg}
            />
            <Text style={h2hContainerStyles.playerInitialsText}>
              {playerTwoInitials ? playerTwoInitials : "?"}
            </Text>
            <Text style={h2hContainerStyles.playerNicknameText}>
              {playerTwoInitials ? playerTwoInitials : "?"}
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

export default H2HContainer;
