import React from "react";
import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import ArrowDown from "../../assets/home_arrow_down.png";
import ArrowUp from "../../assets/home_arrow_up.png";
import MatchButton from "../../Common/components/UI/MatchButton";
import MatchRoom from "../../Common/components/UI/MatchRoomTab";
import AnimatableView from "../../Common/components/UI/AnimatableView";
import globalStyles from "../../globalStyles";
import Shield from "../components/UI/Shield";
import RoomPredictionBetPlayerInfo from "../../Room/UI/RoomPredictionBetPlayerInfo";
import NoStartedMatchContainer from "../../Room/UI/NoStartedMatchContainer";
import MatchResult from "../components/UI/MatchResult";

import { connect } from "react-redux";
import {
  getRoomPredictionsByRoomIdAndMatchId,
  getSpecialRoomPredictionsByRoomIdAndMatchId,
  setResetRoomPredictions,
} from "../../Home/actions/homeScreenLeagues";
import images from "../../Home/actions/clubsImages";
import { onOpenRegister } from "../../Login/actions/auth";

class MatchItem extends React.Component {
  state = {
    isOpen: false,
    isPlayerShowen: false,
    roomIdOpen: -1,
    roomSelected: null,
    fullPeopleRankingInRoom: [],
    currentPage: 2,
    loadedMorePeople: false,
  };

  roomTabVisible = (open) => {
    this.setState({ isOpen: !open });
    if (this.state.isOpen) {
      this.setState({ isPlayerShowen: false });
    }
  };

  showField = () => {
    if (this.props.matchIsOpen) {
      this.setState({ animation: "slideInDown" });
    } else {
      this.setState({ animation: "slideInUp" });
    }
  };

  onStaticsShow = (userId) => {
    this.props.navigation.navigate("StatisticsScreen", { userId: userId });
  };

  onLoadMorePeople = () => {
    this.setState({
      loadedMorePeople: true,
    });

    if (this.state.is_special) {
      this.props.getSpecialRoomPredictionsByRoomIdAndMatchId(
        this.state.roomSelected,
        this.props.id,
        this.state.currentPage
      );
    } else {
      this.props.getRoomPredictionsByRoomIdAndMatchId(
        this.state.roomSelected,
        this.props.id,
        this.state.currentPage
      );
    }
  };

  onShowPlayers = (show, roomId, special) => {
    this.props.setResetRoomPredictions();
    const { roomIdOpen } = this.state;
    if (roomIdOpen === -1) {
      this.setState({
        isPlayerShowen: !show,
        roomIdOpen: roomId,
        fullPeopleRankingInRoom: [],
      });
      if (special) {
        this.setState({ is_special: true });
        this.props.getSpecialRoomPredictionsByRoomIdAndMatchId(
          roomId,
          this.props.id,
          1
        );
      } else {
        this.setState({ is_special: false });
        this.props.getRoomPredictionsByRoomIdAndMatchId(
          roomId,
          this.props.id,
          1
        );
      }
      this.setState({ roomSelected: roomId });
    } else if (roomId === roomIdOpen) {
      this.setState({
        isPlayerShowen: !show,
        roomIdOpen: -1,
        roomSelected: null,
        is_special: null,
        fullPeopleRankingInRoom: [],
        currentPage: 2,
        loadedMorePeople: false,
      });
    } else {
      this.setState({ roomIdOpen: roomId, fullPeopleRankingInRoom: [] });
      if (special) {
        this.setState({ is_special: true });
        this.props.getSpecialRoomPredictionsByRoomIdAndMatchId(
          roomId,
          this.props.id,
          1
        );
      } else {
        this.setState({ is_special: false });
        this.props.getRoomPredictionsByRoomIdAndMatchId(
          roomId,
          this.props.id,
          1
        );
      }
      this.setState({ roomSelected: roomId });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.playersBetedHome !== this.props.playersBetedHome &&
      this.state.loadedMorePeople
    ) {
      this.setState({
        fullPeopleRankingInRoom: this.state.fullPeopleRankingInRoom.concat(
          this.props.playersBetedHome
        ),
        currentPage: this.state.currentPage + 1,
        loadedMorePeople: false,
      });
    }
  }

  render() {
    const {
      matchIsOpen,
      betPoints,
      playTime,
      score,
      homeTeam,
      guestTeam,
      time,
      date,
      isPlaying,
      id,
      homeTeamImg,
      guestTeamImg,
      shield,
      bets,
      bet,
      points,
      playersBeted,
      fullPointsShown,
      default_points,
      navigation,
      leagueId,
      guessed,
      playersBetedHome,
      is_delayed,
      matchesLength,
      index,
      leagueName,
      reactRem,
    } = this.props;

    let timeDateScoreViewStyles = [
      styles.timeDateScoreViewStyles,
      { width: 10 * reactRem },
    ];
    let scores;
    let leftClub = [styles.leftClubName, { fontSize: 2.2 * reactRem }];
    let rigthClub = [styles.rightClubName, { fontSize: 2.2 * reactRem }];
    let mainMatchInfoContainer = [styles.mainMatchInfoContainer];
    let listItemStyle = [styles.listItem];
    let betLineStyle = [
      styles.betLineStyle,
      { height: 0.3 * reactRem, width: 2.5 * reactRem },
    ];
    const leftClubContainerStyle = [styles.leftClubContainer];
    const rightClubContainerStyle = [styles.rightClubContainer];
    const playersBetedContainerStyle = [
      styles.playersBetedContainer,
      { maxHeight: 66 * reactRem },
    ];
    const roomContainerStyle = [styles.roomContainer];
    const availableAfterMatchContainerStyle = [
      styles.availableAfterMatchContainer,
      { height: 6 * reactRem },
    ];
    const dateFieldStyle = [styles.dateField, { fontSize: 1.8 * reactRem }];
    const timeFieldStyle = [styles.timeField, { fontSize: 2.2 * reactRem }];
    let content;
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const { roomSelected } = this.state;
    let betText = bet || "-";

    if (is_delayed) {
      content = (
        <View style={{ justifyContent: "center", alignContent: "center" }}>
          <Text style={[styles.delayedField, { fontSize: 1.8 * reactRem }]}>
            Delayed
          </Text>
        </View>
      );
    }
    if (score) {
      scores = score.split(" : ");
      if (isPlaying && playTime != "Finished") {
        if (playTime === "Awarded") {
          content = (
            <View style={timeDateScoreViewStyles}>
              <Text
                style={[
                  styles.playingTimeField,
                  { fontSize: 1.8 * reactRem, paddingBottom: 0.5 * reactRem },
                ]}
              >
                {" "}
                {playTime === "Finished" ? "FT" : playTime}
              </Text>
              <Text
                style={[
                  styles.playingSocreField,
                  { fontSize: 2 * reactRem, marginTop: -0.5 * reactRem },
                ]}
              >
                {" "}
                {scores[0] + " - " + scores[1]}
              </Text>
            </View>
          );
        } else {
          content = (
            <View style={timeDateScoreViewStyles}>
              <Text
                style={
                  playTime === "Half Time"
                    ? [
                        styles.halfTimeField,
                        {
                          fontSize: 1.8 * reactRem,
                          paddingBottom: 0.5 * reactRem,
                        },
                      ]
                    : [
                        styles.halfTimeMinutesField,
                        {
                          fontSize: 1.8 * reactRem,
                          paddingBottom: 0.5 * reactRem,
                        },
                      ]
                }
              >
                {" "}
                {playTime === "Half Time" ? "HT" : playTime + "'"}
              </Text>
              <Text
                style={
                  playTime === "Half Time"
                    ? [
                        styles.playingSocreField,
                        { fontSize: 2 * reactRem, marginTop: -0.5 * reactRem },
                      ]
                    : [
                        styles.halfTimeScoreField,
                        {
                          fontSize: 2.6 * reactRem,
                          marginTop: -0.5 * reactRem,
                        },
                      ]
                }
              >
                {" "}
                {scores[0] + " - " + scores[1]}
              </Text>
            </View>
          );
        }
      } else {
        if (scores.length === 2 && scores[0] > scores[1]) {
          leftClub.push({
            color: globalStyles.textBlue,
            fontFamily: globalStyles.fontUbuntuBold,
          });
          if (betText === "1") {
            betLineStyle.push({ backgroundColor: globalStyles.green });
          }
          if (betText === "2") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
          }
          if (betText === "X") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
          }
        }
        if (scores.length === 2 && scores[0] < scores[1]) {
          rigthClub.push({
            color: globalStyles.textBlue,
            fontFamily: globalStyles.fontUbuntuBold,
          });
          if (betText === "2") {
            betLineStyle.push({ backgroundColor: globalStyles.green });
          }
          if (betText === "1") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
          }
          if (betText === "X") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
          }
        }
        if (scores.length === 2 && scores[0] === scores[1]) {
          if (bet === "X") {
            betLineStyle.push({ backgroundColor: globalStyles.green });
          }
          if (bet === "1" || bet === "2") {
            betLineStyle.push({ backgroundColor: globalStyles.red });
          }
        }
        content = (
          <View style={timeDateScoreViewStyles}>
            <Text
              style={[
                styles.playingTimeField,
                { fontSize: 1.8 * reactRem, paddingBottom: 0.5 * reactRem },
              ]}
            >
              {" "}
              {playTime === "Finished" ? "FT" : playTime}
            </Text>
            <Text
              style={[
                styles.playingSocreField,
                { fontSize: 2 * reactRem, marginTop: -0.5 * reactRem },
              ]}
            >
              {" "}
              {scores[0] + " - " + scores[1]}
            </Text>
          </View>
        );
      }
    } else if (!is_delayed) {
      leftClub.push({ color: globalStyles.textBlue });
      rigthClub.push({ color: globalStyles.textBlue });
      betLineStyle.push({ backgroundColor: globalStyles.red });

      const matchDate = date.split("-");
      let currentDay = matchDate[1];
      if (currentDay.charAt(0) === "0") {
        currentDay = currentDay.charAt(1);
      }
      let currentMonth = matchDate[0];
      if (currentMonth.charAt(0) === "0") {
        currentMonth = currentMonth.charAt(1);
      }
      const currentDate = months[currentMonth - 1] + " " + currentDay;

      content = (
        <View style={timeDateScoreViewStyles}>
          <Text style={dateFieldStyle}> {currentDate}</Text>
          <Text style={timeFieldStyle}> {time}</Text>
        </View>
      );
    }

    if (playTime === "Postponed") {
      const matchDate = date.split("-");
      let currentDay = matchDate[1];
      if (currentDay.charAt(0) === "0") {
        currentDay = currentDay.charAt(1);
      }
      let currentMonth = matchDate[0];
      if (currentMonth.charAt(0) === "0") {
        currentMonth = currentMonth.charAt(1);
      }
      const currentDate = months[currentMonth - 1] + " " + currentDay;
      dateFieldStyle.push({ color: globalStyles.red });
      timeFieldStyle.push({ color: globalStyles.red });
      content = (
        <View style={timeDateScoreViewStyles}>
          <Text style={dateFieldStyle}> {currentDate}</Text>
          <Text style={timeFieldStyle}> Postp</Text>
        </View>
      );
    }
    if (betPoints) {
      mainMatchInfoContainer.push({ backgroundColor: "red" });
    }
    let pointsText;
    let fullPointsText;
    let pointsToShow;
    if (points != null) {
      pointsToShow = points;
      if (pointsToShow > -1 && pointsToShow != 0) {
        fullPointsText = "+" + pointsToShow;
      } else {
        fullPointsText = pointsToShow;
      }
      if (pointsToShow >= 1000) {
        pointsText = "+" + (pointsToShow / 1000).toFixed(1) + "k";
      }
      if (pointsToShow <= -1000) {
        pointsToShow = pointsToShow * -1;
        pointsText = "-" + (pointsToShow / 1000).toFixed(1) + "k";
      }
      if (pointsToShow > -1000 && pointsToShow < 1000) {
        pointsText = fullPointsText;
      }
    }

    if (playersBetedHome && playersBetedHome.length === 0) {
      playersBetedContainerStyle.push({ height: 0 });
    }

    if (playersBeted && playersBeted.length === 0) {
      playersBetedContainerStyle.push({ height: 0 });
    }
    if (this.state.isPlayerShowen) {
      roomContainerStyle.push({ borderBottomWidth: 0 });
    }
    let homeTeamImgName = homeTeam.replace(" ", "_").toLowerCase();
    let guestTeamImgName = guestTeam.replace(" ", "_").toLowerCase();

    if (homeTeamImgName.includes(".")) {
      homeTeamImgName = homeTeamImgName.replace(".", "");
    }
    if (guestTeamImgName.includes(".")) {
      guestTeamImgName = guestTeamImgName.replace(".", "");
    }

    if (matchIsOpen) {
      listItemStyle.push(styles.activeListItem);
    }

    if (
      this.state.fullPeopleRankingInRoom.length === 0 &&
      playersBetedHome &&
      playersBetedHome.length > 0
    ) {
      this.setState({ fullPeopleRankingInRoom: playersBetedHome });
    }

    return (
      <View style={styles.container}>
        <View
          style={
            matchesLength - 1 === index
              ? [
                  styles.lastContainer,
                  {
                    borderBottomLeftRadius: 1.5 * reactRem,
                    borderBottomRightRadius: 1.5 * reactRem,
                  },
                ]
              : listItemStyle
          }
        >
          <TouchableOpacity
            style={[styles.touchableContainer, { height: 7 * reactRem }]}
            onPress={() =>
              navigation.navigate("MatchScreen", {
                matchId: id,
                leagueId: leagueId,
                shield: shield,
                leagueName: leagueName,
              })
            }
          >
            <View style={{ width: "5%" }}>
              {shield > 0 ? (
                <Shield points={shield} />
              ) : (
                <View
                  style={[
                    styles.noShieldView,
                    { height: 4 * reactRem, width: 4 * reactRem },
                  ]}
                />
              )}
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "95%",
              }}
            >
              <View style={mainMatchInfoContainer}>
                <View style={leftClubContainerStyle}>
                  <View
                    style={[
                      styles.leftClubNameContainer,
                      { marginRight: 0.5 * reactRem },
                    ]}
                  >
                    <Text style={leftClub}>{homeTeam}</Text>
                  </View>
                  <View>
                    {homeTeamImg ? (
                      <Image
                        source={
                          images[homeTeamImgName]
                            ? images[homeTeamImgName]
                            : { uri: homeTeamImg }
                        }
                        style={[
                          styles.clubImage,
                          {
                            height: 5 * reactRem,
                            width: 5 * reactRem,
                            marginHorizontal: 0.7 * reactRem,
                          },
                        ]}
                      />
                    ) : (
                      <View
                        style={[
                          styles.clubImage,
                          {
                            height: 5 * reactRem,
                            width: 5 * reactRem,
                            marginHorizontal: 0.7 * reactRem,
                          },
                        ]}
                      />
                    )}
                  </View>
                </View>
                <View
                  style={[
                    styles.contentContainer,
                    { marginHorizontal: reactRem, width: 10 * reactRem },
                  ]}
                >
                  {content}
                </View>
                <View style={rightClubContainerStyle}>
                  <View
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {guestTeamImg ? (
                      <Image
                        source={
                          images[guestTeamImgName]
                            ? images[guestTeamImgName]
                            : { uri: guestTeamImg }
                        }
                        style={[
                          styles.clubImage,
                          {
                            height: 5 * reactRem,
                            width: 5 * reactRem,
                            marginHorizontal: 0.7 * reactRem,
                          },
                        ]}
                      />
                    ) : (
                      <View
                        style={[
                          styles.clubImage,
                          {
                            height: 5 * reactRem,
                            width: 5 * reactRem,
                            marginHorizontal: 0.7 * reactRem,
                          },
                        ]}
                      />
                    )}
                  </View>
                  <View
                    style={[
                      styles.rightClubNameContainer,
                      { marginLeft: 0.5 * reactRem },
                    ]}
                  >
                    <Text style={rigthClub}>{guestTeam}</Text>
                  </View>
                </View>
                {betPoints ? (
                  <MatchResult
                    matchResult={betText}
                    predicted={guessed != null ? true : false}
                    guessed={guessed}
                  />
                ) : null}
              </View>
              {betPoints ? null : null}
            </View>
          </TouchableOpacity>
          {betPoints ? null : (
            <MatchButton
              image={matchIsOpen ? ArrowUp : ArrowDown}
              onPress={() => {
                this.roomTabVisible(this.state.isOpen);
                this.props.onShowMatchInfo();
                this.showField();
              }}
              isOpen={matchIsOpen}
              betPoints={betPoints}
              bets={bets}
              shield={shield}
              score={playTime}
              last={matchesLength - 1 === index}
            />
          )}
        </View>
        <View>
          {matchIsOpen && this.props.userId ? (
            <AnimatableView style={styles.roomContainer}>
              {betPoints && playersBeted ? (
                <View>
                  {playersBeted.length > 0 ? (
                    <RoomPredictionBetPlayerInfo
                      {...this.props.userBeted}
                      score={score}
                      defaultPoints={default_points}
                      fullPointsShown={fullPointsShown}
                      onStaticsShow={this.onStaticsShow}
                    />
                  ) : null}

                  <ScrollView style={playersBetedContainerStyle}>
                    {playersBeted.map((player, i) => (
                      <RoomPredictionBetPlayerInfo
                        {...player}
                        key={i}
                        position={i + 1}
                        score={score}
                        defaultPoints={default_points}
                        fullPointsShown={fullPointsShown}
                        onStaticsShow={this.onStaticsShow}
                      />
                    ))}
                  </ScrollView>
                </View>
              ) : (
                <ScrollView
                  style={[
                    styles.scrollView,
                    {
                      paddingVertical: reactRem,
                      marginLeft: 2 * reactRem,
                      marginHorizontal: 2 * reactRem,
                    },
                  ]}
                  showsHorizontalScrollIndicator={false}
                  horizontal={true}
                >
                  {bets.map((match, i) => (
                    <MatchRoom
                      {...match}
                      predicted={true}
                      guessed={match.guessed}
                      score={score}
                      key={i}
                      onPress={() =>
                        this.onShowPlayers(
                          this.state.isPlayerShowen,
                          match.roomId,
                          match.is_special
                        )
                      }
                      dataShown={this.state.isPlayerShowen}
                      roomSelected={roomSelected}
                    />
                  ))}
                </ScrollView>
              )}

              {betPoints && !score ? <NoStartedMatchContainer /> : null}
            </AnimatableView>
          ) : null}
          {matchIsOpen && !this.props.userId ? (
            <View
              style={[styles.signInUpContainer, { paddingVertical: reactRem }]}
            >
              <TouchableOpacity
                style={[
                  styles.signInContainer,
                  {
                    paddingHorizontal: 2 * reactRem,
                    paddingVertical: reactRem,
                    minWidth: 15 * reactRem,
                    maxWidth: 15 * reactRem,
                    borderTopLeftRadius: 0.5 * reactRem,
                    borderBottomLeftRadius: 0.5 * reactRem,
                  },
                ]}
                onPress={() => {
                  this.props.onOpenRegister(true, false);
                  this.props.navigation.navigate("LoginScreen");
                }}
              >
                <Text style={[styles.signInText, { fontSize: 2 * reactRem }]}>
                  SIGN IN
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.signUpContainer,
                  {
                    paddingHorizontal: 2 * reactRem,
                    paddingVertical: reactRem,
                    minWidth: 15 * reactRem,
                    maxWidth: 15 * reactRem,
                    borderTopRightRadius: 0.5 * reactRem,
                    borderBottomRightRadius: 0.5 * reactRem,
                  },
                ]}
                onPress={() => {
                  this.props.onOpenRegister(true, true);
                  this.props.navigation.navigate("LoginScreen", {
                    register: true,
                  });
                }}
              >
                <Text style={[styles.signUpText, { fontSize: 2 * reactRem }]}>
                  SIGN UP
                </Text>
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
        {!betPoints &&
        this.state.isPlayerShowen &&
        playersBetedHome &&
        matchIsOpen ? (
          <View style={{ backgroundColor: globalStyles.backgroundGrey }}>
            {playersBetedHome && playersBetedHome.length > 2 ? (
              <RoomPredictionBetPlayerInfo
                {...this.props.userBeted}
                score={score}
                defaultPoints={default_points}
                fullPointsShown={fullPointsShown}
                onStaticsShow={this.onStaticsShow}
              />
            ) : null}
            {playersBetedHome && playersBetedHome.length === 0 ? (
              <View style={availableAfterMatchContainerStyle}>
                <Text
                  style={[
                    styles.availableAfterMatchText,
                    { fontSize: 2.2 * reactRem },
                  ]}
                >
                  AVAILABLE AFTER THE MATCH STARTS!
                </Text>
              </View>
            ) : null}
            <ScrollView style={playersBetedContainerStyle}>
              {this.state.fullPeopleRankingInRoom.map((player, i) => (
                <RoomPredictionBetPlayerInfo
                  {...player}
                  key={i}
                  position={i + 1}
                  score={score}
                  defaultPoints={default_points}
                  fullPointsShown={fullPointsShown}
                  onStaticsShow={this.onStaticsShow}
                />
              ))}
            </ScrollView>
            {this.state.loadedMorePeople ? (
              <View>
                <ActivityIndicator />
              </View>
            ) : null}
            {this.state.currentPage !== this.props.totalPages ? (
              <TouchableOpacity
                style={styles.loadMoreButtonContainer}
                onPress={() => this.onLoadMorePeople()}
              >
                <Text style={styles.loadMoreButtonText}>LOAD MORE</Text>
              </TouchableOpacity>
            ) : null}
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  listItem: {
    width: "100%",
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  activeListItem: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderTopColor: globalStyles.activeBlue,
    borderBottomColor: globalStyles.activeBlue,
    borderLeftColor: globalStyles.activeBlue,
  },
  leftClubName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    textAlign: "right",
    fontSize: "2.2rem",
  },
  leftClubNameContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginRight: "0.5rem",
  },
  leftClubContainer: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    width: "43%",
  },
  rightClubNameContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "0.5rem",
  },
  rightClubContainer: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    width: "43%",
  },
  rightClubName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: "2.2rem",
  },
  clubImage: {
    height: "5rem",
    width: "5rem",
    marginHorizontal: "0.7rem",
  },
  roomContainer: {
    backgroundColor: globalStyles.backgroundGrey,
    flexDirection: "row",
    alignItems: "center",
  },
  signInUpContainer: {
    backgroundColor: globalStyles.backgroundGrey,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    paddingVertical: "1rem",
  },
  scrollView: {
    paddingVertical: "1rem",
    marginLeft: "2rem",
    marginHorizontal: "2rem",
  },
  playingTimeField: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "1.8rem",
    paddingBottom: "0.5rem",
  },
  halfTimeField: {
    color: globalStyles.yellow,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "1.8rem",
    paddingBottom: "0.5rem",
  },
  halfTimeMinutesField: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "1.8rem",
    paddingBottom: "0.5rem",
  },
  playingSocreField: {
    color: globalStyles.textBlue,
    fontSize: "2rem",
    marginTop: "-0.5rem",
    fontFamily: globalStyles.fontUbuntuBold,
  },
  halfTimeScoreField: {
    color: globalStyles.activeBlue,
    fontSize: "2.6rem",
    marginTop: "-0.5rem",
    fontFamily: globalStyles.fontUbuntuBold,
  },
  delayedField: {
    color: globalStyles.red,
    fontSize: "1.8rem",
    fontFamily: globalStyles.fontUbuntuMedium,
  },
  dateField: {
    color: globalStyles.textBlue,
    fontSize: "1.8rem",
    fontFamily: globalStyles.fontUbuntuRegular,
  },
  timeField: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "2.2rem",
  },
  timeDateScoreViewStyles: {
    flexDirection: "column",
    alignItems: "center",
    width: "10rem",
  },
  noShieldView: {
    height: "4rem",
    width: "4rem",
  },
  signInContainer: {
    backgroundColor: globalStyles.white,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: "2rem",
    paddingVertical: "1rem",
    minWidth: "15rem",
    maxWidth: "15rem",
    borderTopLeftRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: "2rem",
    paddingVertical: "1rem",
    minWidth: "15rem",
    maxWidth: "15rem",
    borderTopRightRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
  },
  signUpText: {
    color: globalStyles.white,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: "2rem",
    fontFamily: globalStyles.fontUbuntuBold,
  },
  signInText: {
    color: globalStyles.textBlue,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: "2rem",
    fontFamily: globalStyles.fontUbuntuBold,
  },
  betLineStyle: {
    height: "0.3rem",
    width: "2.5rem",
    backgroundColor: globalStyles.basicBlueGreyColor,
  },
  mainMatchInfoContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "88%",
  },
  smallerClubName: {
    fontSize: "2.2rem",
  },
  playersBetedContainer: {
    maxHeight: "66rem",
  },
  availableAfterMatchContainer: {
    height: "6rem",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: globalStyles.whiteOpacity,
  },
  availableAfterMatchText: {
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "2.2rem",
    color: globalStyles.textBlue,
  },
  container: {
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  lastContainer: {
    width: "100%",
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    alignItems: "center",
    borderBottomColor: globalStyles.backgroundGrey,
    borderBottomLeftRadius: "1.5rem",
    borderBottomRightRadius: "1.5rem",
  },
  contentContainer: {
    width: "10rem",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: "1rem",
  },
  touchableContainer: {
    alignItems: "center",
    width: "93%",
    flexDirection: "row",
    justifyContent: "center",
    height: "7rem",
  },
  loadMoreButtonContainer: {
    backgroundColor: globalStyles.white,
    opacity: 0.7,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingVertical: 12,
    borderBottomRightRadius: 9,
    borderBottomLeftRadius: 9,
    marginHorizontal: 3,
  },
  loadMoreButtonText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 10,
  },
});

const mapStateToProps = (state) => {
  return {
    playersBetedHome: state.homeScreenLeagues.playersBeted,
    userBeted: state.homeScreenLeagues.userBeted,
    reactRem: state.homeScreenLeagues.reactRem,
    token: state.auth.token,
    userId: state.auth.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRoomPredictionsByRoomIdAndMatchId: (roomId, matchId, page) =>
      dispatch(getRoomPredictionsByRoomIdAndMatchId(roomId, matchId, page)),
    getSpecialRoomPredictionsByRoomIdAndMatchId: (roomId, matchId, page) =>
      dispatch(
        getSpecialRoomPredictionsByRoomIdAndMatchId(roomId, matchId, page)
      ),
    onOpenRegister: (loginscreen, register) =>
      dispatch(onOpenRegister(loginscreen, register)),
    setResetRoomPredictions: () => dispatch(setResetRoomPredictions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchItem);
