import React, { useState, useEffect } from "react";
import { View, Button, StyleSheet, Image, TouchableOpacity, Text } from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import { withRouter } from "react-router-dom";
import globalStyles from '../../../globalStyles';
import { connect } from 'react-redux';


const JoinNowBtn = (props) => {
  const { reactRem } = props;

  const joinButtonContainerStyle = [styles.joinButtonContainer, {
    paddingVertical: 1.5 * reactRem,
    paddingHorizontal: 4 * reactRem,
    borderRadius: reactRem,
  }];
  const whiteTextStyle = [styles.whiteText, { fontSize: 3 * reactRem }];

  return (
    <TouchableOpacity style={joinButtonContainerStyle} onPress={() => props.history.push("/login")} >
      <Text style={whiteTextStyle}>JOIN NOW</Text>
    </TouchableOpacity>
  );
};

const styles = EStyleSheet.create({
  whiteText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "3rem"
  },
  joinButtonContainer: {
    backgroundColor: globalStyles.activeBlue,
    paddingVertical: "1.5rem",
    paddingHorizontal: "4rem",
    borderRadius: "1rem",
    alignItems: "center",
    justifyContent: "center"
  },
});


const mapStateToProps = (state, ownProps) => {
  return {
    leagues: state.auth.leagues,
    genders: state.auth.genders,
    nationalities: state.auth.nationalities,
    firstName: state.userProfileReducer.firstName,
    lastName: state.userProfileReducer.lastName,
    reactRem: state.homeScreenLeagues.reactRem,
  }
}



export default connect(mapStateToProps, null)(withRouter(JoinNowBtn));
