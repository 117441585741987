import React from "react";
import {
  Image,
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  deleteJoinSpecialRoom,
  getMySpecialRooms,
  getUpcomingSpecialRooms,
  getHitorySpecialRooms,
  getSelectedSpecialRoomData,
} from "../actions/specialGames";
import SpecialGamesPlayerField from "./SpecialGamesPlayerField";
import { connect } from "react-redux";
import Crown from "../../assets/crown.png";
import PrizeField from "./PrizeField";
import SpecialGamesShowPrizes from "./SpecialGamesShowPrizes";
import AlertDialogWithConfirmButton from "../../Common/components/UI/AlertDialogWithConfirmButton";
import { joinSpecialGamesInfoStyles } from "../styles/styles";

class JoinSpecialGamesInfo extends React.Component {
  state = {
    currentPage: 2,
    pagesRemaining: null,
    joined: false,
    playersToShow: [],
    loadMorePrizes: false,
    loadMorePlayers: false,
    isModalVisible: false,
    isMessageVisible: false,
    allPlayers: [],
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };
  onShowMessage = () => {
    this.setState({ isMessageVisible: !this.state.isMessageVisible });
  };
  onLoadMorePrizes = () => {
    this.setState({ loadMorePrizes: true });
  };
  onLoadMorePlayers = () => {
    this.props.getSelectedSpecialRoomData(
      this.props.roomId,
      this.state.currentPage
    );
    this.setState({
      loadMorePlayers: true,
      currentPage: this.state.currentPage + 1,
    });
  };
  componentDidMount() {
    if (this.state.playersToShow.length != 0) {
      this.setState({ playersToShow: [] });
    }
    if (this.state.allPlayers.length === 0)
      this.setState({ allPlayers: this.props.players });
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.loadMorePlayers &&
      prevProps.specialRoom &&
      this.props.specialRoom !== prevProps.specialRoom
    ) {
      this.setState({
        allPlayers: this.state.allPlayers.concat(
          this.props.specialRoom.peopleRankingInRoom
        ),
        currentPage: this.state.currentPage + 1,
        loadMorePlayers: false,
      });
    }
    if (
      this.props.joinedMessage ===
        "You have successfully joined in this room" &&
      !this.state.isMessageVisible
    ) {
      this.onShowMessage();
    }
  }
  onCloseGameRoom = () => {
    const { type, roomId, rounds } = this.props;
    this.props.deleteJoinSpecialRoom();
    this.props.getUpcomingSpecialRooms(type);
    this.props.getHitorySpecialRooms(type);
    this.props.getMySpecialRooms(type);
    this.props.navigation.navigate("SpecialGamesScreen", {
      fromJoin: "true",
      roomId: roomId,
      rounds: rounds,
    });
  };

  joinedSpecialGame = () => {
    this.setState({ joined: !this.state.joined });
  };

  render() {
    const { prizes, reactRem } = this.props;

    let allPrize = [];
    if (prizes.length > 3) {
      for (var i = 0; i < 3; i++) {
        allPrize.push(prizes[i]);
      }
    } else {
      allPrize = prizes;
    }
    return (
      <View style={joinSpecialGamesInfoStyles.pickerLeagueContainer}>
        <View style={joinSpecialGamesInfoStyles.listItem}>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Image
              source={Crown}
              style={joinSpecialGamesInfoStyles.placeImage}
            />
            <Text style={joinSpecialGamesInfoStyles.leagueTitle}>Prizes</Text>
          </View>
        </View>
        <View
          style={joinSpecialGamesInfoStyles.prizeWinnerPositionTextContainer}
        >
          {allPrize.map((info, i) => (
            <PrizeField
              reactRem={reactRem}
              prize={info.prize}
              position={i}
              key={i}
            />
          ))}
        </View>
        {prizes.length > 3 ? (
          <TouchableOpacity
            style={joinSpecialGamesInfoStyles.loadMoreButtonContainer}
            onPress={() => this.toggleModal()}
          >
            <Text style={joinSpecialGamesInfoStyles.loadMoreButtonText}>
              VIEW ALL
            </Text>
          </TouchableOpacity>
        ) : null}

        <View style={joinSpecialGamesInfoStyles.listItem}>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <Text style={joinSpecialGamesInfoStyles.leagueTitle}>Players</Text>
          </View>
        </View>
        <View style={joinSpecialGamesInfoStyles.playerHeaderContainer}>
          <View style={joinSpecialGamesInfoStyles.positionContainer}>
            <Text style={joinSpecialGamesInfoStyles.playerHeaderText}>
              Pos.
            </Text>
          </View>
          <View style={joinSpecialGamesInfoStyles.playerContainer}>
            <Text style={joinSpecialGamesInfoStyles.playerHeaderText}>
              Player
            </Text>
          </View>
        </View>
        <View
          style={joinSpecialGamesInfoStyles.prizeWinnerPositionTextContainer}
        >
          {this.state.allPlayers.map((info, i) => (
            <SpecialGamesPlayerField
              reactRem={reactRem}
              player={info}
              position={i}
              key={i}
            />
          ))}
        </View>
        {this.state.loadMorePlayers ? (
          <View>
            <ActivityIndicator />
          </View>
        ) : null}
        {this.state.currentPage !== this.props.totalPages &&
        this.state.allPlayers.length >= 10 ? (
          <TouchableOpacity
            style={joinSpecialGamesInfoStyles.loadMoreButtonContainer}
            onPress={() => this.onLoadMorePlayers()}
          >
            <Text style={joinSpecialGamesInfoStyles.loadMoreButtonText}>
              LOAD MORE
            </Text>
          </TouchableOpacity>
        ) : null}
        <View style={joinSpecialGamesInfoStyles.bottomContainer} />
        <SpecialGamesShowPrizes
          reactRem={reactRem}
          isModalVisible={this.state.isModalVisible}
          onChangeModalVisibility={this.toggleModal}
          prizes={prizes}
        />

        <AlertDialogWithConfirmButton
          isModalVisible={this.state.isMessageVisible}
          onChangeModalVisibility={this.onShowMessage}
          onCloseGameRoom={this.onCloseGameRoom}
          title={this.props.joinedMessage}
          fromSpecial={true}
          navigation={this.props.navigation}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    joinedMessage: state.specialGames.joinedMessage,
    reactRem: state.homeScreenLeagues.reactRem,
    specialRoom: state.specialGames.specialRoom,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteJoinSpecialRoom: () => dispatch(deleteJoinSpecialRoom()),
    getUpcomingSpecialRooms: (type) => dispatch(getUpcomingSpecialRooms(type)),
    getHitorySpecialRooms: (type) => dispatch(getHitorySpecialRooms(type)),
    getMySpecialRooms: (type) => dispatch(getMySpecialRooms(type)),
    getSelectedSpecialRoomData: (roomId, page) =>
      dispatch(getSelectedSpecialRoomData(roomId, page)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JoinSpecialGamesInfo);
