import { act } from "react-test-renderer";
import {
  SET_DATA_FOR_LEAGUE,
  SET_SELECTED_ROOM_DATA,
  SET_LEAGUES_IN_ROOM,
} from "../actions/actionTypes";

const initialState = {
  roomName: null,
  peopleRankingInRoom: null,
  leaguesInRoom: null,
  adverts: [],
};

const roomLeagueReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEAGUES_IN_ROOM: {
      return {
        ...state,
        leaguesInRoom: action.data,
      };
    }
    case SET_SELECTED_ROOM_DATA: {
      return {
        ...state,
        roomName: action.data.roomName,
        userBeted: action.data.userBeted,
        peopleRankingInRoom: action.data.peopleRankingInRoom,
        adverts: action.data.banner,
        totalPages: action.data.totalPages,
      };
    }
    case SET_DATA_FOR_LEAGUE: {
      return {
        ...state,
        roomName: action.data.roomName,
        peopleRankingInRoom: action.data.peopleRankingInRoom,
      };
    }
    default:
      return state;
  }
};

export default roomLeagueReducer;
