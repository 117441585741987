import React from "react";
import { Text, View, Image, TouchableOpacity, Linking } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import globalStyles from "./globalStyles";
import glowterLogo from "../src/assets/images/logo.png";
import fbImage from "../src/assets/side_menu_facebook.png";
import twitterImage from "../src/assets/side_menu_twitter.png";
import instagramImage from "../src/assets/side_menu_instagram.png";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const Footer = ({ history, reactRem }) => {
  const containStyle = [styles.container, { paddingVertical: 3 * reactRem }];
  const logoImageStyle = [
    styles.logoImage,
    {
      height: 5 * reactRem,
      width: 15 * reactRem,
      marginRight: 2 * reactRem,
    },
  ];
  const gameRulesContainerStyle = [
    styles.gameRulesContainer,
    {
      marginTop: 2 * reactRem,
      marginBottom: 5 * reactRem,
    },
  ];
  const gameRuleTextStyle = [styles.gameRuleText, { fontSize: 2.2 * reactRem }];
  const borderStyle = [
    styles.border,
    {
      width: 0.2 * reactRem,
      height: 2.2 * reactRem,
      marginHorizontal: 1.5 * reactRem,
    },
  ];
  const mediaContainerStyle = [
    styles.mediaContainer,
    { marginBottom: 3 * reactRem },
  ];
  const mediaImageStyle = [
    styles.mediaImage,
    { width: 4 * reactRem, height: 4 * reactRem },
  ];
  const twitterContainerStyle = [
    styles.twitterContainer,
    { marginHorizontal: 5 * reactRem },
  ];
  const glowterIncTextStyle = [
    styles.glowterIncText,
    { fontSize: 1.6 * reactRem },
  ];

  const onOpen = (link) => {
    Linking.openURL(link);
  };

  return (
    <View style={containStyle}>
      <Image source={glowterLogo} style={logoImageStyle} />
      <View style={gameRulesContainerStyle}>
        <TouchableOpacity onPress={() => history.push("/gamerules")}>
          <Text style={gameRuleTextStyle}>Game Rules</Text>
        </TouchableOpacity>
        <View style={borderStyle} />
        <TouchableOpacity onPress={() => history.push("/termsnconditions")}>
          <Text style={gameRuleTextStyle}>Terms {"&"} Conditions</Text>
        </TouchableOpacity>
        <View style={borderStyle} />
        <TouchableOpacity onPress={() => history.push("/privacypolicy")}>
          <Text style={gameRuleTextStyle}>Privacy Policy</Text>
        </TouchableOpacity>
        <View style={borderStyle} />
        <TouchableOpacity onPress={() => history.push("/contactus")}>
          <Text style={gameRuleTextStyle}>Contact Us</Text>
        </TouchableOpacity>
      </View>
      <View style={mediaContainerStyle}>
        <TouchableOpacity
          onPress={() => onOpen("https://www.facebook.com/GlowterGame/")}
        >
          <Image source={fbImage} style={mediaImageStyle} />
        </TouchableOpacity>
        <TouchableOpacity
          style={twitterContainerStyle}
          onPress={() => onOpen("https://twitter.com/GlowterG")}
        >
          <Image source={twitterImage} style={mediaImageStyle} />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => onOpen("https://www.instagram.com/glowter.game/")}
        >
          <Image source={instagramImage} style={mediaImageStyle} />
        </TouchableOpacity>
      </View>
      <View>
        <Text style={glowterIncTextStyle}>Glowter © All Rights Reserved</Text>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    reactRem: state.homeScreenLeagues.reactRem,
  };
};

export default connect(mapStateToProps, null)(withRouter(Footer));

const styles = EStyleSheet.create({
  container: {
    top: "100%",
    width: "100%",
    backgroundColor: globalStyles.black,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: "3rem",
  },
  logoImage: {
    height: "5rem",
    width: "15rem",
    marginRight: "2rem",
    resizeMode: "contain",
  },
  gameRulesContainer: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: "2rem",
    marginBottom: "5rem",
  },
  gameRuleText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: "2.2rem",
  },
  border: {
    width: "0.2rem",
    height: "2.2rem",
    marginHorizontal: "1.5rem",
    backgroundColor: globalStyles.white,
  },
  mediaContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "3rem",
  },
  mediaImage: {
    width: "4rem",
    height: "4rem",
    resizeMode: "contain",
  },
  twitterContainer: {
    marginHorizontal: "5rem",
  },
  glowterIncText: {
    color: globalStyles.white,
    opacity: 0.4,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: "1.6rem",
  },
});
