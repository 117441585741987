import React from "react";
import { View, Image, Text, TouchableOpacity } from "react-native";
import globalStyles from "../../globalStyles";
import Crown from "../../assets/crown.png";
import DefaultAvatar from "../../assets/profile_avatar.png";
import ArrowUp from "../../assets/images/position_up.png";
import ArrowDown from "../../assets/images/position_down.png";
import SamePosition from "../../assets/images/same_position.png";
import { roomLeagueFieldStyles } from "../styles/styles";

class RoomLeagueField extends React.Component {
  onChangeScreen = () => {
    const { info, roomNameType, navigation } = this.props;
    if (roomNameType === "Special Games") {
      navigation.navigate("SpcialGameRoomLeague", {
        roomId: info.id,
      });
    } else {
      navigation.navigate("RoomLeagueScreen", {
        roomId: info.id,
        roomImg: info.roomImg,
        leagueId: info.leagueId,
        roomNameType: roomNameType,
        roomCode: info.roomCode,
      });
    }
    this.props.setLeaguesInRoom(info.leagueInRoom);
  };

  render() {
    const { info, index, roomsLength, roomNameType } = this.props;
  
    let positionImg = SamePosition;
    let playerOneImg = null;
    let payerTwoImg = null;

    if (info.positionMovement === "down") {
      positionImg = ArrowDown;
    } else if (info.positionMovement === "up") {
      positionImg = ArrowUp;
    }

    if (info.images && info.images.length === 1) {
      playerOneImg = info.images[0];
    }
    if (info.images && info.images.length === 2) {
      playerOneImg = info.images[0];
      payerTwoImg = info.images[1];
    }

    return (
      <TouchableOpacity
        style={
          roomsLength - 1 === index
            ? roomLeagueFieldStyles.lastContainer
            : roomLeagueFieldStyles.roomContainer
        }
        onPress={() => this.onChangeScreen()}
      >
        <View style={roomLeagueFieldStyles.roomTitleContainer}>
          {roomNameType === "Head to Head" ? (
            <View style={roomLeagueFieldStyles.headToHeadImgContainer}>
              <Image
                source={
                  playerOneImg
                    ? { uri: globalStyles.BASE_URL + playerOneImg }
                    : DefaultAvatar
                }
                style={roomLeagueFieldStyles.playerImg}
              />
              <View style={roomLeagueFieldStyles.playerTwoContainer}>
                <Image
                  source={
                    payerTwoImg
                      ? { uri: globalStyles.BASE_URL + payerTwoImg }
                      : DefaultAvatar
                  }
                  style={roomLeagueFieldStyles.playerImg}
                />
              </View>
            </View>
          ) : null}
          {roomNameType === "Private" ? (
            <View style={roomLeagueFieldStyles.privateRoomContainer}>
              <Text style={roomLeagueFieldStyles.playerNumber}>
                {info.players}
              </Text>
              <Text style={roomLeagueFieldStyles.playerText}>PLAYERS</Text>
            </View>
          ) : null}
          <Text style={roomLeagueFieldStyles.roomTitleText}>
            {info.nameOfRoom || info.name}
          </Text>
        </View>
        <View style={roomLeagueFieldStyles.roomPositionContainer}>
          {info.position < 4 && roomNameType != "Head to Head" ? (
            <Image source={Crown} style={roomLeagueFieldStyles.crownImage} />
          ) : null}
          <View style={roomLeagueFieldStyles.positionTextContainer}>
            <Text numberOfLines={1} style={roomLeagueFieldStyles.roomTitleText}>
              {info.position}
            </Text>
          </View>
          <View style={roomLeagueFieldStyles.imageContainer}>
            <Image
              source={positionImg}
              style={
                info.positionMovement != "same"
                  ? roomLeagueFieldStyles.arrowImage
                  : roomLeagueFieldStyles.samePositionImage
              }
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default RoomLeagueField;
