import React from "react";
import { Image, View, Text, FlatList, TouchableOpacity } from "react-native";
import globalStyles from "../../globalStyles";
import PredictionGWField from "./PredictionGWField";
import ArrowLeftImg from "../../assets/left_gw_arrow.png";
import ArrowRightImg from "../../assets/right_gw_arrow.png";
import AlertDialog from "../../Common/components/UI/AlertDialog";
import { predictionLeagueFieldStyles } from "../styles/styles";

class PredictionLeagueField extends React.Component {
  state = {
    isModalVisible: false,
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };
  onOpenPredictionMatchpage = (gameWeek) => {
    const { leagueName, leagueImg, leagueId } = this.props;
    const leagueImgText = leagueImg.split("/");
    this.props.navigation.navigate("PredictMatches", {
      leagueName: leagueName,
      leagueImg: leagueImgText[3],
      gameWeek: gameWeek,
      leagueId: leagueId,
    });
    this.props.onPress(leagueId, gameWeek);
  };
  onNextButtonPressed = (gameWeekIndex) => {
    const { gameWeeks, leagueId, setMobile } = this.props;
    if (setMobile && gameWeeks.length === 2) {
      this.props.onGameWeekData(
        leagueId,
        Number(gameWeeks[gameWeekIndex].gameWeek) + 1
      );
    } else if (gameWeeks.length === 4) {
      this.props.onGameWeekData(
        leagueId,
        Number(gameWeeks[gameWeekIndex].gameWeek) + 1
      );
    }
  };

  render() {
    const {
      leagueImg,
      leagueName,
      gameWeeks,
      leagueId,
      token,
      reactRem,
      setMobile,
    } = this.props;
    const flatListContainerStyle = [
      predictionLeagueFieldStyles.flatListContainer,
    ];

    if (!gameWeeks) {
      return;
    }
    if (gameWeeks && gameWeeks.length === 1) {
      flatListContainerStyle.push(
        predictionLeagueFieldStyles.flatListSingleGWContainer
      );
      flatListContainerStyle.push({ paddingHorizontal: reactRem });
    }
    let forwardGWIndex = 3;
    if (gameWeeks.length < 4 && gameWeeks.length != 0) {
      forwardGWIndex = gameWeeks.length - 1;
    }

    const gameWeeksToShow = [];
    gameWeeks.forEach((gameWeek) => gameWeeksToShow.push(gameWeek));
    if (setMobile && gameWeeks.length === 1) {
      gameWeeksToShow.push({ endOfSeason: "endOfSeason" });
    } else if (gameWeeks.length === 3) {
      gameWeeksToShow.push({ endOfSeason: "endOfSeason" });
    }
    return (
      <View style={predictionLeagueFieldStyles.container}>
        <View style={predictionLeagueFieldStyles.leagueImageContainer}>
          <Image
            style={predictionLeagueFieldStyles.leagueImg}
            source={{ uri: globalStyles.BASE_URL + leagueImg }}
          />
          <View style={predictionLeagueFieldStyles.leagueNameContainer}>
            <Text
              numberOfLines={2}
              style={predictionLeagueFieldStyles.leagueName}
            >
              {leagueName}
            </Text>
          </View>
        </View>
        <View style={predictionLeagueFieldStyles.gameWeekContainer}>
          <View style={predictionLeagueFieldStyles.leftArrowContainer}>
            <View style={{ height: "20%" }}>
              <Text style={predictionLeagueFieldStyles.gameWeekText}>Game</Text>
              <Text style={predictionLeagueFieldStyles.gameWeekText}>Week</Text>
            </View>
            <View style={predictionLeagueFieldStyles.arrowContainer}>
              <TouchableOpacity
                onPress={() =>
                  token
                    ? this.props.onGameWeekData(
                        leagueId,
                        Number(gameWeeks[0].gameWeek) - 1
                      )
                    : this.toggleModal()
                }
                style={{
                  height: "90%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  style={predictionLeagueFieldStyles.arrowImg}
                  source={ArrowLeftImg}
                />
              </TouchableOpacity>
            </View>
          </View>
          <FlatList
            contentContainerStyle={flatListContainerStyle}
            horizontal={true}
            data={gameWeeksToShow}
            keyExtractor={(item, index) => "key" + index}
            renderItem={({ item }) => (
              <PredictionGWField
                reactRem={reactRem}
                {...item}
                onPress={() =>
                  token
                    ? this.onOpenPredictionMatchpage(item.gameWeek)
                    : this.toggleModal()
                }
                gameWeeksLenght={gameWeeks.length}
                token={token}
              />
            )}
          />
          {gameWeeks.length > 1 ? (
            <View style={predictionLeagueFieldStyles.leftArrowContainer}>
              <View style={{ height: "20%" }} />
              <View style={predictionLeagueFieldStyles.arrowContainer}>
                <TouchableOpacity
                  style={predictionLeagueFieldStyles.arrowContainer}
                  onPress={() =>
                    token
                      ? this.onNextButtonPressed(forwardGWIndex)
                      : this.toggleModal()
                  }
                  style={{
                    height: "90%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    style={predictionLeagueFieldStyles.arrowImg}
                    source={ArrowRightImg}
                  />
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <View style={predictionLeagueFieldStyles.leftArrowContainer}>
              <View style={{ height: "20%" }} />
              <View style={predictionLeagueFieldStyles.aloneArrowContainer}>
                <TouchableOpacity>
                  <Image
                    style={predictionLeagueFieldStyles.arrowImg}
                    source={ArrowRightImg}
                  />
                </TouchableOpacity>
              </View>
            </View>
          )}
        </View>
        <AlertDialog
          isModalVisible={this.state.isModalVisible}
          onChangeModalVisibility={this.toggleModal}
          title={"You have to sign up to predict matches."}
          navigation={this.props.navigation}
        />
      </View>
    );
  }
}

export default PredictionLeagueField;
