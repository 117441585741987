import { SET_USER_STATISTICS } from "./actionTypes";
import {authFetch} from '../../store/actions/token';

export const getUserStatistics = userId => {
    return dispatch => {
        dispatch(authFetch('/user/statistics?userId='+userId))
        .catch(err => console.log(err))
        .then(res => {
            dispatch(setUserStatistics(res.data));
        });
    };
}

export const setUserStatistics = data => {
  return {
      type: SET_USER_STATISTICS, data
  };
};


