import React from "react";
import { TouchableOpacity, View, Text, Image } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import globalStyles from "../../globalStyles";
import { connect } from "react-redux";
import CrownImg from "../../assets/crown.png";
import RedArrow from "../../assets/images/position_down.png";
import defaultUserImage from "../../assets/default_user_image.png";
import { roomPredictionBetPlayerInfoStyles } from "../styles/styles";

class RoomPredictionBetPlayerInfo extends React.Component {
  render() {
    const {
      id,
      name,
      bet,
      points,
      fullPointsShown,
      score,
      position,
      loggedUserId,
      onStaticsShow,
      user_name,
      image,
    } = this.props;

    let pointsText;
    let fullPointsText;
    let pointsToShow;
    if (points != null) {
      pointsToShow = points;
      if (pointsToShow > -1 && pointsToShow != 0) {
        fullPointsText = "+" + pointsToShow;
      } else {
        fullPointsText = pointsToShow;
      }
      if (pointsToShow >= 1000) {
        pointsText = "+" + (pointsToShow / 1000).toFixed(1) + "k";
      }
      if (pointsToShow <= -1000) {
        pointsToShow = pointsToShow * -1;
        pointsText = "-" + (pointsToShow / 1000).toFixed(1) + "k";
      }
      if (pointsToShow > -1000 && pointsToShow < 1000) {
        pointsText = fullPointsText;
      }
    }

    let pointsTextStyle = [roomPredictionBetPlayerInfoStyles.pointsText];
    let betText = bet || " - ";
    let scores;
    if (score) {
      scores = score.split(" : ");
    }

    const betContainerStyle = [roomPredictionBetPlayerInfoStyles.betContainer];

    if (scores) {
      if (scores.length === 2 && scores[0] > scores[1]) {
        if (bet === "1") {
          betContainerStyle.push({ backgroundColor: globalStyles.green });
          pointsTextStyle.push({ color: globalStyles.green });
        }
        if (bet === "2" || bet === "X") {
          betContainerStyle.push({ backgroundColor: globalStyles.red });
          pointsTextStyle.push({ color: globalStyles.red });
        }
      }
      if (scores.length === 2 && scores[0] < scores[1]) {
        if (bet === "2") {
          betContainerStyle.push({ backgroundColor: globalStyles.green });
          pointsTextStyle.push({ color: globalStyles.green });
        }
        if (bet === "1" || bet === "X") {
          betContainerStyle.push({ backgroundColor: globalStyles.red });
          pointsTextStyle.push({ color: globalStyles.red });
        }
      }
      if (scores.length === 2 && scores[0] === scores[1]) {
        if (bet === "X") {
          betContainerStyle.push({ backgroundColor: globalStyles.green });
          pointsTextStyle.push({ color: globalStyles.green });
        }
        if (bet === "1" || bet === "2") {
          betContainerStyle.push({ backgroundColor: globalStyles.red });
          pointsTextStyle.push({ color: globalStyles.red });
        }
      }
    }

    if (!bet) {
      betContainerStyle.push({ backgroundColor: globalStyles.unratedGrey });
    }
    let playerInfoContainerStyle = [
      roomPredictionBetPlayerInfoStyles.playerNameBetContainer,
    ];
    let playerPositionContainerStyle = [
      roomPredictionBetPlayerInfoStyles.playerPositionContainer,
    ];
    let betPlayerTextStyle = [roomPredictionBetPlayerInfoStyles.betPlayerText];
    let betTextStyle = [roomPredictionBetPlayerInfoStyles.betText];
    const positionTextStyle = [roomPredictionBetPlayerInfoStyles.positionText];
    const positionContainerStyle = [
      roomPredictionBetPlayerInfoStyles.positionContainer,
    ];
    const betPlayerUsernameStyle = [
      roomPredictionBetPlayerInfoStyles.betPlayerUsername,
    ];
    if (id === loggedUserId) {
      playerInfoContainerStyle.push({
        backgroundColor: globalStyles.activeBlue,
      });
      playerPositionContainerStyle.push({
        backgroundColor: globalStyles.activeBlue,
      });
      positionTextStyle.push(
        roomPredictionBetPlayerInfoStyles.whitePositionText
      );
      betPlayerTextStyle.push(
        roomPredictionBetPlayerInfoStyles.userBetPlayerText
      );
      betTextStyle.push({ color: globalStyles.white });
      pointsTextStyle.push({
        color: globalStyles.white,
        fontFamily: globalStyles.fontUbuntuBold,
      });
      betPlayerUsernameStyle.push(
        roomPredictionBetPlayerInfoStyles.userBetPlayerUsername
      );
    }

    if (position < 4) {
      playerPositionContainerStyle.push({ opacity: 1 });
      playerInfoContainerStyle.push({ opacity: 1 });
    } else {
      positionContainerStyle.push(
        roomPredictionBetPlayerInfoStyles.defaultPositionContainer
      );
    }
    if (pointsText === 0 && id != loggedUserId) {
      pointsTextStyle.push({ color: globalStyles.textBlue });
    }

    return (
      <TouchableOpacity
        style={roomPredictionBetPlayerInfoStyles.playerInfoContainer}
        onPress={() => (loggedUserId ? onStaticsShow(id) : null)}
      >
        <View style={playerPositionContainerStyle}>
          {position < 4 ? (
            <Image
              source={CrownImg}
              style={roomPredictionBetPlayerInfoStyles.crownImg}
            />
          ) : null}
          <View style={positionContainerStyle}>
            <Text style={positionTextStyle}>{position}</Text>
          </View>
          <Image
            source={RedArrow}
            style={roomPredictionBetPlayerInfoStyles.arrowImg}
          />
        </View>
        <View style={playerInfoContainerStyle}>
          <View style={roomPredictionBetPlayerInfoStyles.playerNameContainer}>
            <Image
              source={image ? { uri: image } : defaultUserImage}
              style={roomPredictionBetPlayerInfoStyles.profileImage}
            />
            <View>
              <Text style={betPlayerUsernameStyle}>{user_name}</Text>
              <Text style={betPlayerTextStyle}>{name}</Text>
            </View>
          </View>
          <View style={roomPredictionBetPlayerInfoStyles.betPointsContainer}>
            <View style={betContainerStyle}>
              <Text style={betTextStyle}>{betText}</Text>
            </View>
            <View style={roomPredictionBetPlayerInfoStyles.pointsContainer}>
              <Text style={pointsTextStyle}>
                {fullPointsShown ? fullPointsText : pointsText}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedUserId: state.auth.userId,
  };
};

export default connect(mapStateToProps, null)(RoomPredictionBetPlayerInfo);
