import React from "react";
import {
  View,
  Text,
  FlatList,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import MatchItem from "./MatchItem";
import globalStyles from "../../globalStyles";
import { connect } from "react-redux";
import { setLeagueDataByGameWeek } from "../../Home/actions/homeScreenLeagues";
import { setLeagueDataByGameWeekPrediction } from "../../Room/actions/rooms";
import leftGWArrow from "../../assets/left_gw_arrow.png";
import rightGWArrow from "../../assets/right_gw_arrow.png";
import PredictionItem from "./PredictionItem";
import LeagueAd from "./LeagueAd";
import Background from "../../Common/components/Background";

class LeagueList extends React.Component {
  state = {
    isFullPointsShown: false,
    matchSelected: -1,
    selectedUser: false,
    newPrediction: false,
  };

  toggleShowFullPoints = () => {
    this.setState({
      ...this.state,
      isFullPointsShown: !this.state.isFullPointsShown,
    });
  };

  onSetSelectedUser = () => {
    this.setState({ selectedUser: !this.state.selectedUser });
  };

  getLeagueInfoByGW = (id, gameWeek, lastGameWeek) => {
    this.onShowMatchInfo();

    if (gameWeek != 0 && Number(lastGameWeek) >= gameWeek) {
      if (this.props.betPoints) {
        this.props.setLeagueDataByGameWeekPrediction(id, gameWeek);
      } else {
        this.props.setLeagueDataByGameWeek(id, gameWeek);
        this.props.onChangeLeuageGameWeek();
      }
    }
  };

  onShowMatchInfo = (matchId) => {
    if (this.state.matchSelected != matchId) {
      this.setState({ matchSelected: matchId });
    } else {
      this.setState({ matchSelected: -1 });
    }
  };

  onPrevPage = () => {
    const { gameWeeks, from_game_week } = this.props;
    let gameWeek;
    let gwIndex;
    let currentGameWeek = Number(this.state.gameWeek) - 1;
    if (gameWeeks) {
      gwIndex = gameWeeks.findIndex(
        (game) => Number(game.game_week) === Number(currentGameWeek)
      );
      if (gwIndex === 0 || gwIndex === -1) {
        return Number(this.state.gameWeek);
      } else {
        gameWeek = gameWeeks[gwIndex].game_week;
        this.setState({ gameWeek: gameWeek });
        return Number(gameWeek);
      }
    } else {
      gameWeek = this.state.gameWeek;
      this.setState({ gameWeek: gameWeek });
      return Number(gameWeek) - 1;
    }
  };

  onNextPage = () => {
    const { gameWeeks } = this.props;
    let gameWeek;
    let gwIndex;
    let currentGameWeek = Number(this.state.gameWeek) + 1;
    if (gameWeeks) {
      gwIndex = gameWeeks.findIndex(
        (game) => Number(game.game_week) === Number(currentGameWeek)
      );
      if (gwIndex === 0 || gwIndex === -1) {
        return Number(this.state.gameWeek);
      } else {
        gameWeek = gameWeeks[gwIndex].game_week;
        this.setState({ gameWeek: gameWeek });
        return Number(gameWeek);
      }
    } else {
      gameWeek = this.state.gameWeek;
      this.setState({ gameWeek: gameWeek });
      return Number(gameWeek) + 1;
    }
  };

  componentDidMount() {
    this.setState({ gameWeek: this.props.gameWeek });
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.selectedUserPredictions &&
      prevProps.selectedUserPredictions != this.props.selectedUserPredictions
    ) {
      this.onSetSelectedUser();
    }
    if (
      prevProps.userSelected &&
      !this.props.userSelected &&
      this.state.selectedUser
    ) {
      this.setState({ selectedUser: false, newPrediction: false });
    }
    if (
      !prevProps.userSelected &&
      this.props.userSelected &&
      !this.state.selectedUser
    ) {
      this.setState({ selectedUser: true });
    }

    if (
      prevProps.selectedUserPredictions &&
      this.props.selectedUserPredictions
    ) {
      for (
        let index = 0;
        index < this.props.selectedUserPredictions.length;
        index++
      ) {
        if (
          this.props.selectedUserPredictions[index].prediction !=
          prevProps.selectedUserPredictions[index].prediction
        ) {
          this.setState({ newPrediction: true });
          break;
        }
      }
    }
  }

  render() {
    const {
      roomNameType,
      leagueImg,
      name,
      gameWeek,
      matches,
      loggedUserPredictions,
      lastGameWeeks,
      to_game_week,
      userSelected,
      selectedUserPredictions,
      betPoints,
      id,
      navigation,
      onGetPredictionData,
      userId,
      roomSelected,
      isAllMatches,
      gameWeeks,
      specialRoom,
      roomId,
      search,
      reactRem,
      banner,
      isLoading,
      LoadingIndex,
    } = this.props;

    const { selectedUser, newPrediction } = this.state;
    let theLastGameWeek = lastGameWeeks ? lastGameWeeks : to_game_week;
    let leagueImage = globalStyles.BASE_URL + leagueImg;
    if (matches) {
      if (matches && matches.length === 0) {
        return null;
      }
    }
    let special = false;
    if (gameWeeks) {
      special = true;
    }
    return (
      <View style={{ paddingBottom: 1.5 * reactRem }}>
        <View
          style={[
            styles.listItem,
            {
              marginTop: 0.5 * reactRem,
              borderTopRightRadius: reactRem,
              borderTopLeftRadius: reactRem,
              paddingVertical: reactRem,
              paddingLeft: reactRem,
            },
          ]}
        >
          <TouchableOpacity
            onPress={() =>
              this.props.navigation.navigate("Standings", { leagueId: id })
            }
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <View
                style={[
                  styles.placeImageContainer,
                  {
                    borderTopLeftRadius: reactRem,
                    borderBottomRightRadius: reactRem,
                    marginRight: 2 * reactRem,
                    padding: 0.5 * reactRem,
                  },
                ]}
              >
                <Image
                  source={{ uri: leagueImage }}
                  style={[
                    styles.placeImage,
                    { height: 5 * reactRem, width: 5 * reactRem },
                  ]}
                />
              </View>
              <Text style={[styles.leagueTitle, { fontSize: 2.2 * reactRem }]}>
                {name}
              </Text>
            </View>
          </TouchableOpacity>
          {!specialRoom ? (
            <View style={styles.gameWeekContainer}>
              {isAllMatches ? (
                <TouchableOpacity
                  onPress={() => {
                    onGetPredictionData
                      ? onGetPredictionData(this.onPrevPage(), special)
                      : this.getLeagueInfoByGW(
                          id,
                          Number(gameWeek) - 1,
                          theLastGameWeek
                        );
                  }}
                >
                  <View style={{ padding: 1.5 * reactRem }}>
                    <Image
                      source={leftGWArrow}
                      style={[
                        styles.gameWeekArrow,
                        { width: 2.5 * reactRem, height: 3.5 * reactRem },
                      ]}
                    />
                  </View>
                </TouchableOpacity>
              ) : (
                <View
                  style={[
                    styles.gameWeekArrow,
                    { width: 2.5 * reactRem, height: 3.5 * reactRem },
                  ]}
                />
              )}
              <View style={styles.gameWeekContainer}>
                <View
                  style={[
                    styles.gameWeekTextContainer,
                    { paddingRight: 0.5 * reactRem },
                  ]}
                >
                  <Text
                    style={[
                      styles.gameWeekText,
                      { fontSize: 1.6 * reactRem, marginRight: 0.5 * reactRem },
                    ]}
                  >
                    {"Game"}
                  </Text>
                  <Text
                    style={[
                      styles.gameWeekText,
                      { fontSize: 1.6 * reactRem, marginRight: 0.5 * reactRem },
                    ]}
                  >
                    {"Week"}
                  </Text>
                </View>
                <View>
                  <Text
                    style={[
                      styles.gameWeekNumberText,
                      { fontSize: 3.5 * reactRem },
                    ]}
                  >
                    {gameWeek}
                  </Text>
                </View>
              </View>

              {isAllMatches ? (
                <TouchableOpacity
                  onPress={() => {
                    onGetPredictionData
                      ? onGetPredictionData(this.onNextPage(), special)
                      : this.getLeagueInfoByGW(
                          id,
                          Number(gameWeek) + 1,
                          theLastGameWeek
                        );
                  }}
                >
                  <View style={{ padding: 1.5 * reactRem }}>
                    <Image
                      source={rightGWArrow}
                      style={[
                        styles.gameWeekArrow,
                        { width: 2.5 * reactRem, height: 3.5 * reactRem },
                      ]}
                    />
                  </View>
                </TouchableOpacity>
              ) : (
                <View
                  style={[
                    styles.gameWeekArrow,
                    { width: 2.5 * reactRem, height: 3.5 * reactRem },
                  ]}
                />
              )}
            </View>
          ) : null}
        </View>
        {isLoading && LoadingIndex == this.props.index ? (
          //Using a View with an ActivityIndicator instead of LoadingScreen because of the different height
          <View>
            <Background />
            <View style={[styles.loadingContainer, styles.horizontal]}>
              <ActivityIndicator size="large" color={globalStyles.white} />
            </View>
          </View>
        ) : (
          <FlatList
            data={matches}
            keyExtractor={(item, index) => "key" + index}
            roomSelected={roomSelected}
            userSelected={userSelected}
            selectedUser={selectedUser}
            newPrediction={newPrediction}
            matchSelected={this.state.matchSelected}
            fullPointsShown={this.state.isFullPointsShown}
            renderItem={({ item, index }) => (
              <View>
                {betPoints ? (
                  <PredictionItem
                    matchIsOpen={this.state.matchSelected === item.id}
                    userId={userId}
                    {...item}
                    betPoints={betPoints}
                    navigation={navigation}
                    fullPointsShown={this.state.isFullPointsShown}
                    leagueId={id}
                    specialRoom={specialRoom}
                    leagueName={name}
                    onRoomSelected={this.props.onRoomSelected}
                    onShowMatchInfo={() => this.onShowMatchInfo(item.id)}
                    roomSelected={roomSelected}
                    index={index}
                    roomId={roomId}
                    roomNameType={roomNameType}
                    selectedUser={selectedUser}
                    newPrediction={newPrediction}
                    matchesLength={matches.length}
                    matchSelected={this.state.matchSelected}
                    userPrediction={
                      loggedUserPredictions
                        ? loggedUserPredictions[index].points
                        : null
                    }
                    userSelected={userSelected}
                    search={search}
                    selectedUserPrediction={
                      selectedUserPredictions
                        ? selectedUserPredictions[index]
                        : null
                    }
                  />
                ) : (
                  <MatchItem
                    matchIsOpen={this.state.matchSelected === item.id}
                    userId={userId}
                    {...item}
                    betPoints={betPoints}
                    navigation={navigation}
                    fullPointsShown={this.state.isFullPointsShown}
                    leagueId={id}
                    leagueName={name}
                    onRoomSelected={this.props.onRoomSelected}
                    onShowMatchInfo={() => this.onShowMatchInfo(item.id)}
                    roomSelected={roomSelected}
                    index={index}
                    matchesLength={matches.length}
                    matchSelected={this.state.matchSelected}
                  />
                )}
              </View>
            )}
          />
        )}
        {banner && banner.length != 0 ? (
          <LeagueAd banner={banner} navigation={this.props.navigation} />
        ) : null}
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  loadingContainer: {
    height: "15%",
    justifyContent: "center",
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "center",
    padding: "25rem",
  },
  container: {
    paddingBottom: "1.5rem",
  },
  listItem: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    marginTop: "0.5rem",
    borderTopRightRadius: "1rem",
    borderTopLeftRadius: "1rem",
    paddingVertical: "1rem",
    paddingLeft: "1rem",
  },
  placeImage: {
    height: "5rem",
    width: "5rem",
    resizeMode: "contain",
  },
  placeImageContainer: {
    backgroundColor: globalStyles.white,
    borderTopLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
    marginRight: "2rem",
    padding: "0.5rem",
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: "2.2rem",
    height: "100%",
  },
  gameWeekNumberText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: "3.5rem",
  },
  gameWeekArrowContainer: {
    padding: "1.5rem",
  },
  gameWeekArrow: {
    width: "2.5rem",
    height: "3.5rem",
    resizeMode: "contain",
  },
  gameWeekContainer: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  gameWeekTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "0.5rem",
  },
  gameWeekText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: "1.6rem",
    marginRight: "0.5rem",
  },
});

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    leaguesGW: state.homeScreenLeagues.leaguesGW,
    reactRem: state.homeScreenLeagues.reactRem,
    isLoading: state.loadingReducer.isLoading,
    LoadingIndex: state.loadingReducer.LoadingIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLeagueDataByGameWeek: (leagueId, gameWeek) =>
      dispatch(setLeagueDataByGameWeek(leagueId, gameWeek)),
    setLeagueDataByGameWeekPrediction: (leagueId, gameWeek) =>
      dispatch(setLeagueDataByGameWeekPrediction(leagueId, gameWeek)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueList);
