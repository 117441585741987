import React from "react";
import { View, Image, Text, FlatList, Group } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import globalStyles from "../../globalStyles";
import StandingsFeild from "../../Common/components/UI/StandingsField";
import { connect } from "react-redux";
import { getStandingsLeagueData } from "../actions/standings";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import StandingsGroupStages from "../../Common/components/UI/StandingsGroupStages";
import LeagueAd from "../../Common/LeagueList/LeagueAd";
import { standingsStyles } from "../styles/styles";

class Standings extends React.Component {
  state = {
    refreshing: false,
  };

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  _onRefresh = () => {
    this.setState({ refreshing: true });
    this.props.getStandingsLeagueData(
      this.props.navigation.getParam("leagueId", null)
    );
  };
  componentDidUpdate() {
    if (this.state.refreshing) {
      this.setState({ refreshing: false });
    }
  }

  componentDidMount() {
    this.goToTop();
    this.props.getStandingsLeagueData(
      this.props.navigation.getParam("leagueId", null)
    );
  }

  render() {
    const {
      standings,
      leagueName,
      leagueImg,
      isLoading,
      staging,
      reactRem,
      banner,
    } = this.props;

    return (
      <View
        refreshing={this.state.refreshing}
        onRefresh={this._onRefresh}
        style={standingsStyles.scrollViewStyle}
      >
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <View style={standingsStyles.container}>
            <View style={standingsStyles.leagueImgContainer}>
              <Image
                source={{ uri: globalStyles.BASE_URL + leagueImg }}
                style={standingsStyles.leagueImg}
              />
              <View>
                <Text style={standingsStyles.leagueText}>{leagueName}</Text>
              </View>
            </View>
            <View style={standingsStyles.standingsContainer}>
              {staging ? null : (
                <View>
                  <View style={standingsStyles.listItem}>
                    <Text style={standingsStyles.leagueTitle}>Standings</Text>
                  </View>
                  <View style={standingsStyles.textTableContainer}>
                    <View style={{ flexDirection: "row" }}>
                      <View style={standingsStyles.numberTextContainer}>
                        <Text style={standingsStyles.defaultText}>No.</Text>
                      </View>
                      <View style={standingsStyles.teamImg} />
                      <View style={standingsStyles.teamRankContainer}>
                        <Text style={standingsStyles.defaultText}>Team</Text>
                      </View>
                    </View>
                    <View style={standingsStyles.rankingStatsContainer}>
                      <Text style={standingsStyles.defaultText}>M</Text>
                      <Text style={standingsStyles.defaultText}>W</Text>
                      <Text style={standingsStyles.defaultText}>D</Text>
                      <Text style={standingsStyles.defaultText}>L</Text>
                      <Text style={standingsStyles.defaultText}>GD</Text>
                      <Text style={standingsStyles.defaultText}>PTS</Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={standingsStyles.flatListContainer}>
                <FlatList
                  data={standings}
                  keyExtractor={(item, index) => "key" + index}
                  renderItem={({ item, index }) =>
                    !staging ? (
                      <StandingsFeild
                        {...item}
                        index={index}
                        length={standings.length}
                        reactRem={reactRem}
                      />
                    ) : (
                      <StandingsGroupStages
                        {...item}
                        index={index}
                        length={standings.length}
                        reactRem={reactRem}
                      />
                    )
                  }
                />
                {banner && banner.length != 0 ? (
                  <LeagueAd
                    banner={banner}
                    navigation={this.props.navigation}
                  />
                ) : null}
              </View>
            </View>
          </View>
        )}
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getStandingsLeagueData: (leagueId) =>
      dispatch(getStandingsLeagueData(leagueId)),
  };
};
const mapStateToProps = (state) => {
  return {
    standings: state.standings.rankings,
    staging: state.standings.staging,
    leagueImg: state.standings.leagueImg,
    leagueName: state.standings.leagueName,
    isLoading: state.loadingReducer.isLoading,
    reactRem: state.homeScreenLeagues.reactRem,
    banner: state.standings.adverts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Standings);
