import React from "react";
import { View, Text, TouchableWithoutFeedback } from "react-native";
import globalStyles from "../../globalStyles";
import { createRoomLeagueRoundsPickerFieldStyles } from "../styles/styles";

class CreateRoomLeagueRoundsPickerField extends React.Component {
  render() {
    const { leagueName, choosenKnockoutRound } = this.props;
    const pickerText = [createRoomLeagueRoundsPickerFieldStyles.pickerText];

    if (leagueName === choosenKnockoutRound) {
      pickerText.push({ color: globalStyles.mainBlue });
    } else {
      pickerText.push({ color: globalStyles.borderRoom });
    }
    
    return (
      <TouchableWithoutFeedback
        onPress={() => {
          this.props.onPress();
        }}
      >
        <View
          style={createRoomLeagueRoundsPickerFieldStyles.pickerLeagueContainer}
        >
          <View
            style={createRoomLeagueRoundsPickerFieldStyles.pickerTextContainer}
          >
            <Text style={pickerText}>{leagueName}</Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

export default CreateRoomLeagueRoundsPickerField;
