import { authSetToken } from "../../../Login/actions/auth";
import { BASE_URL } from "../config";

export const authGetToken = () => {
  return (dispatch, getState) => {
    const promise = new Promise((resolve, reject) => {
      let token = getState().auth.token;
      if (!token) {
        token = localStorage.getItem("token");
        if (token) {
          dispatch(authSetToken(token));
          resolve(token);
        } else {
          reject();
        }
      } else {
        resolve(token);
      }
    });
    return promise;
  };
};

export const authFetch = (url, method, body) => {
  return async (dispatch) => {
    const promise = new Promise((resolve, reject) => {
      method = method || "GET";
      body = body ? JSON.stringify(body) : null;
      dispatch(authGetToken())
        .catch((err) => {
          console.log("No auth token exists!");
          reject(err);
        })
        .then((token) => {
          return fetch(BASE_URL + url, {
            method,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              Authorization: token,
            },
            body,
          });
        })
        .catch((err) => reject(err))
        .then((res) => res.json())
        .catch((err) => reject(err))
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
    return await promise;
  };
};

export const authFetchNoToken = (url, method, body) => {
  return async (dispatch) => {
    const promise = new Promise((resolve, reject) => {
      method = method || "GET";
      body = body ? JSON.stringify(body) : null;
      return fetch(BASE_URL + url, {
        method,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body,
      })
        .catch((err) => reject(err))
        .then((res) => res.json())
        .catch((err) => reject(err))
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
    return await promise;
  };
};

