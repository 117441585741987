import React from "react";
import { TouchableOpacity, View, Text, Image } from "react-native";
import globalStyles from "../../globalStyles";
import PredictionDot from "../../assets/prediction_dot.png";
import { predictionRoundFieldStyles } from "../styles/styles";

class PredictionRoundField extends React.Component {
  render() {
    const {
      lastRound,
      firstRound,
      gameWeeksLenght,
      round,
      timeUntilStart,
      isPredicted,
      isCurrentGameWeek,
      isTotalPassed,
      matches,
      predictedMatches,
      isCurrentRound,
      areBetsAllowed,
      remainingAllowedMatchesForBet,
      isPassed,
    } = this.props;

    const predictButtonText = [predictionRoundFieldStyles.predictButtonText];
    const predictButtonContainer = [
      predictionRoundFieldStyles.predictButtonContainer,
    ];
    const gameWeekContainerStyle = [
      predictionRoundFieldStyles.gameWeekContainer,
    ];
    let buttonText = "PREDICT";
    let underButtonText = predictedMatches + "/" + matches;
    if (lastRound === firstRound) {
      predictButtonContainer.push(
        predictionRoundFieldStyles.oneRoundButtonContainer
      );
      gameWeekContainerStyle.push(
        predictionRoundFieldStyles.roundContainerStyle
      );
    }
    if (isTotalPassed || (isPassed && remainingAllowedMatchesForBet === 0)) {
      predictButtonContainer.push(predictionRoundFieldStyles.finishedContainer);
      predictButtonText.push({ color: globalStyles.white });
      buttonText = "VIEW";
      underButtonText = "finished";
    } else if (
      !isTotalPassed &&
      isPredicted &&
      matches === predictedMatches &&
      areBetsAllowed
    ) {
      predictButtonContainer.push(predictionRoundFieldStyles.finishedContainer);
      predictButtonText.push({ color: globalStyles.white });
      buttonText = "PREDICTED";
      underButtonText = predictedMatches + "/" + matches;
    } else if (
      !isTotalPassed &&
      isPredicted &&
      areBetsAllowed &&
      timeUntilStart === "Started" &&
      remainingAllowedMatchesForBet === 0
    ) {
      predictButtonContainer.push(predictionRoundFieldStyles.finishedContainer);
      predictButtonText.push({ color: globalStyles.white });
      buttonText = "PREDICTED";
      underButtonText = predictedMatches + "/" + matches;
    } else if (
      !isTotalPassed &&
      isPredicted &&
      remainingAllowedMatchesForBet === 0 &&
      areBetsAllowed
    ) {
      predictButtonContainer.push(predictionRoundFieldStyles.finishedContainer);
      predictButtonText.push({ color: globalStyles.white });
      buttonText = "PREDICTED";
      underButtonText = predictedMatches + "/" + matches;
    } else if (isTotalPassed && isCurrentGameWeek && !areBetsAllowed) {
      predictButtonContainer.push(predictionRoundFieldStyles.finishedContainer);
      predictButtonText.push({ color: globalStyles.white });
      buttonText = "VIEW";
      underButtonText = predictedMatches + "/" + matches;
    } else if (
      !isTotalPassed &&
      isPredicted &&
      predictedMatches != matches &&
      areBetsAllowed
    ) {
      predictButtonContainer.push({ backgroundColor: globalStyles.activeBlue });
      predictButtonText.push({ color: globalStyles.white });
      buttonText = "PREDICT";
      underButtonText = predictedMatches + "/" + matches;
    } else {
      predictButtonContainer.push(
        predictionRoundFieldStyles.borderRadiusContainer
      );
    }
    if (gameWeeksLenght === 1 && lastRound != firstRound) {
      gameWeekContainerStyle.push(
        predictionRoundFieldStyles.endOfSeasonExtraContainer
      );
    }

    return (
      <View style={gameWeekContainerStyle}>
        {lastRound === firstRound ? (
          <View style={predictionRoundFieldStyles.oneRoundContainer}>
            <View
              style={predictionRoundFieldStyles.oneRoundTimeUntilStartContainer}
            >
              <Text
                style={predictionRoundFieldStyles.onRoundTimeUntilStartText}
              >
                {timeUntilStart}
              </Text>
            </View>
            <TouchableOpacity
              style={predictButtonContainer}
              onPress={() => this.props.onPress()}
            >
              <Text style={predictButtonText}>{buttonText}</Text>
            </TouchableOpacity>
            <View style={predictionRoundFieldStyles.betTextContainer}>
              <Text style={predictionRoundFieldStyles.textUnderPredictButton}>
                {" "}
                {underButtonText}
              </Text>
            </View>
          </View>
        ) : (
          <View style={predictionRoundFieldStyles.gameWeekContainer}>
            <View style={predictionRoundFieldStyles.gameWeekTextContainer}>
              <View style={predictionRoundFieldStyles.predictionDotContainer}>
                <Text style={predictionRoundFieldStyles.gameWeekText}>
                  {round}
                </Text>
                {isCurrentRound ? (
                  <Image
                    source={PredictionDot}
                    style={predictionRoundFieldStyles.predictionDotImg}
                  />
                ) : null}
              </View>
              {timeUntilStart && buttonText !== "VIEW" ? (
                <Text style={predictionRoundFieldStyles.timePeriodText}>
                  {timeUntilStart}
                </Text>
              ) : (
                <Text style={predictionRoundFieldStyles.timePeriodText}> </Text>
              )}
            </View>
            <View style={predictionRoundFieldStyles.predictionContainer}>
              <TouchableOpacity
                style={predictButtonContainer}
                onPress={() => this.props.onPress()}
              >
                <Text style={predictButtonText}>{buttonText}</Text>
              </TouchableOpacity>
              <View style={predictionRoundFieldStyles.betTextContainer}>
                <Text
                  style={[predictionRoundFieldStyles.textUnderPredictButton]}
                >
                  {" "}
                  {underButtonText}
                </Text>
              </View>
            </View>
          </View>
        )}
      </View>
    );
  }
}

export default PredictionRoundField;
