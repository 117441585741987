import React from 'react';
import { TouchableOpacity, Text, Image } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';

class ProfileMenuBtn extends React.Component {

    render() {
        const { image, title, reactRem } = this.props;
        const containerStyle = [styles.container, {
            marginVertical: 1.5 * reactRem,
            paddingHorizontal: 2 * reactRem,
        }];
        const imageStyle = [styles.imageStyle, {
            width: 3 * reactRem,
            height: 3 * reactRem,
            marginRight: reactRem
        }];
        const textStyle = [styles.textStyle, { fontSize: 2.2 * reactRem }];
        return (
            <TouchableOpacity style={containerStyle} onPress={() => this.props.onPress()}>
                <Image source={image} style={imageStyle} />
                <Text style={textStyle}>{title}</Text>
            </TouchableOpacity>
        );
    }
}

const styles = EStyleSheet.create({
    container: {
        flexDirection: "row",
        width: "100%",
        marginVertical: "1.5rem",
        paddingHorizontal: "2rem",
        alignItems: "center",
        margin: "auto"
    },
    imageStyle: {
        width: "3rem",
        height: "3rem",
        resizeMode: "contain",
        marginRight: "1rem"
    },
    textStyle: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuBold,
        fontSize: "2.2rem"
    }


});
export default ProfileMenuBtn;