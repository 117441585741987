import globalStyles from "../../globalStyles";
import EStyleSheet from "react-native-extended-stylesheet";

const joinSpecialGamesPageStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
  },
  fieldsContainer: {
    paddingVertical: 6,
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  weeklyGameImgContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: 3,
  },
  weeklyGameLeaguesImg: {
    height: 25,
    width: 25,
    marginRight: 6,
    resizeMode: "contain",
  },
  titleContainer: {
    backgroundColor: globalStyles.white,
    width: "100%",
    padding: 18,
  },
  titleTextContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingBottom: 6,
  },
  roomNameText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 15,
  },
  specialRoomText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 9,
  },
  roomInfoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  roomExtraInfoContainer: {
    width: "45%",
  },
  extraInfoContainer: {
    paddingTop: 12,
  },
  startEndContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  startContainer: {
    width: "40%",
  },
  endContainer: {
    width: "60%",
  },
  startText: {
    color: globalStyles.greyText,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  endText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  playerText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 14,
  },
  goalImg: {
    height: 12,
    width: 12,
    marginRight: 3,
    resizeMode: "contain",
  },
  joinButtonContainer: {
    width: "40%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 3,
    paddingVertical: 6,
    backgroundColor: globalStyles.activeBlue,
  },
  joinText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 12,
  },
  specialGamesButtonContainer: {
    flexDirection: "row",
    width: "100%",
    paddingTop: 12,
    justifyContent: "space-between",
  },
  specialGamesButton: {
    width: "55%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: globalStyles.black,
    borderRadius: 3,
    paddingVertical: 6,
    paddingHorizontal: 18,
  },
  prizeContainer: {
    flexDirection: "row",
    width: "40%",
    justifyContent: "center",
  },
  prizeTextContainer: {
    alignItems: "center",
    textAlign: "center",
  },
  prizeImageContainer: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: globalStyles.backgroundGrey,
    borderRadius: 6,
    padding: 0.6,
  },
  prizeImage: {
    height: 60,
    width: 60,
    borderRadius: 12,
    resizeMode: "contain",
  },
});

const specialGamesPageStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  fieldsContainer: {
    paddingBottom: 18,
    height: "100%",
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  flatListContainer: {
    paddingHorizontal: 3,
  },
  buttonContainer: {
    flexDirection: "row",
    width: "100%",
    height: "9rem",
    borderBottomWidth: 0.5,
    borderBottomColor: globalStyles.borderRoom,
  },
  commingSoonContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 60,
  },
  commingSoonText: {
    color: globalStyles.textBlue,
    fontSize: 18,
    fontFamily: globalStyles.fontUbuntuMedium,
  },
  signInUpContainer: {
    backgroundColor: globalStyles.backgroundGrey,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    paddingVertical: 6,
  },
  signInContainer: {
    backgroundColor: globalStyles.white,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 18,
    paddingVertical: 9,
    minWidth: 120,
    maxWidth: 120,
    borderTopLeftRadius: 9,
    borderBottomLeftRadius: 9,
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
    paddingHorizontal: 18,
    paddingVertical: 9,
    alignItems: "center",
    justifyContent: "center",
    minWidth: 120,
    maxWidth: 120,
    borderTopRightRadius: 9,
    borderBottomRightRadius: 9,
  },
  signUpText: {
    color: globalStyles.white,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 15,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  signInText: {
    color: globalStyles.textBlue,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 15,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  advertisingContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -30,
  },
});

const joinSpecialGamesInfoStyles = EStyleSheet.create({
  pickerLeagueContainer: {
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingTop: 3,
    paddingHorizontal: 3,
  },
  prizeWinnerPositionTextContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  listItem: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    marginTop: 3,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingVertical: 6,
    paddingLeft: 6,
  },
  placeImage: {
    height: 12,
    width: 18,
    paddingLeft: 24,
    paddingRight: 33,
    resizeMode: "contain",
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: 14,
    height: "100%",
  },
  loadMoreButtonContainer: {
    backgroundColor: globalStyles.white,
    opacity: 0.7,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingVertical: 12,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
  },
  loadMoreButtonText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 10,
  },
  playerHeaderContainer: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: globalStyles.white,
    opacity: 0.7,
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  positionContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
    padding: 6,
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
  },
  playerContainer: {
    width: "80%",
    paddingVertical: 6,
    paddingHorizontal: 30,
  },
  playerHeaderText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: 10,
  },
  bottomContainer: {
    height: 30,
    width: "100%",
  },
});

const prizeFieldStyles = EStyleSheet.create({
  playerInfoContainer: {
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  playerPositionContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 6,
    paddingLeft: 12,
    paddingVertical: 6,
    width: "20%",
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
  },
  positionText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
    color: globalStyles.textBlue,
  },
  prizeContainer: {
    width: "80%",
    padding: 6,
  },
  betPlayerText: {
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 10,
    color: globalStyles.textBlue,
  },
});

const specialGamesFreeStyles = EStyleSheet.create({
  container: {
    borderRadius: 9,
    marginTop: 6,
    padding: 12,
    backgroundColor: globalStyles.white,
  },
  weeklyGameNameContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 6,
  },
  weeklyGameNameText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 17,
  },
  weeklyGameLeagueImgContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 3,
  },
  weeklyGameImgContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "70%",
  },
  weeklyGameLeaguesImg: {
    height: 30,
    width: 30,
    marginRight: 6,
    resizeMode: "contain",
  },
  prizeContainer: {
    alignItems: "center",
    width: "40%",
  },
  weeklyGameNameTextContainer: {
    width: "60%",
  },
  weeklyGameNameTextContainerRight: {
    width: "40%",
    alignItems: "center",
    justifyContent: "center",
  },
  specialGamesButtonContainer: {
    flexDirection: "row",
    width: "100%",
    paddingVertical: 12,
    justifyContent: "space-between",
  },
  specialGamesButton: {
    width: "55%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: globalStyles.black,
    borderRadius: 3,
    paddingVertical: 6,
    paddingHorizontal: 18,
  },
  confirmButton: {
    fontFamily: globalStyles.fontUbuntuBold,
    color: globalStyles.black,
    fontSize: 16,
  },
  prizeImageContainer: {
    alignItems: "center",
    borderRadius: 6,
    borderWidth: 1,
    borderColor: globalStyles.backgroundGrey,
  },
  prizeImage: {
    height: 100,
    width: 100,
    borderRadius: 6,
    resizeMode: "contain",
    borderWidth: 1,
  },
  roomInfoContainer: {
    flexDirection: "row",
    width: "100%",
  },
  roomDataContainer: {
    width: "60%",
  },
  specialRoomTitleText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
  },
  extraInfoContainer: {
    paddingTop: 16,
  },
  startEndContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  startContainer: {
    width: "30%",
  },
  endContainer: {
    width: "70%",
  },
  startText: {
    color: globalStyles.greyText,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 14,
  },
  endText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 14,
  },
  extraLeagueContainer: {
    height: 27,
    width: 27,
    alignItems: "center",
  },
  extraLeagueText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 12,
  },
  playerText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 17,
  },
  prizeText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 14,
  },
  prizeTextContainer: {
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 6,
  },
  goalImg: {
    height: 18,
    width: 18,
    resizeMode: "contain",
    marginRight: 3,
  },
  joinButtonContainer: {
    width: "40%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 3,
    paddingVertical: 6,
    backgroundColor: globalStyles.activeBlue,
  },
  joinText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 16,
  },
  viewButtonContainer: {
    width: "40%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 3,
    paddingVertical: 6,
    backgroundColor: globalStyles.black,
  },
});

const specialGamesMatchesContainerStyles = EStyleSheet.create({
  titleContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: globalStyles.darkBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 15,
  },
});

const specialGamesIndicatorStyles = EStyleSheet.create({
  chooseButtonContainer: {
    backgroundColor: globalStyles.white,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: globalStyles.activeBlue,
    height: 30,
    borderRadius: 12,
    marginVertical: 12,
    paddingHorizontal: 12,
  },
  chooseButtonTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 14,
  },
});

const specialGamesMatchFieldStyles = EStyleSheet.create({
  pickerLeagueContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingVertical: 6,
  },
  matchesContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    paddingRight: 6,
  },
  homeTeamContainer: {
    width: "47.5%",
    justifyContent: "flex-end",
    paddingRight: 12,
  },
  awayTeamContainer: {
    width: "47.5%",
    paddingLeft: 12,
  },
  homeTeamText: {
    color: globalStyles.borderRoom,
    fontSize: 15,
    fontFamily: globalStyles.fontUbuntuRegular,
    textAlign: "right",
  },
  awayTeamText: {
    color: globalStyles.borderRoom,
    fontSize: 15,
    fontFamily: globalStyles.fontUbuntuRegular,
    textAlign: "left",
  },
  border: {
    width: "5%",
    justifyContent: "center",
    alignItems: "center",
  },
  borderStyle: {
    width: 10,
    height: 2,
    backgroundColor: globalStyles.borderRoom,
  },
  shieldContainer: {
    width: "10%",
    alignItems: "center",
  },
  shieldImgView: {
    height: 18,
    width: 18,
    marginTop: -3,
  },
  smallerClubName: {
    fontSize: 15,
  },
});

const specialGamesPlayerFieldStyles = EStyleSheet.create({
  playerInfoContainer: {
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.backgroundGrey,
    width: "100%",
  },
  playerPositionContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "20%",
    paddingVertical: 6,
    paddingRight: 18,
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
  },
  positionText: {
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
    color: globalStyles.textBlue,
  },
  playerNameBetContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "80%",
    paddingVertical: 6,
  },
  playerImageContainer: {
    paddingLeft: 3,
    paddingRight: 9,
  },
  profileImage: {
    height: 18,
    width: 18,
    borderRadius: 9,
    resizeMode: "contain",
  },
  playerUsernameText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 9,
  },
  playerNameText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 9,
    opacity: 0.7,
  },
});

const specialGamesPrizeContainerStyles = EStyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    paddingVertical: 6,
  },
  title: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  leagueImagesContainer: {
    width: "10%",
  },
  leagueTitleContainer: {
    width: "90%",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 18,
  },
});

const specialGamesShowMatchesStyles = EStyleSheet.create({
  mainContainer: {
    flex: 1,
    width: "100%",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  alertContainer: {
    width: "50%",
    paddingVertical: 12,
    borderRadius: 3,
    backgroundColor: globalStyles.white,
  },
  titleContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  title: {
    color: globalStyles.darkBlue,
    fontSize: 15,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  matchesContainer: {
    width: "100%",
    paddingTop: 12,
  },
  goalImg: {
    height: 21,
    width: 21,
    resizeMode: "contain",
    marginRight: 3,
  },
  confirmButton: {
    fontFamily: globalStyles.fontUbuntuBold,
    color: globalStyles.black,
    fontSize: 16,
  },
  specialGamesButton: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: globalStyles.black,
    borderRadius: 3,
    paddingVertical: 6,
    paddingHorizontal: 18,
  },
});

const specialGamesShowPrizesStyles = EStyleSheet.create({
  alertContainer: {
    width: "100%",
    maxHeight: "80%",
    paddingVertical: 12,
    borderRadius: 3,
    backgroundColor: globalStyles.white,
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainer: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    paddingHorizontal: 6,
  },
  title: {
    color: globalStyles.darkBlue,
    fontSize: 12,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  crownContainer: {
    width: "10%",
  },
  titleTextContainer: {
    width: "85%",
    alignItems: "center",
    justifyContent: "center",
  },
  clearContainer: {
    width: "5%",
  },
  crownImg: {
    height: 12,
    width: 18,
    resizeMode: "contain",
  },
  clearImg: {
    height: 9,
    width: 9,
    resizeMode: "contain",
  },
});

export {
  joinSpecialGamesPageStyles,
  specialGamesPageStyles,
  joinSpecialGamesInfoStyles,
  prizeFieldStyles,
  specialGamesFreeStyles,
  specialGamesMatchesContainerStyles,
  specialGamesIndicatorStyles,
  specialGamesMatchFieldStyles,
  specialGamesPlayerFieldStyles,
  specialGamesPrizeContainerStyles,
  specialGamesShowMatchesStyles,
  specialGamesShowPrizesStyles,
};
