import React from 'react';
import { Text, View, Image, TouchableOpacity, ScrollView } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import ArrowDown from '../../../assets/home_arrow_down_old.png';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';


class PopupPicker extends React.Component {
    state = {
        choosenItem: null,
        selectedValue: null,
        isModalVisible: false,
    }

    onChangeState = (value, index) => {
        this.setState({
            selectedValue: value,
            selectedIndex: index,
        });
        this.props.onPick(index);
    }

    onModalVisible = () => { this.setState({ isModalVisible: !this.state.isModalVisible }) };

    onFieldPress = (value, index) => {
        this.onModalVisible();
        this.onChangeState(value, index);
    }

    render() {
        const { onPress, title, style, correctInput, onPick, dates, error, reactRem } = this.props;
        const pickerContainer = [styles.pickerContainer, { height: 8 * reactRem }];
        const pickerText = [styles.pickerText, { fontSize: 2.4 * reactRem }];
        const errorContainerStyle = [styles.errorContainer, {
            paddingRight: 1.5 * reactRem,
            paddingLeft: 3 * reactRem
        }]
        const data = [];
        const paper = [{
            width: "50%",
            border: '2px solid #000',
            padding: 2 * reactRem,
            backgroundColor: 'red'
        }]
        if (dates) {
            dates.forEach(value => {
                data.push(value.name);
            })
        }
        if (style) {
            pickerContainer.push(style)
            errorContainerStyle.push(style)
        }

        const textFieldStyle = [styles.pickerText, {
            height: 6 * reactRem,
            fontSize: 2.4 * reactRem,
        }];

        const textContainerStyle = [{
            height: 6 * reactRem,
            width: "100%",
            backgroundColor: globalStyles.white,
            borderBottomWidth: 1,
            borderBottomColor: globalStyles.backgroundGrey,
            justifyContent: 'center',
            padding: reactRem,
        }];

        const pickerContainer2Style = [styles.pickerContainer2, {
            paddingHorizontal: 2 * reactRem,
            paddingVertical: 2 * reactRem
        }];
        const btnImageStyle = [styles.btnImage, { height: 2 * reactRem, width: 2 * reactRem }];
        const errorTextStyle = [styles.errorText, { fontSize: 2.4 * reactRem }];
        const scrollViewContainerStyle = [{ width: "50%", marginTop: 50 * reactRem }];

        if (data.length > 3) {
            scrollViewContainerStyle.push({ marginTop: 5 * reactRem, marginBottom: 5 * reactRem });
        }
        return (
            <View style={pickerContainer}>
                <TouchableOpacity style={pickerContainer2Style} onPress={() => { this.onModalVisible() }}>
                    <View style={styles.pickerTextContainer}>
                        <Text style={pickerText}>{this.state.selectedValue ? this.state.selectedValue : title}</Text>
                    </View>
                    <Image source={ArrowDown} style={btnImageStyle} />
                </TouchableOpacity>
                {correctInput ?
                    <View style={errorContainerStyle}>
                        <Text style={errorTextStyle}>{error}</Text>
                    </View> : null
                }
                <Modal
                    open={this.state.isModalVisible}
                    onClose={() => this.onModalVisible()}>
                    <TouchableOpacity onPress={() => this.onModalVisible()} style={{ flex: 1, width: "100%", height: "100%", justifyContent: 'center', alignItems: "center", position: "absolute" }}>
                        <ScrollView style={scrollViewContainerStyle}>
                            {data.map((item, i) =>
                                <TouchableOpacity onPress={() => this.onFieldPress(item, i)} key={i} style={textContainerStyle}>
                                    <Text style={textFieldStyle}>{item}</Text>
                                </TouchableOpacity>
                            )}
                        </ScrollView>
                    </TouchableOpacity>
                </Modal>
            </View>
        );
    }
}

const styles = EStyleSheet.create({
    pickerText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuMedium,
    },
    textContainer: {
        alignItems: "center",
        height: "100%",
    },
    pickerContainer2: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        paddingHorizontal: "2rem",
        paddingVertical: "2rem"
    },
    pickerContainer: {
        borderBottomWidth: 2,
        borderBottomColor: globalStyles.backgroundGrey,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: globalStyles.white,
        width: "100%",
        height: "8rem",
    },
    btnImage: {
        height: "2rem",
        width: "2rem",
    },
    pickerTextContainer: {
        alignItems: "center"
    },
    errorContainer: {
        width: "100%",
        backgroundColor: globalStyles.errorBackground,
        paddingRight: "1.5rem",
        paddingLeft: "3rem"
    },
    errorText: {
        color: globalStyles.error,
        fontFamily: globalStyles.fontUbuntuMedium,
        fontSize: "1.8rem"
    },
});

const mapStateToProps = (state, ownProps) => {
    return {

        reactRem: state.homeScreenLeagues.reactRem,
    }
}


export default connect(mapStateToProps, null)(PopupPicker);