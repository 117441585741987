import React from 'react';
import { TouchableWithoutFeedback, View, Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import { connect } from 'react-redux';

class BetButton extends React.Component {

    render() {
        const { id, activeButtonId, disabled, prediction, matchWinnerId, reactRem } = this.props;
        let btnStyle = [styles.btnContainer, {
            height: 6.5 * reactRem,
            width: 6.5 * reactRem,
            marginHorizontal: 0.2 * reactRem,
            borderRadius: 0.5 * reactRem
        }];
        let textStyle = [styles.textStyle, { fontSize: 2.2 * reactRem, paddingRight: 0.5 * reactRem }];

        if (activeButtonId === id) {
            btnStyle.push({
                backgroundColor: globalStyles.textBlue
            });
            textStyle.push({
                color: globalStyles.white,
            });
        }
        if (prediction) {
            btnStyle.push([styles.predictionBtn, { height: 4.5 * reactRem, width: 4.5 * reactRem }]);
            if (matchWinnerId != -1) {
                if (activeButtonId === id && id === matchWinnerId) {
                    btnStyle.push({
                        backgroundColor: globalStyles.green
                    });
                    textStyle.push({
                        color: globalStyles.white,
                    });
                }
                if (activeButtonId === id && id != matchWinnerId) {
                    btnStyle.push({
                        backgroundColor: globalStyles.red
                    });
                    textStyle.push({
                        color: globalStyles.white,
                    });
                }
            } else if (activeButtonId === id) {
                btnStyle.push({
                    backgroundColor: globalStyles.activeBlue
                });
                textStyle.push({
                    color: globalStyles.white,
                });
            }

        }
        if (matchWinnerId != null && matchWinnerId != -1 && id === matchWinnerId) {
            btnStyle.push(styles.borderRadiusContainer);
        }
        return (
            <TouchableWithoutFeedback onPress={() => !disabled && this.props.onPress()}>
                <View style={btnStyle}>
                    <Text style={textStyle}> {this.props.bet}</Text>
                </View>
            </TouchableWithoutFeedback>
        );
    }
}

const styles = EStyleSheet.create({
    btnContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: globalStyles.predictionButtonGrey,
        height: "6.5rem",
        width: "6.5rem",
        marginHorizontal: "0.2rem",
        borderRadius: "0.5rem",
    },
    predictionBtn: {
        height: "4.5rem",
        width: "4.5rem",
    },
    textStyle: {
        textAlign: "center",
        textAlignVertical: "center",
        alignItems: "center",
        fontFamily: globalStyles.fontUbuntuBold,
        fontSize: "2.2rem",
        paddingRight: "0.5rem",
        color: globalStyles.textBlue
    },
    borderRadiusContainer: {
        borderWidth: 1,
        borderColor: globalStyles.black,
    }
});

const mapStateToProps = state => {
    return {
        reactRem: state.homeScreenLeagues.reactRem,
    };
};

export default connect(mapStateToProps, null)(BetButton);