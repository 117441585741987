import { GET_STANDINGS_LEAGUE_DATA } from "./actionTypes";
import { authFetch } from "../../Common/store/actions/token";
import { uiStartLoading, uiStopLoading } from "../../Common/store/actions/ui";

export const getStandingsLeagueData = (leagueId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/leagues/" + leagueId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setStandingsLeagueData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setStandingsLeagueData = (data) => {
  return {
    type: GET_STANDINGS_LEAGUE_DATA,
    data,
  };
};
