import React from "react";
import { TextInput, View, Text, TouchableOpacity } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import globalStyles from "../../../globalStyles";
import { connect } from "react-redux";

class DefaultInput extends React.Component {
  state = {
    editable: false,
  };

  onEdit = () => {
    this.setState({ editable: !this.state.editable });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.settings &&
      this.state.editable &&
      prevProps.inputValue === this.props.inputValue
    ) {
      this.ref.focus();
    }
  }

  render() {
    const {
      style,
      inputValue,
      onChangeText,
      placeholderText,
      numeric,
      password,
      keyboardType,
      correctInput,
      error,
      settings,
      editable,
      firstField,
      reactRem,
    } = this.props;
    const inputBox = [styles.inputBox];
    const inputText = [
      styles.inputText,
      {
        outline: "none",
        border: "none",
        fontSize: 2.4 * reactRem,
        height: 4 * reactRem,
      },
    ];
    const textInputContainerStyle = [
      styles.textInputContainer,
      { paddingHorizontal: 1.5 * reactRem, height: 6 * reactRem },
    ];
    const inputTextContainerStyle = [styles.inputTextContainer];
    const settingsTextStyle = [
      styles.settingsText,
      { fontSize: 2.2 * reactRem },
    ];
    if (style) {
      inputBox.push(style);
    }
    if (firstField) {
      inputBox.push(styles.firstFieldStyle);
      inputBox.push({
        borderTopLeftRadius: 1.5 * reactRem,
        borderTopRightRadius: 1.5 * reactRem,
      });
    }

    if (inputValue != null && inputValue.length > 0) {
      inputBox.push({ backgroundColor: globalStyles.white });
      textInputContainerStyle.push({ width: "100%" });
    }
    if (settings) {
      textInputContainerStyle.push({ width: "100%" });
    } else {
      inputTextContainerStyle.push({ width: "100%" });
    }

    return (
      <View style={inputBox}>
        <View style={textInputContainerStyle}>
          <View style={inputTextContainerStyle}>
            <TextInput
              placeholderTextColor={globalStyles.textBlue}
              placeholder={placeholderText}
              value={inputValue}
              maxLength={numeric ? 4 : 45}
              onChangeText={onChangeText}
              keyboardType={keyboardType}
              secureTextEntry={password}
              ref={(input) => (this.ref = input)}
              style={inputText}
              onBlur={() => {
                this.onEdit();
              }}
              {...this.props}
              editable={editable ? editable : this.state.editable}
            />
          </View>
          {settings ? (
            <View style={styles.settingsContainer}>
              <TouchableOpacity onPress={() => this.onEdit()}>
                <View style={[styles.opacityContainer, { padding: reactRem }]}>
                  <Text style={settingsTextStyle}>{settings}</Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
        {correctInput ? (
          <View
            style={[
              styles.errorContainer,
              {
                paddingRight: 1.5 * reactRem,
                paddingLeft: 3 * reactRem,
              },
            ]}
          >
            <Text style={[styles.errorText, { fontSize: 2.4 * reactRem }]}>
              {error}
            </Text>
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  textInputContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: "1.5rem",
    height: "6rem",
  },
  inputTextContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "85%",
  },
  settingsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "15%",
  },
  opacityContainer: {
    padding: "1rem",
  },
  settingsText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
  },
  inputText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    width: "100%",
    fontSize: "2.4rem",
    height: "4rem",
  },
  inputBox: {
    borderBottomWidth: 1.5,
    borderBottomColor: globalStyles.backgroundGrey,
    backgroundColor: globalStyles.white,
    width: "100%",
    height: "10rem",
    justifyContent: "center",
    alignItems: "center",
  },
  firstFieldStyle: {
    borderBottomWidth: 1.5,
    borderBottomColor: globalStyles.backgroundGrey,
    backgroundColor: globalStyles.white,
    width: "100%",
    borderTopLeftRadius: "1.5rem",
    borderTopRightRadius: "1.5rem",
  },
  errorContainer: {
    width: "100%",
  },
  errorText: {
    color: globalStyles.error,
    fontFamily: globalStyles.fontUbuntuMedium,
    paddingBottom: "1.8rem",
    alignItems: "center",
  },
});
const mapStateToProps = (state, ownProps) => {
  return {
    reactRem: state.homeScreenLeagues.reactRem,
  };
};

export default connect(mapStateToProps, null)(DefaultInput);
