import {
  SELECT_ROOM,
  SELECT_LEAGUE,
  GET_SPECIAL_GAMES,
  GET_SPECIAL_GAMES_MATCHES,
  GET_SPECIAL_GAMES_ROOM_INFO,
  JOIN_SPECIAL_ROOM,
  DELETE_JOIN_SPECIAL_ROOM,
  SET_MY_SPECIAL_ROOM,
  SET_RUNNING_SPECIAL_ROOM,
  SET_UPCOMING_SPECIAL_ROOM,
  SET_HISTORY_SPECIAL_ROOM,
  SET_SPECIAL_ROOM,
  ROOM_MESSAGE,
  SET_PREDICTION_GAMES,
  JOIN_FROM_SPECIAL,
  SET_NAVIGATION,
} from "./actionTypes";
import { authFetch, authFetchNoToken } from "../../Common/store/actions/token";
import { uiStartLoading, uiStopLoading } from "../../Common/store/actions/ui";

export const setNavigation = (navigation) => {
  return {
    type: SET_NAVIGATION,
    data: navigation,
  };
};

export const selectRoom = (id) => {
  return {
    type: SELECT_ROOM,
    roomId: id,
  };
};

export const chooseLeague = (id) => {
  return {
    type: SELECT_LEAGUE,
    leagueId: id,
  };
};

export const getMySpecialRooms = (type) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/special?type=" + type + "&status=MY"))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setMySpecialRooms(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setMySpecialRooms = (data) => {
  return {
    type: SET_MY_SPECIAL_ROOM,
    data,
  };
};

export const getRunningSpecialRooms = (type) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/special?type=" + type + "&status=RUNNING"))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setRunningSpecialRooms(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setRunningSpecialRooms = (data) => {
  return {
    type: SET_RUNNING_SPECIAL_ROOM,
    data,
  };
};

export const getPredictionsForSpecialRoom = (roomId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/specialroom/predictions/" + roomId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setPredictionsForSpecialRoom(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setPredictionsForSpecialRoom = (data) => {
  return {
    type: SET_PREDICTION_GAMES,
    data,
  };
};

export const getUpcomingSpecialRooms = (type) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/special?type=" + type + "&status=UPCOMING"))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setUpcomingSpecialRooms(res.data));
        dispatch(uiStopLoading());
      });
  };
};
export const joinFromSpecial = (data) => {
  return {
    type: JOIN_FROM_SPECIAL,
    data,
  };
};

export const setUpcomingSpecialRooms = (data) => {
  return {
    type: SET_UPCOMING_SPECIAL_ROOM,
    data,
  };
};

export const getHitorySpecialRooms = (type) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/special?type=" + type + "&status=HISTORY"))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setHitorySpecialRooms(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setHitorySpecialRooms = (data) => {
  return {
    type: SET_HISTORY_SPECIAL_ROOM,
    data,
  };
};

export const getSpecialGamesMatches = (roomId) => {
  return (dispatch) => {
    dispatch(authFetch("/special/matches/" + roomId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setSpecialGamesMatches(res.data));
      });
  };
};

export const setSpecialGamesMatches = (data) => {
  return {
    type: GET_SPECIAL_GAMES_MATCHES,
    data,
  };
};

export const getSpecialGameRoomInfo = (roomId, isJoined) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/special/room/" + roomId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setSpecialGameRoomInfo(res.data, isJoined));
        dispatch(uiStopLoading());
      });
  };
};

export const setSpecialGameRoomInfo = (data, isJoined) => {
  return {
    type: GET_SPECIAL_GAMES_ROOM_INFO,
    data,
    isJoined,
  };
};

export const joinSpecialGame = (roomId) => {
  const data = { id: roomId };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/join/special", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        if (res.has_error) {
          dispatch(setJoinMessage(res.message));
        } else {
          dispatch(setJoinMessage(res.message));
          dispatch(setJoinSpecialRoom(res.data));
          dispatch(joinFromSpecial(null));
          dispatch(getSpecialGameRoomInfo(roomId, true));
        }
        dispatch(uiStopLoading());
      });
  };
};
export const setJoinMessage = (data) => {
  return {
    type: ROOM_MESSAGE,
    data,
  };
};

export const setJoinSpecialRoom = (data) => {
  return {
    type: JOIN_SPECIAL_ROOM,
    data,
  };
};

export const deleteJoinSpecialRoom = () => {
  return {
    type: DELETE_JOIN_SPECIAL_ROOM,
  };
};

export const getSelectedSpecialRoomData = (roomId, page) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(
      authFetch(
        "/users/specialroom/" +
          roomId +
          (page !== undefined ? "?page=" + page : "")
      )
    )
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setSelectedSpecialRoomData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setSelectedSpecialRoomData = (data) => {
  return {
    type: SET_SPECIAL_ROOM,
    data,
  };
};

export const getDataForLeague = (roomId, leagueId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/user/specialroom/" + roomId + "?league=" + leagueId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(uiStopLoading());
      });
  };
};
