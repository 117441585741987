import React from "react";
import {
  Text,
  View,
  StyleSheet,
  Animated,
  TouchableOpacity
} from "react-native";
import globalStyles from "../../../globalStyles";




class AnimationOpacity extends React.Component {

  state = {
    stateOpacity: new Animated.Value(1),
  };

  _start = () => {
    Animated.timing(this.state.stateOpacity, {
      toValue: 0,
      duration: 1400
    }).start();
  };



  _start2 = () => {
    Animated.timing(this.state.stateOpacity, {
      toValue: 1,
      duration: 1400
    }).start();

  };


  componentDidUpdate(prevProps) {
    if (this.props.offset != 0) {
      this._start();
    }

    if (prevProps.offset && prevProps.offset > 0 && this.props.offset === 0) {
      this._start2();
    }
  }


  render() {
    return (
      <Animated.Image
        source={this.props.image}
        style={[{
          opacity: this.state.stateOpacity,
        }, this.props.style]}
      >
        {this.props.children}
      </Animated.Image>
    );
  }
}

export default AnimationOpacity;