import React from "react";
import { View, Text } from "react-native";
import { specialGamesPrizeContainerStyles } from "../styles/styles";

class SpecialGamesPrizeContainer extends React.Component {
  render() {
    const { prize, index } = this.props;
    return (
      <View style={specialGamesPrizeContainerStyles.titleContainer}>
        <View style={specialGamesPrizeContainerStyles.leagueImagesContainer}>
          <Text style={specialGamesPrizeContainerStyles.title}>
            {index + 1}.
          </Text>
        </View>
        <View style={specialGamesPrizeContainerStyles.leagueTitleContainer}>
          <Text style={specialGamesPrizeContainerStyles.title}>{prize}</Text>
        </View>
      </View>
    );
  }
}

export default SpecialGamesPrizeContainer;
