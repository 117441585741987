import React from "react";
import { View, FlatList, Text, TouchableHighlight, TouchableOpacity } from "react-native";
import { whileStatement } from "@babel/types";
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles'
import CommentContainer from './CommentContainer';


class LivescoreContainer extends React.Component {
  render() {
    const { livescore, reactRem } = this.props;

    const livematchContainerStyle = [styles.livematchContainer, {
      borderBottomRightRadius: 1.5 * reactRem,
      borderBottomLeftRadius: 1.5 * reactRem,
      marginHorizontal: 0.5 * reactRem,
      paddingBottom: reactRem
    }];
    const liveMatchInfoContainerStyle = [styles.liveMatchInfoContainer, { marginTop: 2.5 * reactRem }];

    if (!livescore) {
      return null;
    }
    return (
      <View style={livematchContainerStyle}>
        <View style={liveMatchInfoContainerStyle}>
          {livescore.liveComments ?
            <FlatList
              reactRem={reactRem}
              style={{ width: "100%" }}
              data={livescore.liveComments}
              keyExtractor={(item, index) => 'key' + index}
              renderItem={({ item }) => (
                <CommentContainer reactRem={reactRem} {...item} />
              )} /> : null}
        </View>
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  livematchContainer: {
    flex: 1,
    backgroundColor: globalStyles.white,
    justifyContent: "center",
    alignItems: "center",
    borderBottomRightRadius: '1.5rem',
    borderBottomLeftRadius: '1.5rem',
    marginHorizontal: "0.5rem",
    paddingBottom: "1rem"
  },
  liveMatchInfoContainer: {
    flex: 3,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2.5rem"
  },

});


export default LivescoreContainer