import {
  SELECT_ROOM,
  SELECT_LEAGUE,
  SET_ROOM_PREDICTION_DATA,
  SET_PREDICTION_BY_WEEK_DATA,
  PUT_BETS_FOR_LEAGUE_BY_WEEK_GAME,
  SET_MOBILE_LEAGUE_PREDICTIONS_BY_WEEK,
  SET_LEAGUE_PREDICTIONS_BY_WEEK,
  ADD_NEW_LEAGUE,
  DELETE_PREDICTION,
  GET_SPECIAL_GAME_DATA,
  SET_MY_SPECIAL_GAMES,
  CHANGE_IS_THERE_BETS,
  SET_ERROR_MESSAGE,
  THE_LAST_GAME_WEEK,
  SET_MOBILE_PREDICTION_DATA,
  SET_SPECIAL_PREDICTION_BY_ROUND,
} from "./actionTypes";
import { authFetch, authFetchNoToken } from "../../Common/store/actions/token";
import { uiStartLoading, uiStopLoading } from "../../Common/store/actions/ui";

export const selectRoom = (id) => {
  return {
    type: SELECT_ROOM,
    roomId: id,
  };
};

export const setLastGameWeek = (leagueId, isMobile) => {
  return {
    type: THE_LAST_GAME_WEEK,
    leagueId: leagueId,
    isMobile: isMobile,
  };
};

export const chooseLeague = (id) => {
  return {
    type: SELECT_LEAGUE,
    leagueId: id,
  };
};

export const getRoomPredictionData = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/predict/web"))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setRoomPredictionData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getMobileRoomPredictionData = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/predict"))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setMobileRoomPredictionData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getRoomPredictionDataNoToken = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetchNoToken("/predict/web"))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setRoomPredictionData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getMobileRoomPredictionDataNoToken = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetchNoToken("/predict"))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setMobileRoomPredictionData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setRoomPredictionData = (data) => {
  return {
    type: SET_ROOM_PREDICTION_DATA,
    data,
  };
};

export const setMobileRoomPredictionData = (data) => {
  return {
    type: SET_MOBILE_PREDICTION_DATA,
    data,
  };
};

export const getPredictionByLeagueData = (leagueId, gameWeek) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/predictions/" + leagueId + "?game_week=" + gameWeek))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setPredictionByLeagueData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getPredictionByLeagueDataNoToken = (leagueId, gameWeek) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(
      authFetchNoToken("/predictions/" + leagueId + "?game_week=" + gameWeek)
    )
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setPredictionByLeagueData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setPredictionByLeagueData = (data) => {
  return {
    type: SET_PREDICTION_BY_WEEK_DATA,
    data,
  };
};

export const putBetsForLeagueByGameWeek = (leagueId, gameWeek, predictions) => {
  const data = {
    leagueId: leagueId,
    gameWeek: gameWeek,
    predictions: predictions,
  };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/predictions", "PUT", data))
      .catch((err) => console.log(err))
      .then((res) => {
        if (res.has_error) {
          dispatch(setErrorMessage(res.message));
          dispatch(getRoomPredictionData());
          dispatch(uiStopLoading());
        } else {
          dispatch(setPredictionByLeagueData(predictions));
          dispatch(getRoomPredictionData());
          dispatch(uiStopLoading());
        }
      });
  };
};

export const setErrorMessage = (message) => {
  return {
    type: SET_ERROR_MESSAGE,
    message,
  };
};

export const getLeaguePredictionsByGameWeekData = (
  leagueId,
  gameWeek,
  mobile
) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/predict/" + leagueId + "?game_week=" + gameWeek))
      .catch((err) => console.log(err))
      .then((res) => {
        if (mobile) {
          dispatch(setMobileLeaguePredictionsByGameWeekData(res.data));
        } else {
          dispatch(setLeaguePredictionsByGameWeekData(res.data));
        }
        dispatch(uiStopLoading());
      });
  };
};
export const setMobileLeaguePredictionsByGameWeekData = (data) => {
  return {
    type: SET_MOBILE_LEAGUE_PREDICTIONS_BY_WEEK,
    data,
  };
};

export const setLeaguePredictionsByGameWeekData = (data) => {
  return {
    type: SET_LEAGUE_PREDICTIONS_BY_WEEK,
    data,
  };
};

export const addNewLeague = (choosenLeagues) => {
  const data = { leagues: choosenLeagues };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/users/league", "PUT", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setAddLeagueFlag(res.message));
        dispatch(uiStopLoading());
      });
  };
};

export const setAddLeagueFlag = (data) => {
  return {
    type: ADD_NEW_LEAGUE,
    data,
  };
};

export const deletePredictions = () => {
  return {
    type: DELETE_PREDICTION,
  };
};

export const getSpecialGamePredictionData = (roomId, roundId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/prediction/" + roomId + "?round_id=" + roundId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setSpecialGamePredictionData(res.data, false));
        dispatch(uiStopLoading());
      });
  };
};

export const setSpecialGamePredictionData = (data, prediction) => {
  return {
    type: GET_SPECIAL_GAME_DATA,
    data,
    prediction,
  };
};

export const onChangeIsThereBets = () => {
  return {
    type: CHANGE_IS_THERE_BETS,
  };
};

export const useMyPredictions = (roomId, leagues) => {
  const data = { id: roomId, leagues: leagues };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/usepredictions", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setSpecialGamePredictionData(res.data, true));
        dispatch(uiStopLoading());
      });
  };
};

export const getMySpecialGames = () => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/myspecial"))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setMySpecialGames(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setMySpecialGames = (data) => {
  return {
    type: SET_MY_SPECIAL_GAMES,
    data,
  };
};

export const putBetsForSpecialGame = (roomId, leagues) => {
  const data = { id: roomId, leagues: leagues };
  return (dispatch) => {
    dispatch(authFetch("/special/predictions", "PUT", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(getSpecialGamePredictionData(roomId));
        dispatch(getMySpecialGames());
      });
  };
};

export const getSpecialPredictionByLeagueData = (roomId, round) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/special/room/" + roomId + "/round/" + round))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setSpecialLeaguePredictionsByRound(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setSpecialLeaguePredictionsByRound = (data) => {
  return {
    type: SET_SPECIAL_PREDICTION_BY_ROUND,
    data,
  };
};
