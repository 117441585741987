import React from "react";
import { View, Image, Text, TouchableHighlight } from "react-native";
import { whileStatement } from "@babel/types";
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles'
import MoreButton from './ConfirmButton';
import TeamStatsField from '../UI/TeamStatsField';


class TeamStatsContainer extends React.Component {

    render() {
        const { standings, leagueId, homeTeamImg, guestTeamImg, reactRem } = this.props;
        let topTeamPosition = null;
        let topTeamName = null;
        let topTeamMatchPlayed = null;
        let topTeamGoalDifference = null;
        let topTeamPoints = null;
        let bottomTeamPosition = null;
        let bottomTeamName = null;
        let bottomTeamMatchPlayed = null;
        let bottomTeamGoalDifference = null;
        let bottomTeamPoints = null;
        let topTeamImg = null;
        let bottomTeamImg = null;

        if (parseInt(standings.homeTeam.position) < parseInt(standings.guestTeam.position)) {
            topTeamPosition = standings.homeTeam.position;
            topTeamName = standings.homeTeam.name;
            topTeamMatchPlayed = standings.homeTeam.matchPlayed;
            topTeamGoalDifference = standings.homeTeam.goalDifference;
            topTeamPoints = standings.homeTeam.points;
            topTeamImg = homeTeamImg;
            bottomTeamPosition = standings.guestTeam.position;
            bottomTeamName = standings.guestTeam.name;
            bottomTeamMatchPlayed = standings.guestTeam.matchPlayed;
            bottomTeamGoalDifference = standings.guestTeam.goalDiffenrece;
            bottomTeamPoints = standings.guestTeam.points;
            bottomTeamImg = guestTeamImg;
        }
        else {
            topTeamPosition = standings.guestTeam.position;
            topTeamName = standings.guestTeam.name;
            topTeamMatchPlayed = standings.guestTeam.matchPlayed;
            topTeamGoalDifference = standings.guestTeam.goalDiffenrece;
            topTeamPoints = standings.guestTeam.points;
            topTeamImg = guestTeamImg;
            bottomTeamPosition = standings.homeTeam.position;
            bottomTeamName = standings.homeTeam.name;
            bottomTeamMatchPlayed = standings.homeTeam.matchPlayed;
            bottomTeamGoalDifference = standings.homeTeam.goalDifference;
            bottomTeamPoints = standings.homeTeam.points;
            bottomTeamImg = homeTeamImg;
        }
        return (
            <View style={[styles.container, { marginTop: 3 * reactRem }]}>
                <View style={[styles.roomStatsContainer, { paddingLeft: 1.5 * reactRem, paddingRight: 1.5 * reactRem }]}>
                    <View style={[styles.standingTextContainer, { paddingLeft: reactRem, marginBottom: reactRem }]}>
                        <View styles={{ width: "60%" }}>
                            <Text style={[styles.standingText, { fontSize: 2.6 * reactRem }]}>STANDINGS</Text>
                        </View>
                        <View style={styles.statsContainer}>
                            <Text style={[styles.matchPlayedText, { fontSize: 2 * reactRem, marginRight: 2 * reactRem }]}>M</Text>
                            <Text style={[styles.pointsText, { fontSize: 2 * reactRem, marginRight: 1.5 * reactRem }]}>Pts</Text>
                        </View>
                    </View>
                    <TeamStatsField
                        firstClub={true}
                        position={topTeamPosition}
                        name={topTeamName}
                        matchPlayed={topTeamMatchPlayed}
                        goalDifference={topTeamGoalDifference}
                        points={topTeamPoints}
                        reactRem={reactRem}
                        clubImg={topTeamImg} />
                    <TeamStatsField
                        reactRem={reactRem}
                        position={bottomTeamPosition}
                        name={bottomTeamName}
                        matchPlayed={bottomTeamMatchPlayed}
                        goalDifference={bottomTeamGoalDifference}
                        points={bottomTeamPoints}
                        clubImg={bottomTeamImg} />
                    <View style={[styles.moreButtonContainer, { marginTop: 1.5 * reactRem }]}>
                        <MoreButton title="SEE ALL" onPress={() =>
                            this.props.navigation.navigate('Standings', { leagueId: leagueId })} />
                    </View>
                </View>
            </View>
        );
    }
}


const styles = EStyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: "3rem"
    },
    statsContainer: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row',
        width: "40%"
    },
    matchPlayedText: {
        color: globalStyles.black,
        fontFamily: globalStyles.fontUbuntuRegular,
        fontSize: "2rem",
        marginRight: "2rem"
    },
    pointsText: {
        color: globalStyles.black,
        fontFamily: globalStyles.fontUbuntuRegular,
        fontSize: "2rem",
        marginRight: "1.5rem"
    },
    roomStatsContainer: {
        width: "100%",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem"
    },
    standingTextContainer: {
        justifyContent: "space-between",
        flexDirection: "row",
        width: "100%",
        paddingLeft: "1rem",
        marginBottom: "1rem",
    },
    standingText: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuBold,
        fontSize: '2.6rem'
    },
    moreButtonContainer: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
        marginTop: "1.5rem",
        width: "100%"
    }
});


export default TeamStatsContainer