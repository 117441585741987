import React from "react";
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  Share,
  ScrollView,
  TextInput,
} from "react-native";
import { connect } from "react-redux";
import globalStyles from "../../globalStyles";
import DotsImg from "../../assets/dots_blue.png";
import CompareImg from "../../assets/compare.png";
import ClearImg from "../../assets/clear.png";
import defaultUserImage from "../../assets/profile_avatar.png";
import AnimationTeamContainer from "../../Common/components/UI/AnimationTeamContainer";
import AnimationOpacity from "../../Common/components/UI/AnimationOpacity";
import AnimationOpacityText from "../../Common/components/UI/AnimationOpacityText";
import { animationRoomLeagueTitleStyles } from "../styles/styles";

class AnimationRoomLeagueTitleContainer extends React.Component {
  state = {
    searchTerm: "",
    search: false,
    query: "",
    filteredData: [],
  };

  onSearch = () => {
    if (!this.state.search && !this.props.userSelected) {
      this.setState({ search: true });
    }
    if (this.state.search && !this.props.userSelected) {
      this.setState({ search: false });
    }
    if (this.props.userSelected) {
      this.props.onSelectUser(null);
      this.setState({ search: false });
    }
  };

  onClearSearch = () => {
    this.searchUpdated("");
  };
  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  onShare = async (roomName, roomCode) => {
    try {
      const result = await Share.share({
        message:
          "Join me in Glowter!\nYou have been invited in room: " +
          roomName +
          "\nwith room code: " +
          roomCode,
      });

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message);
    }
  };

  onPressButton = () => {
    if (this.state.search) {
      this.onSearch();
    }
  };

  handleInputChange = (val) => {
    this.setState({ searchTerm: val });
  };

  render() {
    const {
      leaguesInRoom,
      roomName,
      roomNameType,
      predictions,
      peopleRankingInRoom,
      userSelected,
      user,
    } = this.props;

    const { search, searchTerm } = this.state;
    const filteredEmails =
      searchTerm === ""
        ? []
        : peopleRankingInRoom.filter(
            (p) =>
              p.playerName.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1 ||
              (p.user_name != null
                ? p.user_name
                    .toLowerCase()
                    .indexOf(searchTerm.toLowerCase()) !== -1
                : null)
          );

    const selectedUserTextStyle = [
      animationRoomLeagueTitleStyles.selectedUserText,
    ];
    const userTextStyle = [animationRoomLeagueTitleStyles.selectedUserText];

    if (userSelected && Number(userSelected.lastRoundPoints) > 0) {
      selectedUserTextStyle.push({ color: globalStyles.green });
    } else if (userSelected && Number(userSelected.lastRoundPoints) < 0) {
      selectedUserTextStyle.push({ color: globalStyles.red });
    }
    if (user && Number(user.lastRoundPoints) > 0) {
      userTextStyle.push({ color: globalStyles.green });
    } else if (user && Number(user.lastRoundPoints) < 0) {
      userTextStyle.push({ color: globalStyles.red });
    }

    return (
      <AnimationTeamContainer
        offset={this.props.offset}
        style={animationRoomLeagueTitleStyles.titleContainer}
        defaultHeight={
          roomNameType === "Private" || roomNameType === "Special Game"
            ? 120
            : 80
        }
        heightTo={40}
      >
        <View style={animationRoomLeagueTitleStyles.mainContainer}>
          <View style={animationRoomLeagueTitleStyles.titleDontsContainer}>
            <View style={animationRoomLeagueTitleStyles.titleTextContainer}>
              <Text style={animationRoomLeagueTitleStyles.title}>
                {roomName}
              </Text>
            </View>
            {predictions ? (
              <TouchableOpacity
                style={animationRoomLeagueTitleStyles.dotsContainer}
                onPress={() => this.onSearch()}
              >
                <Image
                  source={userSelected ? ClearImg : CompareImg}
                  style={animationRoomLeagueTitleStyles.compareImg}
                />
                <Text style={animationRoomLeagueTitleStyles.compareText}>
                  {userSelected ? "Clear" : "Compare"}
                </Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={animationRoomLeagueTitleStyles.dotsContainer}
                onPress={() => this.props.onOpenMenu()}
              >
                <Image
                  source={DotsImg}
                  style={animationRoomLeagueTitleStyles.dotsImg}
                />
              </TouchableOpacity>
            )}
          </View>
          <AnimationOpacityText
            offset={this.props.offset}
            style={animationRoomLeagueTitleStyles.roomTypeText}
            text={roomNameType}
            defaultSize={12}
            sizeTo={0}
          />
          {predictions ? null : (
            <View>
              {roomNameType === "Private" || roomNameType === "Special Game" ? (
                <AnimationTeamContainer
                  offset={this.props.offset}
                  style={animationRoomLeagueTitleStyles.leagueImagesContainer}
                  defaultHeight={40}
                  heightTo={0}
                >
                  {leaguesInRoom
                    ? leaguesInRoom.map((room, i) => (
                        <AnimationOpacity
                          offset={this.props.offset}
                          image={{
                            uri: globalStyles.BASE_URL + room.leagueImg,
                          }}
                          key={i}
                          style={animationRoomLeagueTitleStyles.leagueIcon}
                        />
                      ))
                    : null}
                </AnimationTeamContainer>
              ) : null}
            </View>
          )}
        </View>
        {search && !userSelected && predictions ? (
          <View style={animationRoomLeagueTitleStyles.searchBoxContainer}>
            <View
              style={animationRoomLeagueTitleStyles.searchBoxAndButtonContainer}
            >
              <View style={animationRoomLeagueTitleStyles.searchContainer}>
                <View
                  style={animationRoomLeagueTitleStyles.searchImageContainer}
                >
                  <Image
                    source={CompareImg}
                    style={animationRoomLeagueTitleStyles.searchImg}
                  />
                </View>
                <View
                  style={animationRoomLeagueTitleStyles.searchInputContainer}
                >
                  <TextInput
                    style={animationRoomLeagueTitleStyles.searchInput}
                    placeholder="Type a name to search"
                    value={this.state.searchTerm}
                    onChangeText={this.handleInputChange}
                  />
                </View>
              </View>
              <TouchableOpacity
                style={animationRoomLeagueTitleStyles.cancelContainer}
                onPress={() => this.onPressButton()}
              >
                <Text style={animationRoomLeagueTitleStyles.cancelText}>
                  Cancel
                </Text>
              </TouchableOpacity>
            </View>
            <ScrollView>
              {filteredEmails.map((person, index) => {
                return (
                  <TouchableOpacity
                    style={animationRoomLeagueTitleStyles.playerNameContainer}
                    onPress={() => this.props.onSelectUser(person)}
                    key={person.id}
                    style={animationRoomLeagueTitleStyles.emailItem}
                  >
                    <Image
                      source={
                        person.image
                          ? { uri: globalStyles.BASE_URL + person.image }
                          : defaultUserImage
                      }
                      style={animationRoomLeagueTitleStyles.profileImage}
                    />
                    <View>
                      <Text
                        style={animationRoomLeagueTitleStyles.betPlayerUsername}
                      >
                        {person.user_name}
                      </Text>
                      <Text
                        style={animationRoomLeagueTitleStyles.betPlayerText}
                      >
                        {person.playerName}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </ScrollView>
          </View>
        ) : null}
        {userSelected && predictions ? (
          <View style={animationRoomLeagueTitleStyles.userCompareContainer}>
            <View style={animationRoomLeagueTitleStyles.selectedUserContainer}>
              <View
                style={
                  animationRoomLeagueTitleStyles.playerNameContainerSelected
                }
              >
                <Image
                  source={
                    userSelected.image
                      ? { uri: globalStyles.BASE_URL + userSelected.image }
                      : defaultUserImage
                  }
                  style={animationRoomLeagueTitleStyles.profileImage}
                />
                <View>
                  <Text
                    style={animationRoomLeagueTitleStyles.betPlayerUsername}
                  >
                    {userSelected.user_name}
                  </Text>
                  <Text style={animationRoomLeagueTitleStyles.betPlayerText}>
                    {userSelected.playerName}
                  </Text>
                </View>
              </View>
              <View style={animationRoomLeagueTitleStyles.roundPointsContainer}>
                <View style={animationRoomLeagueTitleStyles.profileImage} />
                <Text style={selectedUserTextStyle}>
                  {Number(userSelected.lastRoundPoints) > 0
                    ? "+" + userSelected.lastRoundPoints
                    : userSelected.lastRoundPoints}{" "}
                  Pts.
                </Text>
              </View>
            </View>
            <View style={animationRoomLeagueTitleStyles.vsContainer}>
              <Text style={animationRoomLeagueTitleStyles.vsText}>VS</Text>
            </View>
            <View
              style={[
                animationRoomLeagueTitleStyles.selectedUserContainer,
                { justifyContent: "flex-end" },
              ]}
            >
              <View
                style={
                  animationRoomLeagueTitleStyles.playerNameContainerSelected
                }
              >
                <View>
                  <Text
                    style={[
                      animationRoomLeagueTitleStyles.betPlayerUsername,
                      { textAlign: "right" },
                    ]}
                  >
                    {user.user_name}
                  </Text>
                  <Text
                    style={[
                      animationRoomLeagueTitleStyles.betPlayerText,
                      { textAlign: "right" },
                    ]}
                  >
                    {user.playerName}
                  </Text>
                </View>
                <Image
                  source={
                    user.image
                      ? { uri: globalStyles.BASE_URL + user.image }
                      : defaultUserImage
                  }
                  style={animationRoomLeagueTitleStyles.profileImage}
                />
              </View>
              <View
                style={[
                  animationRoomLeagueTitleStyles.roundPointsContainer,
                  { justifyContent: "flex-end" },
                ]}
              >
                <Text style={userTextStyle}>
                  {Number(user.lastRoundPoints) > 0
                    ? "+" + user.lastRoundPoints
                    : user.lastRoundPoints}{" "}
                  Pts.
                </Text>
                <View style={animationRoomLeagueTitleStyles.profileImage} />
              </View>
            </View>
          </View>
        ) : null}
      </AnimationTeamContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    peopleRankingInRoom: state.roomLeague.peopleRankingInRoom,
  };
};
export default connect(
  mapStateToProps,
  null
)(AnimationRoomLeagueTitleContainer);
