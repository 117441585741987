import React from 'react';
import { View, TouchableWithoutFeedback, ScrollView, TouchableOpacity, Text, Image, Linking } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet'
import globalStyles from '../../globalStyles';
import { connect } from 'react-redux';
import BackArrow from '../../assets/back_button_arrow.png'
import ForwardArrow from '../../assets/forward_button_arrow.png'
import ImageInterval from '../components/UI/ImageInterval';
import { createElement } from "react-native-web";


class LeagueAd extends React.Component {
    constructor(props) {
        super(props);
        this.imageInterval = new ImageInterval();
    }

    state = {
        imageIndex: 0,
    }

    componentDidMount() {
        const { banner } = this.props;
        if (banner.length != 0) {
            if (banner[0].show_after) {
                this.imageInterval.addListeners(this.props.navigation, this.changeImage, banner[0].show_after);
            } else {
                this.imageInterval.addListeners(this.props.navigation, this.changeImage, 10);
            }
        }
    }

    componentDidUpdate(prevState) {
        const { banner } = this.props;
        if (banner && banner.length > 0 && prevState.imageIndex != this.state.imageIndex) {
            this.imageInterval.clearListeners();
            if (banner[this.state.imageIndex].show_after) {
                this.imageInterval.addListeners(this.props.navigation, this.changeImage, banner[this.state.imageIndex].show_after);
            } else {
                this.imageInterval.addListeners(this.props.navigation, this.changeImage, 10);
            }
        }
    }

    componentWillUnmount() {
        if (this.props.banner.length != 1) {
            this.imageInterval.clearListeners();
        }
    }

    onOpen = (link) => {
        // Linking.openURL(link);
        window.open(link, "_blank")
    }

    changeImage = () => {
        const { banner } = this.props;
        const { imageIndex } = this.state;
        if (imageIndex + 1 === banner.length) {
            this.setState({ imageIndex: 0 });
        }
        else if (imageIndex - 1 < banner.length) {
            this.setState({ imageIndex: imageIndex + 1 });
        }
    }

    onBuffer = data => {
    }
    videoError = data => {
    }

    onNextImage = () => {
        const { banner } = this.props;
        const { imageIndex } = this.state;

        if (banner.length > 1) {
            if (imageIndex + 1 === banner.length) {
                this.setState({ imageIndex: 0 })
            } else {
                this.setState({ imageIndex: imageIndex + 1 })
            }
        }
    }

    onPreviousImage = () => {
        const { banner } = this.props;
        const { imageIndex } = this.state;
        if (banner.length > 1) {
            if (imageIndex - 1 === -1) {
                this.setState({ imageIndex: banner.length - 1 })
            } else {
                this.setState({ imageIndex: imageIndex - 1 })
            }
        }
    }

    createVideo(attrs) {
        return createElement("video", attrs);
    }

    render() {
        const { banner, futureMatch, towerRight, towerLeft, reactRem, background } = this.props;
        const { imageIndex } = this.state;
        let video = false;
        let imageToShow;
        let attrs;
        if (banner.length === 1) {
            if (banner[0].video) {
                video = true;
                imageToShow = { uri: globalStyles.BASE_URL + banner[0].video.url };
                attrs = {
                    src: imageToShow.uri,
                    autoplay: "autoplay",
                    loop: "loop",
                    width: "100%",
                    height: 50 * reactRem,
                };
            } else if (banner[0].image) {
                video = false;
                imageToShow = { uri: globalStyles.BASE_URL + banner[0].image.url }
            }
        } else if (banner[imageIndex] && banner[imageIndex].image) {
            video = false;
            imageToShow = { uri: globalStyles.BASE_URL + banner[imageIndex].image.url };
        } else if (banner[imageIndex] && banner[imageIndex].video) {
            video = true;
            attrs = {
                src: imageToShow.uri,
                autoplay: "autoplay",
                loop: "loop",
                width: "100%",
                height: 50 * reactRem,
            };
            imageToShow = { uri: globalStyles.BASE_URL + banner[imageIndex].video.url };
        }


        const bannerContainerStyle = [styles.bannerContainer]
        const bannerImageStyle = [styles.bannerImage]
        const bannerVideoStyle = [styles.bannerVideo]
        if (futureMatch) {
            bannerContainerStyle.push({ position: "absolute", zIndex: 0, });
            bannerImageStyle.push({ position: "absolute", zIndex: 0, });
            bannerVideoStyle.push({ position: "absolute", zIndex: 0 });
        }

        const containerStyle = [styles.container, { height: 30 * reactRem }];
        const towerImageStyle = [{ height: "100vh", width: "12%", position: "fixed", zIndex: 1, }];


        if (video) {
            containerStyle.push({ height: 60 * reactRem });
        } else {
            containerStyle.push({ height: 30 * reactRem });
        }

        if (background) {
            containerStyle.push({ marginTop: 0 });
        } else {
            containerStyle.push({ marginTop: 2 * reactRem });
        }
        if (banner && banner.length === 0) {
            containerStyle.push({ width: 0, height: 0 });
        }

        if (towerRight) {
            containerStyle.push({ height: "100vh", width: "12%", position: "fixed", zIndex: 1, left: "88%", marginTop: 0 })
            towerImageStyle.push({ left: "88%" })
        }
        if (towerLeft) {
            containerStyle.push({ height: "100vh", width: "12%", position: "fixed", zIndex: 1, right: "88%", marginTop: 0 })
            towerImageStyle.push({ right: "88%" })
        }

        const backArrowContainerStyle = [styles.backArrowContainer, { height: 6 * reactRem, paddingLeft: 2 * reactRem }];
        const forwardArrowContainerStyle = [styles.forwardArrowContainer, { height: 6 * reactRem, paddingRight: 2 * reactRem }]
        const arrowImageStyle = [styles.arrowImage, { height: 4 * reactRem, width: 4 * reactRem }];
        return (
            <View style={containerStyle}>
                {futureMatch ? <TouchableOpacity onPress={() => this.onPreviousImage()} style={{ zIndex: 1 }}>
                    <View style={backArrowContainerStyle}>
                        <Image source={BackArrow} style={arrowImageStyle} />
                    </View>
                </TouchableOpacity> : null}
                <TouchableWithoutFeedback style={bannerContainerStyle} onPress={() => this.onOpen(banner[imageIndex].link_url)}>
                    {video ? <View style={{ width: "100%", height: "60%", justifyContent: 'center', alignItems: 'center', marginTop: 10 * reactRem, paddingBottom: 10 * reactRem }}>{this.createVideo(attrs)}</View> :
                        <Image source={imageToShow} style={towerLeft || towerRight ? towerImageStyle : bannerImageStyle} />}
                </TouchableWithoutFeedback>
                {futureMatch ? <TouchableOpacity onPress={() => this.onNextImage()} style={{ zIndex: 1 }}>
                    <View style={forwardArrowContainerStyle}>
                        <Image source={ForwardArrow} style={arrowImageStyle} />
                    </View>
                </TouchableOpacity> : null}
            </View>
        );
    }
}

const styles = EStyleSheet.create({
    container: {
        width: "100%",
        height: "20rem",
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: "row",
    },
    bannerContainer: {
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    bannerImage: {
        resizeMode: "contain",
        width: "100%",
        height: "100%",
    },
    bannerVideo: {
        width: "100%",
        height: "100%",
        justifyContent: 'center',
        alignItems: 'center',

    },
    backArrowContainer: {
        zIndex: 1,
        justifyContent: "center",
        width: "29%",
        maxWidth: "29%",
        height: "6rem",
        paddingLeft: "2rem",
    },
    forwardArrowContainer: {
        zIndex: 1,
        justifyContent: "center",
        width: "29%",
        maxWidth: "29%",
        height: "6rem",
        paddingRight: "2rem",
    },
    arrowImage: {
        height: "4rem",
        width: "4rem",
        resizeMode: "contain"
    },
});


const mapStateToProps = state => {
    return {
        playersBetedHome: state.homeScreenLeagues.playersBeted,
        token: state.auth.token,
        userId: state.auth.userId,
        reactRem: state.homeScreenLeagues.reactRem,
    };
};


const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueAd);