import EStyleSheet from "react-native-extended-stylesheet";
import globalStyles from "../../globalStyles";

const chooseLeaguesStyles = EStyleSheet.create({
  container: {
    width: "50%",
  },
  scrollViewStyle: {
    paddingTop: 18,
  },
  errorMessageContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 12,
  },
  errorMessage: {
    color: globalStyles.error,
    fontFamily: globalStyles.fontRobotoBold,
    fontSize: 18,
  },
});

const forgotPasswordStyles = EStyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  resetPasswordTitleContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 12,
  },
  resetPasswordTitleText: {
    color: globalStyles.black,
    fontFamily: globalStyles.fontRobotoBold,
    fontSize: 24,
  },
  resetPasswordTextContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 36,
  },
  resetPasswordText: {
    color: globalStyles.black,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlign: "center",
    fontSize: 12,
  },
  errorMessage: {
    color: globalStyles.error,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 12,
    paddingBottom: 12,
    paddingTop: -6,
  },
  componentContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 6,
  },
  emailInput: {
    width: "90%",
    borderRadius: 10,
    marginBottom: 12,
  },
});

const loginStyles = EStyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.backgroundGrey,
    paddingTop: 30,
  },
  buttonContainer: {
    padding: 6,
  },
  activeButtonContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  },
  signInRegisterContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 12,
  },
  activeText: {
    color: globalStyles.activeBlue,
    fontSize: 23,
    fontFamily: globalStyles.fontRobotoBold,
  },
  inactiveText: {
    color: globalStyles.black,
    fontSize: 23,
    fontFamily: globalStyles.fontRobotoBold,
  },
  borderContainer: {
    backgroundColor: globalStyles.black,
    height: 42,
    fontSize: 21,
    width: 1.5,
    marginHorizontal: 12,
  },
  inputFieldsContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingVertical: 18,
  },
  firstField: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  lastField: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  forgotPasswordContainer: {
    width: "90%",
    paddingTop: 6,
  },
  forgotPasswordText: {
    color: globalStyles.black,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
  },
  defaultText: {
    color: globalStyles.black,
    fontSize: 18,
    fontFamily: globalStyles.fontRobotoBold,
  },
  defaultTextContainer: {
    width: "100%",
    alignItems: "center",
    padding: 12,
    justifyContent: "center",
  },
  errorContainer: {
    width: "100%",
    marginTop: -18,
    paddingLeft: 18,
    paddingVertical: 4,
  },
  errorMessage: {
    color: globalStyles.error,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
  },
  scrollViewStyle: {
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  tremNConditionsContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 18,
    paddingBottom: 60,
  },
  termNConditionsTextContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 6,
  },
  termNConditionsText: {
    color: globalStyles.black,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 16,
  },
  termNConditionsBoldText: {
    color: globalStyles.black,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 16,
  },
  tickContainer: {
    borderWidth: 1,
    borderColor: globalStyles.black,
    padding: 6,
    marginRight: 12,
    height: 18,
    width: 18,
    borderRadius: 3,
    justifyContent: "center",
    alignItems: "center",
  },
  tickImg: {
    height: 12,
    width: 12,
    resizeMode: "contain",
  },
  confirmButtonContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: 12,
    width: "100%",
  },
  nextStepButton: {
    marginBottom: 6,
  },
  borderBottomContainer: {
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.black,
  },
  fbButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: globalStyles.fbColor,
    padding: 12,
    width: "100%",
    borderRadius: 10,
  },
  fbImage: {
    height: 25,
    width: 25,
    marginRight: 10,
    resizeMode: "contain",
  },
  fbText: {
    fontSize: 16,
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    textAlign: "center",
  },
  miniContainer: {
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
  },
});

const registrationScreenStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
  },
  scrollViewStyle: {
    width: "100%",
    paddingHorizontal: 6,
    paddingTop: 18,
    paddingBottom: 30,
  },
  backgroundContainer: {
    backgroundColor: globalStyles.white,
    borderRadius: 3,
    padding: 12,
    justifyContent: "space-between",
    alignItems: "center",
  },
  facebookbtnContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 12,
    width: "100%",
    height: 72,
  },
  fieldsContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 6,
    borderBottomWidth: 0.5,
    borderBottomColor: globalStyles.basicBlueGreyColor,
    width: "100%",
    height: 360,
  },
  dateOfBirthTextContainer: {
    width: "100%",
    paddingLeft: 12,
  },
  dateOfBirthText: {
    color: globalStyles.borderRoom,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
  },
  dateOfBirthContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  tremNConditionsContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    paddingVertical: 6,
  },
  termNConditionsText: {
    color: globalStyles.darkBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
  },
  tickContainer: {
    backgroundColor: globalStyles.basicBlueGreyColor,
    padding: 6,
    marginRight: 12,
    height: 25,
    width: 25,
  },
  tickImg: {
    height: 12,
    width: 12,
    resizeMode: "contain",
  },
  confirmButtonContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: 12,
    width: "100%",
  },
});

const sessionSplashScreenStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.backgroundGrey,
  },
});

const tutorialScreenStyles = EStyleSheet.create({
  container: {
    width: "100%",
    flex: 1,
    alignItems: "center",
    backgroundColor: globalStyles.backgroundGrey,
  },
  tutorailContainer: {
    flex: 1,
    zIndex: 0,
    top: 150,
    left: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  image: {
    width: "100%",
    height: "75%",
    resizeMode: "contain",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "30%",
  },
  activeText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 30,
    opacity: 1,
  },
  inactiveText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 12,
    opacity: 0.7,
  },
  textButtonContainer: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 12,
    height: 30,
  },
  tutorialContainer: {
    alignItems: "center",
    width: "100%",
    height: "20%",
    zIndex: 1,
    backgroundColor: globalStyles.white,
    paddingVertical: 12,
  },
  tutorialDescriptionContainer: {
    alignItems: "center",
    width: "100%",
    paddingBottom: 6,
  },
  tutorialTitleText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 25,
  },
  tutorialDescriptionText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
    textAlign: "center",
  },
  turorialDescriptionContainer: {
    alignItems: "center",
    width: "100%",
    paddingHorizontal: 60,
    height: 25,
  },
  skipContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 6,
  },
  skipTextContainer: {
    paddingHorizontal: 30,
    paddingVertical: 6,
    borderRadius: 12,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: globalStyles.white,
  },
  skipText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 12,
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
  },
  signUpText: {
    color: globalStyles.white,
  },
});

const datePickerStyles = EStyleSheet.create({
  pickerContainer: {
    position: "absolute",
    top: 440,
    left: 18,
    backgroundColor: globalStyles.basicBlueGreyColor,
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderRadius: 3,
    height: 120,
    borderWidth: 0.5,
    borderColor: globalStyles.white,
  },
  pickerScrollStyle: {
    flex: 1,
    width: "100%",
  },
  monthContainer: {
    left: 110,
  },
  yearsContainer: {
    left: 205,
  },
  genderContainer: {
    width: "90%",
    top: 400,
    left: 21,
    height: 90,
  },
  nationalityContainer: {
    width: "90%",
    top: 370,
    left: 21,
    maxHeight: 60,
  },
});

const datePickerFieldStyles = EStyleSheet.create({
  pickerLeagueContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 6,
    borderBottomWidth: 0.5,
    borderBottomColor: globalStyles.borderRoom,
  },
  pickerText: {
    color: globalStyles.mainBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 9,
  },
  pickerTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    minWidth: 60,
  },
  chooseIcon: {
    width: 12,
    height: 12,
    resizeMode: "contain",
  },
  pickerContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});

const fbLoginButtonStyles = EStyleSheet.create({
  fbButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: globalStyles.fbColor,
    padding: "2rem",
    width: "100%",
    borderRadius: "1.5rem",
  },
  fbImage: {
    height: "4rem",
    width: "4rem",
    marginRight: "1.5rem",
    resizeMode: "contain",
  },
  fbText: {
    fontSize: "2.6rem",
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    textAlign: "center",
  },
});

export {
  chooseLeaguesStyles,
  forgotPasswordStyles,
  loginStyles,
  registrationScreenStyles,
  sessionSplashScreenStyles,
  tutorialScreenStyles,
  datePickerStyles,
  datePickerFieldStyles,
  fbLoginButtonStyles,
};
