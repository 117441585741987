import EStyleSheet from "react-native-extended-stylesheet";
import globalStyles from "../../globalStyles";

const homeStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.backgroundGrey,
  },
  buttonContainer: {
    flexDirection: "row",
    width: "100%",
    height: 55,
  },
  leaguesContainer: {
    width: "100%",
    paddingBottom: 48,
    paddingHorizontal: 3,
  },
  borderRight: {
    borderLeftWidth: 0.5,
    borderLeftColor: globalStyles.borderRoom,
    borderRightWidth: 0.5,
    borderRightColor: globalStyles.borderRoom,
  },
  noMatchesContainer: {
    flex: 1,
    alignItems: "center",
  },
  noMatchesText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 20,
  },
  noMatchesViewOver: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 600,
  },
  noMatchesViewUnder: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});

const liveMatchStyles = EStyleSheet.create({
  flatListStyleContainer: {
    paddingBottom: 85,
  },
  scrollViewStyle: {
    width: "100%",
  },
  container: {
    flex: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  smallContainerLeft: {
    flex: 2,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  smallContainerRight: {
    flex: 2,
    flexDirection: "row",
    alignItems: "center",
  },
  teamsContainer: {
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: globalStyles.white,
    width: "100%",
  },
  clubName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    marginTop: 6,
    fontSize: 18,
    textAlign: "center",
  },
  clubSmallImage: {
    resizeMode: "contain",
    height: 45,
    width: 45,
    marginBottom: 6,
  },
  smallClubName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 16,
  },
  clubTeamContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  smallClubTeamContainerLeft: {
    paddingRight: 3,
  },
  smallClubTeamContainerRight: {
    paddingLeft: 3,
  },
  matchInfoContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  timeDateContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  dateText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
  },
  timeText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  matchFlagContainer: {
    paddingTop: 6,
    alignItems: "center",
    justifyContent: "center",
  },
  smallMatchFlagContainer: {
    alignItems: "center",
    justifyContent: "center",
    zIndex: 0,
    position: "absolute",
    top: 21,
    left: 6,
  },
  buttonsContainer: {
    flexDirection: "row",
    width: "100%",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: globalStyles.backgroundGrey,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  activeButton: {
    backgroundColor: globalStyles.activeBlue,
  },
  activeButtonText: {
    color: globalStyles.white,
  },
  leftButtonContainer: {
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
  },
  inactiveButton: {
    backgroundColor: globalStyles.white,
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    padding: 20,
  },
  inactiveButtonText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 20,
  },
  predictionContainer: {
    marginHorizontal: 3,
    marginTop: 6,
    marginBottom: 120,
  },
  listItem: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    marginTop: 3,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingVertical: 6,
    paddingLeft: 6,
  },
  betContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.green,
    marginRight: 12,
    borderRadius: 3,
    width: 25,
    height: 25,
  },
  betText: {
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 14,
    color: globalStyles.white,
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: 21,
    height: "100%",
  },

  matchPredictionInfoContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: globalStyles.white,
    paddingVertical: 20,
    paddingHorizontal: 12,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
  },
  roomNameContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  arrowImageContainer: {
    padding: 12,
  },
  arrowImage: {
    width: 25,
    height: 25,
    resizeMode: "contain",
  },
  roomNameText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 18,
  },
  clubImageContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingTop: 12,
    paddingHorizontal: 20,
  },
  clubPercentContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingHorizontal: 20,
    height: 36,
    maxHeight: 36,
  },
  progressBarContainer: {
    flexDirection: "row",
    width: "100%",
    height: 6,
    marginTop: 6,
  },
  progressBarPercent: {
    borderRadius: 3,
    height: 6,
    position: "absolute",
    zIndex: 2,
    backgroundColor: globalStyles.error,
  },
  progressBarDrawPercent: {
    backgroundColor: globalStyles.progressBarBlue,
    borderRadius: 3,
    height: 6,
    position: "absolute",
    zIndex: 1,
  },
  progessBarGuestPercent: {
    borderRadius: 3,
    height: 6,
    position: "absolute",
    zIndex: 0,
    backgroundColor: globalStyles.activeBlue,
  },
  percentText: {
    color: globalStyles.black,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 17,
  },
  percentTextBig: {
    fontSize: 25,
  },
  pointsText: {
    color: globalStyles.black,
    opacity: 0.7,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
  },
  pointsContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingHorizontal: 20,
  },

  signInContainer: {
    backgroundColor: globalStyles.white,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    paddingVertical: 12,
    minWidth: 120,
    maxWidth: 120,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 18,
    paddingVertical: 12,
    minWidth: 120,
    maxWidth: 120,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  signUpText: {
    color: globalStyles.white,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 14,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  signInText: {
    color: globalStyles.textBlue,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 14,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  singInUpBtnsContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 20,
  },
  miniContainer: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
});

const standingsStyles = EStyleSheet.create({
  scrollViewStyle: {
    width: "100%",
  },
  standingsContainer: {
    paddingHorizontal: 3,
  },
  container: {
    backgroundColor: globalStyles.backgroundGrey,
  },
  leagueImgContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 12,
    marginBottom: 3,
    flexDirection: "row",
    backgroundColor: globalStyles.white,
  },
  leagueImg: {
    height: 50,
    width: 50,
    marginRight: 6,
    resizeMode: "contain",
  },
  leagueText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 20,
  },
  textTableContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    opacity: 0.7,
    paddingRight: 6,
  },
  numberTextContainer: {
    height: 50,
    width: 50,
    alignItems: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    borderRightColor: globalStyles.backgroundGrey,
  },
  defaultText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: 15,
  },
  rankingStatsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 3,
    marginLeft: 60,
    width: "50%",
  },
  teamRankContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  flatListContainer: {
    marginBottom: 60,
  },
  listItem: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    marginTop: 3,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingVertical: 6,
    paddingLeft: 6,
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: 16,
    padding: 6,
  },
  teamImg: {
    height: 35,
    width: 35,
    marginHorizontal: 3,
  },
});

const futureMatchStyles = EStyleSheet.create({
  backgroundContainer: {
    backgroundColor: globalStyles.backgroundGrey,
  },
  teamsContainer: {
    flexDirection: "row",
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: globalStyles.white,
    width: "100%",
  },
  container: {
    flex: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  clubTeamContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  clubName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    marginTop: 6,
    fontSize: 16,
    textAlign: "center",
  },
  matchInfoContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  timeDateContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  matchFlagContainer: {
    paddingTop: 6,
    alignItems: "center",
    justifyContent: "center",
  },
  dateText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
  },
  timeText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  fieldsContainer: {
    width: "100%",
  },
  flatListStyleContainer: {
    paddingBottom: 85,
    marginBottom: 85,
  },
  smallClubName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  smallMatchFlagContainer: {
    alignItems: "center",
    justifyContent: "center",
    zIndex: 0,
    position: "absolute",
    top: 22,
    left: 3,
  },
  smallContainerLeft: {
    flex: 2,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  smallContainerRight: {
    flex: 2,
    flexDirection: "row",
    alignItems: "center",
  },
  smallClubTeamContainerLeft: {
    paddingRight: 3,
  },
  smallClubTeamContainerRight: {
    paddingLeft: 3,
  },
  predictionContainer: {
    marginHorizontal: 3,
    marginTop: 6,
    paddingVertical: 6,
  },
  signInContainer: {
    backgroundColor: globalStyles.white,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    paddingVertical: 12,
    minWidth: 120,
    maxWidth: 120,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    paddingVertical: 12,
    minWidth: 120,
    maxWidth: 120,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  signUpText: {
    color: globalStyles.white,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 12,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  signInText: {
    color: globalStyles.textBlue,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 12,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  singInUpBtnsContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 20,
  },
});

export { homeStyles, liveMatchStyles, standingsStyles, futureMatchStyles };
