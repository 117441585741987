export const TRY_AUTH = "TRY_AUTH";
export const SEND_EMAIL = "SEND_EMAIL";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const AUTH_SET_TOKEN = "AUTH_SET_TOKEN";
export const SET_GENDERS = "SET_GENDERS";
export const SET_NATIONALITY = "SET_NATIONALITY";
export const SET_LEAGUES = "SET_LEAGUES";
export const AUTH_SET_USER_ID = "AUTH_SET_USER_ID";
export const SET_IS_NEW_USER = "SET_IS_NEW_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_CHANGED_PASSWORD = "DELETE_CHANGED_PASSWORD";
export const SHOW_REGISTER_FORM = "SHOW_REGISTER_FORM";
export const REGISTER_DATA = "REGISTER_DATA";
export const SET_ADVERTS = "SET_ADVERTS";
