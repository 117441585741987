import React, { useState, useEffect } from "react";
import { View, Image, TouchableOpacity, Text } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import { withRouter } from "react-router-dom";
import globalStyles from "./globalStyles";
import { connect } from "react-redux";
import glowterLogo from "../src/assets/images/logo.png";
import TopBarNavigationButton from "./Common/components/UI/TopBarNavigationButton";
import homeImg from "../src/assets/navigation_home.png";
import activeHomeImg from "../src/assets/navigation_home_active.png";
import roomImg from "../src/assets/navigation_rooms.png";
import activeRoomImg from "../src/assets/navigation_rooms_active.png";
import predictImg from "../src/assets/navigation_predict.png";
import activePredictImg from "../src/assets/navigation_predict_active.png";
import chatImg from "../src/assets/navigation_chat.png";
import activeChatImg from "../src/assets/navigation_chat_active.png";
import specialImg from "../src/assets/profile_star.png";
import backArrow from "../src/assets/back_button_arrow.png";
import AvatarImg from "../src/assets/profile_avatar.png";
import ProfileMenu from "./ProfileMenu";
import {
  authSetToken,
  authSetUserId,
  getGenders,
  getNationalities,
  getLeagues,
} from "./Login/actions/auth";
import { getUserProfile } from "./Common/Drawer/actions/profile";
import { getAdverts } from "./Login/actions/auth";
import { useDispatch } from "react-redux";

const TopNav = (props) => {
  const [buttonId, setButtonId] = useState(0);
  const [showProfileMenu, setProfileMenu] = useState(false);
  const dispatch = useDispatch();
  const { reactRem, windowWidth } = props;
  function onShowProfileMenu() {
    setProfileMenu(!showProfileMenu);
  }
  function onPressButton(page, actionId) {
    props.history.push(page);
    setButtonId(actionId);
  }
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(authSetToken(localStorage.getItem("token")));
    }

    if (localStorage.getItem("userId")) {
      if (!props.userId)
        dispatch(authSetUserId(Number(localStorage.getItem("userId"))));
      if (!props.firstName) {
        dispatch(getUserProfile(Number(localStorage.getItem("userId"))));
      }
      if (!props.adverts) dispatch(getAdverts());
    }

    if (!props.leagues) {
      dispatch(getLeagues());
    }
    if (!props.genders) {
      dispatch(getGenders());
    }
    if (!props.nationalities) {
      dispatch(getNationalities());
    }
  }, []);

  let firstName = props.firstName;
  let lastName = props.lastName;

  return (
    <View style={styles.container}>
      <View style={styles.main}>
        {windowWidth > 959 ? (
          <View style={[styles.imageContainer, { height: 9.5 * reactRem }]}>
            <Image
              source={glowterLogo}
              style={[
                styles.logo,
                { height: 3 * reactRem, marginRight: 2 * reactRem },
              ]}
            />
          </View>
        ) : null}
        <View
          style={{
            width: windowWidth > 959 ? "48%" : "68%",
            flexDirection: "row",
            justifyContent: "center",
            marginLeft: windowWidth > 959 ? 0 : 4 * reactRem,
            paddingRight: windowWidth > 959 ? 0 : 2 * reactRem,
          }}
        >
          {props.urlLenght > 4 ||
          window.location.href.includes("privacypolicy") ||
          window.location.href.includes("gamerules") ||
          window.location.href.includes("termsnconditions") ||
          window.location.href.includes("contactus") ? (
            <TouchableOpacity
              style={[
                styles.backArrowContainer,
                {
                  height: 9.5 * reactRem,
                  paddingHorizontal: 2 * reactRem,
                  marginHorizontal: 2 * reactRem,
                },
              ]}
              onPress={() => props.history.goBack()}
            >
              <Image
                source={backArrow}
                style={[
                  styles.backArrow,
                  { width: 2.5 * reactRem, height: 4.5 * reactRem },
                ]}
              />
            </TouchableOpacity>
          ) : (
            <View
              style={[
                styles.backArrowContainer,
                {
                  height: 9.5 * reactRem,
                  paddingHorizontal: 2 * reactRem,
                  marginHorizontal: 2 * reactRem,
                },
              ]}
            >
              <View
                style={[
                  styles.backArrow,
                  { width: 2.5 * reactRem, height: 4.5 * reactRem },
                ]}
              />
            </View>
          )}
          <TopBarNavigationButton
            windowWidth={windowWidth}
            title={"Home"}
            image={buttonId === 0 ? activeHomeImg : homeImg}
            onPress={() => onPressButton("/", 0)}
            buttonId={buttonId === 0}
          />
          <TopBarNavigationButton
            windowWidth={windowWidth}
            title={"Rooms"}
            image={buttonId === 1 ? activeRoomImg : roomImg}
            onPress={() => onPressButton("/rooms", 1)}
            buttonId={buttonId === 1}
          />
          <TopBarNavigationButton
            windowWidth={windowWidth}
            title={"Predict"}
            image={buttonId === 2 ? activePredictImg : predictImg}
            onPress={() => onPressButton("/predict", 2)}
            buttonId={buttonId === 2}
          />
          <TopBarNavigationButton
            windowWidth={windowWidth}
            title={"Chat"}
            image={buttonId === 3 ? activeChatImg : chatImg}
            onPress={() => onPressButton("/chat", 3)}
            buttonId={buttonId === 3}
          />
          {windowWidth > 959 ? null : (
            <TopBarNavigationButton
              windowWidth={windowWidth}
              title={"Special"}
              image={specialImg}
              onPress={() => onPressButton("/specialGames", 4)}
              buttonId={buttonId === 4}
            />
          )}
        </View>
        {windowWidth > 959 ? (
          <View style={styles.specialButtonsContainer}>
            <TopBarNavigationButton
              title={"SPECIAL GAMES"}
              image={specialImg}
              onPress={() => onPressButton("/specialGames", 4)}
              special={true}
            />
          </View>
        ) : null}
        <View
          style={[
            styles.signInContainer,
            { marginLeft: 2 * reactRem, height: 9.5 * reactRem },
          ]}
        >
          {localStorage.getItem("token") ? null : (
            <TouchableOpacity
              style={[
                styles.signUpContainer,
                { width: windowWidth > 959 ? "50%" : 10 * reactRem },
              ]}
              onPress={() => props.history.push("/login")}
            >
              <Text style={[styles.signUpText, { fontSize: 2.8 * reactRem }]}>
                Sign In
              </Text>
            </TouchableOpacity>
          )}
          {localStorage.getItem("token") ? (
            <TouchableOpacity
              style={[styles.avatarContainer]}
              onPress={() => onShowProfileMenu()}
            >
              {windowWidth > 959 ? (
                <View
                  style={{ justifyContent: "flex-end", alignItems: "flex-end" }}
                >
                  <Text style={[styles.userName, { fontSize: 2.4 * reactRem }]}>
                    {firstName ? firstName + " " + lastName : ""}
                  </Text>
                </View>
              ) : null}
              <Image
                source={
                  props.userImage
                    ? globalStyles.BASE_URL + props.userImage
                    : AvatarImg
                }
                style={[
                  styles.avatarImg,
                  {
                    height: 6 * reactRem,
                    width: 6 * reactRem,
                    marginHorizontal: reactRem,
                  },
                ]}
              />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={[
                styles.languageContainer,
                { marginHorizontal: 2 * reactRem },
              ]}
            >
              <Image
                source={props.choosenLanguage.image}
                style={[
                  styles.flagStyle,
                  { height: 4 * reactRem, width: 4 * reactRem },
                ]}
              />
              {windowWidth > 959 ? (
                <Text style={[styles.language, { fontSize: 2.8 * reactRem }]}>
                  {props.choosenLanguage.initials}
                </Text>
              ) : null}
            </TouchableOpacity>
          )}
        </View>
      </View>
      {showProfileMenu ? <ProfileMenu onShowMenu={onShowProfileMenu} /> : null}
    </View>
  );
};

const styles = EStyleSheet.create({
  main: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: globalStyles.black,
    margin: "auto",
  },
  logo: {
    height: "3rem",
    width: "100%",
    resizeMode: "contain",
    marginRight: "2rem",
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  signUpText: {
    color: globalStyles.white,
    fontSize: "2.8rem",
    fontFamily: globalStyles.fontUbuntuBold,
  },
  languageContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    marginHorizontal: "2rem",
    flexDirection: "row",
    width: "45%",
  },
  flagStyle: {
    height: "4rem",
    width: "4rem",
    resizeMode: "contain",
  },
  language: {
    color: globalStyles.white,
    fontSize: "2.8rem",
    fontFamily: globalStyles.fontUbuntuRegular,
  },
  arrowStyle: {
    height: "2.5rem",
    width: "3rem",
    resizeMode: "contain",
    marginTop: "0.5rem",
  },
  specialButtonsContainer: {
    width: "28%",
    flexDirection: "row",
  },
  avatarImg: {
    height: "6rem",
    width: "6rem",
    resizeMode: "contain",
    marginHorizontal: "1rem",
  },
  userName: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "2.4rem",
  },
  balanceText: {
    color: globalStyles.green,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: "2.2rem",
  },
  avatarContainer: {
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
  },
  signInContainer: {
    width: "10%",
    flexDirection: "row",
    marginLeft: "2rem",
    height: "9.5rem",
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "12%",
    height: "9.5rem",
    flexDirection: "row",
  },
  container: {
    width: "100%",
    backgroundColor: globalStyles.black,
    position: "fixed",
    zIndex: 1,
    alignItems: "flex-end",
  },
  backArrow: {
    width: "2.5rem",
    height: "4.5rem",
    resizeMode: "contain",
  },
  backArrowContainer: {
    height: "9.5rem",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: "2rem",
    marginHorizontal: "2rem",
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    leagues: state.auth.leagues,
    genders: state.auth.genders,
    nationalities: state.auth.nationalities,
    firstName: state.userProfileReducer.firstName,
    lastName: state.userProfileReducer.lastName,
    userImage: state.userProfileReducer.image,
    reactRem: state.homeScreenLeagues.reactRem,
  };
};

export default connect(mapStateToProps, null)(withRouter(TopNav));
