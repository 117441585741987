import {
  SELECT_ROOM,
  JOIN_SPECIAL_ROOM,
  SET_MY_SPECIAL_ROOM,
  GET_SPECIAL_GAMES_MATCHES,
  MATCH_BET,
  GET_SPECIAL_GAMES_ROOM_INFO,
  SET_SPECIAL_ROOM,
  DELETE_JOIN_SPECIAL_ROOM,
  SET_RUNNING_SPECIAL_ROOM,
  SET_UPCOMING_SPECIAL_ROOM,
  SET_HISTORY_SPECIAL_ROOM,
  ROOM_MESSAGE,
  SET_PREDICTION_GAMES,
  JOIN_FROM_SPECIAL,
  SET_NAVIGATION,
} from "../actions/actionTypes";
import {
  SET_SPECIAL_USER_DATA,
  SET_SPECIAL_LOGGED_USER_DATA,
} from "../../Room/actions/actionTypes";

const initialState = {
  mySpecialGames: [],
  runningSpecialGames: [],
  upcomingSpecialGames: [],
  historySpecialGames: [],
  specialGamesMatches: null,
  specialRoomInfo: null,
  specialRoom: null,
  joinedSpecialRoom: false,
  joinedMessage: null,
  predictionGames: null,
  joinFromSpecial: false,
  navigation: null,
  myAdverts: [],
  upcomingAdverts: [],
  historyAdverts: [],
  totalWinners: 0,
};

const specialGamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPECIAL_USER_DATA: {
      const predictionGames = { ...state.predictionGames };
      const leagues = [...predictionGames.leagues];
      const userPredictions = action.data.leagues;
      const selectedUserPredictions = [];
      for (var i = 0; i < userPredictions.length; i++) {
        selectedUserPredictions.push(userPredictions[i].games);
      }
      for (var i = 0; i < leagues.length; i++) {
        leagues[i].selectedUserPredictions = selectedUserPredictions[i];
      }
      predictionGames.leagues = leagues;
      return {
        ...state,
        predictionGames,
        selectedUserPredictions: action.data,
      };
    }
    case SET_NAVIGATION: {
      return {
        ...state,
        navigation: action.data,
      };
    }
    case JOIN_FROM_SPECIAL: {
      return {
        ...state,
        joinFromSpecial: action.data,
      };
    }
    case ROOM_MESSAGE: {
      return {
        ...state,
        joinedMessage: action.data,
      };
    }
    case SET_HISTORY_SPECIAL_ROOM: {
      return {
        ...state,
        historySpecialGames: action.data.specialGames,
        historyAdverts: action.data.banner,
      };
    }
    case SET_UPCOMING_SPECIAL_ROOM: {
      return {
        ...state,
        upcomingSpecialGames: action.data.specialGames,
        upcomingAdverts: action.data.banner,
      };
    }
    case SET_RUNNING_SPECIAL_ROOM: {
      return {
        ...state,
        runningSpecialGames: action.data.specialGames,
      };
    }
    case SET_MY_SPECIAL_ROOM: {
      return {
        ...state,
        mySpecialGames: action.data.specialGames,
        myAdverts: action.data.banner,
      };
    }
    case GET_SPECIAL_GAMES_ROOM_INFO: {
      return {
        ...state,
        specialRoomInfo: action.data,
      };
    }
    case JOIN_SPECIAL_ROOM: {
      return {
        ...state,
        joinedSpecialRoom: true,
      };
    }
    case SET_SPECIAL_ROOM: {
      return {
        ...state,
        specialRoom: action.data,
        userBeted: action.data.userBeted,
        joinedSpecialRoom: action.isJoined,
        totalWinners: action.data.totalWinners,
      };
    }
    case DELETE_JOIN_SPECIAL_ROOM: {
      return {
        ...state,
        joinedMessage: null,
        joinedSpecialRoom: false,
      };
    }
    case SET_PREDICTION_GAMES: {
      return {
        ...state,
        predictionGames: action.data,
      };
    }
    case GET_SPECIAL_GAMES_MATCHES: {
      return { ...state, specialGamesMatches: action.data };
    }
    case MATCH_BET: {
      if (state.predictions) {
        const predictions = [...state.predictions];
        const matchIndex = predictions.findIndex(
          (match) => Number(match.id) === Number(action.matchId)
        );
        let match;
        if (matchIndex != -1) {
          match = { ...predictions[matchIndex] };
          let matchBet = { ...match.bet };
          matchBet = action.bet;
          match.bet = matchBet;
          predictions[matchIndex] = match;
          return { ...state, predictions };
        }
      }
      return state;
    }
    default:
      return state;
  }
};

export default specialGamesReducer;
