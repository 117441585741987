import React from "react";
import { Image, View, Text } from "react-native";
import { outComingMessageFieldStyles } from "../styles/styles";

class OutComingMessageField extends React.Component {
  render() {
    const { message, time, image, name } = this.props;

    return (
      <View style={outComingMessageFieldStyles.messageContainer}>
        <View style={{ flexDirection: "row", width: "100%" }}>
          <View>
            <Image
              source={image}
              style={outComingMessageFieldStyles.messageImg}
            />
          </View>
          <View style={outComingMessageFieldStyles.messageTextContainer}>
            <View style={outComingMessageFieldStyles.nameContainer}>
              <Text style={outComingMessageFieldStyles.nameText}>{name}</Text>
            </View>
            <View>
              <Text style={outComingMessageFieldStyles.messageText}>
                {message}
              </Text>
            </View>
          </View>
        </View>
        <View style={outComingMessageFieldStyles.messageTimeContainer}>
          <Text style={outComingMessageFieldStyles.messageTime}>{time}</Text>
        </View>
      </View>
    );
  }
}

export default OutComingMessageField;
