import {
  SET_PREDICTION_BY_WEEK_DATA,
  DELETE_PREDICTION,
  GET_SPECIAL_GAME_DATA,
  SET_MY_SPECIAL_GAMES,
  CHANGE_IS_THERE_BETS,
  SET_ERROR_MESSAGE,
  SET_SPECIAL_PREDICTION_BY_ROUND,
} from "../actions/actionTypes";
import { MATCH_BET } from "../../Home/actions/actionTypes";

const initialState = {
  predictions: null,
  roomName: null,
  leagues: null,
  specialGames: [],
  predictionsForLeagueId: null,
  isThereBets: false,
  errorMessage: null,
  roomId: 0,
};

const predictionsByWeekReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.message,
      };
    }
    case SET_PREDICTION_BY_WEEK_DATA: {
      return {
        ...state,
        predictions: action.data.predictions,
        isGameWeekPassed: action.data.gameWeeks
          ? action.data.gameWeeks.isTotalPassed
          : null,
      };
    }
    case GET_SPECIAL_GAME_DATA: {
      if (!action.prediction && action.data) {
        return {
          ...state,
          leagues: action.data.leagues,
          roomName: action.data.roomName,
          roomId: action.data.roomId,
        };
      } else if (state.leagues) {
        const leagues = [...state.leagues];
        const leagueIndex = leagues.findIndex(
          (league) =>
            Number(league.leagueId) === Number(action.data.leagues[0].leagueId)
        );
        leagues[leagueIndex].games = action.data.leagues[0].games;
        return {
          ...state,
          leagues: leagues,
          roomName: action.data.roomName,
          roomId: action.data.roomId,
          predictionsForLeagueId: action.data.leagues[0].leagueId,
          isThereBets: action.data.isThereBets,
        };
      }
    }
    case CHANGE_IS_THERE_BETS: {
      return {
        ...state,
        predictionsForLeagueId: null,
        isThereBets: !state.isThereBets,
      };
    }
    case SET_MY_SPECIAL_GAMES: {
      return {
        ...state,
        specialGames: action.data.rooms,
      };
    }

    case DELETE_PREDICTION: {
      let predictions = { ...state.predictions };
      let roomName = { ...state.roomName };
      let leagues = { ...state.leagues };
      predictions = null;
      roomName = null;
      leagues = null;
      return { ...state, predictions, roomName, leagues };
    }
    case MATCH_BET: {
      if (state.predictions) {
        const predictions = [...state.predictions];
        const matchIndex = predictions.findIndex(
          (match) => Number(match.id) === Number(action.matchId)
        );
        let match;
        if (matchIndex != -1) {
          match = { ...predictions[matchIndex] };
          let matchBet = { ...match.bet };
          matchBet = action.bet;
          match.bet = matchBet;
          predictions[matchIndex] = match;
          return { ...state, predictions };
        }
      }
      return state;
    }
    case SET_SPECIAL_PREDICTION_BY_ROUND: {
      if (action.data) {
        const specialGames = [...state.specialGames];
        const specialGameIndex = specialGames.findIndex(
          (room) => room.roomId === action.data.roomId
        );
        const room = { ...specialGames[specialGameIndex] };
        const roomRounds = [...room.rounds];
        if (Number(roomRounds[0].round) > Number(action.data.rounds[0].round)) {
          roomRounds[1] = { ...roomRounds[0] };
          roomRounds[0] = action.data.rounds[0];
        }
        if (Number(roomRounds[1].round) < Number(action.data.rounds[0].round)) {
          roomRounds[0] = { ...roomRounds[1] };
          roomRounds[1] = action.data.rounds[0];
        }
        room.rounds = roomRounds;
        specialGames[specialGameIndex] = room;
        return { ...state, specialGames };
      }
    }
    default:
      return state;
  }
};

export default predictionsByWeekReducer;
