import React from "react";
import { View, FlatList, ScrollView, Text } from "react-native";
import { connect } from "react-redux";
import {
  putBetsForSpecialGame,
  deletePredictions,
  getSpecialGamePredictionData,
  useMyPredictions,
  onChangeIsThereBets,
} from "../actions/roompredictions";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import PredictionSpecialGames from "../UI/PredictionSpecialGames";
import AlertDialog from "../../Common/components/UI/AlertDialog";
import TwoFloatingButtons from "../../Common/components/UI/TwoFloatingButtons";

import { getHomeScreenData } from "../../Home/actions/homeScreenLeagues";
import { predictSpecialGamesStyles } from "../styles/styles";

class PredictSpecialGames extends React.Component {
  state = {
    leagues: [],
    isThereABet: false,
    isBetAdded: false,
    isThereABetInThisLeague: false,
    isCheckedForPredictions: false,
    isModalVisible: false,
    betSend: false,
  };

  toggleModal = () => {
    this.props.onChangeIsThereBets();
  };

  onPredictSpecial = (leagueId, matchId, bet) => {
    if (this.state.leagues && this.state.leagues.length > 0) {
      const leagues = [...this.state.leagues];
      const leagueIndex = leagues.findIndex(
        (league) => Number(league.leagueId) === Number(leagueId)
      );
      const games = [...leagues[leagueIndex].games];
      const betIndex = games.findIndex(
        (match) => Number(match.id) === Number(matchId)
      );
      let betInArray = { ...games[betIndex], bet: bet };
      games[betIndex] = betInArray;
      leagues[leagueIndex].games = games;
      this.setState({ leagues: leagues });
    }
  };
  onDiscard = () => {
    this.setState({ leagues: [] });
    this.props.navigation.goBack();
  };
  onPutPredictions = () => {
    this.props.putBetsForSpecialGame(
      this.props.navigation.getParam("roomId", 0),
      this.state.leagues
    );
    this.props.getHomeScreenData();
    this.setState({ betSend: true });
  };

  onUseMyPredictions = (leagueId) => {
    const leagues = this.state.leagues;
    const leagueIndex = leagues.findIndex(
      (league) => Number(league.leagueId) === Number(leagueId)
    );
    const predictionLeague = [];
    predictionLeague.push(leagues[leagueIndex]);
    this.props.useMyPredictions(
      this.props.navigation.getParam("roomId", 0),
      predictionLeague
    );
    this.setState({ isCheckedForPredictions: false });
  };

  componentWillUnmount() {
    this.props.deletePredictions();
    this.setState({ isBetAdded: false });
    this.setState({ isThereABet: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.leagues && prevProps.leagues != this.props.leagues) {
      this.setState({ leagues: this.props.leagues });
    }
    if (this.props.leagues && !this.state.isThereABet) {
      for (var i = 0; i < this.props.leagues.length; i++) {
        this.props.leagues[i].games.forEach((match) => {
          if (match.bet && !this.state.isThereABet) {
            this.setState({ isThereABet: true });
          }
        });
      }
    }
    if (this.state.betSend) {
      const roomId = this.props.navigation.getParam("roomId", null);
      const special = this.props.navigation.getParam("special", false);
      this.props.navigation.navigate("SpcialGameRoomLeague", {
        roomId: roomId,
        special: special,
        fromJoin: false,
      });
    }
  }

  componentDidMount() {
    const rounds = this.props.navigation.getParam("rounds", null);
    if (rounds) {
      this.props.getSpecialGamePredictionData(
        this.props.navigation.getParam("roomId", null),
        rounds[0].id
      );
    } else {
      this.props.getSpecialGamePredictionData(
        this.props.navigation.getParam("roomId", null),
        this.props.navigation.getParam("roundId", null)
      );
    }
  }

  onOpenMatchInfo = (matchId) => {
    const leagueId = this.props.navigation.getParam("leagueId", "1");
    this.props.navigation.navigate("MatchScreen", {
      matchId: matchId,
      leagueId: leagueId,
    });
  };

  render() {
    const {
      leagues,
      roomName,
      userId,
      isThereBets,
      predictionsForLeagueId,
      roomId,
    } = this.props;

    if (!leagues) {
      return <LoadingScreen />;
    }

    return (
      <View style={predictSpecialGamesStyles.container}>
        <ScrollView
          contentContainerStyle={predictSpecialGamesStyles.fieldsContainer}
        >
          <View style={predictSpecialGamesStyles.subtitleContainer}>
            <Text style={predictSpecialGamesStyles.predictionScreenText}>
              Predict Matches
            </Text>
            <Text style={predictSpecialGamesStyles.subtitleText}>
              {roomName}
            </Text>
          </View>
          <View style={predictSpecialGamesStyles.inputContainer}>
            <FlatList
              data={leagues}
              style={{ width: "100%" }}
              keyExtractor={(item, index) => "key" + index}
              renderItem={({ item }) => (
                <PredictionSpecialGames
                  {...item}
                  roomId={roomId}
                  navigation={this.props.navigation}
                  onPredictSpecial={this.onPredictSpecial}
                  onOpenMatchInfo={() => this.onOpenMatchInfo(item.id)}
                  onUseMyPredictions={this.onUseMyPredictions}
                />
              )}
            />
            {userId ? (
              <TwoFloatingButtons
                firstOnPress={this.onDiscard}
                firstButtonText={"DISCARD"}
                secondOnPress={this.onPutPredictions}
                secondButtonText={"CONFIRM"}
              />
            ) : null}
          </View>
          <View style={predictSpecialGamesStyles.borderView} />
        </ScrollView>
        {predictionsForLeagueId ? (
          <AlertDialog
            isModalVisible={!isThereBets}
            onChangeModalVisibility={this.toggleModal}
            useMyPredictions={true}
            title={"There are no predictions"}
            navigation={this.props.navigation}
          />
        ) : null}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomName: state.predictionsByWeek.roomName,
    leagues: state.predictionsByWeek.leagues,
    roomId: state.predictionsByWeek.roomId,
    isThereBets: state.predictionsByWeek.isThereBets,
    predictionsForLeagueId: state.predictionsByWeek.predictionsForLeagueId,
    userId: state.auth.userId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    putBetsForSpecialGame: (roomId, leagues) =>
      dispatch(putBetsForSpecialGame(roomId, leagues)),
    deletePredictions: () => dispatch(deletePredictions()),
    getSpecialGamePredictionData: (roomId, roundId) =>
      dispatch(getSpecialGamePredictionData(roomId, roundId)),
    useMyPredictions: (roomId, leagues) =>
      dispatch(useMyPredictions(roomId, leagues)),
    onChangeIsThereBets: () => dispatch(onChangeIsThereBets()),
    getHomeScreenData: () => dispatch(getHomeScreenData()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PredictSpecialGames);
