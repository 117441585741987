import React from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import RoomLeagueTitleContainer from "../UI/RoomLeagueTitleContainer";
import LeagueList from "../../Common/LeagueList/LeagueList";
import ScreenTitleText from "../../Common/components/UI/ScreenTitleText";
import {
  setGameWeek,
  deleteGameWeek,
} from "../../Home/actions/homeScreenLeagues";
import {
  getPredictionData,
  getPredictionSpecialRoomsData,
} from "../actions/rooms";
import RefreshContainer from "../../Common/components/UI/RefreshContainer";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import Background from "../../Common/components/Background";
import { roomPredictionsStyles } from "../styles/styles";

class RoomPredictionsScreen extends React.Component {
  state = {
    selectBtn: false,
    leagueSelected: false,
    refreshing: false,
    scrollEnabled: true,
  };

  _onRefresh = () => {
    this.setState({ refreshing: true });
    if (this.state.gameWeeks) {
      this.onGetPredictionData(this.state.gameWeeks[0].game_week, true);
    } else {
      this.onGetPredictionData(this.props.gameWeek, false);
    }
    if (!this.state.scrollEnabled) {
      this.setState({ scrollEnabled: !this.state.scrollEnabled });
    }
  };

  changeSelectBtn = (open) => {
    this.setState({ selectBtn: !open });
  };

  onPickLeague = (id) => {
    this.changeSelectBtn(true);
    this.props.chooseLeague(id);
    this.setState({ leagueSelected: true });
  };

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  componentDidMount() {
    this.goToTop();
    const leagueId = this.props.navigation.getParam("leagueId", null);
    const leagueIndex = this.props.leagues.findIndex(
      (league) => Number(league.id) === Number(leagueId)
    );
    if (leagueIndex != -1) {
      this.props.setGameWeek(leagueId);
    }
    this.setState({
      gameWeeks: this.props.navigation.getParam("gameWeeks", null),
    });
  }

  componentWillUnmount() {
    this.props.deleteGameWeek();
  }

  onGetPredictionData = (gameWeek, special) => {
    if (special) {
      this.props.getPredictionSpecialRoomsData(
        this.props.navigation.getParam("roomId", "1"),
        this.props.navigation.getParam("leagueId", "1"),
        gameWeek
      );
    } else {
      this.props.getPredictionData(
        this.props.navigation.getParam("roomId", "1"),
        this.props.navigation.getParam("leagueId", "1"),
        gameWeek
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.state.gameWeeks &&
      this.state.gameWeeks.length != 0 &&
      !this.props.matches
    ) {
      this.onGetPredictionData(this.state.gameWeeks[0].game_week, true);
    }
    if (
      this.props.gameWeek &&
      prevProps.gameWeek != this.props.gameWeek &&
      !this.state.gameWeeks
    ) {
      this.onGetPredictionData(this.props.gameWeek, false);
    }
    if (this.state.refreshing) {
      this.setState({ refreshing: false });
    }
  }

  render() {
    const { leagueGameWeek, matches, roomName, isLoading } = this.props;

    const leagueId = this.props.navigation.getParam("leagueId", "1");
    const leaguesInRoom = this.props.navigation.getParam("leaguesInRoom", "1");
    const leagueImg = this.props.navigation.getParam("leagueImg", "1");
    const leagueName = this.props.navigation.getParam("leagueName", "1");
    const roomCode = this.props.navigation.getParam("roomCode", null);

    return (
      <View style={roomPredictionsStyles.container}>
        <Background notLogged={false} />
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <RefreshContainer
            refreshing={this.state.refreshing}
            onRefresh={this._onRefresh}
            style={roomPredictionsStyles.fieldsContainer}
            scrollEnabled={this.state.scrollEnabled}
          >
            <ScreenTitleText title="League Predictions" />
            <RoomLeagueTitleContainer
              leaguesInRoom={leaguesInRoom}
              roomName={roomName}
              leagueImg={leagueImg}
              roomCode={roomCode}
            />
            <LeagueList
              style={roomPredictionsStyles.leagueContainer}
              navigation={this.props.navigation}
              leagueImg={leagueImg}
              name={leagueName}
              gameWeek={leagueGameWeek}
              matches={matches}
              id={leagueId}
              betPoints={true}
              onGetPredictionData={this.onGetPredictionData}
              isAllMatches={true}
              gameWeeks={this.state.gameWeeks}
            />
          </RefreshContainer>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leagueGameWeek: state.roomPrediction.gameWeek,
    matches: state.roomPrediction.matches,
    roomName: state.roomLeague.roomName,
    gameWeek: state.homeScreenLeagues.gameWeek,
    leagues: state.homeScreenLeagues.leagues,
    isLoading: state.loadingReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setGameWeek: (leagueId) => dispatch(setGameWeek(leagueId)),
    deleteGameWeek: () => dispatch(deleteGameWeek()),
    getPredictionData: (roomId, leagueId, gameWeek) =>
      dispatch(getPredictionData(roomId, leagueId, gameWeek)),
    getPredictionSpecialRoomsData: (roomId, leagueId, gameWeek) =>
      dispatch(getPredictionSpecialRoomsData(roomId, leagueId, gameWeek)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomPredictionsScreen);
