import React from "react";
import {
  View,
  Switch,
  ScrollView,
  Image,
  Text,
  TouchableOpacity,
} from "react-native";
import globalStyles from "../../globalStyles";
import { connect } from "react-redux";
import DefaultInput from "../components/UI/DefaultInput";
import MyDatePicker from "../components/UI/MyDatePicker";
import {
  getUserProfile,
  updateProfile,
  uploadImage,
  checkPasswordMatch,
  deleteAccount,
} from "../Drawer/actions/profile";
import defaultUserImage from "../../assets/profile_avatar.png";
import PopupPicker from "../components/UI/PopupPicker";
import withStyles from "@material-ui/core/styles/withStyles";
import { deleteProfile } from "./actions/profile";
import { deleteHomescreenData } from "../../Home/actions/homeScreenLeagues";
import { deleteChatData } from "../../Chat/actions/chat";
import { deleteRoomData } from "../../Room/actions/rooms";
import { setAlertDialogTitle } from "../store/actions/resources";
import AlertDialogWithConfirmButton from "../components/UI/AlertDialogWithConfirmButton";
import { profileStyles } from "./drawerStyles/styles";

class ProfileScreen extends React.Component {
  state = {
    gameWeekSwitch: false,
    isGameWeekSwitch: false,
    chatSwitch: false,
    isChatSwitch: false,
    notificationsSwitch: false,
    isNotificationSwitch: false,
    firstName: null,
    secondName: null,
    currentDate: null,
    choosenGenderIncorrect: null,
    choosenNationalityIncorrect: null,
    firstNameChanged: false,
    secondNameChanged: false,
    usernameChanged: false,
    password: null,
    confirmPassword: null,
    isModalVisible: false,
    userName: null,
    imageToUpload: null,
    isDeleteModalVisible: false,
    isOnDeleteClicked: false,
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  toggleDeleteModal = () => {
    this.setState({ isDeleteModalVisible: !this.state.isDeleteModalVisible });
  };

  onDiscard = () => {
    this.setState({ imageToUpload: null });
    this.props.navigation.navigate("Home");
  };

  onConfirm = () => {
    if (this.state.imageToUpload) {
      this.props.uploadImage(this.state.imageToUpload);
    }
    this.onChageProfile();
  };

  _pickImage = (event) => {
    if (!event.target.files) {
      return;
    }

    var files = event.target.files;

    for (var index = 0; index < files.length; index++) {
      var reader = new FileReader();
      reader.readAsDataURL(files[index]);
      reader.onload = function (event) {
        var image = event.target.result;
        var imageToSend = image.slice(image.indexOf(",") + 1);
        this.setState({
          avatar: image,
          imageToUpload: imageToSend,
        });
      }.bind(this);
    }
  };

  gameWeekSwitch = () => {
    this.setState({
      gameWeekSwitch: !this.state.gameWeekSwitch,
      isGameWeekSwitch: !this.state.isGameWeekSwitch,
    });
  };
  chatSwitch = () => {
    this.setState({
      chatSwitch: !this.state.chatSwitch,
      isChatSwitch: !this.state.isChatSwitch,
    });
  };
  notificationsSwitch = () => {
    this.setState({
      notificationsSwitch: !this.state.notificationsSwitch,
      isNotificationSwitch: this.state.isNotificationSwitch,
    });
  };
  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    this.goToTop();
    const {
      getUserProfile,
      auth,
      dateOfBirth,
      sex,
      nationality,
      genders,
      nationalities,
      notify_game_week_start_end,
      notify_chat,
      notify_system_notifications,
    } = this.props;

    getUserProfile(auth);

    let genderId;
    let nationalityId;
    if (sex) {
      genderId = genders.findIndex(
        (gender) => Number(gender.id) === Number(sex[0].id)
      );
    }
    if (nationality) {
      nationalityId = nationalities.findIndex(
        (nation) => Number(nation.id) === Number(nationality[0].id)
      );
    }
    this.setState({
      dateOfBirth: dateOfBirth,
      gender: genderId,
      nationality: nationalityId,
      gameWeekSwitch: notify_game_week_start_end,
      chatSwitch: notify_chat,
      notificationsSwitch: notify_system_notifications,
    });
  }

  oldPasswordChangeHandler = (val) => {
    this.setState({ oldPassword: val });
  };

  passwordChangedHandler = (val) => {
    this.setState({ password: val });
    if (this.state.password) {
      this.setState({ passIncorrect: false });
    }
  };

  confirmPasswordChangedHandler = (val) => {
    this.setState({ confirmPassword: val });
    if (this.state.confirmPassword) {
      this.setState({ confirmPassIncorrect: false });
    }
  };

  firstNameChangedHandler = (val) => {
    this.setState({ firstName: val, firstNameChanged: true });
    if (this.state.firstName) {
      this.setState({ firstNameIncorrect: false });
    }
  };

  secondNameChangedHandler = (val) => {
    this.setState({ secondName: val, secondNameChanged: true });
    if (this.state.secondName) {
      this.setState({ secondNameIncorrect: false });
    }
  };

  userNameChangedHandler = (val) => {
    this.setState({ userName: val, usernameChanged: true });
    if (this.state.userName) {
      this.setState({ userNameIncorrect: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const oldPassword = this.state.oldPassword;
    if (prevState.oldPassword !== oldPassword) {
      const data = {
        email: this.props.email,
        password: oldPassword,
      };
      this.props.checkPasswordMatch(data);
    }
    if (!this.state.isOnDeleteClicked) {
      if (
        !prevProps.firstName &&
        !this.state.firstName &&
        this.props.firstName
      ) {
        this.setState({
          firstName: this.props.firstName,
          secondName: this.props.lastName,
          userName: this.props.userName,
        });
      }

      if (
        !this.state.firstName &&
        !this.state.firstNameChanged &&
        this.props.firstName
      ) {
        this.setState({ firstName: this.props.firstName });
      }
      if (
        !this.state.secondName &&
        !this.state.secondNameChanged &&
        this.props.lastName
      ) {
        this.setState({
          secondName: this.props.lastName,
          userName: this.props.userName,
        });
      }

      if (prevProps.firstName != null) {
        if (prevProps.firstName != this.props.firstName) {
          this.toggleModal();
        } else if (prevProps.lastName != this.props.lastName) {
          this.toggleModal();
        } else if (prevProps.userName != this.props.userName) {
          this.toggleModal();
        } else if (
          prevProps.nationality &&
          this.props.nationality &&
          prevProps.nationality.length != 0 &&
          this.props.nationality.length != 0 &&
          prevProps.nationality[0].id != this.props.nationality[0].id
        ) {
          this.toggleModal();
        } else if (
          prevProps.sex &&
          this.props.sex &&
          prevProps.sex.length != 0 &&
          this.props.sex.length != 0 &&
          prevProps.sex[0].id != this.props.sex[0].id
        ) {
          this.toggleModal();
        } else if (prevProps.dateOfBirth != this.props.dateOfBirth) {
          this.toggleModal();
        } else if (this.state.isPasswordChanged) {
          this.toggleModal();
          this.setState({
            password: null,
            confirmPassword: null,
            isPasswordChanged: false,
          });
        } else if (
          prevProps.notify_game_week_start_end !=
            this.props.notify_game_week_start_end &&
          this.state.isGameWeekSwitch
        ) {
          this.toggleModal();
          this.setState({ isGameWeekSwitch: false });
        } else if (
          prevProps.notify_chat != this.props.notify_chat &&
          this.state.isChatSwitch
        ) {
          this.toggleModal();
          this.setState({ isChatSwitch: false });
        } else if (
          prevProps.notify_system_notifications !=
            this.props.notify_system_notifications &&
          this.state.isNotificationSwitch
        ) {
          this.toggleModal();
          this.setState({ isNotificationSwitch: false });
        }

        if (prevProps.image != this.props.image) {
          this.toggleModal();
          this.setState({ image: this.props.image });
        }
      }
      if (!this.state.image && this.props.image) {
        this.setState({ image: this.props.image });
      }
    }
  }

  onDateChange = (date) => {
    this.setState({ currentDate: date, choosenDateIncorrect: false });
    let dateArray = new Date(date).toLocaleDateString().split("/");
    this.setState({
      dateOfBirth: dateArray[1] + "-" + dateArray[0] + "-" + dateArray[2],
    });
  };

  changeIsGenderChoosenBtn = (open) => {
    const { isNationalityChoosen } = this.state;
    this.setState({ isGenderChoosen: !open });
    if (isNationalityChoosen) {
      this.setState({ isNationalityChoosen: false });
    }
  };

  changeIsNationalityChoosenBtn = (open) => {
    const { isGenderChoosen } = this.state;
    this.setState({ isNationalityChoosen: !open });
    if (isGenderChoosen) {
      this.setState({ isGenderChoosen: false });
    }
  };

  onPickGender = (gender) => {
    this.setState({ gender: gender });
    this.changeIsGenderChoosenBtn(this.state.isGenderChoosen);
    this.setState({ choosenGenderIncorrect: false });
  };

  onPickNationality = (nationality) => {
    this.setState({ nationality: nationality });
    this.changeIsNationalityChoosenBtn(this.state.isNationalityChoosen);
    this.setState({ choosenNationalityIncorrect: false });
  };

  onCloseGameRoom = () => {};

  onChageProfile = () => {
    const {
      password,
      firstName,
      secondName,
      userName,
      dateOfBirth,
      gender,
      nationality,
      confirmPassword,
      oldPassword,
    } = this.state;

    if (password) {
      if (!oldPassword) {
        alert("Please enter the current password of the user");
        return false;
      } else {
        if (this.props.rightPassword) {
          alert("The old password doesn't match the profile password");
          return false;
        }
      }
      if (password.length < 6) {
        this.setState({ passIncorrect: true });
        alert("Please provide more secure password");
        return false;
      }
      if (!confirmPassword) {
        this.setState({ confirmPassIncorrect: true });
        alert("Please provide non empty Confirm Password");
        return false;
      }
      if (password !== confirmPassword) {
        this.setState({ confirmPassIncorrect: true });
        alert("The confirm password do not match");
        return false;
      }
      if (password === confirmPassword) {
        this.setState({ isPasswordChanged: true });
      }
    }
    if (!firstName) {
      this.setState({ firstNameIncorrect: true });
      alert("Please provide non empty First Name");
      return false;
    }
    if (!secondName) {
      this.setState({ secondNameIncorrect: true });
      alert("Please provide non empty Last Name");
      return false;
    }
    if (!userName) {
      this.setState({ userNameIncorrect: true });
      alert("Please provide non empty Username");
      return false;
    }

    const notifications_settings = {
      notify_game_week_start_end: this.state.gameWeekSwitch,
      notify_chat: this.state.chatSwitch,
      notify_system_notifications: this.state.notificationsSwitch,
    };

    this.props.updateProfile(
      password,
      firstName,
      secondName,
      userName,
      dateOfBirth,
      gender + 1,
      nationality + 1,
      notifications_settings
    );
  };

  onDeleteAccount = async () => {
    this.setState({ isOnDeleteClicked: true });
    this.props.deleteProfile();
    this.props.deleteHomescreenData();
    this.props.deleteChatData();
    this.props.deleteRoomData();
    await localStorage.removeItem("token");
    localStorage.clear();
    this.props.deleteAccount();
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      genders,
      nationalities,
      userName,
      classes,
    } = this.props;

    const {
      avatar,
      image,
      gender,
      nationality,
      dateOfBirth,
      choosenNationalityIncorrect,
      choosenGenderIncorrect,
      passIncorrect,
      confirmPassIncorrect,
      firstNameIncorrect,
      secondNameIncorrect,
      userNameIncorrect,
    } = this.state;

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let userBirthDay;
    if (dateOfBirth) {
      const birthDay = dateOfBirth.split("-");
      let day = birthDay[2];
      let month = months[birthDay[1] - 1];
      let year = birthDay[0];
      if (day.charAt(0) === "0") {
        day = day.charAt(1);
      }
      userBirthDay = day + " " + month + " " + year;
    }

    let imageToShow = defaultUserImage;
    if (image) {
      imageToShow = { uri: globalStyles.BASE_URL + image };
    }
    if (avatar) {
      imageToShow = { uri: avatar };
    }

    return (
      <View style={profileStyles.container}>
        <View style={profileStyles.profileContainer}>
          <View style={profileStyles.profileImgContainer}>
            <input
              className={classes.input}
              type="file"
              onChange={this._pickImage}
            />
            <Image source={imageToShow} style={profileStyles.profileImg} />
          </View>
          <View style={profileStyles.profileNameMoneyContainer}>
            <View>
              <Text style={profileStyles.profileName}>
                {firstName ? firstName + " " + lastName : ""}
              </Text>
            </View>
            <View>
              <Text style={profileStyles.userNameText}>
                {userName ? userName : ""}
              </Text>
            </View>
          </View>
        </View>
        <ScrollView
          contentContainerStyle={profileStyles.fieldsContainer}
          ref="_scrollView"
        >
          <View style={profileStyles.personalDataContainer}>
            <Text style={profileStyles.personalDataText}>Personal Data</Text>
          </View>
          <View style={profileStyles.dataFieldsContainer}>
            <View style={profileStyles.emailFieldContainer}>
              <Text style={profileStyles.dataFieldNoChangeText}>{email}</Text>
            </View>
            <DefaultInput
              placeholderText={"Current Password"}
              password={true}
              inputValue={this.state.oldPassword}
              onChangeText={this.oldPasswordChangeHandler}
              autoCapitalize="none"
              keyboardType={"default"}
              editable={true}
            />
            <DefaultInput
              placeholderText={"New Password"}
              inputValue={this.state.password}
              onChangeText={this.passwordChangedHandler}
              password={true}
              correctInput={passIncorrect}
              ref="password"
              autoCapitalize="none"
              keyboardType={"default"}
              editable={true}
            />
            <DefaultInput
              placeholderText={"Confirm Password"}
              inputValue={this.state.confirmPassword}
              onChangeText={this.confirmPasswordChangedHandler}
              password={true}
              correctInput={confirmPassIncorrect}
              ref="confirmPassword"
              autoCapitalize="none"
              keyboardType={"default"}
              editable={true}
            />
            <DefaultInput
              placeholderText={"First Name"}
              inputValue={this.state.firstName}
              onChangeText={this.firstNameChangedHandler}
              correctInput={firstNameIncorrect}
              ref="firstName"
              keyboardType={"default"}
              settings={"edit"}
            />
            <DefaultInput
              placeholderText={"Family Name"}
              inputValue={this.state.secondName}
              onChangeText={this.secondNameChangedHandler}
              correctInput={secondNameIncorrect}
              ref="secondName"
              keyboardType={"default"}
              settings={"edit"}
            />
            <DefaultInput
              placeholderText={"Username"}
              inputValue={this.state.userName}
              onChangeText={this.userNameChangedHandler}
              correctInput={userNameIncorrect}
              ref="userName"
              keyboardType={"default"}
              settings={"edit"}
            />
            <View style={profileStyles.dataFieldNoChangeTextContainer}>
              <Text style={profileStyles.dataFieldNoChangeText}>
                Date of birth
              </Text>
            </View>
            <MyDatePicker
              currentDate={userBirthDay}
              onDateChange={this.onDateChange}
              currentYear={new Date().getFullYear()}
              settings={"change"}
            />
            <PopupPicker
              ref="gender"
              title={
                gender > -1
                  ? genders[gender].name
                  : this.props.sex && this.props.sex[0]
                  ? this.props.sex[0].name
                  : "Sex"
              }
              correctInput={choosenGenderIncorrect}
              onPick={this.onPickGender}
              dates={genders}
            />
            <PopupPicker
              ref="nationality"
              title={
                nationality > -1
                  ? nationalities[nationality].name
                  : this.props.nationality && this.props.nationality[0]
                  ? this.props.nationality[0].name
                  : "Nationality"
              }
              correctInput={choosenNationalityIncorrect}
              onPick={this.onPickNationality}
              dates={nationalities}
            />
          </View>
          <View style={profileStyles.discardSaveBtnContainer}>
            <TouchableOpacity
              style={profileStyles.discardButtonContainer}
              onPress={() => this.onDiscard()}
            >
              <Text style={profileStyles.discardText}>DISCARD</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={profileStyles.saveButton}
              onPress={() => this.onConfirm()}
            >
              <Text style={profileStyles.saveText}>SAVE</Text>
            </TouchableOpacity>
          </View>
          <View style={profileStyles.personalDataContainer}>
            <Text style={profileStyles.personalDataText}>Notifications</Text>
          </View>
          <View style={profileStyles.notificationsContainer}>
            <View style={profileStyles.notificationFieldContainer}>
              <Switch
                onValueChange={() => this.gameWeekSwitch()}
                value={this.state.gameWeekSwitch}
              />
              <Text style={profileStyles.notificationText}>
                Gameweek Start and End
              </Text>
            </View>
            <View style={profileStyles.notificationFieldContainer}>
              <Switch
                onValueChange={() => this.chatSwitch()}
                value={this.state.chatSwitch}
              />
              <Text style={profileStyles.notificationText}>Glowter Chats</Text>
            </View>
            <View style={profileStyles.notificationFieldContainer}>
              <Switch
                onValueChange={() => this.notificationsSwitch()}
                value={this.state.notificationsSwitch}
              />
              <Text style={profileStyles.notificationText}>
                System Notifications
              </Text>
            </View>
          </View>

          <View style={profileStyles.deleteAccountContainer}>
            <View style={profileStyles.deleteAccountBorderContainer}>
              <TouchableOpacity
                style={profileStyles.deleteAccountBorderContainer}
                onPress={() => this.toggleDeleteModal()}
              >
                <Text style={profileStyles.deletеAccountText}>
                  Delete my account and personal data
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={profileStyles.footerView} />
        </ScrollView>
        <AlertDialogWithConfirmButton
          isModalVisible={this.state.isModalVisible}
          onChangeModalVisibility={this.toggleModal}
          onCloseGameRoom={this.onCloseGameRoom}
          title={"Your personal data is successfully changed."}
          navigation={this.props.navigation}
        />
        <AlertDialogWithConfirmButton
          isModalVisible={this.state.isDeleteModalVisible}
          onChangeModalVisibility={this.toggleDeleteModal}
          onCloseGameRoom={this.onDeleteAccount}
          deleteAccount={true}
          title={
            "Are you sure you want to delete your account? You won’t be able to enter/play/return again."
          }
          navigation={this.props.navigation}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: state.userProfileReducer.id,
    image: state.userProfileReducer.image,
    firstName: state.userProfileReducer.firstName,
    lastName: state.userProfileReducer.lastName,
    userName: state.userProfileReducer.userName,
    rightPassword: state.userProfileReducer.rightPassword,
    nationality: state.userProfileReducer.nationality,
    sex: state.userProfileReducer.sex,
    dateOfBirth: state.userProfileReducer.dateOfBirth,
    email: state.userProfileReducer.email,
    auth: state.auth.userId,
    genders: state.auth.genders,
    nationalities: state.auth.nationalities,
    notify_game_week_start_end:
      state.userProfileReducer.notify_game_week_start_end,
    notify_chat: state.userProfileReducer.notify_chat,
    notify_system_notifications:
      state.userProfileReducer.notify_system_notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: (userId) => dispatch(getUserProfile(userId)),
    deleteAccount: () => dispatch(deleteAccount()),
    checkPasswordMatch: (data) => dispatch(checkPasswordMatch(data)),
    deleteProfile: () => dispatch(deleteProfile()),
    deleteHomescreenData: () => dispatch(deleteHomescreenData()),
    deleteChatData: () => dispatch(deleteChatData()),
    deleteRoomData: () => dispatch(deleteRoomData()),
    updateProfile: (
      newPassword,
      firstName,
      lastName,
      userName,
      dateOfBirth,
      gender,
      nationality,
      notifications_settings
    ) =>
      dispatch(
        updateProfile(
          newPassword,
          firstName,
          lastName,
          userName,
          dateOfBirth,
          gender,
          nationality,
          notifications_settings
        )
      ),
    uploadImage: (base64) => dispatch(uploadImage(base64)),
    setAlertDialogTitle: (title) => dispatch(setAlertDialogTitle(title)),
  };
};

const extraStyle = {
  input: {
    cursor: "pointer",
    display: "block",
    height: "100%",
    left: "0",
    zIndex: 1,
    opacity: "0!important",
    position: "absolute",
    top: "0",
    width: "100%",
  },
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(extraStyle)(ProfileScreen));
