import React from "react";
import { TouchableOpacity, View, Text, Image } from "react-native";
import globalStyles from "../../globalStyles";
import { connect } from "react-redux";
import CrownImg from "../../assets/crown.png";
import ArrowUp from "../../assets/images/position_up.png";
import ArrowDown from "../../assets/images/position_down.png";
import SamePosition from "../../assets/images/same_position.png";
import defaultUserImage from "../../assets/profile_avatar.png";
import { roomRankingPlayerInfoStyles } from "../styles/styles";

class RoomRankingPlayerInfo extends React.Component {
  render() {
    const {
      roomNameType,
      loggedUserId,
      userId,
      user_name,
      image,
      id,
      positionMovement,
      playerName,
      totalPoints,
      lastRoundPoints,
      lastRoundPointsMovement,
      showFullPoints,
      loggedUser,
      maxLength,
      index,
      onStaticsShow,
      totalWinners,
      fromSpecial,
    } = this.props;

    let pointsText;
    let fullPointsText;
    let pointsToShow;
    if (lastRoundPoints != null) {
      pointsToShow = lastRoundPoints;
      if (pointsToShow > -1 && pointsToShow != 0) {
        fullPointsText = "+" + pointsToShow;
      } else {
        fullPointsText = pointsToShow;
      }
      if (pointsToShow >= 1) {
        pointsText = "+" + (pointsToShow / 1000).toFixed(1) + "k";
      }
      if (pointsToShow <= -1) {
        pointsToShow = pointsToShow * -1;
        pointsText = "-" + (pointsToShow / 1000).toFixed(1) + "k";
      }
    }

    let totalPointsText;
    let fullTotalPointsText;
    let totalPointsToShow;

    if (totalPoints != null) {
      totalPointsToShow = totalPoints;
      if (totalPointsToShow > -1 && totalPointsToShow != 0) {
        fullTotalPointsText = "+" + totalPointsToShow;
      } else {
        fullTotalPointsText = totalPointsToShow;
      }
      if (totalPointsToShow >= 1) {
        totalPointsText = "+" + (totalPointsToShow / 1000).toFixed(1) + "k";
      }
      if (totalPointsToShow <= -1) {
        totalPointsToShow = totalPointsToShow * -1;
        totalPointsText = "-" + (totalPointsToShow / 1000).toFixed(1) + "k";
      }
    } else {
      totalPointsText = 0;
      fullTotalPointsText = 0;
    }
    let pointsTextStyle = [roomRankingPlayerInfoStyles.pointsText];
    let arrowImg = SamePosition;

    if (positionMovement === "down") {
      arrowImg = ArrowDown;
    } else if (positionMovement === "up") {
      arrowImg = ArrowUp;
    }

    if (lastRoundPointsMovement === "down") {
      pointsTextStyle.push({ color: globalStyles.red });
    } else if (lastRoundPointsMovement === "up") {
      pointsTextStyle.push({ color: globalStyles.green });
    }
    const betContainerStyle = [roomRankingPlayerInfoStyles.betContainer];

    let playerNameBetContainerStyle = [
      roomRankingPlayerInfoStyles.playerNameBetContainer,
    ];
    let playerPositionContainerStyle = [
      roomRankingPlayerInfoStyles.playerPositionContainer,
    ];
    let betPlayerTextStyle = [roomRankingPlayerInfoStyles.betPlayerText];
    let betTextStyle = [roomRankingPlayerInfoStyles.betText];
    const positionTextStyle = [roomRankingPlayerInfoStyles.positionText];
    const positionContainerStyle = [
      roomRankingPlayerInfoStyles.positionContainer,
    ];
    const betPlayerUsernameStyle = [
      roomRankingPlayerInfoStyles.betPlayerUsername,
    ];
    const playerInfoContainerStyle = [
      roomRankingPlayerInfoStyles.playerInfoContainer,
    ];
    const crownImgStyle = [roomRankingPlayerInfoStyles.crownImg];
    const pointsContainerStyle = [roomRankingPlayerInfoStyles.pointsContainer];
    const samePositionImageStyle = [
      roomRankingPlayerInfoStyles.samePositionImage,
    ];
    const arrowImageStyle = [roomRankingPlayerInfoStyles.arrowImage];

    if (loggedUser) {
      playerNameBetContainerStyle.push({
        backgroundColor: globalStyles.activeBlue,
      });
      playerPositionContainerStyle.push({
        backgroundColor: globalStyles.activeBlue,
      });
      playerInfoContainerStyle.push({
        backgroundColor: globalStyles.activeBlue,
      });
      positionTextStyle.push(roomRankingPlayerInfoStyles.whitePositionText);
      betPlayerTextStyle.push(roomRankingPlayerInfoStyles.userBetPlayerText);
      betPlayerTextStyle.push({ fontSize: 15 });
      betTextStyle.push({ color: globalStyles.white });
      pointsTextStyle.push({
        color: globalStyles.white,
        fontFamily: globalStyles.fontUbuntuBold,
      });
      betPlayerUsernameStyle.push(
        roomRankingPlayerInfoStyles.userBetPlayerUsername
      );
      betPlayerUsernameStyle.push({ fontSize: 15 });
    }

    let content = null;
    if ((fromSpecial && id <= totalWinners) || (!fromSpecial && id < 4)) {
      content = (
        <Image source={CrownImg} style={roomRankingPlayerInfoStyles.crownImg} />
      );
      playerPositionContainerStyle.push({ opacity: 1 });
      playerNameBetContainerStyle.push({ opacity: 1 });
    } else {
      positionContainerStyle.push(
        roomRankingPlayerInfoStyles.defaultPositionContainer
      );
      positionContainerStyle.push({ paddingRight: 3 });
    }
    if (pointsText === 0 && id != loggedUserId) {
      pointsTextStyle.push({ color: globalStyles.textBlue });
    }

    if (maxLength - 1 === index) {
      playerPositionContainerStyle.push({
        borderBottomLeftRadius: 9,
      });
      playerNameBetContainerStyle.push({
        borderBottomRightRadius: 9,
      });
    }
    return (
      <TouchableOpacity
        style={playerInfoContainerStyle}
        onPress={() => (loggedUserId ? onStaticsShow(userId) : null)}
      >
        <View style={playerPositionContainerStyle}>
          {roomNameType === "Head to Head" ? (
            <View>
              {id === 1 ? (
                <Image source={CrownImg} style={crownImgStyle} />
              ) : null}
            </View>
          ) : (
            <View>{content}</View>
          )}
          <View style={positionContainerStyle}>
            <Text style={positionTextStyle}>{id}</Text>
          </View>
          <Image
            source={arrowImg}
            style={
              positionMovement != "same"
                ? arrowImageStyle
                : samePositionImageStyle
            }
          />
        </View>
        <View style={playerNameBetContainerStyle}>
          <View style={roomRankingPlayerInfoStyles.playerNameContainer}>
            {roomNameType != "Head to Head" ? (
              <Image
                source={
                  image
                    ? { uri: globalStyles.BASE_URL + image }
                    : defaultUserImage
                }
                style={roomRankingPlayerInfoStyles.profileImage}
              />
            ) : null}
            <View>
              <Text style={betPlayerUsernameStyle}>{user_name}</Text>
              <Text style={betPlayerTextStyle}>{playerName}</Text>
            </View>
          </View>
          <View style={roomRankingPlayerInfoStyles.betPointsContainer}>
            <View style={betContainerStyle}>
              <Text style={betTextStyle}>
                {showFullPoints ? fullTotalPointsText : totalPointsText}
              </Text>
            </View>
            <View style={pointsContainerStyle}>
              <Text style={pointsTextStyle}>
                {showFullPoints ? fullPointsText : pointsText}
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loggedUserId: state.auth.userId,
    totalWinners: state.specialGames.totalWinners,
  };
};

export default connect(mapStateToProps)(RoomRankingPlayerInfo);
