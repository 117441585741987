import React from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  PanResponder,
  PanResponderInstance,
  Animated,
  TouchableHighlight,
  Image,
  Dimensions,
  Platform,
  Easing,
} from "react-native";
import EditRoomLeagueField from '../../../Room/UI/EditRoomLeagueField'
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
// import SortableList from 'react-sortable-list';

import { ReactSortable } from "react-sortablejs";




export default class Basic extends React.Component {
  state = {
    roomsState: []
  };

  componentDidMount() {
    this.setState({ roomsState: this.props.rooms })
  }

  onReleaseRow = (newState) => {

    this.setState({ roomsState: newState })
    this.props.getNewOrderList(newState)
  }

  render() {
    const { reactRem } = this.props;
    if (this.state.roomsState.length === 0) {
      return (
        <View />
      );
    }

    return (
      <View style={styles.container}>
        <ReactSortable
          key={'myKey'}
          group="groupName"
          animation={200}
          delayOnTouchStart={true}
          delay={2}
          style={{ width: "100%" }}
          list={this.state.roomsState}
          setList={newState => this.onReleaseRow(newState)}>
          {this.state.roomsState.map(item => (
            <EditRoomLeagueField rooms={item} roomsToList={this.props.rooms} roomsLength={this.props.roomsLength} roomNameType={this.props.roomNameType} navigation={this.props.navigation} />
          ))}
        </ReactSortable>
      </View>
    );
  }



  _renderRow = ({ data, active }) => {
    return <Row data={data} roomsToList={this.props.rooms} active={active} roomsLength={this.props.roomsLength} roomNameType={this.props.roomNameType} navigation={this.props.navigation} />
  }
}

class Row extends React.Component {

  constructor(props) {
    super(props);

    this._active = new Animated.Value(0);

    this._style = {
      ...Platform.select({
        ios: {
          transform: [{
            scale: this._active.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.1],
            }),
          }],
          shadowRadius: this._active.interpolate({
            inputRange: [0, 1],
            outputRange: [2, 10],
          }),
        },

        android: {
          transform: [{
            scale: this._active.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 1.07],
            }),
          }],
          elevation: this._active.interpolate({
            inputRange: [0, 1],
            outputRange: [2, 6],
          }),
        },
      })
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      Animated.timing(this._active, {
        duration: 300,
        easing: Easing.bounce,
        toValue: Number(nextProps.active),
      }).start();
    }
  }

  render() {
    const { data, active, roomsLength, roomNameType, navigation, roomsToList, reactRem } = this.props;
    return (
      <Animated.View style={[
        styles.row,
        this._style,
      ]}>
      </Animated.View>
    );
  }
}

const styles = EStyleSheet.create({
  container: {
    width: "100%",
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: globalStyles.backgroundGrey
  },

  list: {
    width: "100%",
    height: "100%",
  },

  contentContainer: {
    width: "100%",
    paddingBottom: "20rem"
  },

  row: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: "100%",
    ...Platform.select({
      ios: {
        shadowColor: 'rgba(0,0,0,0.2)',
        shadowOpacity: 1,
        shadowOffset: { height: 2, width: 2 },
        shadowRadius: 2,
      },

      android: {
        elevation: 0,
      },
    })
  },
});