import React from "react";
import { View, FlatList, Text, TouchableOpacity } from "react-native";
import { onOpenRegister } from "../../Login/actions/auth";
import { connect } from "react-redux";
import { notificationsStyles } from "./drawerStyles/styles";
import {
  getNotificationData,
  setReadNotification,
  setAllReadNotification,
} from "../../Chat/actions/chat";
import NotificationFiled from "../../Chat/UI/NotificationField";
import LeagueAd from "../LeagueList/LeagueAd";

class ChatPage extends React.Component {
  state = {
    isModalVisible: false,
    currentPage: 1,
    getNotifications: true,
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  onOpenLogin = () => {
    this.props.onOpenRegister(true, false);
    this.props.navigation.navigate("LoginScreen");
  };
  onOpenRegister = () => {
    this.props.onOpenRegister(true, true);
    this.props.navigation.navigate("LoginScreen");
  };
  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    this.goToTop();
    if (!this.props.userId) {
      this.setState({ isModalVisible: true });
    }
    this.props.getNotificationData(this.state.currentPage);
  }
  componentWillUnmount() {
    if (this.state.isModalVisible) {
      this.toggleModal();
    }
  }

  getNewMessages = () => {
    let page = this.props.notifications.length;
    page = page / 10 + 1;
    if (this.state.getNotifications) {
      if (page % 1 === 0) {
        this.setState({ currentPage: page });
        this.props.getNotificationData(page);
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.notifications && this.state.currentPage === 1) {
      this.setState({ currentPage: 2 });
    }
  }

  componentWillUnmount() {
    this.setState({ getNotifications: false });
  }

  render() {
    const { notifications, userId, banner } = this.props;

    if (!userId) {
      return (
        <View style={notificationsStyles.container}>
          <View style={notificationsStyles.signInUpContainer}>
            <TouchableOpacity onPress={() => this.onOpenLogin()}>
              <View style={notificationsStyles.signInContainer}>
                <Text style={notificationsStyles.signInText}>SIGN IN</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.onOpenRegister()}>
              <View style={notificationsStyles.signUpContainer}>
                <Text style={notificationsStyles.signUpText}>SIGN UP</Text>
              </View>
            </TouchableOpacity>
          </View>
          {banner && banner.length != 0 ? (
            <LeagueAd banner={banner} navigation={this.props.navigation} />
          ) : null}
        </View>
      );
    }
    return (
      <View style={notificationsStyles.container}>
        <View style={notificationsStyles.markAsReadContainer}>
          <TouchableOpacity onPress={() => this.props.setAllReadNotification()}>
            <View style={notificationsStyles.markAsReadImageContainer}>
              <Text style={notificationsStyles.markAsReadText}>
                Mark All Notifications as Read
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <FlatList
          contentContainerStyle={notificationsStyles.chatContainer}
          newMessages={this.state.newMessages}
          data={notifications}
          onEndReachedThreshold={0.5}
          onEndReached={({ distanceFromEnd }) => {
            this.getNewMessages();
          }}
          keyExtractor={(item, index) => "key" + index}
          renderItem={({ item }) => (
            <NotificationFiled
              {...item}
              userId={this.props.userId}
              onPress={() => this.props.setReadNotification(item.id)}
              newMessages={this.state.newMessages}
            />
          )}
        />

        {banner && banner.length != 0 ? (
          <LeagueAd banner={banner} navigation={this.props.navigation} />
        ) : null}

        {notifications && notifications.length === 0 ? (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80%",
            }}
          >
            <Text style={notificationsStyles.noMessagesText}>There are no notifications</Text>
          </View>
        ) : null}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    notifications: state.chatReducer.notifications,
    currentPage: state.chatReducer.current_page,
    banner: state.roomLeague.adverts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationData: (page) => dispatch(getNotificationData(page)),
    setReadNotification: (notificationId) =>
      dispatch(setReadNotification(notificationId)),
    setAllReadNotification: () => dispatch(setAllReadNotification()),
    onOpenRegister: (loginscreen, register) =>
      dispatch(onOpenRegister(loginscreen, register)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChatPage);
