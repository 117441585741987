import React from 'react';
import { View, FlatList, TouchableWithoutFeedback, Text, Image, TouchableOpacity } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import Modal from '@material-ui/core/Modal';
import {
    EmailShareButton,
    FacebookShareButton,
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    ViberIcon,
    WhatsappIcon,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
} from "react-share";

class ShareModal extends React.Component {

    componentWillUnmount() {
        if (this.props.isModalVisible) {
            this.props.onChangeModalVisibility();
        }
    }
    render() {
        const { isModalVisible, onChangeModalVisibility, message, reactRem } = this.props;
        const mainContainerStyle = [styles.mainContainer, { padding: 2 * reactRem, margin: 50 * reactRem }];

        return (
            <Modal
                open={isModalVisible}
                onClose={() => onChangeModalVisibility()}>
                <View style={mainContainerStyle}>
                    <View style={{ paddingHorizontal: reactRem }}>
                        <FacebookShareButton
                            url={"http://glowter-web.barinlabs.com/"}
                            quote={message}
                            onShareWindowClose={() => this.props.onChangeModalVisibility()}>
                            <FacebookIcon />
                        </FacebookShareButton>
                    </View>
                    <View style={{ paddingHorizontal: reactRem }}>
                        <TwitterShareButton
                            url={"http://glowter-web.barinlabs.com/"}
                            quote={message}
                            onShareWindowClose={() => this.props.onChangeModalVisibility()}>
                            <TwitterIcon />
                        </TwitterShareButton>
                    </View>
                    <View style={{ paddingHorizontal: reactRem }}>
                        <EmailShareButton
                            url={"http://glowter-web.barinlabs.com/"}
                            quote={message}
                            onShareWindowClose={() => this.props.onChangeModalVisibility()}>
                            <EmailIcon />
                        </EmailShareButton>
                    </View>
                    <View style={{ paddingHorizontal: reactRem }}>
                        <ViberShareButton
                            url={"http://glowter-web.barinlabs.com/ \n" + message}
                            quote={message}
                            onShareWindowClose={() => this.props.onChangeModalVisibility()}>
                            <ViberIcon />
                        </ViberShareButton>
                    </View>
                    <View style={{ paddingHorizontal: reactRem }}>
                        <WhatsappShareButton
                            url={"http://glowter-web.barinlabs.com/ \n" + message}
                            quote={message}
                            onShareWindowClose={() => this.props.onChangeModalVisibility()}>
                            <WhatsappIcon />
                        </WhatsappShareButton>
                    </View>
                </View>
            </Modal >);
    }
}

const styles = EStyleSheet.create({
    mainContainer: {
        justifyContent: "center",
        alignItems: 'center',
        backgroundColor: globalStyles.white,
        flexDirection: 'row'
    },
});
export default ShareModal;