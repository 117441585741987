export const GET_HOME_SCREEN_DATA = "GET_HOME_SCREEN_DATA";
export const SELECT_MATCH = "SELECT_MATCH";
export const SELECT_LEAGUE = "SELECT_LEAGUE";
export const SET_FUTURE_MATCH_DATA = "SET_FUTURE_MATCH_DATA";
export const SET_LIVE_MATCH_DATA = "SET_LIVE_MATCH_DATA";
export const GET_STANDINGS_LEAGUE_DATA = "GET_STANDINGS_LEAGUE_DATA";
export const SET_LEAGUE_DATA_BY_GAME_WEEK = "SET_LEAGUE_DATA_BY_GAME_WEEK";
export const MATCH_BET = "MATCH_BET";
export const SET_GAME_WEEK = "SET_GAME_WEEK";
export const DELETE_GAME_WEEK = "DELETE_GAME_WEEK";
export const SET_ROOM_PREDICTIONS = "SET_ROOM_PREDICTIONS";
export const SET_LEAGUES_WITH_GAME_WEEKS = "SET_LEAGUES_WITH_GAME_WEEKS";
export const DELETE_HOMESCREEN_DATA = "DELETE_HOMESCREEN_DATA";
export const DELETE_FUTURE_MATCH_INFO = "DELETE_FUTURE_MATCH_INFO";
export const SET_LIVE_MATCH_LINEUPS = "SET_LIVE_MATCH_LINEUPS";
export const RESET_LIVE_SCORE = "RESET_LIVE_SCORE";
export const SET_VIEW_HEIGHT = "SET_VIEW_HEIGHT";
export const SET_SPECIAL_VIEW_HEIGHT = "SET_SPECIAL_VIEW_HEIGHT";
export const VALUE_CONSTANT = "VALUE_CONSTANT";
export const SET_LEAGUE_GW_DATA = "SET_LEAGUE_GW_DATA";
export const SET_MATCH_DATA = "SET_MATCH_DATA";
export const RESET_ROOM_PREDICTIONS = "RESET_ROOM_PREDICTIONS";
export const SET_TODAY_MATCHES = "SET_TODAY_MATCHES";
export const SET_LIVE_MATCHES = "SET_LIVE_MATCHES";
