import React from "react";
import { View, Text, Image, TouchableOpacity, FlatList } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import globalStyles from "../../globalStyles";
import EditMenuImg from "../../assets/edit_menu.png";
import RoomLeagueField from "./RoomLeagueField";

class RoomLeagueList extends React.Component {
  render() {
    const { roomImg, roomName, flatListData, reactRem } = this.props;
    return (
      <View
        style={[
          styles.roomsContainer,
          {
            paddingHorizontal: 0.5 * reactRem,
            paddingTop: reactRem,
            paddingBottom: 12 * reactRem,
            minHeight: 200,
          },
        ]}
      >
        <View
          style={[
            styles.listItem,
            {
              marginTop: 0.5 * reactRem,
              borderTopRightRadius: reactRem,
              borderTopLeftRadius: reactRem,
              paddingVertical: reactRem,
              paddingLeft: reactRem,
            },
          ]}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <View
              style={[
                styles.placeImageContainer,
                { marginRight: reactRem, padding: 0.5 * reactRem },
              ]}
            >
              <Image
                source={roomImg}
                style={[
                  styles.placeImage,
                  { height: 5 * reactRem, width: 5 * reactRem },
                ]}
              />
            </View>
            <Text style={[styles.leagueTitle, { fontSize: 2.8 * reactRem }]}>
              {roomName}
            </Text>
          </View>
          {roomName === "Global Rooms" ? (
            <View
              style={[
                styles.gameWeekContainer,
                { paddingHorizontal: 3 * reactRem },
              ]}
            >
              <TouchableOpacity onPress={() => this.props.onShowEditMenu()}>
                <Image
                  source={EditMenuImg}
                  style={[
                    styles.placeImage,
                    { height: 5 * reactRem, width: 5 * reactRem },
                  ]}
                />
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
        <FlatList
          reactRem={reactRem}
          data={flatListData}
          keyExtractor={(item, index) => "key" + index}
          renderItem={({ item, index }) => (
            <RoomLeagueField
              reactRem={reactRem}
              navigation={this.props.navigation}
              info={item}
              index={index}
              setLeaguesInRoom={this.props.setLeaguesInRoom}
              roomsLength={flatListData.length}
              roomNameType={roomName}
            />
          )}
        />
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  roomsContainer: {
    flex: 1,
    backgroundColor: globalStyles.backgroundGrey,
    paddingHorizontal: "0.5rem",
    paddingTop: "1rem",
    paddingBottom: "12rem",
  },
  listItem: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    marginTop: "0.5rem",
    borderTopRightRadius: "1rem",
    borderTopLeftRadius: "1rem",
    paddingVertical: "1rem",
    paddingLeft: "1rem",
  },
  placeImageContainer: {
    marginRight: "1rem",
    padding: "0.5rem",
  },
  placeImage: {
    height: "5rem",
    width: "5rem",
    resizeMode: "contain",
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: "2.8rem",
    height: "100%",
  },
  gameWeekContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: "3rem",
  },
});
export default RoomLeagueList;
