import React from "react";
import { View } from "react-native";
import SplashScreenContainer from "../../Common/components/SplashScreenContainer";
import {
  tryAuth,
  authSetToken,
  authSetUserId,
  faceBookLogin,
  GoogleLogin,
} from "../actions/auth";
import { setError } from "../../Common/store/actions/errors";
import { connect } from "react-redux";
import {
  getGenders,
  getNationalities,
  getLeagues,
  getAdverts,
} from "../actions/auth";
import { getUserProfile } from "../../Common/Drawer/actions/profile";
import { setNavigation } from "../../SpecialGames/actions/specialGames";
import { getHomeScreenData } from "../../Home/actions/homeScreenLeagues";
import { sessionSplashScreenStyles } from "../styles/styles";

class SplashScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    email: null,
    password: null,
    dataLoaded: false,
    activeButtonId: 0,
    regEmail: null,
    regPassword: null,
    confirmPassword: null,
    firstName: null,
    username: null,
    lastName: null,
    dateOfBirth: null,
    genderId: -1,
    nationalityId: -1,
    leagues: [],
    isDateChoosen: false,
    currentDate: null,
    isGenderChoosen: false,
    isNationalityChoosen: false,
    acceptTerms: false,
    emailIncorrect: false,
    passIncorrect: false,
    confirmPassIncorrect: false,
    firstNameIncorrect: false,
    usernameIncorrect: false,
    lastNameIncorrect: false,
    choosenDateIncorrect: false,
    choosenGenderIncorrect: false,
    choosenNationalityIncorrect: false,
    tutorial: false,
  };

  async componentDidUpdate(prevProps) {
    const { leagues, genders, nationalities, specialNavigation } = this.props;
    if (!specialNavigation) {
      this.props.setNavigation(this.props.navigation);
    }
    const data = await this.performTimeConsumingTask();
    if (leagues && genders && nationalities && !this.state.dataLoaded) {
      if (data !== null) {
        this.setState({ dataLoaded: true });
        if (this.props.loginscreen) {
          this.props.navigation.navigate("LoginScreen");
        }
        //sessionStorage
        else if (
          localStorage.getItem("tutorial") ||
          localStorage.getItem("userId")
        ) {
          this.props.navigation.navigate("Home");
        } else {
          this.props.navigation.navigate("Tutorial");
        }
      }
    }
    if (
      this.props.token &&
      this.props.token !== prevProps.token &&
      !this.props.isNewUser
    ) {
      this.props.getHomeScreenData("web");
      if (data !== null) {
        this.setState({ dataLoaded: true });
        this.props.navigation.navigate("Home");
      }
    }
  }
  performTimeConsumingTask = async () => {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve("result");
        sessionStorage.setItem("splash", true);
      }, 6500)
    );
  };

  componentDidMount() {
    this.props.getGenders();
    this.props.getAdverts();
    this.props.getNationalities();
    this.props.getLeagues();
    if (localStorage.getItem("userId")) {
      this.props.authSetUserId(Number(localStorage.getItem("userId")));
      this.props.getUserProfile(Number(localStorage.getItem("userId")));
    }
    if (localStorage.getItem("token")) {
      this.props.authSetToken(localStorage.getItem("token"));
    }
    if (localStorage.getItem("tutorial")) {
      if (localStorage.getItem("tutorial") === "true") {
        this.setState({ tutorial: true });
      }
    }
  }

  render() {
    const { reactRem } = this.props;

    return (
      <View style={sessionSplashScreenStyles.container}>
        <SplashScreenContainer reactRem={reactRem} />
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    loginscreen: state.auth.loginscreen,
    error: state.errorReducer.error,
    isNewUser: state.auth.isNewUser,
    leagues: state.auth.leagues,
    genders: state.auth.genders,
    nationalities: state.auth.nationalities,
    specialNavigation: state.specialGames.navigation,
    reactRem: state.homeScreenLeagues.reactRem,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAuth: (authData) => dispatch(tryAuth(authData)),
    authSetToken: (token) => dispatch(authSetToken(token)),
    authSetUserId: (userId) => dispatch(authSetUserId(userId)),
    setError: (error) => dispatch(setError(error)),
    getGenders: () => dispatch(getGenders()),
    getAdverts: () => dispatch(getAdverts()),
    getLeagues: () => dispatch(getLeagues()),
    getNationalities: () => dispatch(getNationalities()),
    getUserProfile: (userId) => dispatch(getUserProfile(userId)),
    onFaceBookLogin: (authData) => dispatch(faceBookLogin(authData)),
    GoogleLogin: (authData) => dispatch(GoogleLogin(authData)),
    getHomeScreenData: (firebaseToken) =>
      dispatch(getHomeScreenData(firebaseToken)),
    setNavigation: (navigation) => dispatch(setNavigation(navigation)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
