import { SET_RESOURCES, ALERT_DIALOG_TITLE, LEAVE_ROOM, LEAVE_IS_PRESSED, LEAVE_ROOM_ID } from '../actions/actionTypes';

const initialState = {
    resources: null,
    title: null,
    leaveRoom: false,
    isLeavePressed: false,
    rooms: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ALERT_DIALOG_TITLE: {
            return { ...state, title: action.title }
        }
        case LEAVE_ROOM_ID: {
            return { ...state, rooms: action.data }
        }
        case LEAVE_ROOM: {
            return { ...state, leaveRoom: action.data }
        }
        case LEAVE_IS_PRESSED: {
            return { ...state, isLeavePressed: action.data }
        }
        case SET_RESOURCES: {
            return { ...state, resources: action.resources }
        }
        default: return state;

    }
}

export default reducer;
