import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from "react-native";
import globalStyles from "../../../globalStyles";
import EStyleSheet from "react-native-extended-stylesheet";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

class MyDatePicker extends Component {
  state = {
    isDialogOpen: false,
  };
  onOpenDialog = () => {
    this.setState({ isDialogOpen: !this.state.isDialogOpen });
  };

  render() {
    const {
      currentDate,
      correctInput,
      error,
      settings,
      reactRem,
      classes,
    } = this.props;

    const inputTextStyle = [
      styles.inputText,
      { paddingLeft: 2 * reactRem, fontSize: 2.4 * reactRem },
    ];
    const settingsDatePickerContainerStyle = [
      styles.settingsDatePickerContainer,
      { paddingLeft: 2 * reactRem, fontSize: 2.4 * reactRem },
    ];
    const errorContainerStyle = [
      styles.errorContainer,
      {
        paddingRight: 1.5 * reactRem,
        paddingLeft: 3 * reactRem,
      },
    ];
    const errorTextStyle = [styles.errorText, { fontSize: 1.8 * reactRem }];
    const settingsTextStyle = [
      styles.settingsText,
      { fontSize: 2.2 * reactRem },
    ];

    let today = moment.utc(currentDate).format("DD-MM-YYYY");
    let renderInput = (props) => (
      <Text
        onClick={() => this.onOpenDialog()}
        style={settings ? settingsDatePickerContainerStyle : inputTextStyle}
      >
        {today != "Invalid date" ? today : "Select Date of Birth"}
      </Text>
    );

    const pickerStyle = [
      {
        alignItems: "center",
        justifyContent: "center",
        width: "93%",
        height: 8 * reactRem,
        cursor: "pointer",
      },
    ];
    return (
      <View style={styles.inputBox}>
        <View style={styles.dateContainer}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <View style={pickerStyle}>
              <DatePicker
                ref="datepicker"
                margin="normal"
                id="date-picker-dialog"
                label="Select Date of Birth"
                format="MM/dd/yyyy"
                value={currentDate}
                maxDate={new Date()}
                minDate={"01-01-1920"}
                InputProps={{
                  className: classes.input,
                  disableUnderline: true,
                }}
                onChange={this.props.onDateChange}
                open={this.state.isDialogOpen}
                TextFieldComponent={renderInput}
                onAccept={() => this.onOpenDialog()}
                onClose={() => this.onOpenDialog()}
              />
            </View>
          </MuiPickersUtilsProvider>
          {settings ? (
            <View style={styles.settingsContainer}>
              <TouchableOpacity onPress={() => this.onOpenDialog()}>
                <View style={[styles.opacityContainer]}>
                  <Text style={settingsTextStyle}>{settings}</Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
        {correctInput ? (
          <View style={errorContainerStyle}>
            <Text style={errorTextStyle}>{error}</Text>
          </View>
        ) : null}
      </View>
    );
  }
}

const classStyles = (theme) => ({
  input: {
    fontFamily: globalStyles.fontUbuntuMedium,
    width: "50vw",
  },
});
const styles = EStyleSheet.create({
  inputBox: {
    flex: 1,
    borderBottomWidth: 1.5,
    borderBottomColor: globalStyles.backgroundGrey,
    backgroundColor: globalStyles.white,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  dateContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  inputText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    zIndex: 0,
    width: "100%",
    paddingLeft: "2rem",
  },
  opacityContainer: {
    padding: "1rem",
  },
  datePicketContainer: {
    position: "absolute",
    zIndex: 1,
    width: "100%",
  },
  settingsDatePickerContainer: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    zIndex: 0,
    width: "100%",
    paddingLeft: "2rem",
  },
  errorContainer: {
    width: "100%",
    backgroundColor: globalStyles.errorBackground,
    paddingRight: "1.5rem",
    paddingLeft: "3rem",
  },
  errorText: {
    color: globalStyles.error,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: "1.8rem",
  },
  settingsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "7%",
  },
  settingsText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    zIndex: 0,
  },
});

const mapStateToProps = (state, ownProps) => {
  return {
    reactRem: state.homeScreenLeagues.reactRem,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(classStyles)(MyDatePicker));
