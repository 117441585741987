import React from "react";
import {
  View,
  Image,
  Text,
  TouchableWithoutFeedback,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import DefaultInput from "../../Common/components/UI/DefaultInput";
import DefaultPicker from "../../Common/components/UI/DefaultPicker";
import CreateRoomLeaguePicker from "../UI/CreateRoomLeaguePicker";
import { getLeagues } from "../../Login/actions/auth";
import { createRoom } from "../actions/rooms";
import { createFriendlyRoomStyles } from "../styles/styles";
import PrivateRoomWhiteImg from "../../assets/private_room_white.png";

class CreateFriendlyRoom extends React.Component {
  state = {
    isChooseLeagues: false,
    isKnockoutRounds: false,
    choosenLeague: [],
    roomName: null,
    leagueTitle: null,
    knockoutRounds: [
      "1 Game Week",
      "2 Game Weeks",
      "3 Game Weeks",
      "4 Game Weeks",
      "5 Game Weeks",
      "6 Game Weeks",
      "HALF SEASON",
      "FULL SEASON",
    ],
    choosenKnockoutRound: null,
    knockoutRound: null,
  };
  roomNameChangedHandler = (val) => {
    this.setState({ roomName: val, error: null });
  };
  onPickKnockoutRound = (round) => {
    this.setState({ choosenKnockoutRound: round, isKnockoutRounds: false });
    let checkForRound;
    if (round) {
      checkForRound = round.split(" ");
      if (checkForRound.length === 3) {
        this.setState({ knockoutRound: checkForRound[0] });
      } else {
        this.setState({ knockoutRound: round });
      }
    }
  };

  componentDidMount() {
    this.props.getLeagues();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.roomCode && prevProps.roomCode !== this.props.roomCode) {
      this.props.navigation.navigate("SuccessfullyCreatedRoom", {
        roomCode: this.props.roomCode,
        roomName: this.state.roomName,
        roomType: "Private",
      });
    }
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
  }

  changeIsChooseLeaguesBtn = (open) => {
    const { leagues } = this.props;
    this.setState({ isChooseLeagues: !open });
    if (this.state.isKnockoutRounds) {
      this.setState({ isKnockoutRounds: false });
    }
    if (!open) {
      this.setState({ choosenLeague: [] });
    }
    if (open) {
      let leagueTitle = null;
      if (this.state.choosenLeague) {
        for (var i = 0; i < this.state.choosenLeague.length; i++) {
          if (!leagueTitle) {
            let leagueIndex = leagues.findIndex(
              (league) =>
                Number(league.id) === Number(this.state.choosenLeague[i])
            );
            leagueTitle = leagues[leagueIndex].name;
          } else {
            let leagueIndex2 = leagues.findIndex(
              (league) =>
                Number(league.id) === Number(this.state.choosenLeague[i])
            );
            leagueTitle += ", " + leagues[leagueIndex2].name;
          }
        }
      }
      this.setState({ leagueTitle: leagueTitle, error: null });
    }
  };

  changeIsKnockoutRoundsBtn = (open) => {
    this.setState({ isKnockoutRounds: !open });
    if (this.state.isChooseLeagues) {
      this.setState({ isChooseLeagues: false });
    }
  };

  onPickLeague = (id) => {
    this.state.choosenLeague.push(id);
  };
  onRemoveLeague = (id) => {
    this.state.choosenLeague = this.state.choosenLeague.filter((leagueId) => {
      return leagueId !== id;
    });
  };

  onErrorShow = () => {
    const { roomName, choosenLeague, knockoutRound } = this.state;
    if (!roomName) {
      this.setState({ error: "Please enter a room name" });
      return;
    }
    if (choosenLeague.length === 0) {
      this.setState({ error: "Please choose a league" });
      return;
    }
    if (!knockoutRound) {
      this.setState({ error: "Please choose a knockout round" });
    }
  };

  onPressButton = () => {
    this.changeIsChooseLeaguesBtn(true);
    this.changeIsKnockoutRoundsBtn(true);
  };

  render() {
    const { leagues, reactRem } = this.props;
    const {
      leagueTitle,
      roomName,
      choosenLeague,
      isChooseLeagues,
      error,
      isKnockoutRounds,
      knockoutRounds,
      choosenKnockoutRound,
      knockoutRound,
    } = this.state;

    return (
      <TouchableWithoutFeedback onPress={() => this.onPressButton()}>
        <View style={createFriendlyRoomStyles.container}>
          <View style={createFriendlyRoomStyles.listItem}>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <View style={createFriendlyRoomStyles.placeImageContainer}>
                <Image
                  source={PrivateRoomWhiteImg}
                  style={createFriendlyRoomStyles.placeImage}
                />
              </View>
              <Text style={createFriendlyRoomStyles.leagueTitle}>
                Create Private
              </Text>
            </View>
          </View>
          <View style={createFriendlyRoomStyles.pickerContainer}>
            <DefaultInput
              placeholderText="Name"
              inputValue={roomName}
              onChangeText={this.roomNameChangedHandler}
              onFocus={() => this.onPressButton()}
              editable={true}
            />
            <DefaultPicker
              title={leagueTitle ? leagueTitle : "Choose Leagues"}
              onPress={() => this.changeIsChooseLeaguesBtn(isChooseLeagues)}
            />
            <DefaultPicker
              title={
                choosenKnockoutRound ? choosenKnockoutRound : "Knockout Rounds"
              }
              onPress={() => this.changeIsKnockoutRoundsBtn(isKnockoutRounds)}
              style={createFriendlyRoomStyles.lastPicker}
            />
          </View>
          {error ? (
            <View style={createFriendlyRoomStyles.errorMessageContainer}>
              <Text style={createFriendlyRoomStyles.errorMessage}>{error}</Text>
            </View>
          ) : (
            <View style={createFriendlyRoomStyles.errorMessageContainer}>
              <Text style={createFriendlyRoomStyles.errorMessage}></Text>
            </View>
          )}
          <View style={createFriendlyRoomStyles.confirmButtonContainer}>
            <TouchableOpacity
              style={createFriendlyRoomStyles.discardButtonContainer}
              onPress={() => this.props.navigation.goBack()}
            >
              <Text style={createFriendlyRoomStyles.discardText}>
                {"CANCEL"}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={createFriendlyRoomStyles.button}
              onPress={() => {
                roomName && choosenLeague.length != 0 && knockoutRound
                  ? this.props.createRoom(
                      roomName,
                      choosenLeague,
                      knockoutRound,
                      2000
                    )
                  : this.onErrorShow();
              }}
            >
              <Text style={createFriendlyRoomStyles.text}>{"CREATE"}</Text>
            </TouchableOpacity>
          </View>
          {isChooseLeagues ? (
            <CreateRoomLeaguePicker
              reactRem={reactRem}
              onPickLeague={this.onPickLeague}
              onRemoveLeague={this.onRemoveLeague}
              roomLeague={leagues}
              chooseLeague={true}
            />
          ) : null}
          {isKnockoutRounds ? (
            <CreateRoomLeaguePicker
              reactRem={reactRem}
              onPickKnockoutRound={this.onPickKnockoutRound}
              roomLeague={knockoutRounds}
              choosenKnockoutRound={choosenKnockoutRound}
              chooseLeague={false}
            />
          ) : null}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLeagues: () => dispatch(getLeagues()),
    createRoom: (privateRoomName, leagues, knockoutRound, players) =>
      dispatch(createRoom(privateRoomName, leagues, knockoutRound, players)),
  };
};
const mapStateToProps = (state) => {
  return {
    roomLeague: state.roomLeague.leaguesInRoom,
    league: state.roomPrediction.league,
    roomName: state.roomLeague.roomName,
    leagues: state.auth.leagues,
    roomCode: state.privateRooms.roomCode,
    error: state.errorReducer.error,
    reactRem: state.homeScreenLeagues.reactRem,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateFriendlyRoom);
