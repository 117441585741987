import React from "react";
import { Image, View, Text, FlatList, TouchableOpacity } from "react-native";
import globalStyles from "../../globalStyles";
import ArrowLeftImg from "../../assets/left_gw_arrow.png";
import ArrowRightImg from "../../assets/right_gw_arrow.png";
import PredictionRoundField from "./PredictionRoundField";
import AlertDialog from "../../Common/components/UI/AlertDialog";
import { predictionWeeklyGamesStyles } from "../styles/styles";

class PredictionWeeklyGames extends React.Component {
  state = {
    isModalVisible: false,
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  onOpenPredictionMatchpage = (roundId) => {
    this.props.navigation.navigate("PredictSpecialGames", {
      roomId: this.props.roomId,
      roundId: roundId,
    });
  };

  render() {
    const {
      roomId,
      roomName,
      firstRound,
      prizeImage,
      lastRound,
      rounds,
      token,
    } = this.props;

    const flatListContainerStyle = [
      predictionWeeklyGamesStyles.flatListContainer,
    ];
    if (!rounds) {
      return;
    }
    if (rounds && rounds.length === 1) {
      flatListContainerStyle.push(
        predictionWeeklyGamesStyles.flatListSingleGWContainer
      );
      flatListContainerStyle.push({ paddingHorizontal: 6 });
    }
    return (
      <View style={predictionWeeklyGamesStyles.container}>
        <View style={predictionWeeklyGamesStyles.leagueImageContainer}>
          <Image
            style={predictionWeeklyGamesStyles.leagueImg}
            source={{ uri: globalStyles.BASE_URL + prizeImage }}
          />
          <View style={predictionWeeklyGamesStyles.leagueNameContainer}>
            <Text style={predictionWeeklyGamesStyles.leagueName}>
              {roomName}
            </Text>
          </View>
        </View>
        <View style={predictionWeeklyGamesStyles.gameWeekContainer}>
          {firstRound != lastRound ? (
            <View style={predictionWeeklyGamesStyles.leftArrowContainer}>
              <View style={{ height: "20%" }}>
                <Text style={predictionWeeklyGamesStyles.gameWeekText}>
                  Round
                </Text>
              </View>
              <View style={predictionWeeklyGamesStyles.arrowContainer}>
                <TouchableOpacity
                  onPress={() =>
                    token
                      ? this.props.onRoundChange(
                          roomId,
                          Number(rounds[0].round) - 1
                        )
                      : this.toggleModal()
                  }
                  style={predictionWeeklyGamesStyles.miniContainer}
                >
                  <Image
                    style={predictionWeeklyGamesStyles.arrowImg}
                    source={ArrowLeftImg}
                  />
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <View style={predictionWeeklyGamesStyles.leftArrowContainer}>
              <View style={{ height: "20%" }}>
                <Text style={predictionWeeklyGamesStyles.gameWeekText}></Text>
              </View>
              <View style={predictionWeeklyGamesStyles.arrowImg} />
            </View>
          )}

          <FlatList
            contentContainerStyle={flatListContainerStyle}
            horizontal={true}
            data={rounds}
            keyExtractor={(item, index) => "key" + index}
            renderItem={({ item }) => (
              <PredictionRoundField
                {...item}
                firstRound={firstRound}
                lastRound={lastRound}
                onPress={() =>
                  token
                    ? this.onOpenPredictionMatchpage(item.roundId)
                    : this.toggleModal()
                }
                gameWeeksLenght={rounds.length}
              />
            )}
          />
          {firstRound != lastRound ? (
            <View style={predictionWeeklyGamesStyles.leftArrowContainer}>
              <View style={{ height: "20%" }} />
              <View style={predictionWeeklyGamesStyles.arrowContainer}>
                <TouchableOpacity
                  onPress={() =>
                    token
                      ? this.props.onRoundChange(
                          roomId,
                          Number(rounds[1].round) + 1
                        )
                      : this.toggleModal()
                  }
                  style={predictionWeeklyGamesStyles.miniContainer}
                >
                  <Image
                    style={predictionWeeklyGamesStyles.arrowImg}
                    source={ArrowRightImg}
                  />
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            <View style={predictionWeeklyGamesStyles.leftArrowContainer}>
              <View style={{ height: "20%" }} />
              <View style={predictionWeeklyGamesStyles.arrowImg} />
            </View>
          )}
        </View>
        <AlertDialog
          isModalVisible={this.state.isModalVisible}
          onChangeModalVisibility={this.toggleModal}
          title={"You have to sign up to predict matches."}
          navigation={this.props.navigation}
        />
      </View>
    );
  }
}

export default PredictionWeeklyGames;
