import React from "react";
import { View, Image, Text, TouchableHighlight } from "react-native";
import LeaveRoomImg from "../../assets/leave_room.png";
import RoomsEditImg from "../../assets/rooms_edit.png";
import { connect } from "react-redux";
import { setRoomsToLeave } from "../actions/rooms";
import { editRoomLeagueFieldStyles } from "../styles/styles";

class EditRoomLeagueField extends React.Component {
  onPressRoomsToLeave = (roomsToLeave) => {
    this.props.setRoomsToLeave(roomsToLeave);
    this.props.navigation.navigate("ChooseLeagues", {
      addLeague: false,
      isNewUser: false,
      leaveRooms: true,
    });
  };

  render() {
    const { rooms, index, roomsLength, roomNameType, roomsToList } = this.props;

    return (
      <View
        style={
          roomsLength - 1 === index
            ? editRoomLeagueFieldStyles.lastContainer
            : editRoomLeagueFieldStyles.roomContainer
        }
      >
        <View style={editRoomLeagueFieldStyles.roomTitleContainer}>
          {roomNameType === "Global Rooms" ? null : (
            <TouchableHighlight
              onPress={() => this.onPressRoomsToLeave(roomsToList)}
            >
              <View style={editRoomLeagueFieldStyles.headToHeadImgContainer}>
                <Image
                  source={LeaveRoomImg}
                  style={editRoomLeagueFieldStyles.playerImg}
                />
              </View>
            </TouchableHighlight>
          )}
          <Text style={editRoomLeagueFieldStyles.roomTitleText}>
            {rooms.nameOfRoom}
          </Text>
        </View>
        <View style={editRoomLeagueFieldStyles.roomPositionContainer}>
          <Image
            source={RoomsEditImg}
            style={editRoomLeagueFieldStyles.placeImage}
          />
        </View>
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRoomsToLeave: (roomsToLeave) => dispatch(setRoomsToLeave(roomsToLeave)),
  };
};
export default connect(null, mapDispatchToProps)(EditRoomLeagueField);
