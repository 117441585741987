import { ADD_PLACE, DELETE_PLACE, SELECT_MATCH, DESELECT_PLACE } from '../actions/actionTypes';

import PLimage from '../../../assets/leagues/premier_league.png'
import LLimage from '../../../assets/leagues/la_liga.png';
import Bundesliga from '../../../assets/leagues/bundesliga.png';
import SeriaA from '../../../assets/leagues/seria_a.png';
import LeagueOne from '../../../assets/leagues/league_1.png';

const initialState = {
    leagues:[
        {
            id:1,
            leagueImg:PLimage,
            leagueName:"Premier League"
        },
        {
            id:2,
            leagueImg:LLimage,
            leagueName:"La Liga"
        },
        {
            id:3,
            leagueImg:Bundesliga,
            leagueName:"Bundesliga"
        },
        {
            id:4,
            leagueImg:SeriaA,
            leagueName:"Seria A"
        },
        {
            id:5,
            leagueImg:LeagueOne,
            leagueName:"Ligue 1"
        },
    ]
       

};

const leaguelist = (state = initialState, action) => {
    switch (action.type) {
        
        default:
            return state;
    }
};

export default leaguelist;