import React from "react";
import { View, ActivityIndicator } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import Background from "../Background";
import globalStyles from "../../../globalStyles";
import { connect } from "react-redux";

class LoadingScreen extends React.Component {
  render() {
    return (
      <View style={styles.container}>
        {this.props.noBackground ? null : <Background />}
        <View style={styles.horizontal}>
          <ActivityIndicator size="large" color={globalStyles.white} />
        </View>
      </View>
    );
  }
}

const styles = EStyleSheet.create({
  loadingContainer: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
  },
  horizontal: {
    flexDirection: "row",
    justifyContent: "center",
    padding: "25rem",
  },
  container: {
    flex: 1,
  },
});

const mapStateToProps = (state) => {
  return {
    reactRem: state.homeScreenLeagues.reactRem,
  };
};

export default connect(mapStateToProps, null)(LoadingScreen);
