import {
  SELECT_ROOM,
  SET_JOINED_ROOMS_DATA,
  CREATE_ROOM,
  JOIN_ROOM,
  DELETE_ROOM_CODE,
  DELETE_ROOM_DATA,
  SET_H2H_ROOMS_DATA,
  SET_PRIVATE_ROOMS_DATA,
  LEAVE_ROOM,
  SET_PRIORITY_MESSAGE,
  ROOMS_TO_LEAVE,
} from "../actions/actionTypes";

const initialState = {
  globalRooms: null,
  headToHeadRooms: null,
  privateRooms: null,
  roomCode: null,
  message: null,
  roomId: null,
  leaveRoomMessage: null,
  roomsToLeave: null,
  adverts: [],
};

const privateRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROOMS_TO_LEAVE: {
      return {
        ...state,
        roomsToLeave: action.data,
      };
    }
    case SET_PRIORITY_MESSAGE: {
      return {
        ...state,
        priorityMessage: action.data,
      };
    }
    case LEAVE_ROOM: {
      return {
        ...state,
        leaveRoomMessage: action.data,
      };
    }
    case SET_JOINED_ROOMS_DATA: {
      return {
        ...state,
        globalRooms: action.data.rooms,
        adverts: action.data.banner,
      };
    }
    case SET_H2H_ROOMS_DATA: {
      return {
        ...state,
        leaveRoomMessage: null,
        headToHeadRooms: action.data.rooms,
      };
    }
    case SET_PRIVATE_ROOMS_DATA: {
      return {
        ...state,
        leaveRoomMessage: null,
        privateRooms: action.data.rooms,
      };
    }
    case SELECT_ROOM:
      return {
        ...state,
        selectRoom: state.globalRooms.find((room) => {
          return room.id === action.roomId;
        }),
      };
    case CREATE_ROOM: {
      return {
        ...state,
        roomCode: action.data.roomCode,
      };
    }
    case JOIN_ROOM: {
      return {
        ...state,
        message: action.data.roomName,
        roomId: action.data.roomId,
      };
    }
    case DELETE_ROOM_CODE: {
      return {
        ...state,
        roomCode: null,
        roomId: null,
      };
    }
    case DELETE_ROOM_DATA: {
      return {
        ...state,
        globalRooms: null,
        headToHeadRooms: null,
        privateRooms: null,
      };
    }
    default:
      return state;
  }
};

export default privateRoomReducer;
