import React from 'react';
import { Text, View, Image, TouchableWithoutFeedback } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import ArrowDown from '../../../assets/home_arrow_down_old.png';
import { connect } from 'react-redux';

class DefaultPicker extends React.Component {
    render() {
        const { onPress, title, style, correctInput, reactRem } = this.props;
        const pickerContainer = [styles.pickerContainer, {
            height: 8 * reactRem,
            paddingHorizontal: 2 * reactRem
        }];
        const pickerText = [styles.pickerText, { fontSize: 2.4 * reactRem }];
        const btnImageStyle = [styles.btnImage, { height: 2.5 * reactRem, width: 2.5 * reactRem }];

        if (style) {
            pickerContainer.push(style)
        }
        if (correctInput) {
            pickerText.push({ color: globalStyles.red });
        }

        return (<TouchableWithoutFeedback onPress={onPress}>
            <View style={pickerContainer}>
                <View style={styles.pickerTextContinaer}>
                    <Text style={pickerText}>{title}</Text>
                </View>
                <Image source={ArrowDown} style={btnImageStyle} />
            </View>
        </TouchableWithoutFeedback>);
    }
}

const styles = EStyleSheet.create({
    pickerText: {
        fontFamily: globalStyles.fontUbuntuMedium,
        color: globalStyles.textBlue,
        fontSize: "2.4rem",
    },
    pickerContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: globalStyles.white,
        width: "100%",
        height: "8rem",
        paddingHorizontal: "2rem",
        borderBottomWidth: 1,
        borderBottomColor: globalStyles.backgroundGrey
    },
    btnImage: {
        height: "2.5rem",
        width: "2.5rem",
        resizeMode: "contain"
    },
});
const mapStateToProps = state => {
    return {
        reactRem: state.homeScreenLeagues.reactRem,
    };
};
export default connect(mapStateToProps, null)(DefaultPicker);