import React from 'react';
import { Text, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import { connect } from 'react-redux';

const ScreenTitleText = props => {

    const { reactRem } = props;
    const titleStyle = [styles.title, { fontSize: 3.4 * reactRem }];
    const extraTextContainerStyle = [styles.extraTextContainer, { marginBottom: 2 * reactRem }];
    const extraTextStyle = [styles.extraText, { fontSize: 2.4 * reactRem }];
    return (
        <View style={styles.container}>
            <View style={styles.titleContainer}>
                <Text style={titleStyle}>{props.title}</Text>
            </View>
            <View style={extraTextContainerStyle}>
                <Text style={extraTextStyle}>{props.extraText}</Text>
            </View>
        </View>
    )

};

const styles = EStyleSheet.create({
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    titleContainer: {
        width: "100%",
        alignItems: "center",
    },
    title: {
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuBold,
        fontSize: "3.4rem"
    },
    extraTextContainer: {
        width: "100%",
        alignItems: "center",
        marginBottom: "2rem"
    },
    extraText: {
        textAlign: "center",
        color: globalStyles.black,
        fontFamily: globalStyles.fontRobotoMedium,
        fontSize: "2.4rem"
    }
});

const mapStateToProps = state => {
    return {
        reactRem: state.homeScreenLeagues.reactRem,
    };
};
export default connect(mapStateToProps, null)(ScreenTitleText);