import {
  SET_MATCH_DATA,
  SET_FUTURE_MATCH_DATA,
  MATCH_BET,
  DELETE_FUTURE_MATCH_INFO,
} from "./actionTypes";
import { authFetch, authFetchNoToken } from "../../Common/store/actions/token";
import { uiStartLoading, uiStopLoading } from "../../Common/store/actions/ui";

export const getMatchData = (matchId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/match/info/" + matchId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setMatchData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getMatchDataNoToken = (matchId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetchNoToken("/match/info/" + matchId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setMatchData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getFutureMatchData = (matchId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/leagues/matches/" + matchId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setFutureMatchData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getFutureMatchDataNoToken = (matchId) => {
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetchNoToken("/leagues/matches/" + matchId))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setFutureMatchData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const setMatchBet = (bet, matchId, leagueId, roomId, specialRoom) => {
  const data = { matchId: matchId, bet: bet };
  return (dispatch) => {
    dispatch(authFetch("/users/match", "PUT", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(
          setMatchBetInState(bet, matchId, leagueId, roomId, specialRoom)
        );
      });
  };
};

export const setMatchSpecialGameBet = (bet, matchId, leagueId, roomId) => {
  const data = { room_id: roomId, game_id: matchId, prediction: bet };
  return (dispatch) => {
    dispatch(authFetch("/special/prediction", "PUT", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setMatchBetInState(bet, matchId, leagueId, roomId, true));
      });
  };
};

export const setFutureMatchData = (data) => {
  return {
    type: SET_FUTURE_MATCH_DATA,
    data,
  };
};

export const setMatchData = (data) => {
  return {
    type: SET_MATCH_DATA,
    data,
  };
};

export const setMatchBetInState = (
  bet,
  matchId,
  leagueId,
  roomId,
  specialRoom
) => {
  return {
    type: MATCH_BET,
    matchId: matchId,
    bet: bet,
    leagueId: leagueId,
    roomId: roomId,
    specialRoom: specialRoom,
  };
};

export const deleteFutureMatchInfo = () => {
  return {
    type: DELETE_FUTURE_MATCH_INFO,
  };
};
