import React from "react";
import { Text, View } from "react-native";
import { specialGamesIndicatorStyles } from "../styles/styles";

class SpecialGamesIndicator extends React.Component {
  render() {
    const { title } = this.props;

    return (
      <View style={specialGamesIndicatorStyles.chooseButtonContainer}>
        <Text style={specialGamesIndicatorStyles.chooseButtonTitle}>
          {title}
        </Text>
      </View>
    );
  }
}

export default SpecialGamesIndicator;
