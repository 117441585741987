import React from "react";
import { View, Image, Text,TouchableOpacity} from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles'
import BetButton from '../UI/BetButton';
import BackArrow from '../../../assets/back_button_arrow.png'
import ForwardArrow from '../../../assets/forward_button_arrow.png'
import BackArrowWhite from '../../../assets/back_button_white_arrow.png'
import ForwardArrowWhite from '../../../assets/forward_white_button.png'
import { connect } from 'react-redux';
// import LinearGradient from 'react-native-linear-gradient';


class BetContainer extends React.Component {

  state = {
    activeButtonId: -1,
    confirmBet: false,
    currentBet: null,
    matchIsStarted: false,
    selectedRoomId:null,
    isRoomChanged:false,
  }

  changeActiveButton = id => {
    this.setState({ activeButtonId: id,isRoomChanged:false });
  }

  onRemoveBet = ()=>{
    this.setState({ activeButtonId: -1 });
    this.confirmClick();
  }

  confirmClick = () => this.setState({ confirmBet: !this.state.confirmBet });

  matchIsStarted = () => this.setState({ matchIsStarted: true });

  onSetBet = bet => {
    const { matchId, roomId,specialRoom } = this.props;
    this.setState({ currentBet: bet });
    this.props.onSetBet(matchId, bet, roomId,specialRoom)
  }

  onSetCurrentBet = bet => {

    this.setState({ currentBet: bet });
  }

  

  componentDidMount() {
    if (this.props.bet) {
      if (this.props.bet === "1") {
        this.changeActiveButton(0);
      }
      if (this.props.bet === "X") {
        this.changeActiveButton(1)
      }
      if (this.props.bet === "2") {
        this.changeActiveButton(2)
      }
    }
  }

  onRoomChanged =()=>{
    if(this.props.specialRoomsLength>1){
      this.setState({isRoomChanged:!this.state.isRoomChanged})
    }
  }

  componentDidUpdate(prevProps){
    if(!this.state.selectedRoomId){
      this.setState({selectedRoomId:this.props.roomId})
    }
    if(prevProps.roomId===this.state.selectedRoomId && prevProps.roomId!=this.props.roomId){
      this.setState({selectedRoomId:this.props.roomId,isRoomChanged:true})
    }

    if(prevProps.roomId!=this.props.roomId){
      if (this.props.bet === "1") {
        this.changeActiveButton(0);
      }
      else if (this.props.bet === "X") {
        this.changeActiveButton(1)
      }
      else if (this.props.bet === "2") {
        this.changeActiveButton(2)
      }else{
        this.changeActiveButton(-1)
      }
    }
    
  }
  milliseconds = (h, m, s) => ((h * 60 * 60 + m * 60 + s) * 1000);
  millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    return minutes;
  };


  onPrevRoomPress = ()=>{
    this.props.onPrevRoom();
    this.onRoomChanged();
  }

  onNextRoomPress = ()=>{
    this.props.onNextRoom();
    this.onRoomChanged();
  }

  onBetButtonPress=(actionButtonId,bet)=>{
    this.changeActiveButton(actionButtonId);
    this.onSetBet(bet);
  }

  render() {
    const { activeButtonId, confirmBet, currentBet, matchIsStarted } = this.state;
    const {reactRem} = this.props;
    const { specialRoomName, bet, homeTeam, guestTeam, matchId, time, matchDate, leagueName, specialRoom, roomId, onPrevRoom, onNextRoom } = this.props;
    let betContainer = [styles.betContainer,{marginTop:reactRem,borderRadius:1.5*reactRem}];
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    const timeParts = time.split(":");
    const matchTimeMillSec = this.milliseconds(timeParts[0], timeParts[1], 0);
    const currentTimeMillSec = this.milliseconds(hours, min, 0);

    const matchDateToFind = matchDate.split("-");

    var date = new Date(matchDateToFind[1] + "/" + matchDateToFind[2] + "/" + matchDateToFind[0]);
    var matchDateMillSec = date.getTime();
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0)
    var currentDateMillSec = currentDate.getTime();

    if (matchDateMillSec === currentDateMillSec && matchTimeMillSec - currentTimeMillSec < 1 && !matchIsStarted) {
      betContainer.push({ opacity: 0.5 });
      this.matchIsStarted()
    }

    const defaultColor = [globalStyles.white,globalStyles.white,globalStyles.white];
    const selectedColor = [globalStyles.activeBlue, globalStyles.gradientBlue,globalStyles.gradientBlue];
    const betButtonContainerStyle = [styles.betButtonContainer,{height:6*reactRem}];
    const chooseTextStyle =[styles.chooseText,{fontSize:2.6*reactRem}];
    const predictRoomTextStyle =[styles.predictRoomText,{fontSize:2.6*reactRem,marginTop:0.5*reactRem,marginBottom:1.5*reactRem}];
    const roomTitleContainerStyle = [styles.roomTitleContainer];

    if(activeButtonId!=-1){
      betButtonContainerStyle.push({opacity:0.5});
      roomTitleContainerStyle.push({opacity:0.5})
      chooseTextStyle.push({color:globalStyles.white});
      predictRoomTextStyle.push({color:globalStyles.textBlue})
    }
    return (
      <View style={[styles.container,{marginTop:2*reactRem}]}>
        <View style={[styles.roomPredictionContainer,{paddingLeft:1.5*reactRem,paddingRight:1.5*reactRem}]}>
          <View style={[styles.predictTextContainer,{paddingLeft:reactRem}]}>
            <Text style={[styles.predictText,{fontSize:2.6*reactRem}]}>PREDICT</Text>
            {/* <Text style={styles.predictRoomText}>{specialRoom?" "+ specialRoomName :" - Global/Private/H2H"}</Text> */}
          </View>
          <View style={activeButtonId!=-1?[styles.linearGradientContainer,{borderRadius:1.5*reactRem,marginTop:reactRem}]:[styles.linearGradientContainerWhite,{borderRadius:1.5*reactRem,marginTop:reactRem}]}>
            <View style={roomTitleContainerStyle}>
              <View style={{ justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Text style={[styles.pickWinnerText,{fontSize:2.6*reactRem,marginTop:2*reactRem}]}>{specialRoom? specialRoomName:leagueName}</Text>
                <Text style={predictRoomTextStyle}>{specialRoom ? "Special Room" : "Global / Private / H2H"}</Text>
              </View>
            </View>
            <View style={[styles.betInfoContainer,{marginBottom:1.5*reactRem}]}>
              <TouchableOpacity onPress={() => this.onPrevRoomPress()}>
                <View style={[styles.backArrowContainer,{height:6*reactRem,paddingLeft:2*reactRem}]}>
                  <Image source={activeButtonId!=-1?BackArrowWhite:BackArrow} style={[styles.arrowImage,{height:4*reactRem,width:4*reactRem}]} />
                </View>
              </TouchableOpacity>
              <View style={betButtonContainerStyle}>
                <BetButton
                  id={0}
                  activeButtonId={activeButtonId}
                  reactRem={reactRem}
                  onPress={() => this.onBetButtonPress(0,"1")}
                  disabled={activeButtonId!=-1?true:false}
                  bet="1" />
                <BetButton
                  id={1}
                  activeButtonId={activeButtonId}
                  reactRem={reactRem}
                  onPress={() => this.onBetButtonPress(1,"X")}
                  disabled={activeButtonId!=-1?true:false}
                  bet="X" />
                <BetButton
                  id={2}
                  activeButtonId={activeButtonId}
                  reactRem={reactRem}
                  onPress={() => this.onBetButtonPress(2,"2")}
                  disabled={activeButtonId!=-1?true:false}
                  bet="2" />
              </View>
              <TouchableOpacity onPress={() => this.onNextRoomPress()}>
                <View style={[styles.forwardArrowContainer,{height:6*reactRem,paddingLeft:2*reactRem}]}>
                  <Image  source={activeButtonId!=-1?ForwardArrowWhite:ForwardArrow} style={[styles.arrowImage,{height:4*reactRem,width:4*reactRem}]} />
                </View>
              </TouchableOpacity>
            </View>
            <TouchableOpacity onPress={()=>this.onRemoveBet()}>
              <View style={{ flexDirection: "row" }}>
                <View style={[styles.chooseTextContainer,{marginBottom:2*reactRem}]}>
                  <Text style={chooseTextStyle}>{activeButtonId!=-1?"CHANGE":"CHOOSE"}</Text>
                </View>
              </View>
            </TouchableOpacity>
            </View>
        </View>
      </View>
    );
  }
}


const styles = EStyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: "2rem",
  },
  roomPredictionContainer: {
    width: "100%",
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem"
  },
  predictTextContainer: {
    width:"100%",
    flexDirection: "row",
    paddingLeft: "1rem"
  },
  predictText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: '2.6rem'
  },
  predictRoomText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: '2.6rem',
    marginTop: "0.5rem",
    marginBottom: "1.5rem"
  },
  betContainer: {
    backgroundColor: globalStyles.white,
    width: "100%",
    alignItems: 'center',
    marginTop: "1rem",
    borderRadius: "1.5rem"
  },
  pickWinnerText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: '2.6rem',
    marginTop: "2rem",
  },
  betInfoContainer: {
    flexDirection: "row",
    marginBottom: "1.5rem",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  betButtonContainer: {
    width: "41%",
    flexDirection: "row",
    height: "6rem",
    alignItems: "center",
    justifyContent: "center",
  },
  chooseTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "2rem"
  },
  chooseText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: '2.6rem',
  },
  backArrowContainer: {
    justifyContent: "center",
    width: "29%",
    maxWidth: "29%",
    height: "6rem",
    paddingLeft: "2rem",
  },
  forwardArrowContainer: {
    justifyContent: "center",
    width: "29%",
    maxWidth: "29%",
    height: "6rem",
    paddingRight:"2rem",
  },
  arrowImage: {
    height: "4rem",
    width: "4rem",
    resizeMode: "contain"
  },
  roomTitleContainer:{
    flexDirection:'row'
  },
  linearGradientContainer:{
    width:"100%",
    alignItems:"center",
    justifyContent:"center",
    background: 'linear-gradient(151deg, rgba(163,255,231,1) 6%, rgba(145,226,225,1) 41%, rgba(5,171,204,1) 100%)',
    borderRadius: "1.5rem",
    marginTop:"1rem"
  },
  linearGradientContainerWhite:{
    width:"100%",
    alignItems:"center",
    justifyContent:"center",
    backgroundColor:globalStyles.white,
    borderRadius: "1.5rem",
    marginTop:"1rem"
  }
  

});

const mapStateToProps = state => {
  return {
    reactRem:state.homeScreenLeagues.reactRem,
  };
};

export default connect(mapStateToProps,null)(BetContainer);