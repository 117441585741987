import React from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import { connect } from "react-redux";
import ShareImg from "../../assets/white_dots.png";
import firebase from "../../fire";
import Firebase from "firebase";
import { deleteRoomCode } from "../actions/rooms";
import ShareModal from "../../Common/components/UI/ShareModal";
import { successfullyCreatedRoomStyles } from "../styles/styles";
class SuccessfullyCreatedRoom extends React.Component {
  state = {
    isModalVisible: false,
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  componentDidMount() {
    const roomName = this.props.navigation.getParam("roomName", "1");
    const roomType = this.props.navigation.getParam("roomType", "1");
    firebase
      .database()
      .ref("rooms/" + roomName)
      .set({ name: roomName, roomType: roomType });
    let lastMessageUpdates = {};
    let lastMessage = {
      time: Firebase.database.ServerValue.TIMESTAMP,
    };
    lastMessageUpdates["rooms/" + roomName + "/" + "lastMessage"] = lastMessage;
    firebase.database().ref().update(lastMessageUpdates);
  }

  onPressButton = () => {
    this.props.deleteRoomCode();
    setTimeout(() => {
      this.props.navigation.goBack(null);
      this.props.navigation.goBack(null);
    }, 50);
  };

  render() {
    const roomCode = this.props.navigation.getParam("roomCode", "1");
    const roomName = this.props.navigation.getParam("roomName", "1");
    const { reactRem } = this.props;

    return (
      <View style={successfullyCreatedRoomStyles.container}>
        <View style={successfullyCreatedRoomStyles.roomContainer}>
          <Text style={successfullyCreatedRoomStyles.congratulationsText}>
            CONGRATULATIONS!
          </Text>
          <View style={successfullyCreatedRoomStyles.roomNameContainer}>
            <Text style={successfullyCreatedRoomStyles.roomNameText}>
              "{roomName}"
            </Text>
            <Text style={successfullyCreatedRoomStyles.defaultText}>
              was successfully created
            </Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={successfullyCreatedRoomStyles.roomCodeContainer}>
              <Text style={successfullyCreatedRoomStyles.roomCodeText}>
                Room Code:
              </Text>
              <Text style={successfullyCreatedRoomStyles.codeText}>
                {roomCode}
              </Text>
            </View>
            <TouchableOpacity
              style={successfullyCreatedRoomStyles.menuContainer}
              onPress={() => this.toggleModal()}
            >
              <Image
                source={ShareImg}
                style={successfullyCreatedRoomStyles.shareImg}
              />
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            style={successfullyCreatedRoomStyles.continueContainer}
            onPress={() => this.onPressButton()}
          >
            <Text style={successfullyCreatedRoomStyles.continueText}>
              {"CONTINUE"}
            </Text>
          </TouchableOpacity>
        </View>
        <ShareModal
          reactRem={reactRem}
          isModalVisible={this.state.isModalVisible}
          onChangeModalVisibility={this.toggleModal}
          message={
            "Join me in Glowter!\nYou have been invited in room: " +
            roomName +
            "\nwith room code: " +
            roomCode
          }
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomName: state.roomLeague.roomName,
    reactRem: state.homeScreenLeagues.reactRem,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRoomCode: () => dispatch(deleteRoomCode()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessfullyCreatedRoom);
