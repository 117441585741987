import {
  SELECT_ROOM,
  SET_ROOM_PREDICTION_DATA,
  SET_LEAGUE_PREDICTIONS_BY_WEEK,
  ADD_NEW_LEAGUE,
  THE_LAST_GAME_WEEK,
  SET_MOBILE_PREDICTION_DATA,
  SET_MOBILE_LEAGUE_PREDICTIONS_BY_WEEK,
} from "../actions/actionTypes";

const initialState = {
  leagues: null,
  addLeagueSuccesfull: null,
};

const predictionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOBILE_PREDICTION_DATA: {
      return {
        ...state,
        leagues: action.data.leagues,
      };
    }
    case SET_ROOM_PREDICTION_DATA: {
      return {
        ...state,
        leagues: action.data.leagues,
      };
    }
    case SELECT_ROOM:
      return {
        ...state,
        selectRoom: state.globalRooms.find((room) => {
          return room.id === action.roomId;
        }),
      };
    case SET_MOBILE_LEAGUE_PREDICTIONS_BY_WEEK: {
      const leagues = [...state.leagues];
      const leagueIndex = leagues.findIndex(
        (league) => Number(league.leagueId) === Number(action.data.leagueId)
      );
      const league = { ...leagues[leagueIndex] };
      const leagueGameWeeks = [...league.gameWeeks];
      if (
        Number(leagueGameWeeks[0].gameWeek) >
        Number(action.data.gameWeeks.gameWeek)
      ) {
        leagueGameWeeks[1] = { ...leagueGameWeeks[0] };
        leagueGameWeeks[0] = action.data.gameWeeks;
      }
      if (
        Number(leagueGameWeeks[1].gameWeek) <
        Number(action.data.gameWeeks.gameWeek)
      ) {
        leagueGameWeeks[0] = { ...leagueGameWeeks[1] };
        leagueGameWeeks[1] = action.data.gameWeeks;
      }
      league.gameWeeks = leagueGameWeeks;
      leagues[leagueIndex] = league;
      return { ...state, leagues };
    }
    case SET_LEAGUE_PREDICTIONS_BY_WEEK: {
      const leagues = [...state.leagues];
      const leagueIndex = leagues.findIndex(
        (league) => Number(league.leagueId) === Number(action.data.leagueId)
      );
      const league = { ...leagues[leagueIndex] };
      const leagueGameWeeks = [...league.gameWeeks];
      if (leagueGameWeeks.length === 4) {
        if (
          Number(leagueGameWeeks[0].gameWeek) >
          Number(action.data.gameWeeks.gameWeek)
        ) {
          leagueGameWeeks[3] = { ...leagueGameWeeks[2] };
          leagueGameWeeks[2] = { ...leagueGameWeeks[1] };
          leagueGameWeeks[1] = { ...leagueGameWeeks[0] };
          leagueGameWeeks[0] = action.data.gameWeeks;
        } else if (
          Number(leagueGameWeeks[3].gameWeek) <
          Number(action.data.gameWeeks.gameWeek)
        ) {
          leagueGameWeeks[0] = { ...leagueGameWeeks[1] };
          leagueGameWeeks[1] = { ...leagueGameWeeks[2] };
          leagueGameWeeks[2] = { ...leagueGameWeeks[3] };
          leagueGameWeeks[3] = action.data.gameWeeks;
        }
      } else {
        if (
          Number(leagueGameWeeks[0].gameWeek) >
          Number(action.data.gameWeeks.gameWeek)
        ) {
          leagueGameWeeks.splice(0, 0, action.data.gameWeeks);
        }
      }
      league.gameWeeks = leagueGameWeeks;
      leagues[leagueIndex] = league;
      return { ...state, leagues };
    }
    case THE_LAST_GAME_WEEK: {
      const leagues = [...state.leagues];
      const leagueIndex = leagues.findIndex(
        (league) => Number(league.leagueId) === Number(action.leagueId)
      );
      const league = { ...leagues[leagueIndex] };
      const leagueGameWeeks = [...league.gameWeeks];
      if (leagueGameWeeks.length === 4) {
        leagueGameWeeks.splice(0, 1);
      }
      if (action.isMobile && leagueGameWeeks.length === 2) {
        leagueGameWeeks.splice(0, 1);
      }
      league.gameWeeks = leagueGameWeeks;
      leagues[leagueIndex] = league;
      return { ...state, leagues };
    }
    case ADD_NEW_LEAGUE: {
      return {
        ...state,
        addLeagueSuccesfull: action.data,
      };
    }
    default:
      return state;
  }
};

export default predictionsReducer;
