import React from "react";
import { View, Text, Image } from "react-native";
import globalStyles from "../../globalStyles";
import defaultUserImage from "../../assets/profile_avatar.png";
import { specialGamesPlayerFieldStyles } from "../styles/styles";

class SpecialGamesPlayerField extends React.Component {
  render() {
    const { player } = this.props;

    return (
      <View style={specialGamesPlayerFieldStyles.playerInfoContainer}>
        <View style={specialGamesPlayerFieldStyles.playerPositionContainer}>
          <Text style={specialGamesPlayerFieldStyles.positionText}>
            {player.position || this.props.position + 1}
          </Text>
        </View>
        <View style={specialGamesPlayerFieldStyles.playerNameBetContainer}>
          <View style={specialGamesPlayerFieldStyles.playerImageContainer}>
            <Image
              source={
                player.img
                  ? { uri: globalStyles.BASE_URL + player.img }
                  : defaultUserImage
              }
              style={specialGamesPlayerFieldStyles.profileImage}
            />
          </View>
          <View>
            <Text style={specialGamesPlayerFieldStyles.playerUsernameText}>
              {player.user_name}
            </Text>
            <Text style={specialGamesPlayerFieldStyles.playerNameText}>
              {player.playerName}
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

export default SpecialGamesPlayerField;
