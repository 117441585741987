import React from "react";
import {
  View,
  FlatList,
  Platform,
  Image,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Share,
  Clipboard,
} from "react-native";
import { connect } from "react-redux";
import RoomLeagueTitleContainer from "../UI/RoomLeagueTitleContainer";
import AnimationRoomLeagueTitleContainer from "../UI/AnimationRoomLeagueTitleContainer";
import {
  getDataForLeague,
  getSelectedRoomData,
  getSelectedRoomDataNoToken,
  deleteRoomCode,
  getPredictionData,
  getPredictionDataNoToken,
  setLeaguesWithGameWeeks,
  deleteLeagueGW,
  getSelectedUserPredictionData,
  leaveRoom,
  setSelectedUserPredictionData,
  setLeaguesInRoom,
} from "../actions/rooms";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import H2HContainer from "../UI/H2HContainer";
import NavButton from "../../Common/components/UI/HomeNavButton";
import AlertDialogWithConfirmButton from "../../Common/components/UI/AlertDialogWithConfirmButton";

import DownArrow from "../../assets/room_down_arrow.png";
import RoomRankingPlayerInfo from "../UI/RoomRankingPlayerInfo";
import LeagueList from "../../Common/LeagueList/LeagueList";
import SideMenu from "../../Common/components/UI/SideMenu";
import {
  setAlertDialogTitle,
  setLeaveRoom,
  setLeaveRoomId,
} from "../../Common/store/actions/resources";
import LeagueAd from "../../Common/LeagueList/LeagueAd";
import { roomLeagueStyles } from "../styles/styles";

class RoomLeagueScreen extends React.Component {
  state = {
    selectBtn: false,
    leagueSelected: false,
    leagueId: -1,
    refreshing: false,
    activeButtonId: 0,
    userSelected: null,
    sideMenu: false,
    offset: 0,
    showFullPoints: false,
    isModalVisible: false,
    roomIdToLeave: -1,
    search: false,
    scrollDisabled: false,
    fromSideMenu: false,
    fullPeopleRankingInRoom: [],
    currentPage: 1,
    loadedMorePeople: true,
  };
  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };
  onSearchIsPressed = (isOnSearchPressed) => {
    this.setState({ search: isOnSearchPressed });
  };
  onCloseGameRoom = () => {
    this.props.navigation.goBack();
  };

  onShowFullPoint = () => {
    this.setState({
      showFullPoints: !this.state.showFullPoints,
      sideMenu: false,
      fromSideMenu: true,
    });
  };

  onChangeShowPoints = () => {
    this.setState({ showFullPoints: !this.state.showFullPoints });
  };

  onSelectUser = (user) => {
    if (this.props.navigation.getParam("roomNameType", null) !== "Head to Head")
      this.setState({ userSelected: user });
    if (user) {
      this.props.getSelectedUserPredictionData(
        this.props.navigation.getParam("roomId", "1"),
        this.props.leaguesGW,
        user.userId
      );
    }
  };
  changeActiveButton = (id) => {
    if (id === 1) {
      this.setState({ activeButtonId: id, sideMenu: false });
    } else {
      this.setState({ activeButtonId: id });
    }
  };
  _onRefresh = () => {
    this.setState({ refreshing: true });
    if (this.props.userId) {
      this.props.getSelectedRoomData(
        this.props.navigation.getParam("roomId", "1")
      );
    } else {
      this.props.getSelectedRoomDataNoToken(
        this.props.navigation.getParam("roomId", "1")
      );
    }
  };

  changeSelectBtn = (open) => {
    this.setState({ selectBtn: !open });
  };

  onPickLeague = (id) => {
    this.changeSelectBtn(true);
    this.props.getDataForLeague(
      this.props.navigation.getParam("roomId", "1"),
      id
    );
    this.setState({ leagueSelected: true });
    this.setState({ leagueId: id });
  };

  onTotalScorePicker = () => {
    this.changeSelectBtn(true);
    this.setState({ leagueSelected: false });
    if (this.props.userId) {
      this.props.getSelectedRoomData(
        this.props.navigation.getParam("roomId", "1")
      );
    } else {
      this.props.getSelectedRoomDataNoToken(
        this.props.navigation.getParam("roomId", "1")
      );
    }
  };

  onOpenMenu = () => {
    this.setState({ sideMenu: !this.state.sideMenu });
  };

  onLoadMorePeople = () => {
    if (this.props.userId) {
      this.props.getSelectedRoomData(
        this.props.navigation.getParam("roomId", "1")
      );
    } else {
      this.props.getSelectedRoomDataNoToken(
        this.props.navigation.getParam("roomId", "1")
      );
    }
    this.setState({
      loadedMorePeople: true,
    });
  };

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    this.goToTop();
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
    if (this.props.userId) {
      this.props.getSelectedRoomData(
        this.props.navigation.getParam("roomId", "1")
      );
      this.props.getPredictionData(
        this.props.navigation.getParam("roomId", "1"),
        null
      );
    } else {
      this.props.getSelectedRoomDataNoToken(
        this.props.navigation.getParam("roomId", "1")
      );
      this.props.getPredictionDataNoToken(
        this.props.navigation.getParam("roomId", "1"),
        null
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { matches, leaguesGW, roomId } = this.props;

    const roomNameType = this.props.navigation.getParam("roomNameType", null);

    if (this.props.roomIdFrom) {
      this.props.deleteRoomCode();
    }

    if (this.state.refreshing) {
      this.setState({ refreshing: false });
    }

    if (
      this.state.loadedMorePeople &&
      prevProps.peopleRankingInRoom !== this.props.peopleRankingInRoom
    ) {
      this.setState({
        fullPeopleRankingInRoom: this.state.fullPeopleRankingInRoom.concat(
          this.props.peopleRankingInRoom
        ),
        currentPage: this.state.currentPage + 1,
        loadedMorePeople: false,
      });
    }

    if (!leaguesGW && matches) {
      const leaguesWithGameWeeks = [];
      matches.forEach((league) => {
        leaguesWithGameWeeks.push({
          league_id: league.id,
          game_week: league.gameWeek,
        });
      });
      this.props.setLeaguesWithGameWeeks(leaguesWithGameWeeks);
    }
    if (prevProps.leaguesGW && leaguesGW && prevProps.leaguesGW != leaguesGW) {
      if (this.props.userId) {
        this.props.getPredictionData(
          this.props.navigation.getParam("roomId", "1"),
          leaguesGW
        );
      } else {
        this.props.getPredictionDataNoToken(
          this.props.navigation.getParam("roomId", "1"),
          leaguesGW
        );
      }
      if (this.state.userSelected) {
        this.props.getSelectedUserPredictionData(
          this.props.navigation.getParam("roomId", "1"),
          leaguesGW,
          this.state.userSelected.userId
        );
      }
    }
    if (prevProps.roomId != roomId && matches) {
      const leaguesWithGameWeeks = [];
      matches.forEach((league) => {
        leaguesWithGameWeeks.push({
          league_id: league.id,
          game_week: league.gameWeek,
        });
      });

      this.props.setLeaguesWithGameWeeks(leaguesWithGameWeeks);
      if (roomNameType === "Head to Head" && this.state.userSelected) {
        this.props.getSelectedUserPredictionData(
          this.props.navigation.getParam("roomId", "1"),
          leaguesWithGameWeeks,
          this.state.userSelected.userId
        );
      }
    }
    if (
      roomNameType === "Head to Head" &&
      matches &&
      leaguesGW &&
      !matches[0].selectedUserPredictions
    ) {
      if (this.state.userSelected) {
        this.props.getSelectedUserPredictionData(
          this.props.navigation.getParam("roomId", "1"),
          leaguesGW,
          this.state.userSelected.userId
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.deleteLeagueGW();
    this.props.setLeaguesInRoom(null);
  }

  onStaticsShow = (userId) => {
    this.props.navigation.navigate("StatisticsScreen", { userId: userId });
  };

  getInitials = (name) => {
    var parts = name.split(" ");
    var initials = "";
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    return initials;
  };

  onLeaveRoom = (roomId) => {
    const rooms = [];
    rooms.push(roomId);
    this.toggleModal();
    this.onOpenMenu();
    this.props.setLeaveRoom(true);
    this.props.setLeaveRoomId(rooms);
  };

  onShare = async (roomCode) => {
    this.setState({ sideMenu: false });
    try {
      const result = await Share.share({
        message:
          "Join me in Glowter!\nYou have been invited in room: " +
          this.props.roomName +
          "\nwith room code: " +
          roomCode,
      });

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message);
    }
  };

  onCopyCode = async (roomCode) => {
    this.setState({ sideMenu: false });
    await Clipboard.setString(roomCode);
  };

  toggleVisibility() {
    if (window.pageYOffset > 0 && this.state.offset === 0) {
      this.setState({ offset: window.pageYOffset });
    }
    if (window.pageYOffset === 0 && this.state.offset != 0) {
      this.setState({ offset: 0 });
    }
  }

  render() {
    const {
      peopleRankingInRoom,
      roomName,
      matches,
      navigation,
      leaguesInRoom,
      banner,
      totalPages,
    } = this.props;

    const {
      activeButtonId,
      offset,
      showFullPoints,
      fullPeopleRankingInRoom,
      currentPage,
    } = this.state;

    if (!roomName || (roomName && fullPeopleRankingInRoom.length === 0)) {
      return <LoadingScreen />;
    }

    let leagueImg = this.props.navigation.getParam("roomImg", "1");
    let roomId = this.props.navigation.getParam("roomId", "1");
    const roomCode = this.props.navigation.getParam("roomCode", null);
    const roomNameType = this.props.navigation.getParam("roomNameType", null);
    let playerOnePosition;
    let playerOneImage;
    let playerTwoImage;
    let playerOneName;
    let playerTwoName;
    if (
      roomNameType === "Head to Head" &&
      peopleRankingInRoom &&
      peopleRankingInRoom.length > 1
    ) {
      playerOnePosition = peopleRankingInRoom[0].id;
      playerOneImage = peopleRankingInRoom[0].img;
      playerTwoImage = peopleRankingInRoom[1].img;
      playerOneName = peopleRankingInRoom[0].playerName;
      playerTwoName = peopleRankingInRoom[1].playerName;
    }
    if (
      roomNameType === "Head to Head" &&
      peopleRankingInRoom &&
      peopleRankingInRoom.length === 1
    ) {
      playerOnePosition = peopleRankingInRoom[0].id;
      playerOneImage = peopleRankingInRoom[0].img;
      playerOneName = peopleRankingInRoom[0].playerName;
    }

    return (
      <View style={roomLeagueStyles.container}>
        <View style={roomLeagueStyles.buttonContainer}>
          <NavButton
            id={0}
            activeButtonId={activeButtonId}
            onPress={() => this.changeActiveButton(0)}
            onGetData={() => null}
            title={"Predictions"}
          />
          <NavButton
            id={1}
            activeButtonId={activeButtonId}
            onPress={() => this.changeActiveButton(1)}
            onGetData={() => null}
            title={"Standings"}
          />
        </View>
        {activeButtonId === 1 && roomNameType != "Head to Head" ? (
          <View style={{ width: "100%" }}>
            <AnimationRoomLeagueTitleContainer
              offset={offset}
              leaguesInRoom={leaguesInRoom}
              roomNameType={roomNameType}
              roomName={roomName}
              leagueImg={leagueImg}
              roomCode={roomCode}
              user={this.props.userBeted}
              onOpenMenu={this.onOpenMenu}
            />
            <View
              style={
                roomNameType === "Private"
                  ? roomLeagueStyles.roomPlayerPadding
                  : roomLeagueStyles.roomPlayersContainer
              }
            >
              <View style={roomLeagueStyles.listItem}>
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Text style={roomLeagueStyles.leagueTitle}>Ranking</Text>
                </View>
              </View>
              <View style={roomLeagueStyles.infoTableTextContainer}>
                <View style={roomLeagueStyles.positionTextContainer}>
                  <Text style={roomLeagueStyles.infoTableText}>Pos.</Text>
                </View>
                <View style={roomLeagueStyles.nameTextContainer}>
                  <Text style={roomLeagueStyles.infoTableText}>Player</Text>
                </View>
                <View style={roomLeagueStyles.totalTextContainer}>
                  <Text style={roomLeagueStyles.totalText}>Total Pts. </Text>
                  <Image
                    source={DownArrow}
                    style={roomLeagueStyles.totalArrow}
                  />
                </View>
                <View style={roomLeagueStyles.lastTextContainer}>
                  <Text style={roomLeagueStyles.infoTableText}>Last</Text>
                </View>
              </View>
            </View>
            {this.props.userBeted && roomNameType != "Head to Head" ? (
              <View style={roomLeagueStyles.infoTableContainer}>
                <RoomRankingPlayerInfo
                  {...this.props.userBeted}
                  loggedUser={true}
                  onStaticsShow={this.onStaticsShow}
                  showFullPoints={showFullPoints}
                />
              </View>
            ) : null}
          </View>
        ) : null}
        {activeButtonId === 1 ? (
          <View
            refreshing={this.state.refreshing}
            onRefresh={this._onRefresh}
            style={roomLeagueStyles.fieldsContainer}
          >
            {roomNameType === "Head to Head" ? (
              <RoomLeagueTitleContainer
                leaguesInRoom={leaguesInRoom}
                roomNameType={roomNameType}
                roomName={roomName}
                leagueImg={leagueImg}
                roomCode={roomCode}
                onOpenMenu={this.onOpenMenu}
              />
            ) : null}
            {roomNameType === "Head to Head" && peopleRankingInRoom ? (
              <H2HContainer
                playerOnePosition={playerOnePosition}
                playerOneImage={playerOneImage}
                playerTwoImage={playerTwoImage}
                playerOneInitials={playerOneName}
                playerTwoInitials={playerTwoName}
              />
            ) : null}
            {roomNameType === "Head to Head" ? (
              <View style={roomLeagueStyles.roomPlayersContainer}>
                <View style={roomLeagueStyles.listItem}>
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Text style={roomLeagueStyles.leagueTitle}>Ranking</Text>
                  </View>
                </View>
                <View style={roomLeagueStyles.infoTableTextContainer}>
                  <View style={roomLeagueStyles.positionTextContainer}>
                    <Text style={roomLeagueStyles.infoTableText}>Pos.</Text>
                  </View>
                  <View style={roomLeagueStyles.nameTextContainer}>
                    <Text style={roomLeagueStyles.infoTableText}>Player</Text>
                  </View>
                  <View style={roomLeagueStyles.totalTextContainer}>
                    <Text style={roomLeagueStyles.totalText}>Total Pts. </Text>
                    <Image
                      source={DownArrow}
                      style={roomLeagueStyles.totalArrow}
                    />
                  </View>
                  <View style={roomLeagueStyles.lastTextContainer}>
                    <Text style={roomLeagueStyles.infoTableText}>Last</Text>
                  </View>
                </View>
              </View>
            ) : null}
            <View style={roomLeagueStyles.playersContainer}>
              <FlatList
                style={{ width: "100%" }}
                data={fullPeopleRankingInRoom}
                showFullPoints={showFullPoints}
                keyExtractor={(item, index) => "key" + index}
                renderItem={({ item, index }) => (
                  <RoomRankingPlayerInfo
                    {...item}
                    maxLength={fullPeopleRankingInRoom.length}
                    index={index}
                    id={index + 1}
                    roomNameType={roomNameType}
                    showFullPoints={showFullPoints}
                    onStaticsShow={this.onStaticsShow}
                  />
                )}
              />
            </View>
            {this.state.loadedMorePeople ? (
              <View>
                <ActivityIndicator />
              </View>
            ) : null}
            {currentPage !== totalPages &&
            fullPeopleRankingInRoom.length >= 10 ? (
              <TouchableOpacity
                style={roomLeagueStyles.loadMoreButtonContainer}
                onPress={() => this.onLoadMorePeople()}
              >
                <Text style={roomLeagueStyles.loadMoreButtonText}>
                  LOAD MORE
                </Text>
              </TouchableOpacity>
            ) : null}
            <View style={roomLeagueStyles.placeContainer} />
            {banner && banner.length != 0 ? (
              <LeagueAd banner={banner} navigation={this.props.navigation} />
            ) : null}
          </View>
        ) : (
          <View
            refreshing={this.state.refreshing}
            onRefresh={this._onRefresh}
            style={roomLeagueStyles.fieldsContainer}
            scrollDisabled={this.state.scrollDisabled}
          >
            <RoomLeagueTitleContainer
              leaguesInRoom={leaguesInRoom}
              roomNameType={roomNameType}
              roomName={roomName}
              leagueImg={leagueImg}
              roomCode={roomCode}
              predictions={true}
              onSelectUser={this.onSelectUser}
              userSelected={this.state.userSelected}
              user={this.props.userBeted}
              setSelectedUserPredictionData={() => {
                this.props.setSelectedUserPredictionData(null);
              }}
              onSearchIsPressed={this.onSearchIsPressed}
              onOpenMenu={this.onOpenMenu}
            />
            <View style={roomLeagueStyles.predictionsContainer}>
              <FlatList
                data={matches}
                userSelected={this.state.userSelected}
                search={this.state.search}
                keyExtractor={(item, index) => "key" + index}
                renderItem={({ item, index }) => (
                  <LeagueList
                    {...item}
                    roomId={roomId}
                    betPoints={true}
                    roomNameType={roomNameType}
                    navigation={navigation}
                    isAllMatches={true}
                    userSelected={this.state.userSelected}
                    search={this.state.search}
                  />
                )}
              />
            </View>
            <View style={roomLeagueStyles.placeContainer} />
            {banner && banner.length != 0 ? (
              <LeagueAd banner={banner} navigation={this.props.navigation} />
            ) : null}
          </View>
        )}
        {this.state.sideMenu ? (
          <SideMenu
            roomCode={roomCode}
            onLeaveRoom={() => this.onLeaveRoom(roomId)}
            roomNameType={roomNameType}
            message={
              roomCode && roomCode != "undefined"
                ? "Join me in Glowter!\nYou have been invited in room: " +
                  this.props.roomName +
                  "\nwith room code: " +
                  roomCode
                : "Join me in Glowter!\nYou have been invited in room: " +
                  this.props.roomName
            }
            onShare={() => this.onShare(roomCode)}
            onCopyCode={() => this.onCopyCode(roomCode)}
            showFullPoints={this.state.showFullPoints}
            onShowFullPoint={() => this.onShowFullPoint()}
          />
        ) : null}
        <AlertDialogWithConfirmButton
          isModalVisible={this.state.isModalVisible}
          onChangeModalVisibility={this.toggleModal}
          onCloseGameRoom={this.onCloseGameRoom}
          leaveRooms={true}
          title={
            "Are you sure you want to leave the room? You won’t be able to enter/play/return again."
          }
          navigation={this.props.navigation}
        />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDataForLeague: (roomId, leagueId) =>
      dispatch(getDataForLeague(roomId, leagueId)),
    getSelectedRoomData: (roomId, page) =>
      dispatch(getSelectedRoomData(roomId, page)),
    getSelectedRoomDataNoToken: (roomId, page) =>
      dispatch(getSelectedRoomDataNoToken(roomId, page)),
    deleteRoomCode: () => dispatch(deleteRoomCode()),
    getPredictionData: (roomId, data) =>
      dispatch(getPredictionData(roomId, data)),
    getPredictionDataNoToken: (roomId, data) =>
      dispatch(getPredictionDataNoToken(roomId, data)),
    setLeaguesWithGameWeeks: (leagues) =>
      dispatch(setLeaguesWithGameWeeks(leagues)),
    deleteLeagueGW: () => dispatch(deleteLeagueGW()),
    getSelectedUserPredictionData: (roomId, leagues, userId) =>
      dispatch(getSelectedUserPredictionData(roomId, leagues, userId)),
    leaveRoom: (rooms) => dispatch(leaveRoom(rooms)),
    setSelectedUserPredictionData: (predictions) =>
      dispatch(setSelectedUserPredictionData(predictions)),
    setLeaguesInRoom: (leaguesInRoom) =>
      dispatch(setLeaguesInRoom(leaguesInRoom)),
    setAlertDialogTitle: (title) => dispatch(setAlertDialogTitle(title)),
    setLeaveRoom: (leave) => dispatch(setLeaveRoom(leave)),
    setLeaveRoomId: (rooms) => dispatch(setLeaveRoomId(rooms)),
  };
};

const mapStateToProps = (state) => {
  return {
    peopleRankingInRoom: state.roomLeague.peopleRankingInRoom,
    userBeted: state.roomLeague.userBeted,
    roomName: state.roomLeague.roomName,
    leaguesInRoom: state.roomLeague.leaguesInRoom,
    userId: state.auth.userId,
    matches: state.roomPrediction.matches,
    leaguesGW: state.roomPrediction.leaguesGW,
    roomId: state.roomPrediction.roomId,
    banner: state.roomLeague.adverts,
    page: state.roomLeague.page,
    totalPages: state.roomLeague.totalPages,
    isLoading: state.loadingReducer.isLoading,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RoomLeagueScreen);
