import React from "react";
import { View } from "react-native";
import { GoogleLogin } from "react-google-login";
import { withStyles } from "@material-ui/core/styles";
import { loginStyles } from "../styles/styles";
import globalStyles from "../../globalStyles";

class GoogleLoginButton extends React.Component {
  render() {
    const { reactRem, classes } = this.props;

    return (
      <View style={loginStyles.miniContainer}>
        <GoogleLogin
          clientId="514363847583-79beusu0cfaos86jdetdiflodadcv6t6.apps.googleusercontent.com"
          className={classes.fbLogin}
          onSuccess={this.props.onFBLogin}
          onFailure={this.props.onFBLogin}
          loginHint={null}
          style={{ borderRadius: 2 * reactRem }}
          cookiePolicy={"single_host_origin"}
        />
      </View>
    );
  }
}
const extraStyles = (theme) => ({
  fbLogin: {
    justifyContent: "center",
    borderRadius: "8px !important",
    marginTop: "1rem",
    alignItems: "center",
    zIndex: 2,
    cursor: "pointer",
    width: "100%",
    boxShadow: "none !important",
    fontFamily: "Ubuntu-Bold !important",
    fontSize: "0.8rem !important",
    color: globalStyles.black + " !important",
  },
});
export default withStyles(extraStyles)(GoogleLoginButton);
