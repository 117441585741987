import {
  AUTH_SET_TOKEN,
  SET_GENDERS,
  SET_NATIONALITY,
  SET_LEAGUES,
  CHANGE_PASSWORD,
  AUTH_SET_USER_ID,
  SET_IS_NEW_USER,
  DELETE_USER,
  DELETE_CHANGED_PASSWORD,
  SHOW_REGISTER_FORM,
  REGISTER_DATA,
  SET_ADVERTS,
} from "../actions/actionTypes";

const initialState = {
  token: null,
  mailSent: false,
  genders: null,
  nationalities: null,
  leagues: null,
  changedPassword: null,
  userId: null,
  isNewUser: false,
  register: false,
  loginscreen: false,
  registerData: null,
  adverts: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADVERTS: {
      return {
        ...state,
        adverts: action.data,
      };
    }
    case REGISTER_DATA: {
      return {
        ...state,
        registerData: action.data,
      };
    }
    case SHOW_REGISTER_FORM: {
      return {
        ...state,
        register: action.register,
        loginscreen: action.loginscreen,
      };
    }
    case AUTH_SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case SET_GENDERS: {
      return { ...state, genders: action.data };
    }
    case SET_NATIONALITY: {
      return { ...state, nationalities: action.data };
    }
    case SET_LEAGUES: {
      return { ...state, leagues: action.data };
    }
    case CHANGE_PASSWORD: {
      return { ...state, changedPassword: action.data };
    }
    case AUTH_SET_USER_ID: {
      return {
        ...state,
        userId: action.userId,
      };
    }
    case SET_IS_NEW_USER: {
      return {
        ...state,
        isNewUser: action.isNewUser,
      };
    }
    case DELETE_USER: {
      let token = { ...state.token };
      let mailSent = { ...state.mailSent };
      let genders = { ...state.genders };
      let nationalities = { ...state.nationalities };
      let leagues = { ...state.leagues };
      let changedPassword = { ...state.changedPassword };
      let userId = { ...state.userId };
      let isNewUser = { ...state.isNewUser };
      token = null;
      mailSent = false;
      genders = null;
      nationalities = null;
      leagues = null;
      changedPassword = null;
      userId = null;
      isNewUser = false;

      return {
        ...state,
        token,
        mailSent,
        genders,
        nationalities,
        leagues,
        changedPassword,
        userId,
        isNewUser,
      };
    }
    case DELETE_CHANGED_PASSWORD: {
      let changedPassword = { ...state.changedPassword };
      changedPassword = null;
      return { ...state, changedPassword };
    }
    default:
      return state;
  }
};

export default reducer;
