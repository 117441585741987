import React from "react";
import { View, ScrollView, Text } from "react-native";
import { connect } from "react-redux";
import ScreenTitleText from "../../Common/components/UI/ScreenTitleText";
import ConfirmButton from "../../Common/components/UI/ConfirmButton";
import DefaultInput from "../../Common/components/UI/DefaultInput";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import AlertDialogWithConfirmButton from "../../Common/components/UI/AlertDialogWithConfirmButton";
import { joinRoom } from "../actions/rooms";
import { getJoinedRoomData } from "../actions/rooms";
import { joinRoomStyles } from "../styles/styles";

class JoinRoom extends React.Component {
  state = {
    roomCode: null,
    isModalVisible: false,
  };

  toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  roomCodeChangedHandler = (val) => {
    this.setState({ roomCode: val });
    this.setState({ error: null });
  };

  onJoinRoom = (roomCode) => {
    this.props.joinRoom(roomCode);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.message && this.props.message) {
      this.props.getJoinedRoomData();

      this.toggleModal();
    }
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
  }

  onErrorShow = () => {
    const { roomCode } = this.state;
    if (!roomCode) {
      this.setState({ error: "Please enter a room code" });
      return;
    }
  };

  componentWillUnmount() {
    if (this.state.isModalVisible) {
      this.toggleModal();
    }
  }

  render() {
    const { isLoading } = this.props;
    const { roomCode, error } = this.state;
    return (
      <View style={joinRoomStyles.container}>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <ScrollView contentContainerStyle={joinRoomStyles.fieldsContainer}>
            <ScreenTitleText title="Join Room" />
            <View style={joinRoomStyles.inputContainer}>
              <DefaultInput
                placeholderText="Enter room code to join"
                inputValue={this.state.roomCode}
                onChangeText={this.roomCodeChangedHandler}
                editable={true}
              />
            </View>
            {error ? (
              <View style={joinRoomStyles.errorMessageContainer}>
                <Text style={joinRoomStyles.errorMessage}>{error}</Text>
              </View>
            ) : (
              <View style={joinRoomStyles.errorMessageContainer}>
                <Text style={joinRoomStyles.errorMessage}></Text>
              </View>
            )}
            <View style={joinRoomStyles.confirmButtonContainer}>
              <ConfirmButton
                title="JOIN ROOM"
                onPress={() => {
                  roomCode
                    ? this.onJoinRoom(this.state.roomCode)
                    : this.onErrorShow();
                }}
              />
            </View>
          </ScrollView>
        )}
        <AlertDialogWithConfirmButton
          isModalVisible={this.state.isModalVisible}
          onChangeModalVisibility={this.toggleModal}
          onCloseGameRoom={this.onCloseGameRoom}
          title={"You have joined room " + this.props.message + " successfully"}
          navigation={this.props.navigation}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    message: state.privateRooms.message,
    error: state.errorReducer.error,
    isLoading: state.loadingReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    joinRoom: (roomCode) => dispatch(joinRoom(roomCode)),
    getJoinedRoomData: () => dispatch(getJoinedRoomData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(JoinRoom);
