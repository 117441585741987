import globalStyles from "../../globalStyles";
import EStyleSheet from "react-native-extended-stylesheet";

const predictionPageStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    alignItems: "center",
  },
  fieldsContainer: {
    paddingVertical: 6,
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  buttonContainer: {
    flexDirection: "row",
    width: "100%",
    height: 54,
    borderBottomWidth: 0.5,
    borderBottomColor: globalStyles.borderRoom,
  },
  noMatchesContainer: {
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  noMatchesText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 18,
  },
  lastContainerBorder: {
    width: "100%",
    height: 48,
  },
  signInUpContainer: {
    backgroundColor: globalStyles.backgroundGrey,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    paddingVertical: 6,
  },
  signInContainer: {
    backgroundColor: globalStyles.white,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 18,
    paddingVertical: 10,
    minWidth: 120,
    maxWidth: 120,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  signUpContainer: {
    backgroundColor: globalStyles.activeBlue,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 18,
    paddingVertical: 10,
    minWidth: 120,
    maxWidth: 120,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  signUpText: {
    color: globalStyles.white,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 12,
    fontFamily: globalStyles.fontUbuntuBold,
  },
  signInText: {
    color: globalStyles.textBlue,
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 12,
    fontFamily: globalStyles.fontUbuntuBold,
  },
});

const predictMatchesStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  fieldsContainer: {
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  inputContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
  },
  confirmButtonContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: 72,
    width: "100%",
  },
  listItem: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    marginTop: 3,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingVertical: 6,
    paddingLeft: 6,
  },
  placeImage: {
    height: 30,
    width: 30,
    resizeMode: "contain",
  },
  placeImageContainer: {
    backgroundColor: globalStyles.white,
    borderTopLeftRadius: 6,
    borderBottomRightRadius: 6,
    marginRight: 12,
    padding: 3,
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: 14,
    height: "100%",
  },
  gameWeekContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  gameWeekArrowContainer: {
    paddingHorizontal: 10,
  },
  gameWeekArrow: {
    width: 15,
    height: 21,
    resizeMode: "contain",
  },
  subtitleContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 21,
    backgroundColor: globalStyles.white,
  },
  subtitleText: {
    color: globalStyles.textBlue,
    opacity: 0.7,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 14,
  },
  predictionScreenText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 18,
    paddingBottom: 3,
  },
  gameWeekTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingRight: 3,
  },
  gameWeekText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  gameWeekNumberText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: 21,
    marginRight: 12,
  },
  dataContainer: {
    paddingHorizontal: 3,
  },
  discardSaveBtnContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginVertical: 12,
  },
  discardButtonContainer: {
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    padding: 6,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: globalStyles.backgroundGrey,
    borderLeftColor: globalStyles.backgroundGrey,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  discardText: {
    fontSize: 17,
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    textAlign: "center",
  },
  saveButton: {
    backgroundColor: globalStyles.activeBlue,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    padding: 6,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: globalStyles.backgroundGrey,
    borderRightColor: globalStyles.backgroundGrey,
    borderBottomColor: globalStyles.backgroundGrey,
  },
  saveText: {
    fontSize: 17,
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    textAlign: "center",
  },
});

const predictSpecialGamesStyles = EStyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  fieldsContainer: {
    width: "100%",
    paddingHorizontal: 6,
    paddingVertical: 18,
  },
  inputContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
  },
  subtitleContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 18,
    backgroundColor: globalStyles.white,
  },
  subtitleText: {
    color: globalStyles.textBlue,
    opacity: 0.7,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  predictionScreenText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 14,
    paddingBottom: 3,
  },
  borderView: {
    height: 60,
    width: "100%",
  },
});

const predictionGWFieldStyles = EStyleSheet.create({
  gameWeekContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  gameWeekText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 21,
  },
  timePeriodText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  predictButtonContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    height: 24,
    width: 72,
    borderRadius: 3,
  },
  predictButtonText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 11,
  },
  textUnderPredictButton: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
  },
  gameWeekTextContainer: {
    alignItems: "center",
    justifyContent: "center",
    height: "45%",
  },
  predictionContainer: {
    alignItems: "center",
    height: "55%",
    paddingTop: 3,
  },
  predictionDotContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  predictionDotImg: {
    width: 5,
    height: 10,
    resizeMode: "contain",
    marginLeft: 2,
  },
  betTextContainer: {
    paddingTop: 3,
  },
  finishedContainer: {
    backgroundColor: globalStyles.textBlue,
  },
  borderRadiusContainer: {
    borderWidth: 1,
    borderColor: globalStyles.textBlue,
  },
  endofSeasonText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 12,
    textAlign: "center",
  },
  endofSeasonTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    height: 30,
    width: 72,
    borderRadius: 3,
    textAlign: "center",
  },
});

const predictionLeagueFieldStyles = EStyleSheet.create({
  container: {
    flexDirection: "row",
    height: 105,
    borderRadius: 3,
    marginTop: 12,
  },
  leagueImageContainer: {
    backgroundColor: "rgba(255,255,255, 0.4)",
    justifyContent: "center",
    alignItems: "center",
    width: "20%",
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
  },
  leagueImg: {
    width: 50,
    height: 50,
    resizeMode: "contain",
    paddingBottom: 6,
  },
  leagueName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 15,
    textAlign: "center",
  },
  gameWeekContainer: {
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
  },
  arrowImg: {
    width: 22,
    height: 22,
    resizeMode: "contain",
  },
  flatListContainer: {
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    height: 90,
  },
  flatListSingleGWContainer: {
    justifyContent: "space-around",
    alignItems: "center",
    paddingHorizontal: 6,
    width: "100%",
  },
  leagueNameContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 24,
  },
  leftArrowContainer: {
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 6,
  },
  gameWeekText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  arrowContainer: {
    height: "80%",
    alignItems: "center",
    justifyContent: "center",
  },
  aloneArrowContainer: {
    height: "80%",
    alignItems: "center",
    justifyContent: "center",
  },
  predictionContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: "55%",
    paddingTop: 3,
  },
  betTextContainer: {
    paddingTop: 3,
  },
  endofSeasonText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 12,
    textAlign: "center",
  },
  endofSeasonTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    height: 30,
    width: 72,
    borderRadius: 3,
    textAlign: "center",
  },
});

const predictionMatchFieldStyles = EStyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: globalStyles.basicBlueGreyColor,
    backgroundColor: globalStyles.white,
  },
  betButtonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "25%",
  },
  homeTeamContainer: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    textAlign: "right",
    paddingRight: 6,
    width: "35%",
  },
  homeTeam: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlign: "right",
    fontSize: 14,
  },
  guestTeamContainer: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    textAlign: "left",
    paddingLeft: 6,
    width: "35%",
  },
  guestTeam: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlign: "left",
    fontSize: 14,
  },
  shieldContainer: {
    width: "10%",
    justifyContent: "center",
    alignItems: "center",
  },
  showMatchInfoContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "10%",
    height: 25,
  },
  btnImage: {
    height: 12,
    width: 12,
    margin: 6,
    resizeMode: "contain",
  },
  emptyShieldView: {
    height: 25,
    width: 25,
  },
  betContainer: {
    flexDirection: "row",
    width: "80%",
    justifyContent: "center",
    alignItems: "center",
  },
  smallerClubName: {
    fontSize: 14,
  },
});

const predictionRoundFieldStyles = EStyleSheet.create({
  gameWeekContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  gameWeekText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 21,
  },
  timePeriodText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 8,
  },
  predictButtonContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    height: 24,
    width: 72,
    borderRadius: 3,
  },
  oneRoundButtonContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    height: 24,
    width: 135,
    borderRadius: 3,
  },
  predictButtonText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 8,
  },
  textUnderPredictButton: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 9,
  },
  gameWeekTextContainer: {
    alignItems: "center",
    justifyContent: "center",
    height: "45%",
  },
  predictionContainer: {
    alignItems: "center",
    height: "55%",
  },
  predictionDotContainer: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  predictionDotImg: {
    width: "0.8rem",
    height: "0.8rem",
    resizeMode: "contain",
    marginLeft: 2,
  },
  betTextContainer: {
    paddingTop: 3,
  },
  finishedContainer: {
    backgroundColor: globalStyles.textBlue,
  },
  borderRadiusContainer: {
    borderWidth: 1,
    borderColor: globalStyles.textBlue,
  },
  endofSeasonText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 8,
    textAlign: "center",
  },
  endofSeasonTextContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: globalStyles.white,
    height: 20,
    width: 60,
    borderRadius: 3,
    textAlign: "center",
  },
  endOfSeasonExtraContainer: {
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row",
  },

  oneRoundContainer: {
    alignItems: "center",
    width: "100%",
  },
  oneRoundTimeUntilStartContainer: {
    width: "100%",
    alignItems: "center",
    paddingBottom: 6,
  },
  onRoundTimeUntilStartText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 12,
  },
  roundContainerStyle: {
    alignItems: "center",
    justifyContent: "center",
  },
});

const predictionSpecialGamesStyles = EStyleSheet.create({
  container: {
    width: "100%",
  },
  inputContainer: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    width: "100%",
  },
  listItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: globalStyles.black,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    paddingVertical: 6,
    marginTop: 3,
    paddingLeft: 6,
  },
  leagueTitle: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuMedium,
    textAlignVertical: "center",
    fontSize: 14,
    height: "100%",
  },
  userMyPredictionsBtnContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 6,
  },
  userMyPredictionsBtnText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 10,
  },
  userMyPredictionsPlusText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 15,
    paddingRight: 6,
  },
  placeImage: {
    height: 30,
    width: 30,
    resizeMode: "contain",
  },
  placeImageContainer: {
    backgroundColor: globalStyles.white,
    borderTopLeftRadius: 6,
    borderBottomRightRadius: 6,
    marginRight: 12,
    padding: 3,
  },
});

const predictionWeeklyGamesStyles = EStyleSheet.create({
  container: {
    flexDirection: "row",
    height: 90,
    borderRadius: 3,
    marginTop: 12,
  },
  leagueImageContainer: {
    backgroundColor: globalStyles.white,
    opacity: 0.7,
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
  },
  leagueName: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: 10,
    textAlign: "center",
  },
  gameWeekContainer: {
    backgroundColor: globalStyles.white,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "70%",
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
  },
  arrowImg: {
    width: 18,
    height: 18,
    resizeMode: "contain",
  },
  flatListContainer: {
    justifyContent: "space-around",
    alignContent: "center",
    width: "100%",
  },
  flatListSingleGWContainer: {
    justifyContent: "flex-start",
    alignContent: "center",
    paddingHorizontal: 6,
    width: "100%",
  },
  leagueNameContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 25,
  },
  leftArrowContainer: {
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 6,
  },
  gameWeekText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: 8,
  },
  arrowContainer: {
    height: "80%",
    alignItems: "center",
    justifyContent: "center",
  },
  aloneArrowContainer: {
    height: "80%",
    alignItems: "center",
    justifyContent: "center",
  },
  leagueImg: {
    width: 64,
    height: 64,
    resizeMode: "contain",
    paddingBottom: 6,
  },
  miniContainer: {
    height: "90%",
    alignItems: "center",
    justifyContent: "center",
  },
});

export {
  predictionPageStyles,
  predictMatchesStyles,
  predictSpecialGamesStyles,
  predictionGWFieldStyles,
  predictionLeagueFieldStyles,
  predictionMatchFieldStyles,
  predictionRoundFieldStyles,
  predictionSpecialGamesStyles,
  predictionWeeklyGamesStyles,
};
