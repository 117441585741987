import React from "react";
import { Image, View, Text, TouchableOpacity } from "react-native";
import BetButton from "../../Common/components/UI/BetButton";
import Shield from "../../Common/components/UI/Shield";
import ArrowRight from "../../assets/predict_matches_view_arrow.png";
import { predictionMatchFieldStyles } from "../styles/styles";

class PredictionMatchField extends React.Component {
  state = {
    activeButtonId: -1,
    matchIsStarted: false,
    matchStartedId: -1,
    matchWinnerId: -1,
  };

  matchIsStarted = (matchId) => {
    this.setState({ matchIsStarted: true, matchStartedId: matchId });
    if (this.props.showErrorMessage) {
      this.props.onMatchesStarter(matchId);
    }
  };

  changeActiveButton = (id, matchId) => {
    this.setState({ activeButtonId: id });
    const { onPredict, leagueId } = this.props;
    if (id === 0) {
      if (onPredict) {
        this.props.onPredict(matchId, "1");
      } else {
        this.props.onPredictSpecial(leagueId, matchId, "1");
      }
    }
    if (id === 1) {
      if (onPredict) {
        this.props.onPredict(matchId, "X");
      } else {
        this.props.onPredictSpecial(leagueId, matchId, "X");
      }
    }
    if (id === 2) {
      if (onPredict) {
        this.props.onPredict(matchId, "2");
      } else {
        this.props.onPredictSpecial(leagueId, matchId, "2");
      }
    }
  };

  componentDidMount() {
    if (this.props.bet) {
      if (this.props.bet === "1") {
        this.changeActiveButton(0);
      }
      if (this.props.bet === "X") {
        this.changeActiveButton(1);
      }
      if (this.props.bet === "2") {
        this.changeActiveButton(2);
      }
    } else {
      this.changeActiveButton(-1);
    }
  }

  componentDidUpdate(prevProp) {
    const { bet, homeTeamScore, awayTeamScore, showErrorMessage } = this.props;
    if (
      this.state.matchIsStarted &&
      this.state.matchStartedId != -1 &&
      showErrorMessage &&
      this.state.activeButtonId != -1
    ) {
      this.changeActiveButton(-1);
    }

    if (prevProp.bet != bet) {
      if (bet === "1") {
        this.changeActiveButton(0);
      }
      if (bet === "X") {
        this.changeActiveButton(1);
      }
      if (bet === "2") {
        this.changeActiveButton(2);
      }
    }

    if (this.state.matchWinnerId === -1 && homeTeamScore) {
      if (Number(homeTeamScore) > Number(awayTeamScore)) {
        this.setState({ matchWinnerId: 0 });
      } else if (Number(homeTeamScore) === Number(awayTeamScore)) {
        this.setState({ matchWinnerId: 1 });
      } else if (Number(homeTeamScore) < Number(awayTeamScore)) {
        this.setState({ matchWinnerId: 2 });
      }
    }
  }

  milliseconds = (h, m, s) => (h * 60 * 60 + m * 60 + s) * 1000;
  millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    return minutes;
  };

  render() {
    const {
      homeTeam,
      guestTeam,
      shield,
      id,
      onOpenMatchInfo,
      matchDate,
      matchTime,
    } = this.props;
    
    const { activeButtonId, matchIsStarted, matchWinnerId } = this.state;

    let betContainer = [predictionMatchFieldStyles.betContainer];

    if (matchIsStarted) {
      betContainer.push({ opacity: 0.5 });
    }

    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    const timeParts = matchTime.split(":");
    const matchTimeMillSec = this.milliseconds(timeParts[0], timeParts[1], 0);
    const currentTimeMillSec = this.milliseconds(hours, min, 0);
    const matchDateToFind = matchDate.split("-");

    var date = new Date(
      matchDateToFind[1] + "/" + matchDateToFind[2] + "/" + matchDateToFind[0]
    );
    var matchDateMillSec = date.getTime();
    var currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    var currentDateMillSec = currentDate.getTime();

    if (matchDateMillSec === currentDateMillSec) {
      if (matchTimeMillSec - currentTimeMillSec < 1 && !matchIsStarted) {
        betContainer.push({ opacity: 0.5 });
        this.matchIsStarted(id);
      }
    }
    if (matchDateMillSec < currentDateMillSec && !matchIsStarted) {
      betContainer.push({ opacity: 0.5 });
      this.matchIsStarted(id);
    }

    return (
      <View style={predictionMatchFieldStyles.container}>
        <View style={predictionMatchFieldStyles.shieldContainer}>
          {shield ? (
            <Shield points={shield} />
          ) : (
            <View style={predictionMatchFieldStyles.emptyShieldView} />
          )}
        </View>
        <View style={betContainer}>
          <View style={predictionMatchFieldStyles.homeTeamContainer}>
            <Text style={predictionMatchFieldStyles.homeTeam}>{homeTeam}</Text>
          </View>
          <View style={predictionMatchFieldStyles.betButtonContainer}>
            <BetButton
              id={0}
              activeButtonId={activeButtonId}
              onPress={() => this.changeActiveButton(0, id)}
              prediction={true}
              disabled={matchIsStarted}
              matchWinnerId={matchWinnerId}
              bet="1"
            />
            <BetButton
              id={1}
              activeButtonId={activeButtonId}
              onPress={() => this.changeActiveButton(1, id)}
              prediction={true}
              disabled={matchIsStarted}
              matchWinnerId={matchWinnerId}
              bet="X"
            />
            <BetButton
              id={2}
              activeButtonId={activeButtonId}
              onPress={() => this.changeActiveButton(2, id)}
              prediction={true}
              disabled={matchIsStarted}
              matchWinnerId={matchWinnerId}
              bet="2"
            />
          </View>
          <View style={predictionMatchFieldStyles.guestTeamContainer}>
            <Text style={predictionMatchFieldStyles.guestTeam}>{guestTeam}</Text>
          </View>
        </View>

        <View style={predictionMatchFieldStyles.showMatchInfoContainer}>
          <TouchableOpacity onPress={onOpenMatchInfo}>
            <Image
              source={ArrowRight}
              style={predictionMatchFieldStyles.btnImage}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default PredictionMatchField;
