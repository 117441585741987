import React from "react";
import { TextInput, View, Image, TouchableOpacity } from "react-native";
import globalStyles from "../../globalStyles";
import SendBtnImage from "../../assets/send_message_icon.png";
import { messageInputFieldStyles } from "../styles/styles";

class MessageInputField extends React.Component {
  render() {
    const {
      inputValue,
      onChangeText,
      placeholderText,
      onSendMessasge,
    } = this.props;

    return (
      <View style={messageInputFieldStyles.messageInputContainer}>
        <TextInput
          placeholderTextColor={globalStyles.borderRoom}
          placeholder={placeholderText}
          value={inputValue}
          onChangeText={onChangeText}
          style={messageInputFieldStyles.inputText}
          multiline={true}
          onRequestClose={this.props.onRequestClose}
          {...this.props}
        />
        <View style={messageInputFieldStyles.imageContainer}>
          <TouchableOpacity onPress={() => onSendMessasge()}>
            <Image
              source={SendBtnImage}
              style={messageInputFieldStyles.image}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default MessageInputField;
