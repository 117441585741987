import {
  GET_HOME_SCREEN_DATA,
  SELECT_MATCH,
  SELECT_LEAGUE,
  SET_LEAGUE_DATA_BY_GAME_WEEK,
  SET_GAME_WEEK,
  DELETE_GAME_WEEK,
  SET_ROOM_PREDICTIONS,
  SET_LEAGUES_WITH_GAME_WEEKS,
  DELETE_HOMESCREEN_DATA,
  SET_VIEW_HEIGHT,
  SET_SPECIAL_VIEW_HEIGHT,
  VALUE_CONSTANT,
  SET_LEAGUE_GW_DATA,
  RESET_ROOM_PREDICTIONS,
  SET_LIVE_MATCHES,
  SET_TODAY_MATCHES,
} from "./actionTypes";
import { authFetch, authFetchNoToken } from "../../Common/store/actions/token";
import { uiStartLoading, uiStopLoading } from "../../Common/store/actions/ui";

export const setValueConstant = (constant) => {
  return {
    type: VALUE_CONSTANT,
    constant: constant,
  };
};

export const setSpecialViewHeigh = (specialHeight) => {
  return {
    type: SET_SPECIAL_VIEW_HEIGHT,
    specialHeight: specialHeight,
  };
};
export const setViewHeight = (height) => {
  return {
    type: SET_VIEW_HEIGHT,
    height: height,
  };
};
export const getHomeScreenData = (firebaseToken) => {
  const data = { user_push_token: "web" };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/home", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        if (res) {
          dispatch(setHomeScreenData(res.data));
        }
        dispatch(uiStopLoading());
      });
  };
};

export const getDataForLeagueGW = (league, index) => {
  const leagueArray = [];
  leagueArray.push(league);
  const data = { leagues: leagueArray };
  return (dispatch) => {
    dispatch(uiStartLoading(index));
    dispatch(authFetchNoToken("/home", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setLeagueGWData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getHomeScreenDataNoToken = () => {
  const data = {};
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetchNoToken("/home", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setHomeScreenData(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getHomeScreenDataTodayMatches = () => {
  const data = { when: "today" };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/home", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setTodayMatches(res.data));
        dispatch(uiStopLoading());
      });
  };
};
export const getHomeScreenDataTodayMatchesNoToken = () => {
  const data = { when: "today" };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetchNoToken("/home", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setTodayMatches(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getHomeScreenDataLive = () => {
  const data = { when: "live" };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetch("/home", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setLiveMatches(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getHomeScreenDataLiveNoToken = () => {
  const data = { when: "live" };
  return (dispatch) => {
    dispatch(uiStartLoading());
    dispatch(authFetchNoToken("/home", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setLiveMatches(res.data));
        dispatch(uiStopLoading());
      });
  };
};

export const getHomeScreenDataForGameWeek = (leagues) => {
  const data = { leagues: leagues };
  return (dispatch) => {
    dispatch(authFetch("/home", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setHomeScreenData(res.data));
      });
  };
};

export const getHomeScreenDataForGameWeekNoToken = (leagues) => {
  const data = { leagues: leagues };
  return (dispatch) => {
    dispatch(authFetchNoToken("/home", "POST", data))
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setHomeScreenData(res.data));
      });
  };
};

export const getSpecialRoomPredictionsByRoomIdAndMatchId = (
  roomId,
  matchId,
  page
) => {
  return (dispatch) => {
    dispatch(
      authFetch(
        "/special/predictions?room_id=" +
          roomId +
          "&match_id=" +
          matchId +
          "&page=" +
          page
      )
    )
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setRoomPredictions(res.data));
      });
  };
};

export const getRoomPredictionsByRoomIdAndMatchId = (roomId, matchId, page) => {
  return (dispatch) => {
    dispatch(
      authFetch(
        "/room/predictions?room_id=" +
          roomId +
          "&match_id=" +
          matchId +
          "&page=" +
          page
      )
    )
      .catch((err) => console.log(err))
      .then((res) => {
        dispatch(setRoomPredictions(res.data));
      });
  };
};

export const setHomeScreenData = (data) => {
  return {
    type: GET_HOME_SCREEN_DATA,
    data,
  };
};

export const setLeagueGWData = (data) => {
  return {
    type: SET_LEAGUE_GW_DATA,
    data,
  };
};

export const setLeagueDataByGameWeek = (leagueId, gameWeek) => {
  const data = { api_football_id: leagueId, game_week: gameWeek };
  return {
    type: SET_LEAGUE_DATA_BY_GAME_WEEK,
    data,
  };
};
export const setRoomPredictions = (data) => {
  return {
    type: SET_ROOM_PREDICTIONS,
    data,
  };
};

export const selectMatch = (matchId) => {
  return {
    type: SELECT_MATCH,
    matchId: matchId,
  };
};

export const selectLeague = (leagueId) => {
  return {
    type: SELECT_LEAGUE,
    leagueId: leagueId,
  };
};

export const setGameWeek = (leagueId) => {
  return {
    type: SET_GAME_WEEK,
    leagueId: leagueId,
  };
};

export const deleteGameWeek = () => {
  return {
    type: DELETE_GAME_WEEK,
  };
};

export const deleteHomescreenData = () => {
  return {
    type: DELETE_HOMESCREEN_DATA,
  };
};

export const setLeaguesWithGameWeeks = (leaguesGW) => {
  return {
    type: SET_LEAGUES_WITH_GAME_WEEKS,
    leaguesGW: leaguesGW,
  };
};

export const setResetRoomPredictions = (data) => {
  return {
    type: RESET_ROOM_PREDICTIONS,
    data,
  };
};

export const setTodayMatches = (data) => {
  return {
    type: SET_TODAY_MATCHES,
    data,
  };
};

export const setLiveMatches = (data) => {
  return {
    type: SET_LIVE_MATCHES,
    data,
  };
};
