import {
  SET_FUTURE_MATCH_DATA,
  MATCH_BET,
  DELETE_FUTURE_MATCH_INFO,
  SET_MATCH_DATA,
} from "../actions/actionTypes";

const initialState = {
  standings: null,
  form: null,
  headToHead: null,
  bets: [],
  date: null,
  time: null,
  odsBanner: [],
};

const futureMatchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FUTURE_MATCH_DATA: {
      return {
        ...state,
        standings: action.data.standings,
        form: action.data.form,
        headToHead: action.data.headToHead,
        bets: action.data.bets,
        date: action.data.date,
        time: action.data.time,
        odsBanner: action.data.banner,
      };
    }
    case SET_MATCH_DATA: {
      return {
        ...state,
        standings: action.data.standings,
        form: action.data.form,
        headToHead: action.data.headToHead,
        bets: action.data.bets,
        date: action.data.date,
        time: action.data.time,
        shield: action.data.shield,
        homeTeam: action.data.homeTeam,
        homeTeamImg: action.data.homeTeamImg,
        guestTeam: action.data.guestTeam,
        guestTeamImg: action.data.guestTeamImg,
        liveScore: action.data.liveScore,
        odsBanner: action.data.banner,
        rooms: action.data.rooms,
      };
    }
    case MATCH_BET: {
      const bets = [...state.bets];
      let matchBet = { ...bets[0].bet };
      matchBet = action.bet;
      bets[0].bet = matchBet;
      return { ...state, bets };
    }
    case DELETE_FUTURE_MATCH_INFO: {
      return {
        ...state,
        standings: null,
        form: null,
        headToHead: null,
        bets: [],
        date: null,
        time: null,
      };
    }
    default:
      return state;
  }
};

export default futureMatchReducer;
