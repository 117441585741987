import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import { connect } from 'react-redux';

class ConfirmButton extends React.Component {

  render() {
    const { reactRem } = this.props;
    const confirmBtnContainerStyle = [styles.confirmBtnContainer, {
      height: 6 * reactRem,
      paddingHorizontal: 5 * reactRem,
      borderRadius: reactRem
    }];
    const confirmButtonStyle = [styles.confirmButton, { fontSize: 2.6 * reactRem }];
    return (
      <TouchableOpacity style={confirmBtnContainerStyle} onPress={() => this.props.onPress()} >
        <Text style={confirmButtonStyle}>{this.props.title}</Text>
      </TouchableOpacity>
    );
  }
}

const styles = EStyleSheet.create({
  confirmBtnContainer: {
    alignItems: 'center',
    justifyContent: "center",
    backgroundColor: globalStyles.activeBlue,
    height: "6rem",
    paddingHorizontal: "5rem",
    borderRadius: "1rem"
  },
  confirmButton: {
    textAlign: "center",
    textAlignVertical: "center",
    fontFamily: globalStyles.fontUbuntuBold,
    color: globalStyles.white,
    fontSize: "2.6rem",
  }
});

const mapStateToProps = state => {
  return {

    reactRem: state.homeScreenLeagues.reactRem,
  };
};

export default connect(mapStateToProps, null)(ConfirmButton);