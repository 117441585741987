import React from "react";
import {
  Text,
  View,
  StyleSheet,
  Animated,
  TouchableOpacity
} from "react-native";
import globalStyles from "../../../globalStyles";




class AnimatableText extends React.Component {

  state = {
    stateFontSize: new Animated.Value(this.props.defaultSize),
  };

  _start = () => {
    Animated.timing(this.state.stateFontSize, {
      toValue: this.props.sizeTo,
      duration: 1400
    }).start();
  };


  
  _start2 = () => {
    Animated.timing(this.state.stateFontSize, {
        toValue: this.props.defaultSize,
        duration: 1400
      }).start();
   
  };


  componentDidUpdate(prevProps){
    if(this.props.offset!=0){
        this._start();
    }
    
    if(prevProps.offset&& prevProps.offset>0&& this.props.offset===0){
        this._start2();
    }
  }


  render() {
    return (    
        <Animated.Text
          style={[{
            fontSize: this.state.stateFontSize
          },this.props.style]}
        >
        {this.props.text}
    </Animated.Text>
    );
  }
}

const styles = StyleSheet.create({
  

  

 
});
export default AnimatableText;