import {
    SET_PREDICTION_DATA,
    SET_LEAGUES_WITH_GAME_WEEKS_PREDICTION,
    SET_LEAGUE_DATA_BY_GAME_WEEK_PREDICTION,
    DELETE_LEAGUES_GW,
    GET_USER_PREDICTIONS_BY_ID,
    GET_SELECTED_USER_PREDICTIONS_BY_ID,
} from '../actions/actionTypes';


const initialState = {
    roomId: null,
    matches: null,
    leaguesGW: null,
    userPredictions: null,
    selectedUserPredictions: null
};

const roomPredictionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SELECTED_USER_PREDICTIONS_BY_ID: {
            if (action.data != null) {
                let matches = [...state.matches];
                let userPredictions = action.data.leagues;
                let selectedUserPredictions = [];
                for (var i = 0; i < userPredictions.length; i++) {
                    selectedUserPredictions.push(userPredictions[i].games)
                }
                for (var i = 0; i < matches.length; i++) {
                    matches[i].selectedUserPredictions = selectedUserPredictions[i];
                }
                return {
                    ...state,
                    matches,
                    selectedUserPredictions: action.data,
                }
            }

        }
        case GET_USER_PREDICTIONS_BY_ID: {
            if (action.data != null && state.matches && state.matches.length != 0) {
                const matches = [...state.matches];
                const userPredictions = action.data.leagues;
                const loggedUserPredictions = [];
                for (var i = 0; i < userPredictions.length; i++) {
                    loggedUserPredictions.push(userPredictions[i].games)
                }
                for (var i = 0; i < matches.length; i++) {
                    matches[i].loggedUserPredictions = loggedUserPredictions[i];
                }
                return {
                    ...state,
                    matches,
                    userPredictions: action.data,
                }
            }
        }
        case SET_PREDICTION_DATA: {
            if (action.data != null) {
                return {
                    ...state,
                    roomId: action.roomId,
                    matches: action.data.leagues,
                }
            }
        };
        case SET_LEAGUES_WITH_GAME_WEEKS_PREDICTION: {
            return {
                ...state,
                leaguesGW: action.leaguesGW
            }
        };
        case SET_LEAGUE_DATA_BY_GAME_WEEK_PREDICTION: {
            const leagueIndex = state.leaguesGW.findIndex(league => Number(league.league_id) === Number(action.data.league_id));
            const leaguesGW = [...state.leaguesGW];
            leaguesGW[leagueIndex] = action.data;
            return { ...state, leaguesGW };
        };
        case DELETE_LEAGUES_GW: {
            return {
                ...state,
                leaguesGW: null,
            }
        };
        default:
            return state;
    }
};

export default roomPredictionReducer;