
import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import { connect } from 'react-redux';

const matchRoomBet = props => {
    const { reactRem } = props;
    const matchBetText = [styles.matchBetText, { fontSize: 1.8 * reactRem }];
    const matchBetContainer = [styles.matchBetContainer, {
        padding: 2 * reactRem,
        borderTopLeftRadius: 0.5 * reactRem,
        borderBottomLeftRadius: 0.5 * reactRem,
        height: 4 * reactRem
    }];
    const roomBorder = [styles.roomText, { fontSize: 1.8 * reactRem }];
    const roomBorderContainer = [styles.roomBorderContainer, {
        paddingHorizontal: 1.5 * reactRem,
        borderTopRightRadius: 0.5 * reactRem,
        borderBottomRightRadius: 0.5 * reactRem,
        marginRight: reactRem,
        height: 4 * reactRem,
        marginLeft: -0.2 * reactRem,
    }]
    const roomContainerStyle = [styles.roomContainer, { marginHorizontal: reactRem }];

    if (props.bet != "-") {
        if (props.score) {
            if (props.guessed) {
                matchBetContainer.push({ backgroundColor: globalStyles.green })
                roomBorderContainer.push({ borderColor: globalStyles.green })
                if (props.is_special) {
                    roomBorderContainer.push({ backgroundColor: globalStyles.yellow, opacity: 0.5 })
                }
            }
            else {
                matchBetContainer.push({ backgroundColor: globalStyles.red })
                roomBorderContainer.push({ borderColor: globalStyles.red })
                if (props.is_special) {
                    roomBorderContainer.push({ backgroundColor: globalStyles.yellow, opacity: 0.5 })
                }
            }
        }
        else if (props.is_special) {
            matchBetContainer.push({ backgroundColor: globalStyles.yellow })
            roomBorderContainer.push({ borderColor: globalStyles.yellow })
            matchBetText.push({ color: globalStyles.white })
        }
        else {
            matchBetContainer.push({ backgroundColor: globalStyles.textBlue })
            roomBorderContainer.push({ borderColor: globalStyles.textBlue })
        }

    }
    else if (props.is_special) {
        matchBetContainer.push({ backgroundColor: globalStyles.yellow })
        roomBorderContainer.push({ borderColor: globalStyles.yellow })
        matchBetText.push({ color: globalStyles.white })
    }
    else if (props.score && props.bet === "-") {
        matchBetContainer.push({
            backgroundColor: globalStyles.red,
        });
    }
    else {
        matchBetContainer.push({ backgroundColor: globalStyles.basicBlueGreyColor });
        matchBetText.push({ color: globalStyles.borderRoom });
        roomBorderContainer.push({ borderColor: globalStyles.borderRoom });
        roomBorder.push({ color: globalStyles.borderRoom });
    }
    if (props.roomSelected === props.roomId && props.dataShown) {
        roomContainerStyle.push({ opacity: 1 })
    }
    if (props.roomSelected === props.roomId && !props.dataShown) {

        roomContainerStyle.push({ opacity: 0.5 })
    }


    const bet = props.bet || '-';
    return (
        <TouchableOpacity style={roomContainerStyle} onPress={props.onPress}>
            <View style={matchBetContainer}>
                <Text style={matchBetText}>{bet}</Text>
            </View>
            <View style={roomBorderContainer}>
                <Text style={roomBorder} numberOfLines={1}>{props.roomName}</Text>
            </View>
        </TouchableOpacity>
    );
}

const styles = EStyleSheet.create({
    matchBetContainer: {
        backgroundColor: globalStyles.red,
        justifyContent: "center",
        alignItems: "center",
        padding: "2rem",
        borderTopLeftRadius: "0.5rem",
        borderBottomLeftRadius: "0.5rem",
        height: "4rem",
        width: "30%"
    },
    matchBetText: {
        color: globalStyles.white,
        textAlign: "center",
        textAlignVertical: "center",
        fontFamily: globalStyles.fontUbuntuBold,
        fontSize: "1.8rem",
    },
    roomBorderContainer: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: globalStyles.white,
        paddingHorizontal: "1.5rem",
        borderTopRightRadius: "0.5rem",
        borderBottomRightRadius: "0.5rem",
        marginRight: "1rem",
        height: "4rem",
        marginLeft: "-0.2rem",
        width: "70%"
    },
    roomText: {
        color: globalStyles.textBlue,
        textAlign: "center",
        textAlignVertical: "center",
        fontSize: "1.8rem",
        fontFamily: globalStyles.fontUbuntuBold
    },
    roomContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        width: "100%",
        opacity: 0.5,
        marginHorizontal: "1rem",
    },
});

const mapStateToProps = (state) => {
    return {
        reactRem: state.homeScreenLeagues.reactRem,
    }
}

export default connect(mapStateToProps, null)(matchRoomBet);