import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import pageUpImg from './assets/page_up.png';
import globalStyles from './globalStyles';
import { connect } from 'react-redux';



class GoUpArrow extends React.Component {

    render() {
        const { reactRem } = this.props;

        const menuContainerStyle = [styles.menuContainer, { height: 12 * reactRem, width: 12 * reactRem, borderRadius: 6 * reactRem }];
        const arrowImageStyle = [styles.arrowImage, { height: 6 * reactRem, width: 6 * reactRem }];
        const pickerContainerStyle = [styles.pickerContainer, { height: 8 * reactRem, width: 8 * reactRem, borderRadius: 4 * reactRem }];
        return (
            <View style={menuContainerStyle}>
                <TouchableOpacity style={pickerContainerStyle} onPress={() => this.props.goOnTop()}>
                    <Image source={pageUpImg} style={arrowImageStyle} />
                </TouchableOpacity>
            </View>
        );
    }
}

const styles = EStyleSheet.create({
    menuContainer: {
        left: "80%",
        top: "85%",
        position: "fixed",
        height: "12rem",
        justifyContent: 'center',
        alignItems: 'center',
        width: "12rem",
        borderRadius: "6rem",
        zIndex: 1,
    },
    arrowImage: {
        height: "6rem",
        width: "6rem",
        resizeMode: "contain",
    },
    pickerContainer: {
        width: "8rem",
        height: "8rem",
        borderRadius: "4rem",
        backgroundColor: globalStyles.black,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
    },
});

const mapStateToProps = (state, ownProps) => {
    return {
        reactRem: state.homeScreenLeagues.reactRem,
    }
}

export default connect(mapStateToProps, null)(GoUpArrow);