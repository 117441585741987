// App.js - WEB
import React, { Component } from "react";
import {
  View,
  Dimensions,
  Image,
  TouchableWithoutFeedback,
  Text,
} from "react-native";
import WebRoutesGenerator from "./NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "./Home/screens/Home";
import LoginScreen from "./Login/screens/Login";
import TopNav from "./TopNav";
import SecondScreen from "./SecondScreen";
import UserScreen from "./UserScreen";
import DasModalScreen from "./DasModalScreen";
import AlertDialogWithConfirmButton from "./Common/components/UI/AlertDialogWithConfirmButton";
import EStyleSheet from "react-native-extended-stylesheet";
import MatchScreen from "./Home/screens/MatchScreen";
import Standings from "./Home/screens/Standings";
import RoomScreen from "./Room/screens/Room";
import RoomLeagueScreen from "./Room/screens/RoomLeague";
import RoomPredictionsScreen from "./Room/screens/RoomPredictions";
import PredictionPage from "./Prediction/screens/PredictionPage";
import PredictMatches from "./Prediction/screens/PredictMatches";
import JoinRoom from "./Room/screens/JoinRoom";
import CreateFriendlyRoom from "./Room/screens/CreateFriendlyRoom";
import CreateH2HRoom from "./Room/screens/CreateH2HRoom";
import SuccessfullyCreatedRoom from "./Room/screens/SuccessfullyCreatedRoom";
import SpecialGamesScreen from "./SpecialGames/screens/SpecialGamesPage";
import globalStyles from "./globalStyles";
import Banner from "./assets/images/banner.png";
import TutorialScreen from "./Login/screens/TutorialScreen";
import StatisticsScreen from "./Common/Drawer/StatisticsScreen";
import ChooseLeagues from "./Login/screens/ChooseLeagues";
import SpcialGameRoomLeague from "./SpecialGames/screens/SpcialGameRoomLeague";
import JoinSpecialGamesScreen from "./SpecialGames/screens/JoinSpecialGamesPage";
import ContactUsScreen from "./Common/Drawer/ContactUsScreen";
import GameRulesScreen from "./Common/Drawer/GameRulesScreen";
import TermsNConditionsScreen from "./Common/Drawer/TermsNConditionsScreen";
import PrivacyPolicyScreen from "./Common/Drawer/PrivacyPolicyScreen";
import ProfileScreen from "./Common/Drawer/ProfileScreen";
import NotificationsScreen from "./Common/Drawer/NotificationsScreen";
import Footer from "./Footer";
import LangaugeMenu from "./LangaugeMenu";
import GoUpArrow from "./GoUpArrow";
import GetHeight from "./GetHeight";
import PredictSpecialGames from "./Prediction/screens/PredictSpecialGames";
import VerticalBanner from "../src/assets/images/register_bg.jpg";
import flagEn from "../src/assets/images/united_kingdom.png";
import JoinNowBtn from "./Common/components/UI/JoinNowBtn";
import ChatPage from "./Chat/screens/ChatPage";
import MessagePage from "./Chat/screens/MessagePage";
import ForgotPasswordScreen from "./Login/screens/ForgotPassword";
import LeagueAd from "./Common/LeagueList/LeagueAd";

const routeMap = {
  ForgottenPassword: {
    component: ForgotPasswordScreen,
    path: "/forgotpassword",
    exact: true,
  },
  ChatPage: {
    component: ChatPage,
    path: "/chat",
    exact: true,
  },
  MessagePage: {
    component: MessagePage,
    path: "/chats/:roomName?",
    exact: true,
  },
  ProfileScreen: {
    component: ProfileScreen,
    path: "/profile/:userId?",
    exact: true,
  },
  NotificationsScreen: {
    component: NotificationsScreen,
    path: "/notifications/:userId?",
    exact: true,
  },
  ContactUsScreen: {
    component: ContactUsScreen,
    path: "/contactus",
    exact: true,
  },
  GameRulesScreen: {
    component: GameRulesScreen,
    path: "/gamerules",
    exact: true,
  },
  TermsNConditionsScreen: {
    component: TermsNConditionsScreen,
    path: "/termsnconditions",
    exact: true,
  },
  PrivacyPolicyScreen: {
    component: PrivacyPolicyScreen,
    path: "/privacypolicy",
    exact: true,
  },
  JoinSpecialGamesScreen: {
    component: JoinSpecialGamesScreen,
    path: "/specialgame/join/:roomId?/:type?/:duration?",
    exact: true,
  },
  SpcialGameRoomLeague: {
    component: SpcialGameRoomLeague,
    path: "/specialgame/:roomId?",
    exact: true,
  },
  ChooseLeagues: {
    component: ChooseLeagues,
    path: "/chooseleagues/:addLeague?/:isNewUser?/:leaveRooms?",
    exact: true,
  },
  StatisticsScreen: {
    component: StatisticsScreen,
    path: "/statistics/:userId?",
    exact: true,
  },
  Tutorial: {
    component: TutorialScreen,
    path: "/tutorial",
    exact: true,
  },
  SpecialGamesScreen: {
    component: SpecialGamesScreen,
    path: "/specialGames/:fromJoin?",
    exact: true,
  },
  PredictSpecialGames: {
    component: PredictSpecialGames,
    path: "/predictSpecialGames/:roomId?/:roundId?",
    exact: true,
  },
  PredictionPage: {
    component: PredictionPage,
    path: "/predict",
    exact: true,
  },
  PredictMatches: {
    component: PredictMatches,
    path:
      "/predictions/:leagueName?/:leagueId?/:leagueImg?/:gameWeek?/:isPassed?",
    exact: true,
  },
  RoomScreen: {
    component: RoomScreen,
    path: "/rooms",
    exact: true,
  },
  JoinRoom: {
    component: JoinRoom,
    path: "/rooms/join",
    exact: true,
  },
  CreateFriendlyRoom: {
    component: CreateFriendlyRoom,
    path: "/rooms/create_room",
    exact: true,
  },
  CreateH2HRoom: {
    component: CreateH2HRoom,
    path: "/rooms/create_h2h_room",
    exact: "true",
  },
  SuccessfullyCreatedRoom: {
    component: SuccessfullyCreatedRoom,
    path: "/rooms/room_created/:roomCode?/:roomName?/:roomType?",
    exact: "true",
  },
  JoinRoom: {
    component: JoinRoom,
    path: "/rooms/join",
    exact: true,
  },
  RoomLeagueScreen: {
    component: RoomLeagueScreen,
    path: "/room/:roomId?/:leagueId?/:roomNameType?/:roomCode?",
    exact: true,
  },
  RoomPredictionsScreen: {
    component: RoomPredictionsScreen,
    path: "/room/prediction/:roomId?",
    exact: true,
  },
  LoginScreen: {
    component: LoginScreen,
    path: "/login",
    exact: true,
  },
  Standings: {
    component: Standings,
    path: "/standings/:leagueId?",
    exact: true,
  },
  Home: {
    component: HomeScreen,
    path: "/",
    exact: true,
  },
  MatchScreen: {
    component: MatchScreen,
    path: "/match/:matchId?/:leagueId?/:shield?/:leagueName?",
    exact: true,
  },
  Second: {
    component: SecondScreen,
    path: "/second",
  },
  User: {
    component: UserScreen,
    path: "/user/:name?",
    exact: true,
  },
  DasModal: {
    component: DasModalScreen,
    path: "*/dasmodal",
    modal: true,
  },
  AlertDialogWithConfirmButton: {
    component: AlertDialogWithConfirmButton,
    path: "*/message",
    modal: true,
  },
};

let { height, width } = Dimensions.get("window");
EStyleSheet.build({
  $rem: width / 250,
});

class App extends Component {
  state = {
    showProfileMenu: false,
    showLanguageMenu: false,
    is_visible: false,
    windowWidth: 1000,
    stateHeight: "100vh",
    choosenLanguage: { image: flagEn, language: "English", initials: "En" },
    reactRem: 6,
    adverts: null,
  };

  onDismissMenu = () => {
    if (this.state.showProfileMenu) {
      this.onShowProfileMenu();
    } else if (this.state.showLanguageMenu) {
      this.onShowLanguageMenu();
    }
  };

  onLayout = (e) => {
    this.setState({
      width: e.nativeEvent.layout.width,
      height: e.nativeEvent.layout.height,
      x: e.nativeEvent.layout.x,
      y: e.nativeEvent.layout.y,
    });
  };
  toggleVisibility() {
    if (window.pageYOffset > 100) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }

  onShowProfileMenu = () => {
    this.setState({ showProfileMenu: !this.state.showProfileMenu });
  };

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  onSetHeight = (height) => {
    this.setState({ stateHeight: height });
  };

  onShowLanguageMenu = () => {
    this.setState({ showLanguageMenu: !this.state.showLanguageMenu });
  };
  onSetLanguage = (language) => {
    this.setState({ choosenLanguage: language });
  };

  onSetAdverts = (adverts) => {
    this.setState({ adverts: adverts });
  };
  componentDidMount() {
    var scrollComponent = this;
    document.addEventListener("scroll", function (e) {
      scrollComponent.toggleVisibility();
    });
    // Your web app's Firebase configuration

    // var firebaseConfig = {
    //   apiKey: "AIzaSyD-hOBStNbJWmM7s8KjdhzP0zk6A9hN6BY",
    //   authDomain: "glowter-9eadd.firebaseapp.com",
    //   databaseURL: "https://glowter-9eadd.firebaseio.com",
    //   projectId: "glowter-9eadd",
    //   storageBucket: "",
    //   messagingSenderId: "514363847583",
    //   appId: "1:514363847583:web:afbfea6215e47ea3"
    // };
    // // Initialize Firebase
    // firebase.initializeApp(firebaseConfig);
    let { height, width } = Dimensions.get("window");
    // this.props.setValueConstant((window.innerWidth + window.innerHeight) / 350);
    this.setState({ windowWidth: window.innerWidth });
    window.addEventListener("resize", () => {
      EStyleSheet.build({
        $rem: width / 300,
      });
      //Proveri za witdh za mobilno
      this.forceUpdate();
      this.setState({ windowWidth: window.innerWidth });
      // this.props.setValueConstant((window.innerWidth + window.innerHeight) / 350);
    });
  }

  onChangeReactRem = (reactRem, loggedUserId) => {
    this.setState({ reactRem: reactRem, loggedUserId: loggedUserId });
  };

  render() {
    // localStorage.clear();
    const pageLocation = window.location.href;
    const { choosenLanguage, windowWidth, reactRem, loggedUserId } = this.state;
    // const { reactRem } = this.props;

    const mainRoutContainerStyle = [styles.mainRoutContainer];
    if (pageLocation.includes("chat") && loggedUserId === null) {
      mainRoutContainerStyle.push({ width: "86%" });
    }
    if (windowWidth < 959) {
      mainRoutContainerStyle.push({ width: "100%" });
    }

    const routContainerStyle = [
      styles.routContainer,
      { marginTop: 9.5 * reactRem },
    ];
    if (loggedUserId === null) {
      routContainerStyle.push({ marginTop: 9.5 * reactRem });
    }
    if (
      loggedUserId === null &&
      (localStorage.getItem("token") ||
        window.location.href.includes("login") ||
        window.location.href.includes("forgotpassword") ||
        window.location.href.includes("chooseleagues"))
    ) {
      routContainerStyle.push({ marginTop: 9.5 * reactRem });
    }
    const noTokenContainerStyle = [
      styles.noTokenContainer,
      {
        height: 25 * reactRem,
      },
    ];
    const noTokenTextContainerStyle = [
      styles.noTokenTextContainer,
      { height: 15 * reactRem },
    ];
    const newHereTextStyle = [styles.newHereText, { fontSize: 2.5 * reactRem }];
    const whiteTextStyle = [styles.whiteText, { fontSize: 3 * reactRem }];
    if (sessionStorage.getItem("splash") !== null) {
      return (
        <TouchableWithoutFeedback
          style={{
            flex: 1,
            width: "100%",
            backgroundColor: globalStyles.backgroundGrey,
          }}
          onPress={() => this.onDismissMenu()}
        >
          <View
            style={{
              flex: 1,
              width: "100%",
              backgroundColor: globalStyles.backgroundGrey,
              cursor: "default",
            }}
          >
            <GetHeight
              windowWidth={windowWidth}
              onSetHeight={this.onSetHeight}
              onChangeReactRem={this.onChangeReactRem}
              onSetAdverts={this.onSetAdverts}
              stateAdverts={this.state.adverts}
            />
            <TopNav
              windowWidth={windowWidth}
              onShowMenu={this.onShowProfileMenu}
              onShowLanguageMenu={this.onShowLanguageMenu}
              choosenLanguage={choosenLanguage}
              urlLenght={pageLocation.split("/").length}
            />
            {this.state.showLanguageMenu ? (
              <LangaugeMenu
                onShowMenu={this.onShowLanguageMenu}
                onSetLanguage={this.onSetLanguage}
              />
            ) : null}
            <View
              style={{
                flex: 1,
                width: "100%",
                flexDirection: "row",
                margin: "auto",
              }}
            >
              <View style={{ width: "100%", margin: "auto" }}>
                {pageLocation.includes("login") ||
                window.location.href.includes("forgotpassword") ||
                window.location.href.includes("chooseleagues") ||
                pageLocation.includes("profile") ||
                pageLocation.includes("tutorial") ||
                pageLocation.includes("special") ? (
                  <View style={routContainerStyle}>
                    {pageLocation.includes("special") && windowWidth > 959 ? (
                      <Image
                        source={VerticalBanner}
                        style={styles.rightBanner}
                      />
                    ) : null}
                    <View
                      style={
                        pageLocation.includes("special")
                          ? {
                              width: windowWidth > 959 ? "47%" : "100%",
                              minHeight: "100vh",
                              margin: "auto",
                              justifyContent: "center",
                              alignItems: "center",
                            }
                          : {
                              width: "100%",
                              minHeight: "100vh",
                              margin: "auto",
                              justifyContent: "center",
                              alignItems: "center",
                            }
                      }
                      onLayout={this.onLayout}
                    >
                      {WebRoutesGenerator({ routeMap })}
                    </View>
                    {pageLocation.includes("special") && windowWidth > 959 ? (
                      <Image
                        source={VerticalBanner}
                        style={styles.bannerContainer}
                      />
                    ) : null}
                  </View>
                ) : (
                  <View style={routContainerStyle}>
                    {windowWidth > 959 ? (
                      <LeagueAd
                        towerLeft={true}
                        banner={
                          this.state.adverts ? this.state.adverts.towerLeft : []
                        }
                        navigation={this.props.navigation}
                      />
                    ) : null}
                    {windowWidth > 959 ? (
                      <View style={styles.bannerShadow} />
                    ) : null}
                    <View
                      style={{
                        flexDirection: "column",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {localStorage.getItem("token") ||
                      window.location.href.includes("login") ||
                      pageLocation.includes("gamerules") ||
                      pageLocation.includes("contactus") ||
                      pageLocation.includes("termsnconditions") ||
                      pageLocation.includes("privacypolicy") ||
                      window.location.href.includes("forgotpassword") ||
                      window.location.href.includes("chooseleagues") ? (
                        <LeagueAd
                          background={true}
                          banner={
                            this.state.adverts
                              ? this.state.adverts.backGround
                              : []
                          }
                          navigation={this.props.navigation}
                        />
                      ) : (
                        <View style={noTokenContainerStyle}>
                          <View style={styles.container}>
                            <Image style={styles.image} source={Banner} />
                          </View>
                          <View style={noTokenTextContainerStyle}>
                            <Text style={newHereTextStyle}>New here?</Text>
                            <Text style={whiteTextStyle}>
                              Create your account and join Glowter
                            </Text>
                            <JoinNowBtn />
                          </View>
                        </View>
                      )}
                      <View
                        style={{
                          flexDirection: "row",
                          width: "100%",
                          margin: "auto",
                          justifyContent: "center",
                        }}
                      >
                        <View
                          style={mainRoutContainerStyle}
                          onLayout={this.onLayout}
                        >
                          {WebRoutesGenerator({ routeMap })}
                        </View>
                        {windowWidth > 959 ? (
                          <View style={{ width: "1%" }} />
                        ) : null}
                        {windowWidth < 959 ||
                        pageLocation.includes("gamerules") ||
                        pageLocation.includes("contactus") ||
                        pageLocation.includes("termsnconditions") ||
                        pageLocation.includes("privacypolicy") ||
                        (pageLocation.includes("chat") &&
                          loggedUserId === null) ? null : (
                          <View style={styles.specialGamesContainer}>
                            <SpecialGamesScreen />
                          </View>
                        )}
                      </View>
                    </View>
                    {windowWidth > 959 ? (
                      <View style={styles.bannerShadow} />
                    ) : null}
                    {windowWidth > 959 ? (
                      <LeagueAd
                        towerRight={true}
                        banner={
                          this.state.adverts
                            ? this.state.adverts.towerRight
                            : []
                        }
                        navigation={this.props.navigation}
                      />
                    ) : null}
                  </View>
                )}
                {this.state.is_visible ? (
                  <GoUpArrow goOnTop={this.goToTop} />
                ) : null}
                <Footer />
              </View>
            </View>
            <ModalContainer />
          </View>
        </TouchableWithoutFeedback>
      );
    } else {
      return (
        <View style={mainRoutContainerStyle}>
          {WebRoutesGenerator({ routeMap })}
        </View>
      );
    }
  }
}

export default App;

const styles = EStyleSheet.create({
  container: {
    flex: 1,
    zIndex: 0,
    top: 0,
    left: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: globalStyles.backgroundGrey,
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },
  noTokenContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    height: "25rem",
  },
  noTokenTextContainer: {
    zIndex: 1,
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    height: "15rem",
  },
  newHereText: {
    color: globalStyles.activeBlue,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: "2.5rem",
  },
  whiteText: {
    color: globalStyles.white,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: "3rem",
  },
  routContainer: {
    width: "100%",
    flex: 1,
    minHeight: "100vh",
    flexDirection: "row",
    justifyContent: "center",
  },
  bannerContainer: {
    width: "12%",
    height: "100vh",
    position: "fixed",
    zIndex: 1,
    left: "88%",
  },
  rightBanner: {
    width: "12%",
    height: "100vh",
    position: "fixed",
    zIndex: 1,
    right: "88%",
  },
  mainRoutContainer: {
    width: "47%",
    minHeight: "100vh",
  },
  specialGamesContainer: {
    width: "28%",
    minHeight: "250vh",
  },
  bannerShadow: {
    width: "12%",
    flex: 1,
  },
});
