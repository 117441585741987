import {
  SET_LIVE_MATCH_DATA,
  SET_LIVE_MATCH_LINEUPS,
  RESET_LIVE_SCORE,
} from "../actions/actionTypes";

const initialState = {
  livescore: null,
  rooms: null,
  lineups: null,
};
const futureMatchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIVE_MATCH_DATA: {
      return {
        ...state,
        livescore: action.data.liveScore,
        rooms: action.data.liveScore.rooms,
      };
    }
    case SET_LIVE_MATCH_LINEUPS: {
      return { ...state, lineups: action.data };
    }
    case RESET_LIVE_SCORE: {
      return { ...state, livescore: null, rooms: null };
    }
    default:
      return state;
  }
};

export default futureMatchReducer;
