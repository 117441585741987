import React from "react";
import {
  View,
  Text,
  Image,
  ScrollView,
  TouchableOpacity,
  BackHandler,
} from "react-native";
import DefaulWhiteButton from "../../Common/components/UI/DefaultWhiteButton";
import LoginInputText from "../../Common/components/UI/LoginInputText";
import FacebookLogo from "../../assets/fb_logo.png";
import FacebookLogin from "react-facebook-login";
import {
  tryAuth,
  authSetToken,
  authSetUserId,
  faceBookLogin,
  GoogleLogin,
  onOpenRegister,
} from "../actions/auth";
import { setError } from "../../Common/store/actions/errors";
import { connect } from "react-redux";
import {
  getGenders,
  getNationalities,
  getLeagues,
  setRegisterData,
} from "../actions/auth";
import { getUserProfile } from "../../Common/Drawer/actions/profile";
import GoogleLoginButton from "../UI/GoogleLoginButton";
import MyDatePicker from "../../Common/components/UI/MyDatePicker";
import DefaultInput from "../../Common/components/UI/DefaultInput";
import PopupPicker from "../../Common/components/UI/PopupPicker";
import TickImg from "../../assets/white_tick.png";
import { uiStartLoading } from "../../Common/store/actions/ui";
import { getHomeScreenData } from "../../Home/actions/homeScreenLeagues";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { loginStyles } from "../styles/styles";

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this._bootstrapAsync();
  }

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  _bootstrapAsync = () => {
    if (localStorage.getItem("userId")) {
      this.props.authSetUserId(Number(localStorage.getItem("userId")));
      this.props.getUserProfile(Number(localStorage.getItem("userId")));
    }
    if (localStorage.getItem("token")) {
      this.props.authSetToken(localStorage.getItem("token"));
    }
  };

  state = {
    email: null,
    password: null,
    dataLoaded: false,
    activeButtonId: 0,
    regEmail: null,
    regPassword: null,
    confirmPassword: null,
    firstName: null,
    username: null,
    lastName: null,
    dateOfBirth: null,
    genderId: -1,
    nationalityId: -1,
    leagues: [],
    isDateChoosen: false,
    currentDate: null,
    isGenderChoosen: false,
    isNationalityChoosen: false,
    acceptTerms: false,
    emailIncorrect: false,
    passIncorrect: false,
    confirmPassIncorrect: false,
    firstNameIncorrect: false,
    usernameIncorrect: false,
    lastNameIncorrect: false,
    choosenDateIncorrect: false,
    choosenGenderIncorrect: false,
    choosenNationalityIncorrect: false,
    singInPress: false,
    date: new Date(),
  };

  emailChangedHandler = (val) => {
    this.setState({ email: val });
  };
  passwordChangedHandler = (val) => {
    this.setState({ password: val });
  };

  regEmailChangedHandler = (val) => {
    this.setState({ regEmail: val });
    if (this.state.regEmail) {
      this.setState({ emailIncorrect: false });
    }
  };
  regPasswordChangedHandler = (val) => {
    this.setState({ regPassword: val });
    if (this.state.regPassword) {
      this.setState({ passIncorrect: false });
    }
  };
  confirmPasswordChangedHandler = (val) => {
    this.setState({ confirmPassword: val });
    if (this.state.confirmPassword) {
      this.setState({ confirmPassIncorrect: false });
    }
  };
  firstNameChangedHandler = (val) => {
    this.setState({ firstName: val });
    if (this.state.firstName) {
      this.setState({ firstNameIncorrect: false });
    }
  };

  usernameChangedHandler = (val) => {
    this.setState({ username: val });
    if (this.state.username) {
      this.setState({ usernameIncorrect: false });
    }
  };
  lastNameChangedHandler = (val) => {
    this.setState({ lastName: val });
    if (this.state.lastName) {
      this.setState({ lastNameIncorrect: false });
    }
  };

  changeIsGenderChoosenBtn = (open) => {
    const { isNationalityChoosen } = this.state;
    this.setState({ isGenderChoosen: !open });
    if (isNationalityChoosen) {
      this.setState({ isNationalityChoosen: false });
    }
  };

  changeIsNationalityChoosenBtn = (open) => {
    const { isGenderChoosen } = this.state;
    this.setState({ isNationalityChoosen: !open });
    if (isGenderChoosen) {
      this.setState({ isGenderChoosen: false });
    }
  };

  chageAcceptTerms = (open) => {
    this.setState({ acceptTerms: !open });
  };

  onDateChange = (date) => {
    let covertedDate = moment.utc(date).format("YYYY-MM-DD");
    this.setState({ currentDate: covertedDate });
    this.setState({ choosenDateIncorrect: false });
  };

  onPickGender = (gender) => {
    this.setState({ genderId: gender });
    this.changeIsGenderChoosenBtn(this.state.isGenderChoosen);
    this.setState({ choosenGenderIncorrect: false });
  };

  onPickNationality = (nationality) => {
    this.setState({ nationalityId: nationality });
    this.changeIsNationalityChoosenBtn(this.state.isNationalityChoosen);
    this.setState({ choosenNationalityIncorrect: false });
  };
  generateDate() {
    if (this.state.currentDate) {
      let date = this.state.currentDate.split("-");
      this.setState({ dateOfBirth: date[0] + "-" + date[1] + "-" + date[2] });
    }
  }

  measurement = (element) => {};

  checkForEmptyFields() {
    const {
      regEmail,
      regPassword,
      confirmPassword,
      firstName,
      username,
      lastName,
      currentDate,
      genderId,
      nationalityId,
      acceptTerms,
    } = this.state;

    if (!regEmail) {
      this.setState({
        emailIncorrect: true,
        error: "Please provide non empty email address",
      });
      this.measurement(this.refs.email);
      if (currentDate) {
        this.generateDate();
      }
      return false;
    }
    if (regEmail) {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(regEmail) === false) {
        this.setState({
          emailIncorrect: true,
          error: "Please provide correct email address",
        });
        this.measurement(this.refs.email);
        return false;
      }
    }
    if (!regPassword) {
      this.setState({
        passIncorrect: true,
        error: "Please provide non empty password",
      });
      this.measurement(this.refs.password);
      return false;
    }
    if (regPassword.length < 6) {
      this.setState({
        passIncorrect: true,
        error: "Please provide more secure password",
      });
      this.measurement(this.refs.password);
      return false;
    }
    if (!confirmPassword) {
      this.setState({
        confirmPassIncorrect: true,
        error: "Please provide non empty confirm password",
      });
      this.measurement(this.refs.confirmPassword);
      return false;
    }
    if (regPassword !== confirmPassword) {
      this.setState({
        confirmPassIncorrect: true,
        error: "The confirm password do not match",
      });
      this.measurement(this.refs.confirmPassword);
      return false;
    }
    if (!firstName) {
      this.setState({
        firstNameIncorrect: true,
        error: "Please provide non empty first name",
      });
      this.measurement(this.refs.firstName);
      return false;
    }
    if (!lastName) {
      this.setState({
        lastNameIncorrect: true,
        error: "Please provide non empty surname",
      });
      this.measurement(this.refs.lastName);
      return false;
    }
    if (!username) {
      this.setState({
        usernameIncorrect: true,
        error: "Please provide non empty username",
      });
      this.measurement(this.refs.username);
      return false;
    }

    if (!currentDate) {
      this.setState({
        choosenDateIncorrect: true,
        error: "Please select birthday",
      });
      return false;
    }

    if (genderId === -1) {
      this.setState({
        choosenGenderIncorrect: true,
        error: "Please select gender",
      });
      this.measurement(this.refs.gender);
      return false;
    }
    if (nationalityId === -1) {
      this.setState({
        choosenNationalityIncorrect: true,
        error: "Please select country",
      });
      this.measurement(this.refs.nationality);
      return false;
    }
    if (!acceptTerms) {
      this.measurement(this.refs.nationality);
      return false;
    }
    this.setState({
      emailIncorrect: false,
      usernameIncorrect: false,
      passIncorrect: false,
      confirmPassIncorrect: false,
      firstNameIncorrect: false,
      lastNameIncorrect: false,
      error: null,
    });
    return true;
  }

  loginHandler = () => {
    this.setState({ singInPress: true });
    const data = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.onTryAuth(data);
  };

  //Added it to redux the data of the user
  registerHandler = () => {
    if (this.checkForEmptyFields() && this.state.acceptTerms) {
      this.props.setRegisterData({
        email: this.state.regEmail,
        password: this.state.regPassword,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        dateOfBirth: this.state.dateOfBirth,
        username: this.state.username,
        gender: this.state.genderId + 1,
        nationality: this.state.nationalityId + 1,
      });
      this.props.navigation.navigate("ChooseLeagues");
    }
  };

  async componentDidUpdate(prevProps) {
    const { leagues, genders, nationalities } = this.props;
    const data = await this.performTimeConsumingTask();
    if (leagues && genders && nationalities && !this.state.dataLoaded) {
      if (data !== null) {
        this.setState({ dataLoaded: true });
      }
    }
    if (this.props.isNewUser) {
      this.props.navigation.navigate("ChooseLeagues", {
        addLeague: true,
        isNewUser: true,
        leaveRooms: false,
      });
    }
    if (
      this.props.token &&
      this.props.token !== prevProps.token &&
      !this.props.isNewUser
    ) {
      this.props.getHomeScreenData("123123");
      if (data !== null) {
        this.setState({ dataLoaded: true });
        this.props.navigation.navigate("Home");
      }
    }
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
    if (this.state.singInPress && this.props.error) {
      this.setState({ singInPress: false });
    }
    if (this.state.singInPress && prevProps.error !== this.props.error) {
      this.setState({ singInPress: false });
    }
  }
  performTimeConsumingTask = async () => {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve("result");
      }, 3000)
    );
  };
  componentWillMount() {
    BackHandler.addEventListener("hardwareBackPress", function () {
      return true;
    });
  }
  componentDidMount() {
    this.goToTop();
    if (this.props.register) {
      this.setState({ activeButtonId: 1 });
    }
    this.props.getGenders();
    this.props.getNationalities();
    this.props.getLeagues();
    this.props.onOpenRegister(false, false);
  }

  initUser(token) {
    fetch(
      "https://graph.facebook.com/v2.5/me?fields=email,name,friends&access_token=" +
        token
    )
      .then((response) => response.json())
      .then((json) => {
        // Some user object has been set up somewhere, build that user here
      })
      .catch(() => {});
  }

  onFbResponse = (response) => {
    const { onFaceBookLogin } = this.props;
    //TODO: send login with social network request to Krasi (it will register as well)
    let data;
    if (response.status != "unknown") {
      data = {
        email: response.email,
        name: response.name,
        photo_url: response.picture.data.url,
        facebook_id: response.id,
        access_token: response.accessToken,
      };
      onFaceBookLogin(data);
    }
  };

  responseGoogle = (response) => {
    if (response.googleId && response.tokenId) {
      let data = {
        google_id: response.googleId,
        access_token: response.tokenId,
      };
      this.props.GoogleLogin(data);
    }
  };

  onPressButton = (acceptTerms) => {
    this.chageAcceptTerms(acceptTerms);
    this.generateDate();
  };

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.loginHandler();
    }
  };

  render() {
    const { genders, nationalities, reactRem, classes } = this.props;
    if (!genders || !nationalities) {
      return null;
    }
    const {
      genderId,
      nationalityId,
      acceptTerms,
      emailIncorrect,
      passIncorrect,
      confirmPassIncorrect,
      usernameIncorrect,
      firstNameIncorrect,
      lastNameIncorrect,
      choosenDateIncorrect,
      choosenGenderIncorrect,
      choosenNationalityIncorrect,
      error,
      activeButtonId,
      singInPress,
    } = this.state;

    return (
      <ScrollView style={loginStyles.scrollViewStyle} ref="_scrollView">
        <View style={loginStyles.container}>
          <View style={loginStyles.signInRegisterContainer}>
            <TouchableOpacity
              style={loginStyles.buttonContainer}
              onPress={() => this.setState({ activeButtonId: 0 })}
            >
              <Text
                style={
                  activeButtonId === 0
                    ? loginStyles.activeText
                    : loginStyles.inactiveText
                }
              >
                Sign in
              </Text>
            </TouchableOpacity>
            <View style={loginStyles.borderContainer} />
            <TouchableOpacity
              style={loginStyles.buttonContainer}
              onPress={() => this.setState({ activeButtonId: 1 })}
            >
              <Text
                style={
                  activeButtonId === 1
                    ? loginStyles.activeText
                    : loginStyles.inactiveText
                }
              >
                Sign up
              </Text>
            </TouchableOpacity>
          </View>
          {activeButtonId === 0 ? (
            <View style={loginStyles.activeButtonContainer}>
              <View style={loginStyles.inputFieldsContainer}>
                <LoginInputText
                  onKeyPress={this.keyPressed}
                  placeholderText="E-mail or Username"
                  inputValue={this.state.email}
                  onChangeText={this.emailChangedHandler}
                  keyboardType={"email-address"}
                  onFocus={() => this.props.setError("")}
                  style={loginStyles.firstField}
                />
                <LoginInputText
                  onKeyPress={this.keyPressed}
                  placeholderText="Password"
                  inputValue={this.state.password}
                  onChangeText={this.passwordChangedHandler}
                  onFocus={() => this.props.setError("")}
                  password={true}
                  style={loginStyles.lastField}
                />
              </View>
              <View style={loginStyles.errorContainer}>
                {error ? (
                  <Text style={loginStyles.errorMessage}>{error}</Text>
                ) : null}
              </View>
            </View>
          ) : (
            <View style={loginStyles.activeButtonContainer}>
              <View style={loginStyles.inputFieldsContainer}>
                <DefaultInput
                  placeholderText={"E-mail address"}
                  inputValue={this.state.regEmail}
                  onChangeText={this.regEmailChangedHandler}
                  keyboardType={"email-address"}
                  correctInput={emailIncorrect}
                  error={error}
                  ref="email"
                  firstField={true}
                  autoCapitalize="none"
                  onFocus={() => this.measurement(this.refs.email)}
                  editable={true}
                />
                <DefaultInput
                  placeholderText={"Password"}
                  inputValue={this.state.regPassword}
                  onChangeText={this.regPasswordChangedHandler}
                  password={true}
                  correctInput={passIncorrect}
                  error={error}
                  ref="password"
                  autoCapitalize="none"
                  onFocus={() => {
                    this.measurement(this.refs.password);
                  }}
                  keyboardType={"default"}
                  editable={true}
                />
                <DefaultInput
                  placeholderText={"Confirm Password"}
                  inputValue={this.state.confirmPassword}
                  onChangeText={this.confirmPasswordChangedHandler}
                  password={true}
                  correctInput={confirmPassIncorrect}
                  error={error}
                  ref="confirmPassword"
                  autoCapitalize="none"
                  onFocus={() => {
                    this.measurement(this.refs.confirmPassword);
                  }}
                  keyboardType={"default"}
                  editable={true}
                />
                <DefaultInput
                  placeholderText={"First name"}
                  inputValue={this.state.firstName}
                  onChangeText={this.firstNameChangedHandler}
                  correctInput={firstNameIncorrect}
                  error={error}
                  ref="firstName"
                  onFocus={() => {
                    this.measurement(this.refs.firstName);
                  }}
                  keyboardType={"default"}
                  editable={true}
                />
                <DefaultInput
                  placeholderText={"Surname"}
                  inputValue={this.state.lastName}
                  onChangeText={this.lastNameChangedHandler}
                  correctInput={lastNameIncorrect}
                  error={error}
                  ref="lastName"
                  onFocus={() => {
                    this.measurement(this.refs.lastName);
                  }}
                  keyboardType={"default"}
                  editable={true}
                />
                <DefaultInput
                  placeholderText={"Username"}
                  inputValue={this.state.username}
                  onChangeText={this.usernameChangedHandler}
                  correctInput={usernameIncorrect}
                  error={error}
                  ref="username"
                  onFocus={() => {
                    this.measurement(this.refs.username);
                  }}
                  keyboardType={"default"}
                  editable={true}
                />
                <MyDatePicker
                  currentDate={this.state.currentDate}
                  onDateChange={this.onDateChange}
                  currentYear={new Date().getFullYear()}
                  correctInput={choosenDateIncorrect}
                  error={error}
                />

                <PopupPicker
                  ref="gender"
                  title={
                    genderId > -1 ? this.props.genders[genderId].name : "Sex"
                  }
                  correctInput={choosenGenderIncorrect}
                  onPick={this.onPickGender}
                  dates={this.props.genders}
                  error={error}
                />
                <PopupPicker
                  ref="nationality"
                  title={
                    nationalityId > -1
                      ? this.props.nationalities[nationalityId].name
                      : "Nationality"
                  }
                  correctInput={choosenNationalityIncorrect}
                  onPick={this.onPickNationality}
                  style={loginStyles.lastField}
                  dates={this.props.nationalities}
                  error={error}
                />
              </View>
            </View>
          )}
          <DefaulWhiteButton
            reactRem={reactRem}
            onPress={() => {
              activeButtonId === 0
                ? this.loginHandler()
                : this.registerHandler();
            }}
            style={activeButtonId === 1 ? loginStyles.nextStepButton : null}
            acceptTerms={acceptTerms}
            activeButtonId={activeButtonId}
            loginOrRegister={true}
            singInPress={singInPress}
          >
            {activeButtonId === 0 ? "Sign In" : "NEXT STEP"}
          </DefaulWhiteButton>
          {activeButtonId === 0 ? (
            <View style={loginStyles.miniContainer}>
              <View style={loginStyles.forgotPasswordContainer}>
                <TouchableOpacity
                  onPress={() =>
                    this.props.navigation.navigate("ForgottenPassword")
                  }
                >
                  <Text style={loginStyles.forgotPasswordText}>
                    Forgot password?
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={loginStyles.defaultTextContainer}>
                <Text style={loginStyles.defaultText}> or</Text>
              </View>
            </View>
          ) : null}

          <View style={loginStyles.miniContainer}>
            <FacebookLogin
              appId="863238727372967"
              fields="name,email,picture"
              textButton=""
              cssClass={classes.fbLogin}
              callback={this.onFbResponse}
            />
            <TouchableOpacity style={loginStyles.fbButtonContainer}>
              <Image style={loginStyles.fbImage} source={FacebookLogo} />
              <Text style={loginStyles.fbText}>Sign in with Facebook</Text>
            </TouchableOpacity>
          </View>
          <GoogleLoginButton
            onFBLogin={() => this.responseGoogle}
            reactRem={reactRem}
          />
          {activeButtonId === 0 ? (
            <View style={loginStyles.defaultTextContainer}>
              <TouchableOpacity
                onPress={() => {
                  this.props.onOpenRegister(false, false);
                  this.props.navigation.navigate("Home");
                }}
              >
                <Text style={loginStyles.defaultText}>
                  proceed without registration{" "}
                </Text>
              </TouchableOpacity>
            </View>
          ) : null}
          {activeButtonId === 1 ? (
            <View style={loginStyles.tremNConditionsContainer}>
              <View style={loginStyles.termNConditionsTextContainer}>
                <TouchableOpacity
                  style={loginStyles.tickContainer}
                  onPress={() => this.onPressButton(acceptTerms)}
                >
                  {acceptTerms ? (
                    <Image source={TickImg} style={loginStyles.tickImg} />
                  ) : null}
                </TouchableOpacity>
                <Text style={loginStyles.iAgreeText}>
                  I agree to the Glowter
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={loginStyles.borderBottomContainer}
                  onPress={() =>
                    this.props.navigation.navigate("TermsNConditionsScreen")
                  }
                >
                  <Text style={loginStyles.termNConditionsBoldText}>
                    Terms {"&"} Conditions{" "}
                  </Text>
                </TouchableOpacity>
                <Text style={loginStyles.termNConditionsText}>and</Text>
                <TouchableOpacity
                  style={loginStyles.borderBottomContainer}
                  onPress={() =>
                    this.props.navigation.navigate("PrivacyPolicyScreen")
                  }
                >
                  <Text style={loginStyles.termNConditionsBoldText}>
                    {" "}
                    Privacy Policy{" "}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          ) : null}
        </View>
      </ScrollView>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    token: state.auth.token,
    register: state.auth.register,
    error: state.errorReducer.error,
    isLoading: state.loadingReducer.isLoading,
    isNewUser: state.auth.isNewUser,
    leagues: state.auth.leagues,
    genders: state.auth.genders,
    reactRem: state.homeScreenLeagues.reactRem,
    nationalities: state.auth.nationalities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAuth: (authData) => dispatch(tryAuth(authData)),
    authSetToken: (token) => dispatch(authSetToken(token)),
    authSetUserId: (userId) => dispatch(authSetUserId(userId)),
    setError: (error) => dispatch(setError(error)),
    getGenders: () => dispatch(getGenders()),
    getLeagues: () => dispatch(getLeagues()),
    getNationalities: () => dispatch(getNationalities()),
    getUserProfile: (userId) => dispatch(getUserProfile(userId)),
    onFaceBookLogin: (authData) => dispatch(faceBookLogin(authData)),
    GoogleLogin: (authData) => dispatch(GoogleLogin(authData)),
    getHomeScreenData: (firebaseToken) =>
      dispatch(getHomeScreenData(firebaseToken)),
    uiStartLoading: () => dispatch(uiStartLoading()),
    onOpenRegister: (loginscreen, register) =>
      dispatch(onOpenRegister(loginscreen, register)),
    setRegisterData: (registerData) => dispatch(setRegisterData(registerData)),
  };
};

const extraStyles = (theme) => ({
  fbLogin: {
    position: "absolute",
    zIndex: 1,
    opacity: 0,
    width: "100%",
    paddingTop: "20px",
    marginLeft: "-250px",
    paddingBottom: "20px",
    paddingLeft: "250px",
    paddingRight: "250px",
    cursor: "pointer",
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(extraStyles)(LoginScreen));
