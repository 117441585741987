import React from 'react';
import {Text, Image,View} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';
import WarningImg from '../../../assets/warning.png'
import {connect} from 'react-redux';

const matchResult = props =>{
    const{reactRem}=props;
    const matchResultStyle = [styles.matchResultStyle,{fontSize:2*reactRem}]
    const matchResultContainer = [styles.matchResultContainer,{ borderRadius:0.5*reactRem,
                                                                marginHorizontal:0.2*reactRem,
                                                                height:5.5*reactRem,
                                                                width:5.5*reactRem}];
    const borderStyle = [styles.border];
    let bet = props.matchResult || '-';
    
    if(props.predicted){
        if(props.guessed){
            matchResultContainer.push({backgroundColor:globalStyles.green});
        }else{
            matchResultContainer.push({backgroundColor:globalStyles.red});
        }
    }else{
        matchResultContainer.push({backgroundColor:globalStyles.unratedGrey});
        matchResultStyle.push({color:globalStyles.white})
    }
    
    if(props.form){
        matchResultStyle.push({color:globalStyles.white});
    }
    
    if(props.matchResult == 'W'){
        matchResultContainer.push({backgroundColor:globalStyles.green});
        if(props.id===0){
            borderStyle.push({borderBottomColor:globalStyles.green});
        }
    }
    if(props.matchResult == 'D'){
        matchResultContainer.push({backgroundColor:globalStyles.unratedGrey});
        matchResultStyle.push({color:globalStyles.white})
        if(props.id===0){
            borderStyle.push({borderBottomColor:globalStyles.unratedGrey});
        }
    }
    if(props.matchResult == 'L'){
        matchResultContainer.push({backgroundColor:globalStyles.red});
        if(props.id===0){
            borderStyle.push({borderBottomColor:globalStyles.red});
        }
    }
 
    if(props.loggedUser && props.matchResult==="-"&&!props.score){
        matchResultContainer.push({backgroundColor:globalStyles.white});
    }
    

    return(
        <View style={borderStyle}>
            
            <View style={matchResultContainer}>
            {props.loggedUser && props.matchResult==="-"&& !props.score?
                <Image source={WarningImg} style={[styles.warningImg,{height:4*reactRem,width:4*reactRem}]}/>:
                <Text style={matchResultStyle}>{bet}</Text>
            }
            </View>
        </View>);
    }

const styles = EStyleSheet.create({
    matchResultContainer:{
        borderRadius:"0.5rem",
        marginHorizontal:"0.2rem",
        height:"5.5rem",
        width:"5.5rem",
        alignItems:'center',
        justifyContent:'center',
    },
    matchResultStyle:{
        color:globalStyles.white,
        textAlign:'center',
        textAlignVertical:"center",
        fontFamily: globalStyles.fontUbuntuBold,
        fontSize:"2rem"
      },
      border:{
          borderBottomWidth:2,
          borderBottomColor:globalStyles.white,
      },
      warningImg:{
          height:"4rem",
          width:"4rem",
          resizeMode:"contain"
      }
      
});
const mapStateToProps = (state) => {
    return {
        reactRem:state.homeScreenLeagues.reactRem,
    }
  }
  
  export default connect(mapStateToProps,null)(matchResult);