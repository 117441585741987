import React from "react";
import { View, Text, Animated, TouchableOpacity } from "react-native";
import moment from "moment";
import ClearImg from "../../assets/clear.png";
import { notificationFieldStyles } from "../styles/styles";

const RightActions = ({ progress, dragX, onPress }) => {
  const scale = dragX.interpolate({
    inputRange: [-100, 0],
    outputRange: [1, 0],
    extrapolate: "clamp",
  });
  return (
    <TouchableOpacity
      style={notificationFieldStyles.rightAction}
      onPress={onPress}
    >
      <View style={notificationFieldStyles.rightAction}>
        <Animated.Image
          source={ClearImg}
          style={[notificationFieldStyles.clearImg, { transform: [{ scale }] }]}
        />
        <Animated.Text
          style={[
            notificationFieldStyles.actionText,
            { transform: [{ scale }] },
          ]}
        >
          Clear
        </Animated.Text>
      </View>
    </TouchableOpacity>
  );
};

class NotificationField extends React.Component {
  state = {
    lastSeenChatTime: 0,
  };

  convertTime = (time) => {
    let d = new Date(time);
    let result = (d.getHours() < 10 ? "0" : "") + d.getHours() + ":";
    result += (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    return result;
  };

  onOpenChat = () => {
    this.setState({ lastSeenChatTime: 0 });
  };

  render() {
    const { description, seen, created_at, reactRem } = this.props;
    let month = moment.utc(created_at, "YYYY-MM-DD hh:mm:ss").format("MMM DD");
    let today = moment.utc(moment(), "YYYY-MM-DD hh:mm:ss").format("MMM DD");

    if (seen) {
      return (
        <View style={notificationFieldStyles.notificationContainerSeen}>
          <View style={notificationFieldStyles.notificationImageContainer}>
            <Text style={notificationFieldStyles.dateText}>
              {month === today ? "Today" : month}
            </Text>
          </View>
          <View style={notificationFieldStyles.notificationTextContainer}>
            <Text style={notificationFieldStyles.notificationTextSeen}>
              {description}
            </Text>
          </View>
        </View>
      );
    }
    return (
      <View style={notificationFieldStyles.notificationContainer}>
        <View style={notificationFieldStyles.notificationImageContainer}>
          <Text style={notificationFieldStyles.dateTextStyle}>
            {month === today ? "Today" : month}
          </Text>
        </View>
        <View style={notificationFieldStyles.notificationTextContainer}>
          <Text style={notificationFieldStyles.notificationText}>
            {description}
          </Text>
        </View>
      </View>
    );
  }
}

export default NotificationField;
