import React from 'react';
import { StyleSheet, Image, Dimensions, View } from 'react-native';
import loggedBgImage from '../../assets/images/splash_logo.png';
import EStyleSheet from 'react-native-extended-stylesheet';
import AnimatableProgressbar from '../components/UI/AnimatableProgressbar';
import globalStyles from '../../globalStyles';
import Background from './Background';


const splashScreen = (props) => {
    const{reactRem}=props;
    let image = loggedBgImage;
    return (<View style={styles.container}>
        <Background/>
        <Image style={[styles.image, props.style,{width:15*reactRem,
                                                  height:15*reactRem,
                                                  paddingBottom:5*reactRem}]} source={image} />
        <View style={{justifyContent:"center",alignItems:"center",width:"50%",}}>
          <View style={[styles.progressBarContainer,{ height:reactRem,
                                                      borderRadius:0.5*reactRem,
                                                      marginTop:reactRem}]}>
            <AnimatableProgressbar style={[styles.progressBarPercent, { backgroundColor: globalStyles.activeBlue,borderRadius:0.5*reactRem,height:reactRem}]} widthTo={100} />
          </View>
        </View>
    </View>);

}


export default splashScreen;

const styles = EStyleSheet.create({
  container: {
    flex:1,
    justifyContent:"center",
    alignItems:"center",
    width: '100%',
  },
  image: {
    width: '15rem',
    height: '15rem',
    resizeMode: 'contain',
    paddingBottom:"5rem"    
  },
  progressBarPercent: {
    borderRadius: "0.5rem",
    height: "1rem",
  },
  progressBarContainer: {
    flexDirection: "row",
    width: "50%",
    height: "1rem",
    borderRadius: "0.5rem",
    marginTop: "1rem",
    backgroundColor: 'rgba(255,255,255, 0.1)',
  },
});

