import React from "react";
import {
  View,
  FlatList,
  TouchableOpacity,
  Image,
  Text,
  Platform,
  ActivityIndicator,
} from "react-native";
import { connect } from "react-redux";
import RoomLeagueTitleContainer from "../../Room/UI/RoomLeagueTitleContainer";
import {
  getSelectedSpecialRoomData,
  getPredictionsForSpecialRoom,
} from "../actions/specialGames";
import {
  getSpecialSelectedUserPredictionData,
  setSelectedUserPredictionData,
  setLeaguesWithGameWeeks,
} from "../../Room/actions/rooms";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import NavButton from "../../Common/components/UI/HomeNavButton";
import DownArrow from "../../assets/room_down_arrow.png";
import RoomRankingPlayerInfo from "../../Room/UI/RoomRankingPlayerInfo";
import LeagueList from "../../Common/LeagueList/LeagueList";
import SpecialSideMenu from "../UI/SpecialSideMenu";
import AnimationRoomLeagueTitleContainer from "../../Room/UI/AnimationRoomLeagueTitleContainer";
import { roomLeagueStyles } from "../../Room/styles/styles";

class SpcialGameRoomLeague extends React.Component {
  state = {
    selectBtn: false,
    leagueSelected: false,
    leagueId: -1,
    refreshing: false,
    activeButtonId: 0,
    loadMore: false,
    sideMenu: false,
    offset: 0,
    showFullPoints: false,
    search: false,
    scrollDisabled: false,
    userSelected: null,
    fromSideMenu: false,
    fullPeopleRankingInRoom: [],
    loadedMorePeople: true,
    currentPage: 1,
  };

  onSelectUser = (user) => {
    this.setState({ userSelected: user });
    if (user) {
      this.props.getSpecialSelectedUserPredictionData(
        this.props.navigation.getParam("roomId", "1"),
        this.props.leaguesGW,
        user.userId
      );
    }
  };
  onSearchIsPressed = (isOnSearchPressed) => {
    this.setState({ search: isOnSearchPressed });
  };

  onLoadMorePeople = () => {
    this.props.getSelectedSpecialRoomData(
      this.props.navigation.getParam("roomId", "1"),
      this.state.currentPage
    );
    this.setState({
      loadedMorePeople: true,
    });
  };

  onShowFullPoint = () => {
    this.setState({
      showFullPoints: !this.state.showFullPoints,
      sideMenu: false,
      fromSideMenu: true,
    });
  };
  onChangeShowPoints = () => {
    this.setState({ showFullPoints: !this.state.showFullPoints });
  };

  onOpenMenu = () => {
    this.setState({ sideMenu: !this.state.sideMenu });
  };

  changeActiveButton = (id) => {
    if (id === 1) {
      this.setState({ activeButtonId: id, sideMenu: false });
    } else {
      this.setState({ activeButtonId: id });
    }
  };
  _onRefresh = () => {
    this.setState({ refreshing: true });
    if (this.state.activeButtonId === 0) {
      this.props.getSelectedSpecialRoomData(
        this.props.navigation.getParam("roomId", "1")
      );
    } else {
      this.props.getPredictionsForSpecialRoom(
        this.props.navigation.getParam("roomId", "1")
      );
    }
  };

  changeSelectBtn = (open) => {
    this.setState({ selectBtn: !open });
  };

  onPickLeague = (id) => {
    this.changeSelectBtn(true);
    this.setState({ leagueSelected: true });
    this.setState({ leagueId: id });
  };

  onShowInfo = (roomId) => {
    this.onOpenMenu();
    this.props.navigation.navigate("JoinSpecialGamesScreen", {
      roomId: roomId,
      info: "info",
    });
  };
  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    this.goToTop();
    this.props.getSelectedSpecialRoomData(
      this.props.navigation.getParam("roomId", "1")
    );
    this.props.getPredictionsForSpecialRoom(
      this.props.navigation.getParam("roomId", "1")
    );
  }
  componentDidUpdate(prevProps) {
    const { matches, leaguesGW } = this.props;
    if (this.state.refreshing) {
      this.setState({ refreshing: false });
    }

    if (!leaguesGW && matches) {
      const leaguesWithGameWeeks = [];
      matches.forEach((league) => {
        leaguesWithGameWeeks.push({
          league_id: league.id,
          game_week: league.gameWeek,
        });
      });
      this.props.setLeaguesWithGameWeeks(leaguesWithGameWeeks);
    }
    if (
      this.state.loadedMorePeople &&
      !prevProps.specialRoom &&
      this.props.specialRoom
    ) {
      this.setState({
        fullPeopleRankingInRoom: this.state.fullPeopleRankingInRoom.concat(
          this.props.specialRoom.peopleRankingInRoom
        ),
        currentPage: this.state.currentPage + 1,
        loadedMorePeople: false,
      });
    }
    if (
      this.state.loadedMorePeople &&
      prevProps.specialRoom &&
      this.props.specialRoom.peopleRankingInRoom !=
        prevProps.specialRoom.peopleRankingInRoom
    ) {
      this.setState({
        fullPeopleRankingInRoom: this.state.fullPeopleRankingInRoom.concat(
          this.props.specialRoom.peopleRankingInRoom
        ),
        currentPage: this.state.currentPage + 1,
        loadedMorePeople: false,
      });
    }
  }

  onStaticsShow = (userId) => {
    this.props.navigation.navigate("StatisticsScreen", { userId: userId });
  };

  handleScroll = (offset) => {
    if (this.state.offset === 0) {
      this.setState({ offset: offset });
    }
    if (offset === 0 && this.state.offset != 0) {
      this.setState({ offset: 0 });
    }
  };

  render() {
    const { specialRoom, navigation, predictionGames } = this.props;

    const {
      activeButtonId,
      offset,
      currentPage,
      showFullPoints,
      fullPeopleRankingInRoom,
    } = this.state;

    if (!specialRoom || (specialRoom && fullPeopleRankingInRoom.length === 0)) {
      return <LoadingScreen />;
    }

    let leaguesInRoom;
    const roomNameType = "Special Game";
    let roomId = this.props.navigation.getParam("roomId", "1");
    let choosenLeagueIndex;
    let leagueWithSpace;

    if (this.props.navigation.getParam("leaguesInRoom", "1")) {
      leaguesInRoom = specialRoom.leagueInRoom;
      if (leaguesInRoom) {
        choosenLeagueIndex = leaguesInRoom.findIndex(
          (league) => Number(league.leagueId) === Number(this.state.leagueId)
        );
      }
      if (choosenLeagueIndex != -1) {
        if (/\s/.test(leaguesInRoom[choosenLeagueIndex].leagueName)) {
          leagueWithSpace = leaguesInRoom[choosenLeagueIndex].leagueName.split(
            " "
          );
        }
      }
    }

    if (!leaguesInRoom) {
      if (/\s/.test(specialRoom.roomName)) {
        leagueWithSpace = specialRoom.roomName.split(" ");
      }
    }

    return (
      <View style={roomLeagueStyles.container}>
        <View style={roomLeagueStyles.buttonContainer}>
          <NavButton
            id={0}
            activeButtonId={activeButtonId}
            onPress={() => this.changeActiveButton(0)}
            onGetData={() => null}
            title={"Predictions"}
          />
          <NavButton
            id={1}
            activeButtonId={activeButtonId}
            onPress={() => this.changeActiveButton(1)}
            onGetData={() => null}
            title={"Standings"}
          />
        </View>
        {activeButtonId === 1 ? (
          <View style={{ width: "100%" }}>
            <AnimationRoomLeagueTitleContainer
              user={this.props.userBeted}
              offset={offset}
              leaguesInRoom={leaguesInRoom}
              roomNameType={roomNameType}
              roomName={specialRoom.roomName}
              onOpenMenu={this.onOpenMenu}
            />
              <View style={roomLeagueStyles.roomPlayersContainer}>
                <View style={roomLeagueStyles.listItem}>
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Text style={roomLeagueStyles.leagueTitle}>Ranking</Text>
                  </View>
                </View>
                <View style={roomLeagueStyles.infoTableTextContainer}>
                  <View style={roomLeagueStyles.positionTextContainer}>
                    <Text style={roomLeagueStyles.infoTableText}>Pos.</Text>
                  </View>
                  <View style={roomLeagueStyles.nameTextContainer}>
                    <Text style={roomLeagueStyles.infoTableText}>Player</Text>
                  </View>
                  <View style={roomLeagueStyles.totalTextContainer}>
                    <Text style={roomLeagueStyles.totalText}>Total Pts. </Text>
                    <Image
                      source={DownArrow}
                      style={roomLeagueStyles.totalArrow}
                    />
                  </View>
                  <View style={roomLeagueStyles.lastTextContainer}>
                    <Text style={roomLeagueStyles.infoTableText}>Last</Text>
                  </View>
                </View>
              </View>
              {this.props.userBeted ? (
                <View style={roomLeagueStyles.infoTableContainer}>
                  <RoomRankingPlayerInfo
                    {...this.props.userBeted}
                    loggedUser={true}
                    onStaticsShow={this.onStaticsShow}
                    showFullPoints={showFullPoints}
                  />
                </View>
              ) : null}
              <View style={roomLeagueStyles.playersContainer}>
                <FlatList
                  style={{ width: "100%" }}
                  data={fullPeopleRankingInRoom}
                  showFullPoints={showFullPoints}
                  keyExtractor={(item, index) => "key" + index}
                  renderItem={({ item, index }) => (
                    <RoomRankingPlayerInfo
                      {...item}
                      fromSpecial={true}
                      maxLength={fullPeopleRankingInRoom.length}
                      index={index}
                      id={index + 1}
                      roomNameType={roomNameType}
                      onStaticsShow={this.onStaticsShow}
                      showFullPoints={showFullPoints}
                    />
                  )}
                />
              </View>
              {this.state.loadedMorePeople ? (
                <View>
                  <ActivityIndicator />
                </View>
              ) : null}
              {currentPage !== specialRoom.totalPages &&
              fullPeopleRankingInRoom.length >= 10 ? (
                <TouchableOpacity
                  style={roomLeagueStyles.loadMoreButtonContainer}
                  onPress={() => this.onLoadMorePeople()}
                >
                  <Text style={roomLeagueStyles.loadMoreButtonText}>
                    LOAD MORE
                  </Text>
                </TouchableOpacity>
              ) : null}
              <View style={roomLeagueStyles.placeContainer} />
          </View>
        ) : (
          <View
            refreshing={this.state.refreshing}
            onRefresh={this._onRefresh}
            style={roomLeagueStyles.fieldsContainer}
            scrollDisabled={this.state.scrollDisabled}
          >
            <RoomLeagueTitleContainer
              leaguesInRoom={leaguesInRoom}
              roomNameType={roomNameType}
              roomName={specialRoom.roomName}
              predictions={true}
              onSelectUser={this.onSelectUser}
              userSelected={this.state.userSelected}
              user={this.props.userBeted}
              setSelectedUserPredictionData={() =>
                this.props.setSelectedUserPredictionData(null)
              }
              onSearchIsPressed={this.onSearchIsPressed}
              specialGamePeopleRaking={specialRoom.peopleRankingInRoom}
            />
            <View style={roomLeagueStyles.predictionsContainer}>
              <FlatList
                data={predictionGames.leagues}
                userSelected={this.state.userSelected}
                search={this.state.search}
                keyExtractor={(item, index) => "key" + index}
                renderItem={({ item }) => (
                  <LeagueList
                    {...item}
                    roomId={roomId}
                    betPoints={true}
                    navigation={navigation}
                    isAllMatches={true}
                    specialRoom={true}
                    userSelected={this.state.userSelected}
                    search={this.state.search}
                  />
                )}
              />
            </View>
            <View style={roomLeagueStyles.placeContainer} />
          </View>
        )}
        {this.state.sideMenu ? (
          <SpecialSideMenu
            onShowInfo={() => this.onShowInfo(roomId)}
            showFullPoints={this.state.showFullPoints}
            onShowFullPoint={() => this.onShowFullPoint()}
          />
        ) : null}
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSelectedSpecialRoomData: (roomId, page) =>
      dispatch(getSelectedSpecialRoomData(roomId, page)),
    getPredictionsForSpecialRoom: (roomId) =>
      dispatch(getPredictionsForSpecialRoom(roomId)),
    getSpecialSelectedUserPredictionData: (roomId, leagues, userId) =>
      dispatch(getSpecialSelectedUserPredictionData(roomId, leagues, userId)),
    setSelectedUserPredictionData: (predictions) =>
      dispatch(setSelectedUserPredictionData(predictions)),
    setLeaguesWithGameWeeks: (leagues) =>
      dispatch(setLeaguesWithGameWeeks(leagues)),
  };
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    specialRoom: state.specialGames.specialRoom,
    predictionGames: state.specialGames.predictionGames,
    userBeted: state.specialGames.userBeted,
    leaguesGW: state.roomPrediction.leaguesGW,
    matches: state.roomPrediction.matches,
    roomId: state.roomPrediction.roomId,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpcialGameRoomLeague);
