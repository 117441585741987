import React, { useState, useEffect, useRef } from "react";
import { View, FlatList, Text, TouchableOpacity } from "react-native";
import { onOpenRegister } from "../../Login/actions/auth";
import { connect } from "react-redux";
import firebase from "../../fire";
import ChatRoomField from "../UI/ChatRoomField";
import {
  getJoinedRoomDataNoToken,
  getJoinedRoomData,
  getH2HRoomData,
  getPrivateRoomData,
} from "../../Room/actions/rooms";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import { setReadMessage } from "../actions/chat";
import LeagueAd from "../../Common/LeagueList/LeagueAd";
import { chatPageStyles } from "../styles/styles";
import { useSelector } from "react-redux";

const ChatPage = (props) => {
  const [rooms, setRooms] = useState([]);
  const [userRooms, setUserRooms] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const headToHeadRooms = useSelector(
    (state) => state.privateRooms.headToHeadRooms
  );
  const privateRooms = useSelector((state) => state.privateRooms.privateRooms);
  const userId = useSelector((state) => state.auth.userId);
  const readMessage = useSelector((state) => state.chatReducer.readMessage);
  const banner = useSelector((state) => state.roomLeague.adverts);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const onOpenRegister = () => {
    props.onOpenRegister(true, false);
    props.navigation.navigate("LoginScreen");
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    goToTop();
    if (userId) {
      props.getJoinedRoomData();
      props.getPrivateRoomData();
      props.getH2HRoomData();
    } else {
      props.getJoinedRoomDataNoToken();
      setIsModalVisible(true);
    }
    let dbRef = firebase.database().ref("rooms");
    dbRef.on("value", (val) => {
      let roomsFromDb = [];
      val.forEach((child) => {
        roomsFromDb.push(child.val());
      });
      setRooms(roomsFromDb);
    });
    return () => {
      if (isModalVisible) toggleModal();
    };
  }, [userId]);

  const prevRooms = usePrevious(rooms);
  const prevReadMessage = usePrevious(readMessage);

  useEffect(() => {
    if (prevReadMessage !== null && prevReadMessage !== readMessage) {
      props.setReadMessage(null);
    }
    if (
      (prevRooms !== null && prevRooms !== rooms) ||
      (userRooms.length === 0 && rooms.length !== 0)
    ) {
      let userRoomsLocal = [];
      if (headToHeadRooms) {
        for (var i = 0; i < rooms.length; i++) {
          for (var j = 0; j < headToHeadRooms.length; j++) {
            if (headToHeadRooms[j].nameOfRoom === rooms[i].name) {
              userRoomsLocal.push(rooms[i]);
              break;
            }
          }
        }
      }
      if (privateRooms) {
        for (var i = 0; i < rooms.length; i++) {
          for (var j = 0; j < privateRooms.length; j++) {
            if (privateRooms[j].nameOfRoom === rooms[i].name) {
              userRoomsLocal.push(rooms[i]);
              break;
            }
          }
        }
      }
      if (userRoomsLocal.length != 0) {
        setUserRooms(userRoomsLocal);
      }
    }
  }, [rooms, readMessage]);

  if (!userId) {
    return (
      <View style={chatPageStyles.container}>
        <View style={chatPageStyles.signInUpContainerStyle}>
          <TouchableOpacity onPress={() => onOpenRegister()}>
            <View style={chatPageStyles.signInContainerStyle}>
              <Text style={chatPageStyles.signInTextStyle}>SIGN IN</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onOpenRegister()}>
            <View style={chatPageStyles.signUpContainer}>
              <Text style={chatPageStyles.signUpTextStyle}>SIGN UP</Text>
            </View>
          </TouchableOpacity>
        </View>
        {banner && banner.length != 0 ? (
          <LeagueAd banner={banner} navigation={props.navigation} />
        ) : null}
      </View>
    );
  }
  if (!userRooms) {
    return <LoadingScreen />;
  }

  let roomsLocal;
  roomsLocal = userRooms.sort((room1, room2) => {
    return room2.lastMessage.time - room1.lastMessage.time;
  });

  return (
    <View style={chatPageStyles.container}>
      <View style={chatPageStyles.activeButtonContainer}>
        {userRooms && userRooms.length === 0 ? (
          <View style={chatPageStyles.noMessagesTextContainer}>
            <Text style={chatPageStyles.noMessagesTextStyle}>
              {"There are no messages"}
            </Text>
          </View>
        ) : (
          <FlatList
            style={chatPageStyles.chatContainerStyle}
            data={roomsLocal}
            keyExtractor={(item, index) => "key" + index}
            renderItem={({ item, index }) => (
              <ChatRoomField
                {...item}
                index={index}
                length={roomsLocal.length}
                userId={userId}
                onPress={() =>
                  props.navigation.navigate("MessagePage", {
                    roomName: item.name,
                  })
                }
              />
            )}
          />
        )}
      </View>
      {banner && banner.length != 0 ? (
        <LeagueAd banner={banner} navigation={props.navigation} />
      ) : null}
    </View>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJoinedRoomData: () => dispatch(getJoinedRoomData()),
    getJoinedRoomDataNoToken: () => dispatch(getJoinedRoomDataNoToken()),
    setReadMessage: (message) => dispatch(setReadMessage(message)),
    onOpenRegister: (loginscreen, register) =>
      dispatch(onOpenRegister(loginscreen, register)),
    getH2HRoomData: () => dispatch(getH2HRoomData()),
    getPrivateRoomData: () => dispatch(getPrivateRoomData()),
  };
};
export default connect(null, mapDispatchToProps)(ChatPage);
