import React from "react";
import {
  View,
  FlatList,
  ScrollView,
  Image,
  Text,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import globalStyles from "../../globalStyles";
import PredictionMatchField from "../UI/PredictionMatchField";
import {
  putBetsForLeagueByGameWeek,
  deletePredictions,
  getPredictionByLeagueData,
  setErrorMessage,
} from "../actions/roompredictions";
import LoadingScreen from "../../Common/components/UI/LoadingScreen";
import { setAlertDialogTitle } from "../../Common/store/actions/resources";
import AlertDialogWithConfirmButton from "../../Common/components/UI/AlertDialogWithConfirmButton";
import { predictMatchesStyles } from "../styles/styles";

let matchesStarted = [];
class PredictMatches extends React.Component {
  state = {
    predictions: [],
    isThereABet: false,
    isBetAdded: false,
    currentGameWeek: -1,
    showErrorMessage: false,
    matchesStartedIds: [],
  };

  onMatchesStarter = (matchId) => {
    if (matchesStarted.length === 0) {
      matchesStarted.push(matchId);
    }
    if (matchesStarted.length != 0) {
      const matchIndex = matchesStarted.findIndex(
        (match) => Number(match) === Number(matchId)
      );
      if (matchIndex === -1) {
        matchesStarted.push(matchId);
      }
    }
    this.setState({ matchesStartedIds: matchesStarted });
  };

  onDiscard = () => {
    this.setState({ predictions: [] });
    this.props.navigation.goBack();
  };

  onShowErroMessage = () => {
    if (!this.state.showErrorMessage) {
      this.onGetPredictionData(this.state.currentGameWeek, this.props.special);
    }

    this.setState({ showErrorMessage: !this.state.showErrorMessage });
  };

  componentDidMount() {
    matchesStarted = [];
    if (!this.props.predictionsByWeek) {
      this.props.getPredictionByLeagueData(
        this.props.navigation.getParam("leagueId", "1"),
        this.props.navigation.getParam("gameWeek", "1")
      );
    }
  }

  onPredict = (matchId, bet) => {
    const predictions = [...this.state.predictions];
    const betIndex = predictions.findIndex(
      (match) => Number(match.id) === Number(matchId)
    );
    let betInArray = { ...predictions[betIndex], matchId: matchId, bet: bet };
    predictions[betIndex] = betInArray;
    this.setState({ predictions: predictions });
    this.setState({ betIndex: betIndex });
  };

  onGetPredictionData = (gameWeek, special) => {
    if (special) {
      this.props.getPredictionSpecialRoomsData(
        this.props.navigation.getParam("roomId", "1"),
        this.props.navigation.getParam("leagueId", "1"),
        gameWeek
      );
    } else {
      this.props.getPredictionByLeagueData(
        this.props.navigation.getParam("leagueId", "1"),
        gameWeek
      );
    }
  };

  onPrevPage = () => {
    const { currentGameWeek } = this.state;
    this.setState({ currentGameWeek: Number(currentGameWeek) - 1 });
    return Number(currentGameWeek) - 1;
  };

  onNextPage = () => {
    const { currentGameWeek } = this.state;
    this.setState({ currentGameWeek: Number(currentGameWeek) + 1 });
    return Number(currentGameWeek) + 1;
  };

  onPutPredictions = () => {
    this.props.putBetsForLeagueByGameWeek(
      this.props.navigation.getParam("leagueId", "1"),
      this.props.navigation.getParam("gameWeek", "1"),
      this.state.predictions
    );
  };

  componentWillUnmount() {
    this.setState({ betIndex: null });
    this.setState({ isBetAdded: false });
    this.setState({ isThereABet: false });
  }

  onCloseGameRoom = () => {
    this.props.setErrorMessage(null);
  };

  componentDidUpdate(prevProps, prevState) {
    const { matchesStartedIds } = this.state;
    if (matchesStartedIds.length != 0) {
      let newPredictions = [...this.state.predictions];
      for (let index = 0; index < matchesStartedIds.length; index++) {
        let matchIndex = newPredictions.findIndex(
          (match) => Number(match.id) === Number(matchesStartedIds[index])
        );
        if (matchIndex != -1) {
          newPredictions.splice(matchIndex, 1);
        }
      }
      this.setState({ matchesStartedIds: [], predictions: newPredictions });
    }
    if (prevState.predictions.length === 0 && this.props.predictionsByWeek) {
      this.setState({ predictions: this.props.predictionsByWeek });
    }
    if (
      prevProps.predictionsByWeek &&
      prevProps.predictionsByWeek != this.props.predictionsByWeek &&
      !this.props.predictionsByWeek
    ) {
      this.props.navigation.goBack();
    }
    if (this.props.predictionsByWeek) {
      this.props.predictionsByWeek.forEach((match) => {
        if (match.bet && !this.state.isThereABet) {
          this.setState({ isThereABet: true });
        }
      });
    }
    if (this.state.currentGameWeek === -1) {
      this.setState({
        currentGameWeek: this.props.navigation.getParam("gameWeek", "1"),
      });
    }
    if (
      prevProps.errorMessage != this.props.errorMessage &&
      this.props.errorMessage != null
    ) {
      this.onShowErroMessage();
    }
  }

  onOpenMatchInfo = (matchId, score) => {
    const leagueId = this.props.navigation.getParam("leagueId", "1");
    this.props.navigation.navigate("MatchScreen", {
      matchId: matchId,
      leagueId: leagueId,
    });
  };

  render() {
    const { predictionsByWeek, token, errorMessage, reactRem } = this.props;
    const { currentGameWeek } = this.state;
    if (!predictionsByWeek) {
      return <LoadingScreen />;
    }
    const leagueImg =
      "//uploads/leagues/" + this.props.navigation.getParam("leagueImg", "1");
    const leagueName = this.props.navigation.getParam("leagueName", "1");
    const leagueId = this.props.navigation.getParam("leagueId", "1");

    return (
      <View style={predictMatchesStyles.container}>
        <ScrollView
          contentContainerStyle={predictMatchesStyles.fieldsContainer}
        >
          <View style={predictMatchesStyles.subtitleContainer}>
            <Text style={predictMatchesStyles.predictionScreenText}>
              Predict Matches
            </Text>
            <Text style={predictMatchesStyles.subtitleText}>
              Global/Private/H2H
            </Text>
          </View>
          <View style={predictMatchesStyles.dataContainer}>
            <View style={predictMatchesStyles.listItem}>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
                onPress={() =>
                  this.props.navigation.navigate("Standings", {
                    leagueId: leagueId,
                  })
                }
              >
                <View style={predictMatchesStyles.placeImageContainer}>
                  <Image
                    source={{ uri: globalStyles.BASE_URL + leagueImg }}
                    style={predictMatchesStyles.placeImage}
                  />
                </View>
                <Text style={predictMatchesStyles.leagueTitle}>
                  {leagueName}
                </Text>
              </TouchableOpacity>
              <View style={predictMatchesStyles.gameWeekContainer}>
                <View style={predictMatchesStyles.gameWeekContainer}>
                  <View style={predictMatchesStyles.gameWeekTextContainer}>
                    <Text style={predictMatchesStyles.gameWeekText}>
                      {"Game"}
                    </Text>
                    <Text style={predictMatchesStyles.gameWeekText}>
                      {"Week"}
                    </Text>
                  </View>
                  <View>
                    <Text style={predictMatchesStyles.gameWeekNumberText}>
                      {currentGameWeek}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={predictMatchesStyles.inputContainer}>
              <FlatList
                reactRem={reactRem}
                style={{ width: "100%" }}
                data={predictionsByWeek}
                keyExtractor={(item, index) => "key" + index}
                renderItem={({ item }) => (
                  <PredictionMatchField
                    {...item}
                    navigation={this.props.navigation}
                    showErrorMessage={this.state.showErrorMessage}
                    onPredict={this.onPredict}
                    predictions={this.state.predictions}
                    onMatchesStarter={this.onMatchesStarter}
                    onOpenMatchInfo={() =>
                      this.onOpenMatchInfo(item.id, item.homeTeamScore)
                    }
                  />
                )}
              />
            </View>
            {token && !this.props.isGameWeekPassed ? (
              <View style={predictMatchesStyles.discardSaveBtnContainer}>
                <TouchableOpacity
                  style={predictMatchesStyles.discardButtonContainer}
                  onPress={() => this.onDiscard()}
                >
                  <Text style={predictMatchesStyles.discardText}>DISCARD</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={predictMatchesStyles.saveButton}
                  onPress={() => this.onPutPredictions()}
                >
                  <Text style={predictMatchesStyles.saveText}>CONFIRM</Text>
                </TouchableOpacity>
              </View>
            ) : null}
          </View>
        </ScrollView>
        <AlertDialogWithConfirmButton
          isModalVisible={this.state.showErrorMessage}
          onChangeModalVisibility={this.onShowErroMessage}
          onCloseGameRoom={this.onCloseGameRoom}
          title={errorMessage}
          fromPredict={true}
          navigation={this.props.navigation}
        />
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    predictionsByWeek: state.predictionsByWeek.predictions,
    token: state.auth.token,
    errorMessage: state.predictionsByWeek.errorMessage,
    reactRem: state.homeScreenLeagues.reactRem,
    isGameWeekPassed: state.predictionsByWeek.isGameWeekPassed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    putBetsForLeagueByGameWeek: (leagueId, gameWeek, predictions) =>
      dispatch(putBetsForLeagueByGameWeek(leagueId, gameWeek, predictions)),
    deletePredictions: () => dispatch(deletePredictions()),
    getPredictionByLeagueData: (leagueId, gameWeek) =>
      dispatch(getPredictionByLeagueData(leagueId, gameWeek)),
    setErrorMessage: () => dispatch(setErrorMessage()),
    setAlertDialogTitle: (title) => dispatch(setAlertDialogTitle(title)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PredictMatches);
