import React from "react";
import { View, Text } from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles'
import MatchResult from './MatchResult';

class LastMatchesContainer extends React.Component {

  render() {
    const { form, homeTeamName, guestTeamName, leagueId, reactRem } = this.props;
    return (
      <View style={[styles.clubFormContainer, { marginTop: 3 * reactRem, paddingHorizontal: 2 * reactRem }]}>
        <View style={[styles.formTextContainer, { marginBottom: reactRem, paddingLeft: reactRem }]}>
          <Text style={[styles.formText, { fontSize: 2.6 * reactRem }]}>FORM</Text>
          <Text style={[styles.lastMatchesText, { fontSize: 2.2 * reactRem }]}> - Last 5 matches</Text>
        </View>
        <View style={[styles.lastMatchesContainer, {
          borderRadius: 1.5 * reactRem,
          paddingHorizontal: 1.5 * reactRem,
          paddingVertical: 2 * reactRem
        }]}>
          <View style={styles.clubsNameContainer}>
            <Text style={[styles.clubNameText, { fontSize: 2.4 * reactRem }]}>{homeTeamName}</Text>
            <Text style={[styles.clubNameText, { fontSize: 2.4 * reactRem }]}>{guestTeamName}</Text>
          </View>
          <View style={[styles.lastFiveMatchesContainer, { paddingHorizontal: -0.5 * reactRem, paddingVertical: 2 * reactRem }]}>
            <View style={styles.clubMatches}>
              {form.homeTeamLastFive.map((match, i) => <MatchResult matchResult={match} key={i} form={true} id={i} />)}
            </View>
            <View style={styles.clubMatches}>
              {form.guestTeamLastFive.map((match, i) => <MatchResult matchResult={match} key={i} form={true} id={i} />)}
            </View>
          </View>
          <View style={[styles.goalsScoredContainer, { paddingHorizontal: 1.5 * reactRem }]}>
            <Text style={[styles.goalsScoredText, { fontSize: 2.4 * reactRem }]}>{form.homeTeamGoalsScored}</Text>
            <Text style={[styles.goalsScoredInfoText, { fontSize: 2.4 * reactRem }]}>Goal Scored</Text>
            <Text style={[styles.goalsScoredText, { fontSize: 2.4 * reactRem }]}>{form.guestTeamGoalsScored}</Text>
          </View>
          <View style={[styles.goalsScoredContainer, { paddingHorizontal: 1.5 * reactRem }]}>
            <Text style={[styles.goalsScoredText, { fontSize: 2.4 * reactRem }]}>{form.homeTeamGoalsConceded}</Text>
            <Text style={[styles.goalsScoredInfoText, { fontSize: 2.4 * reactRem }]}>Goal Conceded</Text>
            <Text style={[styles.goalsScoredText, { fontSize: 2.4 * reactRem }]}>{form.guestTeamGoalsConceded}</Text>
          </View>
        </View>
      </View>
    );
  }
}


const styles = EStyleSheet.create({
  clubFormContainer: {
    marginTop: "3rem",
    paddingHorizontal: "2rem",
  },
  formTextContainer: {
    flexDirection: "row",
    marginBottom: "1rem",
    paddingLeft: "1rem",
  },
  formText: {
    color: globalStyles.textBlue,
    fontFamily: globalStyles.fontUbuntuBold,
    fontSize: '2.6rem'
  },
  lastMatchesText: {
    color: globalStyles.textGrey,
    fontFamily: globalStyles.fontUbuntuRegular,
    fontSize: "2.2rem"
  },
  lastMatchesContainer: {
    backgroundColor: globalStyles.white,
    borderRadius: "1.5rem",
    paddingHorizontal: "1.5rem",
    paddingVertical: "2rem"
  },
  clubsNameContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  clubNameText: {
    color: globalStyles.black,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: '2.4rem',
  },
  lastFiveMatchesContainer: {
    paddingHorizontal: "-0.5rem",
    paddingVertical: "2rem",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%"
  },
  clubMatches: {
    flexDirection: "row",
  },
  goalsScoredContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingHorizontal: "1.5rem"
  },
  goalsScoredText: {
    color: globalStyles.black,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: '2.4rem',
  },
  goalsScoredInfoText: {
    color: globalStyles.black,
    fontFamily: globalStyles.fontUbuntuMedium,
    fontSize: '2.4rem',
    opacity: 0.7
  },

});


export default LastMatchesContainer