import React from "react"
import {
    Text,
    View,
    TouchableOpacity
} from 'react-native';

import EStyleSheet from 'react-native-extended-stylesheet';
import globalStyles from '../../../globalStyles';

const twoFloatingButtons = props => {
    const { firstButtonText, secondButtonText } = props;
    const buttonStyle = [styles.button];
    if (props.style) {
        buttonStyle.push(props.style)
    }
    if (props.secondButtonText === "LEAVE") {
        buttonStyle.push({ backgroundColor: globalStyles.error });
    }
    return (
        <View style={styles.container}>
            <TouchableOpacity style={styles.discardButtonContainer}
                onPress={() => props.firstOnPress()} >
                <Text style={styles.discardText}>{firstButtonText}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={buttonStyle} onPress={() => props.secondOnPress()} >
                <Text style={styles.text}>{secondButtonText}</Text>
            </TouchableOpacity>
        </View>

    );

}

const styles = EStyleSheet.create({
    container: {
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: 12,
    },
    discardButtonContainer: {
        backgroundColor: globalStyles.white,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: "30%",
        padding: 6,
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderTopColor: globalStyles.backgroundGrey,
        borderLeftColor: globalStyles.backgroundGrey,
        borderBottomColor: globalStyles.backgroundGrey,

    },
    discardText: {
        fontSize: 17,
        color: globalStyles.textBlue,
        fontFamily: globalStyles.fontUbuntuBold,
        textAlign: "center"
    },
    button: {
        backgroundColor: globalStyles.activeBlue,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: "30%",
        padding: 6,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderTopColor: globalStyles.backgroundGrey,
        borderRightColor: globalStyles.backgroundGrey,
        borderBottomColor: globalStyles.backgroundGrey,

    },
    text: {
        fontSize: 17,
        color: globalStyles.white,
        fontFamily: globalStyles.fontUbuntuBold,
        textAlign: "center"
    },
    blurButton: {
        opacity: 0.5
    }
});

export default twoFloatingButtons;