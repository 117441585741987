import React from "react";
import { View, Text } from "react-native";
import Shield from "../../Common/components/UI/Shield";
import { specialGamesMatchFieldStyles } from "../styles/styles";

class SpecialGamesMatchField extends React.Component {
  render() {
    const { homeTeamName, awayTeamName, matchShield } = this.props;
    return (
      <View style={specialGamesMatchFieldStyles.pickerLeagueContainer}>
        <View style={specialGamesMatchFieldStyles.shieldContainer}>
          {matchShield ? (
            <Shield
              points={matchShield}
              style={specialGamesMatchFieldStyles.shieldImgView}
            />
          ) : (
            <View style={specialGamesMatchFieldStyles.shieldImgView} />
          )}
        </View>
        <View style={specialGamesMatchFieldStyles.matchesContainer}>
          <View style={specialGamesMatchFieldStyles.homeTeamContainer}>
            <Text style={specialGamesMatchFieldStyles.homeTeamText}>
              {homeTeamName}
            </Text>
          </View>
          <View style={specialGamesMatchFieldStyles.border}>
            <View style={specialGamesMatchFieldStyles.border} />
          </View>
          <View style={specialGamesMatchFieldStyles.awayTeamContainer}>
            <Text style={specialGamesMatchFieldStyles.awayTeamText}>
              {awayTeamName}
            </Text>
          </View>
        </View>
        <View style={specialGamesMatchFieldStyles.shieldContainer}></View>
      </View>
    );
  }
}

export default SpecialGamesMatchField;
