import React from "react";
import { Image, View, Text, FlatList, TouchableOpacity } from "react-native";
import globalStyles from "../../globalStyles";
import PredictionMatchField from "../../Prediction/UI/PredictionMatchField";
import { predictionSpecialGamesStyles } from "../styles/styles";

class PredictionSpecialGames extends React.Component {
  state = {
    currentGameWeek: -1,
  };

  onOpenMatchInfo = (matchId) => {
    const { leagueId, isPlaying, roomId } = this.props;
    if (!isPlaying) {
      this.props.navigation.navigate("MatchScreen", {
        matchId: matchId,
        leagueId: leagueId,
        shield: 0,
        leagueName: null,
        specialRoom: true,
        roomId: roomId,
      });
    } else {
      this.props.navigation.navigate("MatchScreen", {
        matchId: matchId,
        leagueId: leagueId,
      });
    }
  };

  checkIfGameWeekIsPassed = (games) => {
    const valueToReturn = (game) => {
      const matchDateToFind = game.matchDate.split("-");
      var date = new Date(
        matchDateToFind[1] + "/" + matchDateToFind[2] + "/" + matchDateToFind[0]
      );
      var matchDateMillSec = date.getTime();
      var currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      var currentDateMillSec = currentDate.getTime();

      return matchDateMillSec <= currentDateMillSec;
    };
    return games.every(valueToReturn);
  };

  render() {
    const { img, name, games, leagueId } = this.props;
    const isPassed = this.checkIfGameWeekIsPassed(games);
    return (
      <View style={predictionSpecialGamesStyles.container}>
        <View style={predictionSpecialGamesStyles.listItem}>
          <TouchableOpacity
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
            onPress={() =>
              this.props.navigation.navigate("Standings", {
                leagueId: leagueId,
              })
            }
          >
            <View style={predictionSpecialGamesStyles.placeImageContainer}>
              <Image
                source={{ uri: globalStyles.BASE_URL + img }}
                style={predictionSpecialGamesStyles.placeImage}
              />
            </View>
            <Text style={predictionSpecialGamesStyles.leagueTitle}>{name}</Text>
          </TouchableOpacity>
        </View>
        <View style={predictionSpecialGamesStyles.inputContainer}>
          <FlatList
            style={{ width: "100%" }}
            data={games}
            keyExtractor={(item, index) => "key" + index}
            renderItem={({ item }) => (
              <PredictionMatchField
                {...item}
                matchTime={item.matchTime}
                navigation={this.props.navigation}
                leagueId={leagueId}
                onPredictSpecial={this.props.onPredictSpecial}
                onOpenMatchInfo={() => this.onOpenMatchInfo(item.id)}
              />
            )}
          />
        </View>
        {!isPassed ? (
          <TouchableOpacity
            style={predictionSpecialGamesStyles.userMyPredictionsBtnContainer}
            onPress={() => this.props.onUseMyPredictions(leagueId)}
          >
            <Text
              style={predictionSpecialGamesStyles.userMyPredictionsPlusText}
            >
              +
            </Text>
            <Text style={predictionSpecialGamesStyles.userMyPredictionsBtnText}>
              Use my Global Predictions
            </Text>
          </TouchableOpacity>
        ) : (
          <Text
            style={predictionSpecialGamesStyles.userMyPredictionsBtnContainer}
          >
            {""}
          </Text>
        )}
      </View>
    );
  }
}

export default PredictionSpecialGames;
